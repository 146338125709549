import giftCardOrderSummaryPartial from "../../templates/includes/gift_cards/gift_card_order_summary_partial";
import {
  cardCreateParams,
  cardPurchaseParams,
  initialPurchaseParams,
} from "../../config/utils/utilsConfig";

export default class GiftCardPaymentController {
  constructor(
    $rootScope,
    $state,
    $q,
    GiftCardManager,
    User,
    OmnibarPointer,
    Payment,
    stripe
  ) {
    $rootScope.currentUser = User.getData();
    this.root = $rootScope;
    this.$q = $q;
    this.$state = $state;
    this.giftCard = GiftCardManager;
    this.omnibarPointer = OmnibarPointer;
    this.stripe = stripe;
    this.paymentService = Payment;
    this.giftCardOrderSummaryPartial = giftCardOrderSummaryPartial;
    this.creditCards = this.root.currentUser
      ? this.root.currentUser.credit_cards
      : [];
    this.creditCardForm = {};
    this.cardId = (this.creditCards || []).length
      ? this.creditCards[0].id
      : false;
    this.addPayment = !this.cardId;
    this.paymentTab = "card";
    this.allowAmazonPay = this.giftCard.allowAmazonPay;
    this.shownAmazonButton = false;
  }

  createGiftCard(params) {
    this.giftCard
      .createGiftCard(params)
      .then((res) => {
        this.giftCard.giftCard = res.data.gift_card;
        this.$state.go("gift-cards.confirmation");
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
        this.loading = false;
      });
  }

  // cta link
  goTo(state) {
    this.$state.go(state);
  }

  // cta purchase
  packagePurchase(token) {
    this.loading = true;
    this.paypalError = null;
    const purchaseParams = initialPurchaseParams(this.giftCard);

    if (this.paymentTab === "paypal") {
      const paypalNonce = document.querySelector(
        'input[name="payment_method_nonce"]'
      );
      purchaseParams.paypal = { nonce: paypalNonce.value };
      if (!paypalNonce.value) {
        this.paypalError = "Please click the paypal button";
        this.loading = false;
      } else {
        this.createGiftCard(purchaseParams);
      }
    } else if (this.cardId) {
      purchaseParams.credit_card = { credit_card_id: this.cardId };
      this.createGiftCard(purchaseParams);
    } else if (this.paymentTab === "card") {
      this.creditCardForm.$setSubmitted();
      if (this.creditCardForm.$valid) {
        if (this.giftCard.isGuest) {
          this.createGuestCard()
            .then((res) => {
              purchaseParams.credit_card = cardPurchaseParams(res);
              this.createGiftCard(purchaseParams);
            })
            .catch(angular.noop);
        } else {
          this.getCreditCard()
            .then((res) => {
              const card = { credit_card_id: res.data.credit_card.id };
              purchaseParams.credit_card = card;
              this.createGiftCard(purchaseParams);
            })
            .catch(angular.noop);
        }
      } else {
        this.loading = false;
      }
    } else if (this.paymentTab === "amazonPay") {
      if (window.orderReferenceId) {
        purchaseParams.amazon_pay = {
          amazon_order_reference_id: window.orderReferenceId,
        };
        this.createGiftCard(purchaseParams);
      } else {
        this.amazonError = "Please log in to Amazon and select a payment";
      }
    }
  }

  // credit card methods
  createGuestCard() {
    this.stripe.setPublishableKey(this.root.stripePublishableKey);
    const creditCardParams = cardCreateParams(this.creditCardForm);
    return this.stripe.card.createToken(creditCardParams);
  }

  getCreditCard() {
    this.stripe.setPublishableKey(this.root.stripePublishableKey);
    const creditCardParams = cardCreateParams(this.creditCardForm);

    return this.$q((resolve, reject) => {
      this.stripe.card
        .createToken(creditCardParams)
        .then((res) => {
          const card = cardPurchaseParams(res);
          this.paymentService
            .createCreditCard({ credit_card: card })
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              this.omnibarPointer.pushErrorByHash(err.data.errors);
            });
        })
        .catch((err) => {
          this.omnibarPointer.pushErrorByMessage(err.message);
          reject(err.message);
        });
    });
  }

  activeCardType() {
    const cardNumber = this.creditCardForm.cardNumber.$viewValue;

    if (cardNumber && cardNumber.length) {
      switch (cardNumber[0]) {
        case "3":
          return "americanExpress";
        case "4":
          return "visa";
        case "5":
          return "mastercard";
        case "6":
          return "discover";
      }
    }
  }

  selectCard() {
    this.addPayment = false;
  }

  selectUserCard(id) {
    this.cardId = id;
  }

  paymentHighlight(id) {
    return this.cardId == id;
  }

  invalidFormInput(input) {
    if (this.creditCardForm.$submitted) return input.$invalid;
  }

  shortenYear(card) {
    const year = card.expiration_year;

    return year.substr(year.length - 2);
  }

  // other order methods
  useAnotherPayment() {
    this.addPayment = true;
    this.cardId = false;
  }

  switchPaymentTab(tab) {
    this.paymentTab = tab;

    if (tab === "amazonPay" && !this.shownAmazonButton) {
      //running showAmazonPayButton multiple times will produce multiple buttons
      this.shownAmazonButton = true;
      showAmazonPayButton();
    }
  }

  disablePlaceOrder(force) {
    if (force !== undefined) {
      return force;
    }
    return false;
  }
}

GiftCardPaymentController.$inject = [
  "$rootScope",
  "$state",
  "$q",
  "GiftCardManager",
  "User",
  "OmnibarPointer",
  "Payment",
  "stripe",
];
