const getURLParameters = (param) => {
  const searchString = window.location.search.substring(1);

  if (param) {
    const paramsLength = searchString.length;

    if (paramsLength > 0) {
      for (let i = 0; i < paramsLength; i++) {
        const val = searchString[i].split("=");
        if (val[0] === param) return val[1];
      }
    }

    return null;
  } else {
    return searchString.split("&");
  }
};

export default getURLParameters;
