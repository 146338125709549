import { calendarIcon, settingsIcon, walletIcon } from "../../icons/iconConfig";

const sidebarMenuGamer = (subscriptionsOn) => {
  const menu = [
    {
      name: "Sessions",
      icon: calendarIcon,
      dockLink: "gamer_dashboard.lessons.list",
      submenu: lessonSubMenu(),
    },
    {
      name: "Wallet",
      icon: walletIcon,
      dockLink: "gamer_dashboard.wallet.summary",
      submenu: walletSubmenu(subscriptionsOn),
    },
    {
      name: "Preferences",
      icon: settingsIcon,
      dockLink: "gamer_dashboard.profile",
      submenu: preferencesSubmenu(),
    },
  ];

  return menu;
};

function preferencesSubmenu() {
  const submenuItems = [
    {
      displayOrder: 1,
      name: "User Profile",
      state: "gamer_dashboard.profile",
    },
    {
      displayOrder: 2,
      name: "Contact Information",
      state: "gamer_dashboard.contact_information",
    },
    {
      displayOrder: 3,
      name: "Notification Settings",
      state: "gamer_dashboard.notifications",
    },
    {
      displayOrder: 4,
      name: "Payment Options",
      state: "gamer_dashboard.payment",
    },
  ];

  return submenuItems;
}

function walletSubmenu(subscriptionsOn) {
  const submenuItems = [
    {
      displayOrder: 1,
      name: "Summary",
      state: "gamer_dashboard.wallet.summary",
    },
    {
      displayOrder: 2,
      name: "Purchased Sessions",
      state: "gamer_dashboard.wallet.tokens",
    },
    {
      displayOrder: 4,
      name: "History",
      state: "gamer_dashboard.wallet.history",
    },
    {
      displayOrder: 5,
      name: "Redeem Gift Code",
      state: "gamer_dashboard.wallet.redeem_gift_card",
    },
    {
      displayOrder: 6,
      name: "Redeem Session Token",
      state: "gamer_dashboard.wallet.redeem_session_token",
    },
  ];

  if (subscriptionsOn) {
    submenuItems.push({
      displayOrder: 3,
      name: "Subscriptions",
      state: "gamer_dashboard.wallet.subscriptions",
    });
  }

  return submenuItems;
}

function lessonSubMenu() {
  var submenuItem = [
    {
      displayOrder: 1,
      name: "Book a Session",
      state: "gamer_dashboard.lessons.book_lesson_landing",
      defaultActiveState: true,
    },
    {
      displayOrder: 2,
      name: "Calendar View",
      state: "gamer_dashboard.lessons.calendar",
      defaultActiveState: true,
    },
    {
      displayOrder: 3,
      name: "List View",
      state: "gamer_dashboard.lessons.list",
    },
    {
      displayOrder: 4,
      name: "Reviews",
      state: "gamer_dashboard.lessons.my_reviews",
    },
    {
      displayOrder: 5,
      name: "Session Notes",
      state: "gamer_dashboard.lessons.sensei_notes",
    },
    {
      displayOrder: 6,
      name: "Messages",
      state: "gamer_dashboard.lessons.sensei_chats",
    },
  ];

  return submenuItem;
}

export default sidebarMenuGamer;
