import giftCardOrderSummaryPartial from "../../templates/includes/gift_cards/gift_card_order_summary_partial";

export default class GiftCardAccountController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $timeout,
    GiftCardManager,
    AccessControl,
    User
  ) {
    this.gigya = $scope.isGigyaOn;
    // angular providers
    this.root = $rootScope;
    this.$state = $state;
    this.$timeout = $timeout;
    // services
    this.userService = User;
    this.access = AccessControl;
    this.giftCard = GiftCardManager;
    // imported and injected
    this.giftCardOrderSummaryPartial = giftCardOrderSummaryPartial;
    // strings
    this.accountTab = "guest";
    // booleans
    this.loading = false;
  }

  // action methods
  goTo(state) {
    this.$state.go(state);
  }

  continueAsUser(newUser, role) {
    this.updateUser(newUser);
  }

  continueAsNewlyRegistered(newUser) {
    this.updateUser(newUser);
  }

  continueAsGuest($event) {
    if ($event) $event.preventDefault();

    this.giftCard.isGuest = true;
    this.giftCard.purchaserName = this.guestForm.name.$viewValue;
    this.giftCard.purchaserEmail = this.guestForm.email.$viewValue;
    this.$state.go("gift-cards.payment");
  }

  switchAccountTab(tab) {
    this.accountTab = tab;
  }

  isSignUpTab() {
    return this.accountTab === "signup";
  }

  isLogInTab() {
    return this.accountTab === "login";
  }

  isGuestTab() {
    return this.accountTab === "guest";
  }

  isAccessTab() {
    return this.isSignUpTab() || this.isLogInTab();
  }

  // internal methods
  updateUser(user) {
    const role = this.userService.getNewUserRole(user);
    this.userService.setCurrentId(user.id);
    this.userService.setRole(role);

    this.userService
      .getUser(true)
      .then((userData) => {
        this.loading = false;

        this.currentUser = userData;
        this.giftCard.purchaserName =
          userData.full_name ||
          userData.screen_name ||
          "a Friend at Gamer Sensei";
        this.giftCard.purchaserEmail = userData.email;

        const totalValue = this.giftCard.calculateValue();

        if (totalValue <= this.currentUser.current_balance) {
          this.$state.go("gift-cards.review");
        } else {
          this.$state.go("gift-cards.payment");
        }
      })
      .catch(angular.noop);
  }
}

GiftCardAccountController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$timeout",
  "GiftCardManager",
  "AccessControl",
  "User",
];
