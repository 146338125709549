const cardPurchaseParams = (data) => {
  return {
    stripe_card_token: data.id,
    credit_card_name: data.card.name,
    credit_card_last_four: data.card.last4,
    credit_card_type: data.card.brand,
    expiration_month: data.card.exp_month,
    expiration_year: data.card.exp_year,
    postal_code: data.card.address_zip,
  };
};

export default cardPurchaseParams;
