export default function omnibarPointerService(Omnibar, User) {
  var service = {
    // Functions
    pushTooltip: pushTooltip,
    pushErrorByMessage: pushErrorByMessage,
    pushErrorByHash: pushErrorByHash,
    resetOmnibar: resetOmnibar,
    dismissTooltip: dismissTooltip,
    setOptions: setOptions,
    emailEndpoint: emailEndpoint,
    // Properties
    message: "",
    errorStatus: false,
    omnibarStatus: false,
    sendEmailFlag: false,
    errors: { omnibarErrorMessage: "" },
    // Options
    timer: false,
    tooltipCallback: true,
    persist: false,
    appendString: "",
    timerMilliseconds: 4000,
  };

  return service;

  ///////////

  function pushErrorByMessage(message, options) {
    service.setOptions(options);
    service.errorStatus = true;
    service.omnibarStatus = true;
    service.errors.omnibarErrorMessage = message;
  }

  function pushErrorByHash(hash, options) {
    service.setOptions(options);
    service.errorStatus = true;
    service.omnibarStatus = true;
    var message = "";
    for (let key in hash) {
      message += hash[key] + ". ";
    }
    if (service.appendString) {
      message += service.appendString;
    }
    service.errors.omnibarErrorMessage = message;
  }

  function pushTooltip(tooltip, options) {
    service.setOptions(options);
    service.omniTooltip = {
      key: tooltip.key,
      image: tooltip.display_image,
      title: tooltip.title,
      body: tooltip.body,
    };
    service.omnibarStatus = true;
  }

  function dismissTooltip(omnibarKey, options) {
    service.setOptions(options);
    var closeParams = {
      dismissal: {
        tooltip: omnibarKey,
      },
    };
    if (service.tooltipCallback) Omnibar.close(closeParams);
    resetOmnibar();
  }

  function resetOmnibar() {
    service.message = "";
    service.errorStatus = false;
    service.omnibarStatus = false;
    service.errors = { omnibarErrorMessage: "" };
  }

  function emailEndpoint() {
    User.resendConfirmationEmail(User.getId())
      .then((res) => {
        var tooltip = {
          title: "Your email has been sent!",
        };
        service.pushTooltip(tooltip);
        service.errorStatus = false;
        service.errors = { omnibarErrorMessage: "" };
      })
      .catch(angular.noop);
  }

  function setOptions(options) {
    if (options) {
      for (let key in options) {
        service[key] = options[key];
      }
    }
  }
}

omnibarPointerService.$inject = ["Omnibar", "User"];
