export default function userDataSenseiService(UserData) {
  const senseiData = function (_userData) {
    UserData.apply(this, arguments);
    this.data.description = _userData.sensei.description;
    this.social_urls = this.buildSocialURL(_userData);
    if (_userData.sensei !== undefined) {
      this.data.games = _userData.sensei.games;
      this.data.tier_by_hours_coached = _userData.tier_by_hours_coached;
      this.data.game_description = this.getGameDescriptionFromGame(
        _userData.sensei.games
      );
      this.data.description = _userData.sensei.description;
      this.data.hourlyRate = this.getHourlyRateFromGames(
        _userData.sensei.games
      );
      this.data.currency_conversion_rate = this.getConversionRateFromGames(
        _userData.sensei.games
      );
      this.data.hourlyCurrencyRate = this.getHourlyRateCurrencyFromGames(
        _userData.sensei.games
      );
      this.data.status = _userData.sensei.status;
      this.data.paypal_handle = _userData.sensei.paypal_handle;
      this.data.achievements = this.getAchievementsFromGames(
        _userData.sensei.games
      );
      //
      this.data.hourly_rate = this.getHourlyRateFromGames(
        _userData.sensei.games
      );
      this.data.hourly_rate_in_currency = this.getHourlyRateCurrencyFromGames(
        _userData.sensei.games
      );
      //
      this.data.current_balance = this.getCurrentBalance(_userData);
      this.data.sensei_notification_preference_id =
        this.getSenseiNotificationPreferenceId(_userData);
    }
  };

  senseiData.prototype = Object.create(UserData.prototype);

  senseiData.prototype.getHourlyRateFromGames = function (games) {
    // Return '1000' for sensei first run
    if (games === undefined || games[0] === undefined) return "1000";
    if (games[0].hourly_rate) return games[0].hourly_rate;
    return "1000";
  };

  senseiData.prototype.getHourlyRateCurrencyFromGames = function (games) {
    if (games === undefined || games[0] === undefined)
      return 1000 / this.data.currency_conversion_rate;
    if (games[0].hourly_rate_in_currency)
      return games[0].hourly_rate_in_currency;
    return 1000 / this.data.currency_conversion_rate;
  };

  senseiData.prototype.getConversionRateFromGames = function (games) {
    if (games === undefined || games[0] === undefined) return 0.8;
    if (games[0].currency_conversion_rate)
      return games[0].currency_conversion_rate;
    return 0.8;
  };

  senseiData.prototype.buildSocialURL = function (user) {
    return [
      { url: user.facebook_url, name: "facebook" },
      { url: user.twitter_url, name: "twitter" },
      { url: user.instagram_url, name: "instagram" },
      { url: user.twitch_url, name: "twitch" },
    ];
  };

  senseiData.prototype.getAchievementsFromGames = function (games) {
    if (games === undefined || games[0] === undefined) return [];
    var achievements = [];
    angular.forEach(games[0].coaching_achievements, function (value, index) {
      achievements.push(value.achievement);
    });
    return achievements;
  };

  senseiData.prototype.hasSenseiCurrencyAccount = function (userData) {
    return userData.sensei.sensei_currency_account;
  };

  senseiData.prototype.getCurrentBalance = function (userData) {
    if (!this.hasSenseiCurrencyAccount(userData)) return 0;
    if (!userData.sensei.sensei_currency_account.payoutable_balance) return 0;
    return userData.sensei.sensei_currency_account.payoutable_balance;
  };

  senseiData.prototype.getSenseiNotificationPreferenceId = function (userData) {
    return userData.sensei.sensei_notification_preference_id;
  };

  return senseiData;
}

userDataSenseiService.$inject = ["UserData"];
