export default function isProfileValid(OmnibarPointer) {
  return (isValid) => {
    if (!isValid) {
      OmnibarPointer.pushErrorByMessage(
        "Something went wrong, please check for errors."
      );
      return false;
    }
    return true;
  };
}

isProfileValid.$inject = ["abExperiences"];
