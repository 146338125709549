export default function accountService($http) {
  const service = {
    update: updateAccount,
  };

  return service;

  ///////////

  function updateAccount(id, data) {
    return $http({ method: "PUT", url: "/api/web/accounts/" + id, data: data });
  }
}

accountService.$inject = ["$http"];
