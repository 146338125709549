export default function orderReviewsFilter() {
  return function (reviews) {
    var filtered = [];
    var testimonial = null;
    angular.forEach(reviews, function (review) {
      if (review.is_testimonial) {
        testimonial = review;
      } else {
        filtered.push(review);
      }
    });

    filtered.sort(function (a, b) {
      return a.updated_at - b.updated_at;
    });

    if (testimonial) {
      filtered.unshift(testimonial);
    }

    return filtered;
  };
}
