const bookingAvailabilityObject = (availSenseis, currentDate, durationMins) => {
  const coachLabel =
    availSenseis.length > 1
      ? `${availSenseis.length} coaches available`
      : "Coach Available";
  const events = [];

  const singleBookingAvail = {
    id: "avail_" + currentDate.format("YYYY_MM_DD_H") + "00",
    start: currentDate.toDate(),
    end: currentDate.clone().add(durationMins, "m").toDate(),
    duration: durationMins,
    stick: false,
    status: "available",
    senseis: availSenseis,
    title: coachLabel,
  };

  if (durationMins === 30) {
    const secondBookingAvail = {
      id: "avail_" + currentDate.format("YYYY_MM_DD_H") + "30",
      start: currentDate.clone().add(durationMins, "m").toDate(),
      end: currentDate
        .clone()
        .add(durationMins * 2, "m")
        .toDate(),
      duration: durationMins,
      stick: false,
      status: "available",
      senseis: availSenseis,
      title: coachLabel,
    };

    events.push(singleBookingAvail, secondBookingAvail);
  } else {
    events.push(singleBookingAvail);
  }

  return events;
};

export default bookingAvailabilityObject;
