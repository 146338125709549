import sessionListItemTemplate from "../../templates/directives/dashboard/session_list_item_template";

export default function sessionListItemDirective($state, TokenBookingService) {
  return {
    restrict: "C",
    scope: {
      includeQty: "=",
      session: "=",
      communicationInfoReminder: "=",
      userRole: "=",
      refundsAvailable: "&",
      refundToken: "&",
    },
    link: link,
    template: sessionListItemTemplate,
  };

  function link(scope, element, attr) {
    scope.tokenBooking = TokenBookingService;

    scope.makeTitle = function (slug) {
      return slug
        .toLowerCase()
        .split("-")
        .map((i) => i[0].toUpperCase() + i.substr(1))
        .join(" ");
    };

    scope.lengthFormat = function (duration) {
      if (duration % 60 === 0) {
        return duration / 60 + " hour";
      }
      return duration + " minute";
    };

    scope.isScheduleDisabled = function () {
      return (
        scope.communicationInfoReminder &&
        scope.communicationInfoReminder.show &&
        !scope.communicationInfoReminder.completed
      );
    };

    scope.tokenTierDisplay = function (token) {
      if (token.type.includes("skills-assessment")) {
        return "";
      }
      return scope.makeTitle(token.tier);
    };

    scope.setDepositAndGoToLink = function (event, token) {
      event.preventDefault();

      if (scope.isScheduleDisabled()) {
        return false;
      }

      scope.tokenBooking.setSelectedTokenDeposit(token);

      $state.go(`${scope.userRole}_dashboard.lessons.book_lesson_calendar`, {
        tokenTemplateId: token.template_id,
        sensei_id: token.sensei_id,
      });
    };

    scope.getLink = function (session) {
      const senseiId = session.sensei_id
        ? `?sensei_id=${session.sensei_id}`
        : "";

      if (scope.isScheduleDisabled()) {
        return "#";
      } else {
        return `/${scope.userRole}/dashboard/lessons/book/${session.template_id}${senseiId}`;
      }
    };

    scope.convertExpiration = function (date) {
      return moment.utc(date).local().format("MM/DD/YYYY h:mmA");
    };

    scope.isTokenBookable = function (token) {
      if (token.type.includes("masterclass")) {
        return false;
      }
      return true;
    };
  }
}

sessionListItemDirective.$inject = ["$state", "TokenBookingService"];
