export default function gameService($http, $q) {
  const service = {
    list: [],
    query: queryGames,
    findGame: findGame,
    specificPage: gameSpecificPage,
    getGames,
    getAllActiveGames,
  };

  return service;

  ///////////

  function queryGames(config) {
    return $http.get("/api/web/games", config);
  }
  function findGame(id) {
    return $http.get("/api/web/games/" + id);
  }
  function gameSpecificPage(config) {
    return $http.get("/api/web/game_annotations", config);
  }
  function getGames() {
    return service.list;
  }
  function getAllActiveGames(config) {
    const deferred = $q.defer();

    if (service.list.length) {
      deferred.resolve({ data: { games: service.list } });
    } else {
      service
        .query(config)
        .then((res) => {
          if (!res.data.games) return deferred.resolve(res);

          service.list = addGameDisplayOrder(res.data.games);

          deferred.resolve({ data: { games: service.list } });
        })
        .catch((err) => {
          deferred.reject(err);
        });
    }
    return deferred.promise;
  }
  function addGameDisplayOrder(games) {
    return games.map(function (game) {
      game.displayOrder = getGameOrderBySlug(game.slug);
      return game;
    });
    function getGameOrderBySlug(slug) {
      var gameOrder = {
        "league-of-legends": 1,
        "rocket-league": 2,
        valorant: 3,
        overwatch: 4,
        fortnite: 5,
        "dota-2": 6,
        "apex-legends": 7,
        "call-of-duty-warzone": 8,
        hearthstone: 9,
        "teamfight-tactics": 10,
        "super-smash-bros": 11,
        "counter-strike-global-offensive": 12,
        "playerunknowns-battlegrounds": 13,
        "world-of-tanks": 14,
        "magic-the-gathering-arena": 15,
        "starcraft-ii": 16,
        smite: 17,
        "heroes-of-the-storm": 18,
      };
      if (gameOrder[slug] === undefined) return 99;
      return gameOrder[slug];
    }
  }
}

gameService.$inject = ["$http", "$q"];
