export const title = "GAMER SENSEI – PRIVACY POLICY SUPPLEMENT";

const supplement = {
  title: title,
  introBody: `<p>Gamer Sensei supports gamers of all ages and abilities by connecting you with the best coaches in gaming. In connection with the operation of the Gamer Sensei website and services ("<strong>GS Services</strong>"), we collect and process Personal Data in various ways, as described below. Both the <a class="alt" href="/privacy-policy">Privacy Policy ("Privacy Policy")</a> and this Privacy Policy Supplement apply to your use of the GS Services. To the extent this Privacy Policy Supplement is inconsistent with the Privacy Policy, this Privacy Policy Supplement controls in connection with the GS Services.</p>`,
  content: {
    dataWeCollect: {
      header: "Personal Data We Collect",
      body: `<p>We collect additional <a class="alt" href="https://www.corsair.com/ww/en/s/privacy-policy#:~:text=Supplemental%20Privacy%20Notices-,WHAT%20IS%20PERSONAL%20DATA%20AT%20CORSAIR%3F,to%20identify%20you%20%E2%80%94%20such%20as%20the%20serial%20number%20of%20your%20device.,-PERSONAL%20DATA%20CORSAIR" target="_blank"><strong>Personal Data</strong></a> in connection with GS Services. The following categories and examples are supplements to the descriptions of the categories described in our Privacy Policy.</p>
      <ul>
        <li>When individuals ("<strong>Users</strong>") access or use the GS Services ;</li>
        <li>The purchase of or payment for lessons and coaching sessions ("<strong>Sessions</strong>") by Users seek ing Sessions;</li>
        <li>The creation of Coach Accounts which enable Coaches to offer and manage Sessions;</li>
        <li>The creation of Users Account to schedule and manage Sessions and track progress. Note, some Accounts may behave differently based on the age of the U ser so that we can ensure Parent/Guardian have a say over how their child's data is handled and can comply with certa in privacy laws (such as COPPA);</li>
        <li>To operate the Sessions, including via video chat and other interactions with coaches offering services through Gamer Sensei ("<strong>Coaches</strong>" or "<strong>Coach</strong>"); and</li>
        <li>Tracking/sharing progress and development, and providing Account features specific to the GS Services.</li>
      </ul>
      <p>Please see below for additional information regarding the Personal Data we collect and how we process it as part of the GS Services. If you are a Parent/Guardian, please review information about your rights as a Parent/Guardian with respect to your child's Personal Data.</p>
      <p>We collect additional Personal Data in connection with the GS Services. The following categories and examples are supplements to the descriptions of the categories described in our <a class="alt" href="/privacy-policy"> Privacy Policy</a>.</p>
      <ul>
        <li><strong>Identity Data</strong> – We may collect birthdate or age range of registered Users.</li>
        <li><strong>Contact Data</strong> – We may collect addresses, email addresses, telephone numbers, social media account names, and location information of Users including Users' Parent/Guardian information from Users, Parent/Guardian, or child that seeks to create an Account.</li>
        <li><strong>Audio/Visual Data</strong> – In connection with Sessions, we may (directly or through our service provider) live stream video from a User's device to a Coach, and vice versa (note, in some cases, the Sessions may be recorded).</li>
        <li><strong>Device/Network Data</strong> – In connection with Sessions or operation of the GS Services and cookies/similar technologies, we collect Device/Network Data, such as IP address from devices you use to access GS Services.</li>
        <li><strong>Transaction Data</strong> – As part of the purchase of a Session, we may collect information relating to Sessions' credits/purchases, and related information.</li>
        <li><strong>Payment Data</strong> – As part of the purchase of Session s or payment of Coaches, our service providers may collect payment card, gift card, or personal information sufficient to collect payments and pay Coaches.</li>
        <li><strong>Usage Data</strong> – When Users use the GS Services, we and our service providers collect information relating to the use and performance of the GS Services.</li>
        <li><strong>User Content</strong> – We collect any information provided to the GS Services by Users, such as free form text, audio, video, or other content disclosed or revealed in Sessions.</li>
      </ul>`,
    },
    howWeUseCollectedData: {
      header: "How We Use Your Information",
      body: `<p><u>Account Registration and Age-Based Account Behavior</u></p>
        <p>When you create an Account on the GS Services ("<strong>Account</strong>" or <strong>"Accounts"</strong>), we will collect Identity Data and Contact Data, as described in our Privacy Policy. We may also collect birthdate or age range, which is used to manage/verify parental consent (where required), and to help ensure compliance with applicable law.</p>
        <p>To help ensure that GS Services can serve gamers of all ages, we maintain different settings based on your age, and in some cases, whether you are a Parent/Guardian of Users. Specifically, we have the following Accounts:</p>
        <p><strong>Adults</strong> – If you are an adult over the age of 18 operating an Account on your behalf, we will collect Personal Data in connection with your registration as described above and in our Privacy Policy in relation to Account registration. Your Personal Data will be used and shared as and for the purposes set forth in the Privacy Policy and elsewhere in this Supplement as it relates to the general (non-Parent) use of the GS Services.</p>
        <p><strong>Parents/Guardians</strong> – Parents/Guardians of children under 18 will be required to create an Account before the child may use the GS Services. The Account allows parents/guardians to manage their child's use of the GS Services, including by authorizing purchases, scheduling and monitoring Sessions, managing Account settings, and exercising any rights of parents/guardians available under applicable law (e.g. rights to access or delete information). If a child indicates that they are under the age of 18, we may require the Users to provide the Parent/Guardian's email address. We may send the Parent/Guardian an email requesting that the Parent/Guardian create an Account and, if the Users under the age 13, to provide consent to the processing of the child's Personal Data.</p>
        <p>How you use an Account, the rights you have, and how we process Personal Data in connection with your child's use of the GS Services, varies by the child's age. Specifically:</p>
        <ul>
          <li><em>If you have a child under the age of 13 (or the age of minority in your jurisdiction)</em>: Minors under 13—or the relevant age for minors in your jurisdiction — may access the GS Services only via the Parent/Guardian's Account. The Parent/Guardian is responsible for granting the child access to GS Services via the Parent/Guardian's Account, scheduling Sessions, authorizing payments, and supervising their child's use of the GS Services. Parents/Guardians must consent to the collection and processing of any Personal Data via the Parent/Guardian's Account as set forth below. Additionally, Parents/Guardians may exercise their rights with respect to their child's Personal Data through their Account and via the data rights functional ity provided through the GS Services (see below).</li>
          <li><em>If you have a child under the age of 18, but 13 or older (or the age of minority in your jurisdiction)</em>: Teens aged 13, or over the age of minority in your jurisdiction, they may create their own Account.</li>
        </ul>
        <p><strong>Teens</strong> – If you are a teen over the age of minority in your jurisdiction (usually between 13-16) then you are allowed to create an Account.</p>
        <p><strong>Unverified Accounts</strong> - If a minor requests Parents/Guardians to open an Account, we may process Personal Data as necessary to create each Account at the time of the request. Although we may create the Account, the Personal Data collected in connection with Account creation may be processed only for limited purposes pending verification and consent to processing by the Parent/Guardian. Parents/Guardians may verify an Account by purchasing Sessions and providing consent (as described below). Until the Account is verified, we do not: (a) market our products and services to the Account holder using the Contact Data or other registration data submitted in connection with the Account creation process (<em>note:</em> certain marketing via cookies and similar technologies may be used in connection with the use of the GS Services); or (b) share Personal Data with third parties other than our service providers providing hosting or similar operational or business purposes on our behalf. If the Account is not verified within 30 days from the minor's request, we will delete the Account.</p>
        <p><strong>Coaches</strong> - If you register for an Account as a Coach, we will collect Personal Data in connection with your registration as described above and in our Privacy Policy in relation to Account registration. Additionally, we may collect your contractor agreement status and certain Transaction Data relating to Sessions you coach as necessary to generate invoices and issue payment through our third-party service providers. Your Personal Data will otherwise be used and shared as and for the purposes set forth in the Privacy Policy and elsewhere in this Privacy Policy Supplement applicable to Adults.</p>
        <p><em>Please note</em>: To act as a Coach, you may be required to create an Account on a third-party platform that provides invoicing and payment processing services on our behalf. Our Privacy Policy does not apply to third party platforms, and we do not receive sensitive Personal Data (such as your Social Security Number) from the third party. Collection and processing of Payment Data of Coaches is subject to the privacy policy of the third party platform, including in cases of data breach.</p>`,
    },
    servicesFeaturesAndFunctions: {
      header: "Services Features and Functions",
      body: `<p><strong>Sessions</strong> – When Users participate in coaching Sessions, we connect the Users and Coach live via video chat, and we may process Audio/Visual Data and User Content in connection with the Sessions.</p>
        <p>We use the Audio/Visual Data, and User Content only to provide the Session and to otherwise operate the GS Services. Our video chat is hosted on our Discord Server, and certain information may be processed in accordance with the <a class="alt" href="https://discord.com/privacy" target="_blank">Discord Privacy Policy</a>. Further, subject to the parties' consent, we may record certain Sessions. <strong>Note – We cannot control third parties' use of the GS Services and cannot control recordings, images, screenshots, or other images taken by a third party without our authorization, including by Coaches.</strong></p>
        <p>Additionally, we collect Usage Data and Device/Network Data to provide and maintain Sessions, provide Users support, ensure network stability, ensure security, prevent unauthorized interception, improve our GS Services, and for other security and operational purposes described in our Privacy Policy.</p>
        <p><strong>User Profiles and Progress</strong> – To provide the GS Services, track progress, maintain your Account, personalize Users' experience on the GS Services, and otherwise assist in your development, we process your Personal Data to track your progress and achievements on the GS Services, recommend additional services or coaching pathways, and help you make the most of the GS Services. Recommendations and other outputs from Users progress analytics may be shared with Coaches so that they can customize coaching programs and plans.</p>
        <p><strong>Payment</strong> – Our payment service provider collects and processes Identity Data, Contact Data, and Payment Data on our behalf in connection with the purchase of Sessions and such collection and processing is subject to its privacy policy, including in the case of data breach. We may allow you to purchase Sessions via credit card, gift cards, or via the purchase of credits. This Personal Data is processed only as necessary to fulfill the payment transaction, and for related security, anti-fraud, and appropriate internal service delivery processes.</p>`,
    },
    howWeShareCollectedDate: {
      header: "How We Share Your Personal Data",
      body: `<p>Although we share Personal Data as described below, the GS Services do not enable children to make their Personal Data publicly available.</p>
        <p><strong>Coaches</strong> – We may grant Coaches access to the Identity Data, Audio/Visual Data, Contact Data, User Content, and certain Usage Data (e.g. Session progress, achievements) processed in connection with the GS Services as necessary for the Coach to provide and personalize a Session. Coaches are not authorized to download, copy, or record Sessions, Audio/Visual Data or other Personal Data, nor are Coaches permitted to process Personal Data other than via the GS Services for the limited purpose of providing and personalizing a Session.</p>
        <p><strong>Parents/Guardians</strong> – Accounts may involve the collection and processing of Personal Data of Users under 13 that use the Account with the Parent/Guardian supervision. The Parent/Guardian will have access to all such information processed under their Account.</p>
        <p><strong>Service Providers</strong> – We may share any Personal Data with service providers that perform certain services on our behalf, as described in our <a class="alt" href="/privacy-policy"> Privacy Policy</a> . For example, we share Personal Data with Discord to operate the Discord Server on which the Sessions are provided, and we make available additional Personal Data, such as Device/Network Data and Identity Data, to service providers operating analytics or advertising services on our Service (see our <a class="alt" href="/cookies-and-similar-technologies">Cookie and Similar Technology Policy</a> for additional details).</p>
        <p><em>Service Providers (as of Effective Date)</em></p>
        <ul>
          <li>Amazon</li>
          <li>CrazyEgg</li>
          <li>Cloud4wi</li>
          <li>Convercent</li>
          <li>DataDog</li>
          <li>Daily.co</li>
          <li>Discord</li>
          <li>Doubleclick</li>
          <li>Facebook (including its <a class="alt" target="_blank" href="https://www.facebook.com/legal/ads-subprocessors"> subprocessors</a>)</li>
          <li>Google (including its <a class="alt" target="_blank" href="https://business.safety.google/adssubprocessors/"> subprocessors</a>)</li>
          <li>inspectlet.com</li>
          <li>Instagram</li>
          <li>Lacework</li>
          <li>Letsdeel</li>
          <li>Looker Data Sciences</li>
          <li>MailChimp</li>
          <li>mParticle</li>
          <li>Mobyt</li>
          <li>myfonts.net</li>
          <li>New Relic</li>
          <li>OneTrust</li>
          <li>Oracle</li>
          <li>Paypal (including its <a class="alt" target="_blank" href="https://www.paypal.com/uk/webapps/mpp/ua/third-parties-list">subprocessors</a>)</li>
          <li>QlikTech International</li>
          <li>Redislabs</li>
          <li>Snowflake</li>
          <li>Stripe (including its <a class="alt" target="_blank" href="https://stripe.com/service-providers/legal">subprocessors</a>)</li>
          <li>SumoLogic</li>
          <li>TimescaleDB</li>
          <li>Tugboat Logic</li>
          <li>Twilio</li>
          <li>Twitch</li>
          <li>Twitter</li>
          <li>Xirsys</li>
          <li>Zendesk</li>
        </ul>
        <p>You may contact Corsair Memory, Inc., at <a class="alt" target="_self" href="mailto:data.controller@corsair.com">data.controller@corsair.com</a> for inquiries about any of the service providers listed above.</p>`,
    },
    rightsAndChoices: {
      header: "Rights and Choices",
      body: `<h4 class="h6">Generally</h4>
        <p>You may exercise your rights with respect to the Personal Data in several ways. You can access and update most Personal Data we process via the GS Services through your Account settings menu. You may exercise your rights with respect to the use of cookies by visiting our Cookie Settings section of our website at <a class="alt" href="https://gamersensei.com/"> Esports Coaching and Lessons with Pro Gamers (gamersensei.com)</a> . Additionally, if you wish to request that we export, delete, or limit the processing of Personal Data, contact us at <a class="alt" target="_self" href="mailto:data.controller@corsair.com">data.controller@corsair.com </a>.</p>
        <h4 class="h6">Parental Rights and Controls</h4>
        <p>When Users indicate that they are under the age of 13 (or the age of majority in the relevant jurisdiction) we require that the parent or guardian open an Account and consent to the processing of Personal Data about the child in connection with the GS Services. You may withdraw your consent at any time by ceasing to use the GS Services and closing your Account. You may exercise your rights to access, correct, or delete data as set forth above. The GS Services do not enable children to make their Personal Data publicly available.</p>
        <p><strong>If you operate an Account, by purchasing a Session, you acknowledge that you have read and you hereby consent to the processing of your child's Personal Data as set forth in our <a class="alt" href="/privacy-policy">Privacy Policy</a> and this Gamer Sensei Privacy Policy Supplement. You consent, without limitation, to the sharing of Personal Data with operators of cookies and similar technologies for analytics and advertising purposes (as further described in our <a class="alt" href="/cookies-and-similar-technologies">Cookie and Similar Technology Policy)</a> to the extent active on the GS Services. If you do not consent, we will not process the information Users provide as part of the Account registration or request form except as necessary to contact you or maintain the unverified Account. If we do not receive consent in a reasonable time, you and your child's contact information may be deleted, and the Account closed.</strong></p>
        <p><strong><em>Please note</em>: neither our <a class="alt" href="/privacy-policy">Privacy Policy</a> nor this Gamer Sensei Privacy Policy Supplement apply to third parties. Third parties may require you to separately consent to certain processing of your child's Personal Data. Please review all third party privacy policies prior to consenting to the processing of Personal Data by third parties.</strong></p>
        <p>Parents/guardians have the right to review, correct, or have deleted from our database their child's personal information and/or have the right to refuse further collection or use of a child's personal information. If you have questions about the collection and use of children's information or wish to make a request about your child's personal information, please contact us at <a class="alt" target="_self" href="mailto:data.controller@corsair.com">data.controller@corsair.com</a>.</p>`,
    },
    contactData: {
      header: "Contact Data",
      body: `<p>Corsair Memory, Inc., <a class="alt" target="_self" href="mailto:data.controller@corsair.com">data.controller@corsair.com</a>.</p>`,
    },
  },
};

export default supplement;
