export default class VerifyUserEmailController {
  constructor(
    $scope,
    $state,
    $timeout,
    User,
    OmnibarPointer,
    EventTrackingManager
  ) {
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.userLoaded = false;
    this.loading = false;
    this.disable = false;

    this.userService.setCurrentId($scope.currentId);

    var userType;
    if (this.userService.isGamer()) {
      userType = "gamer";
      this.redirectState = "gamer_dashboard.profile";
    } else if (this.userService.isSensei()) {
      userType = "sensei";
      this.redirectState = "sensei_dashboard.profile";
    }

    this.userService.setRole(userType);

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.userLoaded = true;
      })
      .catch(angular.noop);

    this.pushVerifyEvent = () => {
      this.eventTracking.pushVerifiedUser(this.currentUser, true);
      $timeout(() => {
        $state.go(this.redirectState, {}, { reload: true });
      }, 2000);
    };

    this.verificationAlreadyComplete = () => {
      return (
        this.userLoaded &&
        this.currentUser &&
        this.currentUser.is_email_verified
      );
    };
  }
}

VerifyUserEmailController.$inject = [
  "$scope",
  "$state",
  "$timeout",
  "User",
  "OmnibarPointer",
  "EventTrackingManager",
];
