export default function bookingEventRender(event, element, view) {
  const elm = element[0];
  (elm.nodeName === "A" ? element : element.find("a")).attr("href", "#");

  const endTime = event.end.format("h(:mm)a");
  const startTime = event.start.format("h(:mm)a");
  const newHtml = `<span>${startTime}<span class="hide-in-mobile">- ${endTime}</span></span>`;

  elm.querySelectorAll(".fc-time")[0].innerHTML = newHtml;
}
