export default function pricingFilterOptions() {
  const pricingOptions = [5, 10, 15, 20, 25, 50, 75, 100];
  const pricingArr = [];

  for (const price of pricingOptions) {
    const isNum = typeof price === "number";
    pricingArr.push({
      name: isNum ? `$${price}.00` : price,
      value: isNum ? price * 100 : null,
    });
  }

  return pricingArr;
}
