export default function senseiTrainingRequestManagerService(
  $stateParams,
  Dashboard,
  SenseiTrainingRequest,
  SenseiTrainingRequestHolder,
  $q,
  $filter
) {
  const manager = {
    isConfirmedLessonBucketAvailable: false,
    role: null,
    noMoreUpcomingOrActiveSessions: true,
    actionableLessons: new SenseiTrainingRequestHolder(),
    confirmedLessons: new SenseiTrainingRequestHolder(),
    frozenLessons: new SenseiTrainingRequestHolder(),
    allLessons: new SenseiTrainingRequestHolder(),
    pageNumber: 1,
    showMore: false,
    withSenseiNotes: false,
    withSenseiChats: false,
  };

  manager.init = function (force) {
    if (
      !force &&
      (manager.actionableLessons.length !== 0 ||
        manager.frozenLessons.length !== 0)
    )
      return manager.updateActionable();

    manager.pageNumber = 1;
    manager.actionableLessons.clear();
    manager.allLessons.clear();
    if (manager.isConfirmedLessonBucketAvailable) {
      manager.confirmedLessons.clear();
    }
    manager.frozenLessons.clear();
    manager.loadingLessons = true;
    return manager.loadPage();
  };

  manager.loadPage = function (page) {
    var date = [];
    var config = {
      params: { page: page || manager.pageNumber },
    };

    if (manager.hasFilter.date()) {
      date = toFromDate($stateParams.date);
      config.params.from_date = date[0];
      config.params.to_date = date[1];
    }

    if (manager.hasFilter.status()) {
      config.params.status = $stateParams.status;
    }

    if (manager.hasFilter.tokenType()) {
      config.params.token_type = $stateParams.tokenType;
    }

    if (manager.hasFilter.gameID()) {
      config.params.game_id = $stateParams.game;
    }

    if (manager.hasFilter.withSenseiNotes()) {
      config.params.with_sensei_notes = this.withSenseiNotes;
    }

    if (manager.hasFilter.withSenseiChats()) {
      config.params.with_sensei_chats = this.withSenseiChats;
    }

    if (manager.hasFilter.studentName()) {
      config.params.student_name = $stateParams.studentName;
    }

    return $q(function (resolve, reject) {
      Dashboard.getSenseiTrainingRequests(config).then((res) => {
        var requests = res.data.sensei_training_requests;
        manager.showMore = requests.length === 10;
        angular.forEach(requests, function (request) {
          manager.bucketRequest(request, manager.role);
        });
        manager.loadingLessons = false;
        resolve();
      }, reject);
    });
  };

  manager.loadNextPage = function () {
    manager.pageNumber += 1;
    manager.loadPage(manager.pageNumber);
    return this;
  };

  manager.bucketRequest = function (request, role) {
    var sensei_training_request = new SenseiTrainingRequest(request, role);
    manager.allLessons.addOrUpdate(sensei_training_request);

    if (manager.isConfirmedLessonBucketAvailable) {
      if (sensei_training_request.affinity < 3)
        manager.actionableLessons.addOrUpdate(sensei_training_request);
      else if (sensei_training_request.affinity == 3)
        manager.confirmedLessons.addOrUpdate(sensei_training_request);
      else manager.frozenLessons.addOrUpdate(sensei_training_request);
    } else {
      if (sensei_training_request.affinity <= 3)
        manager.actionableLessons.addOrUpdate(sensei_training_request);
      else manager.frozenLessons.addOrUpdate(sensei_training_request);
    }
  };

  manager.updateActionable = function () {
    return $q(function (resolve, reject) {
      manager
        .getActionableSenseiTrainingRequests()
        .then((res) => {
          var actionableRequests = res.data.sensei_training_requests;
          var results = manager.actionableLessons.updateByRequests(
            actionableRequests,
            manager.role,
            manager.pageNumber
          );

          resolve(results);
        })
        .catch(angular.noop);
    });
  };
  manager.getActionableSenseiTrainingRequests = function () {
    var config = { params: { status: "actionable" } };
    if (manager.hasFilter.date()) {
      var date = toFromDate($stateParams.date);
      config.params.from_date = date[0];
      config.params.to_date = date[1];
    }
    return Dashboard.getSenseiTrainingRequests(config);
  };
  manager.moveToFrozenByRequest = function (_request) {
    manager.actionableLessons.removeByRequest(_request);
    manager.frozenLessons.push(
      new SenseiTrainingRequest(_request, manager.role)
    );
    return this;
  };

  manager.dismissUnread = function (_id) {
    var data = { changes_viewed_by: manager.role };
    Dashboard.lessonChangesView(_id, data);
    return this;
  };

  manager.setWithSenseiNotes = function (val) {
    this.withSenseiNotes = val;
  };

  manager.setWithSenseiChats = function (val) {
    this.withSenseiChats = val;
  };

  manager.setRole = function (_role) {
    this.role = _role;
  };
  manager.getRole = function () {
    return this.role;
  };
  manager.isGamer = function () {
    return this.role === "gamer";
  };
  manager.isSensei = function () {
    return this.role === "sensei";
  };
  manager.setNoMoreUpcomingOrActiveSessions = function (
    _noMoreUpcomingOrActiveSessions
  ) {
    this.noMoreUpcomingOrActiveSessions = _noMoreUpcomingOrActiveSessions;
  };
  manager.getNoMoreUpcomingOrActiveSessions = function () {
    return this.noMoreUpcomingOrActiveSessions;
  };
  manager.getLessonChanged = function () {
    return $filter("filter")(manager.actionableLessons, function (d) {
      return d.unread;
    }).length;
  };
  manager.addOrUpdateToActionable = function (_request) {
    manager.actionableLessons.addOrUpdateByRequest(_request, manager.role);
  };
  manager.hasFilter = {
    date: function () {
      return !!$stateParams.date;
    },
    status: function () {
      return !!$stateParams.status;
    },
    tokenType: function () {
      return !!$stateParams.tokenType;
    },
    gameID: function () {
      return !!$stateParams.game;
    },
    any: function () {
      return this.date() || this.status() || this.gameID();
    },
    withSenseiNotes: function () {
      return !!this.withSenseiNotes;
    },
    withSenseiChats: function () {
      return !!this.withSenseiChats;
    },
    studentName: function () {
      return !!$stateParams.studentName;
    },
  };
  return manager;
}

senseiTrainingRequestManagerService.$inject = [
  "$stateParams",
  "Dashboard",
  "SenseiTrainingRequest",
  "SenseiTrainingRequestHolder",
  "$q",
  "$filter",
];

function toFromDate(date) {
  var fromDate = moment(date).utc().format("YYYY-MM-DDT:HH:mm:ss") + "Z";
  var toDate =
    moment(date).add(1, "days").utc().format("YYYY-MM-DDT:HH:mm:ss") + "Z";
  return [fromDate, toDate];
}
