import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class PasswordRecoveryPageController {
  constructor(
    $rootScope,
    $scope,
    $window,
    $timeout,
    passwordRecoveryCheck,
    PasswordReset,
    OmnibarPointer,
    MetaTagsService,
    AccessControl
  ) {
    $rootScope.bodylayout = "recovery request";
    this.$window = $window;
    this.timeout = $timeout;
    this.passwordReset = PasswordReset;
    this.omnibarPointer = OmnibarPointer;
    this.access = AccessControl;
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;
    this.gigya = $scope.isGigyaOn;
    this.isPasswordRecoveryVisible =
      passwordRecoveryCheck.data.ui_feature_gates.password_recovery;

    $window.recaptchaResults = this.recaptchaResults;
    $window.recaptchaSetupError = this.recaptchaSetupError;

    this.timeout(() => this.initRecaptcha($scope, $window), 0);

    this.initMetaTags(MetaTagsService);

    angular.element(() => {
      if (!this.isPasswordRecoveryVisible) {
        $window.location.href = "/";
      }
    });
  }

  // Init setup
  initMetaTags(MetaTagsService) {
    const metaTitle = "Request to Change Your Gamer Sensei Password";
    const metaDescription = "Change your password.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initRecaptcha($scope, $window) {
    const watchCaptcha = $scope.$watch(
      () => {
        return $window.grecaptcha;
      },
      (_newVal) => {
        if (
          $window.grecaptcha &&
          typeof $window.grecaptcha.render === "function"
        ) {
          grecaptcha.render("recaptcha-check", {
            sitekey: this.access.invisibleSiteKey,
            callback: (token) => this.recaptchaResults(token),
            "error-callback": () => this.recaptchaSetupError,
            size: "invisible",
            theme: "dark",
          });
          watchCaptcha();
        }
      }
    );
  }

  // Recaptcha
  recaptchaResults(token) {
    this.captchaToken = token;
    this.completeRequestRecovery();
  }

  recaptchaSetupError() {
    this.noRecaptcha = true;
  }

  checkCaptchaAndRequestRecovery() {
    if (this.noRecaptcha || !this.gigya) {
      this.completeRequestRecovery();
    } else {
      this.$window.grecaptcha.execute();
    }
  }

  completeRequestRecovery() {
    const email = {
      email: this.emailRequest,
    };

    this.passwordReset
      .request(email)
      .then((res) => {
        this.passwordReset.setStatus("requested");
        this.timeout(() => {
          this.$window.location.href = "/sign_in/new";
        }, 0);
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }
}

PasswordRecoveryPageController.$inject = [
  "$rootScope",
  "$scope",
  "$window",
  "$timeout",
  "passwordRecoveryCheck",
  "PasswordReset",
  "OmnibarPointer",
  "MetaTagsService",
  "AccessControl",
];
