import sessionBundleTierTemplate from "../../templates/directives/session/session_bundle_tier_template";

export default function tokenBundleTierDirective(TokenService, CartManager) {
  return {
    restrict: "E",
    scope: {
      tierName: "=",
      addCartItem: "&",
    },
    link: function (scope, elem, attrs) {
      scope.tokenBundles = TokenService.getTokenBundles();
      scope.cart = CartManager;
      scope.currentTierBundles = [];

      angular.forEach(scope.tokenBundles, function (bundle) {
        if (bundle.token_tier === scope.tierName) {
          scope.currentTierBundles.push(bundle);
        }
      });

      scope.isThereAPriceReduction = function (token) {
        return (
          token.advertised_student_cost &&
          token.advertised_student_cost > token.base_student_cost
        );
      };

      scope.getBundleShortName = function (bundle) {
        var count = 0;

        angular.forEach(bundle.items, function (item) {
          count = count + item.quantity;
        });

        var name = bundle.token_type
          ? `${bundle.token_type} ${count}-Pack`
          : `${count}-Pack`;

        return name;
      };
    },
    template: sessionBundleTierTemplate,
  };
}

tokenBundleTierDirective.$inject = ["TokenService", "CartManager"];
