import Blazy from "blazy";

export default function lazyLoadService() {
  var bLazy;

  var service = {
    set: setLazyLoad,
  };

  return service;

  ///////////

  function setLazyLoad() {
    return (bLazy = new Blazy({
      src: "data-load",
      srcset: "data-set",
      breakpoints: [
        {
          width: 767,
          src: "data-load-small",
        },
      ],
      offset: 100,
      loadInvisible: true,
      selector: ".lazy",
      successClass: "loaded",
      errorClass: "error",
    }));
  }
}
