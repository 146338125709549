export default function senseiTrainingRequestAPIService($http) {

  var service = {
    show: getById,
    addNotes: addNotes,
    addAssessment: addAssessment,
    addMessage: addMessage
  };

  return service;

  ///////////

  function getById(id) {
    return $http.get('/api/web/sensei_training_requests/' + id);
  }

  function addNotes(id, notes) {
    return $http.put(`/api/web/sensei_training_requests/${id}/add_notes`, notes);
  }

  function addAssessment(id, rubricSelections, assessments) {
    return $http.put(`/api/web/sensei_training_requests/${id}/add_assessment`, { 
      rubric_selections: rubricSelections,
      ...assessments
    });
  }

  function addMessage(id, message) {
    return $http.post(`/api/web/sensei_training_requests/${id}/add_message`, message);
  }
}

senseiTrainingRequestAPIService.$inject = ['$http'];
