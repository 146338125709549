export default class DisclaimerPageController {
  constructor(MetaTagsService) {
    this.currentYear = new Date().getFullYear();

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Disclaimers | Gamer Sensei";
    const metaDescription = "Gamer Sensei general disclaimers.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

DisclaimerPageController.$inject = ["MetaTagsService"];
