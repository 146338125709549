import { calendarIcon, settingsIcon, walletIcon } from "../../icons/iconConfig";

const sidebarMenuSensei = (status, subscriptionsOn) => {
  const menu = [
    {
      name: "Sessions",
      icon: calendarIcon,
      dockLink: "sensei_dashboard.lessons.list",
      submenu: lessonSubMenu(status, subscriptionsOn),
    },
    {
      name: "Preferences",
      icon: settingsIcon,
      dockLink: "sensei_dashboard.profile",
      submenu: preferencesSubmenu(status, subscriptionsOn),
    },
  ];

  if (status === "active") {
    menu.push({
      name: "Wallet",
      icon: walletIcon,
      dockLink: "sensei_dashboard.wallet.cashout",
      submenu: [
        {
          name: "Cash Out",
          state: "sensei_dashboard.wallet.cashout",
        },
        {
          name: "Transaction History",
          state: "sensei_dashboard.wallet.history",
        },
      ],
    });
  }

  return menu;
};

function preferencesSubmenu(status, subscriptionsOn) {
  const submenuItems = [
    {
      displayOrder: 1,
      name: "Profile",
      state: "sensei_dashboard.profile",
    },
    {
      displayOrder: 2,
      name: "Contact Information",
      state: "sensei_dashboard.contact_information",
    },
    {
      displayOrder: 6,
      name: "Notification Settings",
      state: "sensei_dashboard.notifications",
    },
  ];

  if (status === "active") {
    submenuItems.push({
      displayOrder: 3,
      name: "Set Schedule",
      state: "sensei_dashboard.availability",
    });
    submenuItems.push({
      displayOrder: 4,
      name: "Session Offerings",
      state: "sensei_dashboard.session_offerings",
    });

    if (subscriptionsOn) {
      submenuItems.push({
        displayOrder: 5,
        name: "Subscription Plans",
        state: "sensei_dashboard.subscription_plans",
      });
    }
  }

  return submenuItems;
}

function lessonSubMenu(status, subscriptionsOn) {
  const submenuItem = [
    {
      displayOrder: 1,
      name: "Calendar View",
      state: "sensei_dashboard.lessons.calendar",
      defaultActiveState: true,
    },
    {
      displayOrder: 2,
      name: "List View",
      state: "sensei_dashboard.lessons.list",
    },
  ];

  if (status === "active") {
    submenuItem.push({
      displayOrder: 4,
      name: "Reviews",
      state: "sensei_dashboard.lessons.my_reviews",
    });
    submenuItem.push({
      displayOrder: 5,
      name: "Session Notes",
      state: "sensei_dashboard.lessons.sensei_notes",
    });
    submenuItem.push({
      displayOrder: 6,
      name: "Messages",
      state: "sensei_dashboard.lessons.sensei_chats",
    });
    submenuItem.push({
      displayOrder: 3,
      name: "Student History",
      state: "sensei_dashboard.lessons.student_history",
    });

    if (subscriptionsOn) {
      submenuItem.push({
        displayOrder: 3,
        name: "Subscribers",
        state: "sensei_dashboard.lessons.subscribers",
      });
    }
  }

  return submenuItem;
}

export default sidebarMenuSensei;
