export default function lazyLoadResetDirective($timeout, LazyLoad) {
  return function (scope) {
    // when last item in repeater is initialized
    // init bLazy
    if (scope.$last) {
      $timeout(function () {
        LazyLoad.set().revalidate();
      }, 0);
    }
  };
}

lazyLoadResetDirective.$inject = ["$timeout", "LazyLoad"];
