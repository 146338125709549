const userIsOnProfile = (url) => {
  const profileUrls = [
    "/profile",
    "/contact-info",
    "/pricing",
    "/payment",
    "/availability",
    "/notifications",
    "/sensei-kit",
  ];

  return profileUrls.includes(url);
};

export default userIsOnProfile;
