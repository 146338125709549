import collegesImg from "../../images/backgrounds/game_time02";
import testimonialVid from "../../videos/testimonials/testimonial_kennesaw_state.mp4";
import { seminarIcon, workshopIcon } from "../../config/icons/iconConfig";
import { populateGameIds } from "../../config/utils/utilsConfig";

export default class CollegesPageController {
  constructor(
    $rootScope,
    User,
    MetaTagsService,
    EventTrackingManager,
    PartnersUtils,
    LazyLoad,
    OmnibarPointer,
    ProgramContactService,
    pulledGames
  ) {
    // angular providers
    this.root = $rootScope;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.partners = PartnersUtils;
    this.programContact = ProgramContactService;
    this.userService = User;
    // injected and imported
    this.collegesImg = collegesImg;
    this.testimonialVid = testimonialVid;
    this.games = pulledGames.data.games;
    // booleans
    this.editGamesFlag = false;
    this.formSubmit = false;
    // arrays and objects
    this.currentUser = {
      games: [],
    };
    this.gameIds = [];
    this.eduOfferings = {
      title: `<h2 class="h1 text-light">Educational<br />Offerings</h1>`,
      body: `<p>We also offer seminars and workshops, assisting collegiate organizations build and maintain the highest quality of esports programs. Don't just level up your players, upgrade your coaching staff too.</p><div class="gs-flx gs-flx-y-0 gs-flx-even"><div class="section-block gs-flx gs-flx-col"><div class="icon icon-marketing icon-light">${workshopIcon}</div><h4 class="text-light mt-2 mb-2">Workshop</h4><ul><li>5-10 participants</li><li>More individual attention</li><li>2-3 hours runtime</li></ul></div><div class="section-block gs-flx gs-flx-col"><div class="icon icon-marketing icon-light">${seminarIcon}</div><h4 class="text-light mt-2 mb-2">Seminar</h4><ul><li>10-50 attendees</li><li>Lecture with Q&amp;A</li><li>2 hours runtime</li></ul></div></div>`,
    };

    this.initMetaTags(MetaTagsService);

    // DOM ready
    angular.element(() => this.initPage(LazyLoad));
  }

  // init methods
  initUser() {
    const watchUser = this.root.$watch(
      () => this.userService._userData,
      (newVal) => {
        if (newVal) {
          this.currentUser = newVal;
          this.gameIds = populateGameIds(newVal.games);
          watchUser();
        }
      }
    );
  }
  initMetaTags(MetaTagsService) {
    const metaTitle = "Schools | Gamer Sensei";
    const metaDescription =
      "Take your school's esport team to the next level with a professional coach from Gamer Sensei.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initPage(LazyLoad) {
    LazyLoad.set();
    this.eventTracking.pushViewEvent("Schools");
  }

  editGamesToggle() {
    this.editGamesFlag = !this.editGamesFlag;
  }

  sendProgramContactInfo($event) {
    if ($event) $event.preventDefault();
    if (
      !this.currentUser.game_description ||
      this.currentUser.game_description.length === 0
    ) {
      return this.omnibarGameError();
    }

    this.formSubmit = true;

    const sendParams = {
      program_contact_form: {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        school_name: this.schoolName,
        program_name: this.programName,
        games: this.currentUser.game_description
          ? this.currentUser.game_description.split(", ")
          : null,
        receive_newsletter: this.agreeNotifications,
      },
    };

    this.programContact
      .sendProgramContactInfo(sendParams)
      .then(() => {
        this.formSubmit = false;
        this.contactComplete = true;

        let omniTooltipParams = {
          title: "Submitted",
          body: "<p>Your application has been submitted.</p>",
        };

        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          timer: true,
        });
      })
      .catch((err) => {
        this.formSubmit = false;
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  omnibarGameError() {
    let omniTooltipParams = {
      title: "Game required",
      body: "<p>Error. Please select a game and try again.</p>",
    };

    this.omnibarPointer.pushTooltip(omniTooltipParams, {
      tooltipCallback: false,
      timer: true,
    });
  }
}

CollegesPageController.$inject = [
  "$rootScope",
  "User",
  "MetaTagsService",
  "EventTrackingManager",
  "PartnersUtils",
  "LazyLoad",
  "OmnibarPointer",
  "ProgramContactService",
  "pulledGames",
];
