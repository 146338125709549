export default function flexiTokenService($q, $http) {
  var flexEndpoint = "/api/web/flexi_tokens";
  var flexPurchaseEndpoint = "/api/web/flexi_token_bulk_purchases";
  var flexRedeemEndpoint = "/api/web/flexi_token_bulk_redeemers";
  var service = {
    loading: false,
    finished: false,
    retailer: null,
    cart: {
      status: "none",
      modal: false,
      tab: null,
      qty: 5,
      payment: {
        credit_card: { void: true },
        paypal: { void: true },
        amazon_pay: { void: true },
      },
      items: {},
    },
    // flexi items and selection
    selectedFlexiToken: {},
    selectedTokenTemplateOption: {},
    flexiTokens: [],
    tokenTemplateOptions: [],
    getFlexiRetailerId,
    setFlexiRetailerId,
    getSelectedFlexiToken,
    chooseSelectedFlexiToken,
    getSelectedTokenTemplateOption,
    chooseSelectedTokenTemplateOption,
    getFlexiTokens,
    setFlexiTokens,
    getTokenTemplateOptions,
    setTokenTemplateOptions,
    // flexi purchasing
    getCartModal,
    setCartModal,
    getPaymentTab,
    setPaymentTab,
    getStatus,
    getCartItems,
    adjustQty,
    getPaymentDetails,
    setPaymentDetails,
    isPaymentMethodActive,
    processFlexiTokenPurchase,
    redeemOwnedFlexTokens,
  };

  window.flex = service;

  return service;

  /////////////

  function getFlexiRetailerId() {
    return service.retailer;
  }

  function setFlexiRetailerId(id) {
    service.retailer = id;
  }

  function getSelectedFlexiToken() {
    return service.selectedFlexiToken;
  }

  function chooseSelectedFlexiToken(selected) {
    service.selectedFlexiToken = selected;
    service.setTokenTemplateOptions(selected.token_template_options);
    service.chooseSelectedTokenTemplateOption(
      selected.token_template_options[0]
    );
  }

  function getSelectedTokenTemplateOption() {
    return service.selectedTokenTemplateOption;
  }

  function chooseSelectedTokenTemplateOption(selected) {
    service.selectedTokenTemplateOption = selected;
  }

  function getSelectedTokenTemplateOption() {
    return service.selectedTokenTemplateOption;
  }

  function chooseSelectedTokenTemplateOption(selected) {
    service.selectedTokenTemplateOption = selected;
  }

  function getFlexiTokens() {
    return service.flexiTokens;
  }

  function setFlexiTokens(params) {
    service.loading = true;
    return $http.get(flexEndpoint, params).then((res) => {
      service.flexiTokens = formatFlexiTokens(res.data.flexi_tokens);
      service.loading = false;
      service.finished = true;
      service.chooseSelectedFlexiToken(service.flexiTokens[0]);
    });
  }

  function getTokenTemplateOptions() {
    return service.tokenTemplateOptions;
  }

  function setTokenTemplateOptions(options) {
    service.tokenTemplateOptions = options;
  }

  function adjustQty(qty) {
    service.cart.qty = qty;
  }

  function getCartModal() {
    return service.cart.modal;
  }

  function setCartModal(bool) {
    service.cart.modal = bool;
  }

  function getPaymentTab() {
    return service.cart.tab;
  }

  function setPaymentTab(tab) {
    service.cart.tab = tab;
  }

  function getStatus() {
    return service.cart.status;
  }

  function getCartItems() {
    return service.cart.items;
  }

  function getPaymentDetails() {
    return service.cart.payment;
  }

  function setPaymentDetails(payment) {
    service.cart.payment = payment;
  }

  function isPaymentMethodActive() {
    var active = true;
    var payConfig = service.getPaymentDetails();
    var payTab = service.getPaymentTab();

    if (payConfig.amazon_pay.void && payTab === "amazonPay") {
      active = false;
    }
    if (payConfig.paypal.void && payTab === "paypal") {
      active = false;
    }
    if (payConfig.credit_card.void && payTab === "card") {
      active = false;
    }

    return active;
  }

  function processFlexiTokenPurchase() {
    var deferred = $q.defer();
    var data = {
      ...service.cart.payment,
      token_retailer_id: service.retailer,
      flexi_token_templates: [
        {
          flexi_token_template_id: service.selectedFlexiToken.id,
          flexi_token_template_name:
            service.selectedFlexiToken.flexi_token_name,
          count: service.cart.qty,
        },
      ],
    };

    $http
      .post(flexPurchaseEndpoint, data)
      .then((res) => {
        deferred.resolve(res);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function redeemOwnedFlexTokens() {
    var data = {
      flexi_tokens: {
        count: service.cart.qty,
        token_retailer_id: service.retailer,
        token_template_id:
          service.selectedTokenTemplateOption.token_template_id,
        flexi_token_template_id: service.selectedFlexiToken.id,
      },
    };
    return $http.post(flexRedeemEndpoint, data);
  }

  // Internal functions
  function formatFlexiTokens(tokens) {
    var initialValue = [];
    return tokens.reduce(function (arr, item) {
      return [
        ...arr,
        {
          ...item,
          token_template_options: formatTokenTemplates(
            item.token_template_options
          ),
        },
      ];
    }, initialValue);
  }

  function formatTokenTemplates(templates) {
    var initialValue = [];
    return templates.reduce(function (arr, item) {
      return [
        ...arr,
        {
          ...item,
          quantity: item.quantity ? item.quantity : 1,
        },
      ];
    }, initialValue);
  }
}

flexiTokenService.$inject = ["$q", "$http"];
