export default function availabilityProposeService($http) {

  var service = {
    getSenseiAvailability: getSenseiAvailability
  };

  return service;

  ///////////

  function getSenseiAvailability(config) {
    return $http.get('/api/web/bookable_hours', config);
  }
}

availabilityProposeService.$inject = ['$http'];