export default function tokenSubscriptionService($q, $http) {
  var subEndpoint = "/api/web/token_subscription_plans";
  var subItemEndpoint = "/api/web/sensei_subscription_items";

  var service = {
    loading: true,
    subscriptions: {},
    senseiPlans: {},
    subscriptionItems: {},
    selectedSubscription: {},
    getLoadingStatus,
    getSubscriptionList,
    setSubscriptionList,
    getSelectedSubscription,
    setSelectedSubscription,
    getSenseiSubscriptionPlans,
    getSubscriptionItems,
    setSubscriptionItems,
    createSubscriptionItems,
    updateSubscriptionItems,
    deleteSubscriptionItems,
  };

  window.sub = service;

  return service;

  // Service functions
  function getLoadingStatus() {
    return service.loading;
  }

  function getSubscriptionList() {
    return service.subscriptions;
  }

  function setSubscriptionList() {
    if (!service.loading) service.loading = true;
    var deferred = $q.defer();

    fetchSubscriptions()
      .then((res) => {
        service.subscriptions = formatTokenSubscriptionsToObj(
          res.data.token_subscription_plans
        );
        service.loading = false;
        deferred.resolve(service.subscriptions);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getSelectedSubscription() {
    return service.selectedSubscription;
  }

  function setSelectedSubscription(selected) {
    service.selectedSubscription = selected;
  }

  function getSenseiSubscriptionPlans() {
    return service.senseiPlans;
  }

  function getSubscriptionItems() {
    return service.subscriptionItems;
  }

  function setSubscriptionItems(id) {
    if (!service.loading) service.loading = true;
    var deferred = $q.defer();

    return $http
      .get(subItemEndpoint, { params: { sensei_id: id } })
      .then((res) => {
        service.subscriptionItems = formatTokenSubscriptionsToObj(
          res.data.sensei_subscription_items
        );
        service.senseiPlans = res.data.sensei_subscription_plan;
        service.loading = false;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });
  }

  function createSubscriptionItems(subItemObj) {
    if (!service.loading) service.loading = true;
    var deferred = $q.defer();

    return $http
      .post(subItemEndpoint, subItemObj)
      .then((res) => {
        var planItem = res.data.sensei_subscription_items;
        service.subscriptionItems = addPlanItem(planItem);
        service.senseiPlans = res.data.sensei_subscription_plan;

        service.loading = false;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });
  }

  function updateSubscriptionItems(id) {
    if (!service.loading) service.loading = true;

    return $http
      .put(`${subItemEndpoint}?id=${id}`)
      .then((res) => {
        planItem = res.data.sensei_subscription_items;

        service.subscriptionItems = addPlanItem(planItem);
        service.loading = false;
      })
      .catch((err) => {
        service.loading = false;
        return err;
      });
  }

  function deleteSubscriptionItems(id) {
    if (!service.loading) service.loading = true;
    var deferred = $q.defer();

    return $http
      .delete(`${subItemEndpoint}?id=${id}`)
      .then((res) => {
        delete service.subscriptionItems[`planItem-${id}`];
        service.loading = false;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });
  }

  // Internal functions
  function fetchSubscriptions() {
    return $http.get(subEndpoint);
  }

  function formatTokenSubscriptionsToObj(items) {
    const initialValue = {};

    return items.reduce((obj, item) => {
      const keyName = returnItemTypeAndId(item);

      return {
        ...obj,
        [`${keyName}`]: {
          ...item,
        },
      };
    }, initialValue);
  }

  function returnItemTypeAndId(item) {
    if (item.token_subscription_plan_id) {
      return `planItem-${item.id}`;
    } else if (item.token_template && !item.token_bundle) {
      return `single-${item.token_template.id}-${item.id}`;
    } else if (item.token_bundle && !item.token_template) {
      return `bundle-${item.token_bundle.id}-${item.id}`;
    } else {
      return `senseiSub-${item.id}`;
    }
  }

  function addPlanItem(planItem) {
    return {
      ...service.subscriptionItems,
      [`planItem-${planItem.id}`]: planItem,
    };
  }
}

tokenSubscriptionService.$inject = ["$q", "$http"];
