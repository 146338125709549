export default class SenseiLessonsNotesListController {
  constructor(
    $rootScope,
    $state,
    $stateParams,
    UIFeatureGate,
    SenseiTrainingRequestManager,
    User
  ) {
    $rootScope.bodylayout = "lessons list sensei";
    // angular providers
    this.$state = $state;
    // services
    this.userService = User;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.SenseiTrainingRequestManager.setWithSenseiNotes(true);
    this.SenseiTrainingRequestManager.setWithSenseiChats(null);
    this.allLessons = this.SenseiTrainingRequestManager.allLessons;

    // Feature Gate
    this.isSenseiNotesAvailable = true;
    UIFeatureGate.data.ui_feature_gates.sensei_notes_available;

    if ($stateParams.studentName) {
      this.studentName = $stateParams.studentName;
    }

    if ($stateParams.tokenType) {
      this.tokenType = $stateParams.tokenType;
    }

    this.initUser();
    this.initSenseiNotesList(true);
  }

  // init methods
  initUser() {
    this.userService
      .getUser()
      .then((userData) => {
        this.currentUser = userData;
      })
      .catch(angular.noop);
  }
  initSenseiNotesList(force) {
    this.SenseiTrainingRequestManager.init(force)
      .then(function () {})
      .catch(angular.noop);
  }

  // display methods
  buildSessionTitle(session) {
    let time;

    if (session.sessionDurationHours === 0.5) {
      time = "30-Minute";
    } else {
      time = `${session.sessionDurationHours}-Hour`;
    }

    if (session.tokenTypeName) {
      return `${time} ${session.gameName} ${session.tokenTypeName} Session on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
    }

    return `${time} ${session.gameName} Lesson on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
  }

  // action methods
  filterBy() {
    const notesLink = "sensei_dashboard.lessons.sensei_notes";

    this.$state.go(notesLink, {
      studentName: this.studentName,
      tokenType: this.tokenType,
    });
  }

  toggleSenseiNotes(lesson) {
    lesson.senseiNotesOpened = !lesson.senseiNotesOpened;
  }

  openNotes(lesson) {
    this.$state.go("sensei_dashboard.lessons.sensei_note_page", {
      lessonId: lesson.request.id,
    });
  }
}

SenseiLessonsNotesListController.$inject = [
  "$rootScope",
  "$state",
  "$stateParams",
  "UIFeatureGate",
  "SenseiTrainingRequestManager",
  "User",
];
