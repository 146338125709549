export default class GiftCardLandingController {
  constructor($scope, $state, GiftCardManager, OmnibarPointer, UIFeatureGate) {
    this.$state = $state;
    this.userId = $scope.currentId;
    this.omnibarPointer = OmnibarPointer;
    this.giftCard = GiftCardManager;
    this.buttonSelected = true;
    this.customError = false;
    this.customPrice = null;
    this.errorMessage = "";
    this.confirmRecipientEmail = "";

    if (
      UIFeatureGate.data.ui_feature_gates
        .final_balance_protection_measures_activated
    ) {
      window.location = "/404";
      return;
    }

    this.giftOptions = [2000, 5000, 10000];
    const selectedOption = this.giftCard.selectedOption;

    if (selectedOption && this.giftOptions.indexOf(selectedOption) === -1) {
      this.customPrice = selectedOption / 100;
    }
  }

  nextStep() {
    if (this.isValid()) {
      if (this.userId) {
        this.$state.go("gift-cards.payment");
      } else {
        this.$state.go("gift-cards.account");
      }
    } else {
      this.omnibarPointer.pushErrorByMessage(this.errorMessage);
    }
  }
  isValid() {
    let valid = true;
    this.errorMessage = "";

    if (this.giftCard.selectedOption < 500) {
      this.errorMessage += "Please select an amount at least $5.<br>";
      valid = false;
    }
    if (!this.giftCard.selectedDesign) {
      this.errorMessage += "Please select a design.<br>";
      valid = false;
    }
    if (this.giftCard.deliveryMethod === "email") {
      if (!this.giftCard.recipientEmail) {
        this.errorMessage += "Please enter an email to send the gift card.<br>";
        valid = false;
      } else if (this.giftCard.recipientEmail !== this.confirmRecipientEmail) {
        this.errorMessage +=
          "Emails do not match, please check and try again.<br>";
        valid = false;
      }
    }
    if (!this.giftCard.recipientName) {
      this.errorMessage += "Please enter a name for the recepient.<br>";
      valid = false;
    }
    return valid;
  }

  hasCustomError() {
    return !this.buttonSelected && this.customError;
  }

  hintMessage() {
    let message = "";

    if (this.giftCard.deliveryMethod === "print") {
      message =
        "You will be presented with a code and message that you can print at home.";
    } else {
      message =
        "Your card will be sent as soon as possible. Usually within 2 hours of processing but may take up to 24 hours";
    }

    return message;
  }

  selectDesign(option) {
    this.giftCard.selectedDesign = option;
    this.giftCard.addToCartAnalytics();
  }

  getPriceButtonClass(option) {
    if (this.giftCard.selectedOption === option && this.buttonSelected) {
      return "btn-green";
    }

    return "btn-gray-solid";
  }

  selectOption(custom, option) {
    if (custom) {
      this.buttonSelected = false;
      this.giftCard.selectedOption = option * 100;
      this.customError = option < 5;
    } else {
      this.buttonSelected = true;
      this.giftCard.selectedOption = option;
    }

    this.giftCard.addToCartAnalytics();
  }

  showWhenNecessary() {
    const confirmState = this.$state.current.name === "gift-cards.confirmation";
    const deliveryMethod = this.giftCard.deliveryMethod === "print";

    return !(confirmState && deliveryMethod);
  }
}

GiftCardLandingController.$inject = [
  "$scope",
  "$state",
  "GiftCardManager",
  "OmnibarPointer",
  "UIFeatureGate",
];
