import selectGamesTemplate from "../../../templates/directives/popup/select/select_games_template";

export default function selectGamesService($rootScope, Game, GamePublisher) {
  return {
    restrict: "E",
    scope: {
      editGamesFlag: "=",
      gameIds: "=",
      user: "=",
    },
    template: selectGamesTemplate,
    transclude: true,
    link: link,
  };

  function link(scope, elem, attrs) {
    let originalGames = [];
    let elems;
    scope.games = Game.getGames();

    scope.editGamesToggle = () => {
      const currentGames = scope.user.games;
      scope.editGamesFlag = !scope.editGamesFlag;

      if (scope.editGamesFlag) {
        elems = angular.element(document.getElementsByClassName("gs-ms--item"));

        angular.forEach(elems, (item) => {
          const gameId = parseInt(item.attributes["data-game-id"].value);
          for (let x in currentGames) {
            if (gameId === currentGames[x].id) {
              angular.element(item).addClass("gs-ms--item-selected");
            }
          }
        });
      } else {
        scope.updateGames();
      }
    };

    scope.select = (e) => {
      let gameDescriptionArray = [];
      const target = angular.element(e.currentTarget);
      const gameId = parseInt(target[0].attributes["data-game-id"].value);
      const gameName = target[0].attributes["data-game-name"].value;

      if (target.hasClass("gs-ms--item-selected")) {
        const indexId = scope.gameIds.indexOf(gameId);
        const indexName = gameDescriptionArray.indexOf(gameName);

        if (indexId > -1 || indexName > -1) {
          scope.gameIds.splice(indexId, 1);
          gameDescriptionArray.splice(indexName, 1);
        }

        target.removeClass("gs-ms--item-selected");
      } else if ($rootScope.role == "sensei") {
        scope.gameIds = [gameId];
        elems = angular.element(
          document.getElementsByClassName("gs-ms--item gs-ms--item-selected")
        );
        angular.forEach(elems, (item) => {
          angular.element(item).removeClass("gs-ms--item-selected");
        });
        target.addClass("gs-ms--item-selected");
      } else {
        scope.gameIds.push(gameId);
        target.addClass("gs-ms--item-selected");
      }

      elems = angular.element(
        document.getElementsByClassName("gs-ms--item gs-ms--item-selected")
      );
      angular.forEach(elems, (item) => {
        gameDescriptionArray.push(item.attributes["data-game-name"].value);
      });

      scope.user.game_description = gameDescriptionArray.sort().join(", ");
      scope.updateGames();
    };

    scope.updateGames = () => {
      originalGames =
        originalGames.length > 0 ? originalGames : scope.user.games;
      const tempGames = [];

      angular.forEach(scope.games, (game) => {
        angular.forEach(scope.gameIds, (id) => {
          if (game.id == id) {
            tempGames.push(game);
          }
        });
      });

      angular.forEach(tempGames, (game, ind) => {
        angular.forEach(originalGames, (originalGame) => {
          if (game.id == originalGame.id) {
            tempGames[ind] = originalGame;
          }
        });
      });

      scope.user.games = tempGames;
      GamePublisher.refreshPublisherConnections(scope.currentId);
    };
  }
}

selectGamesService.$inject = ["$rootScope", "Game", "GamePublisher"];
