import imgDisplayTemplate from "../../templates/directives/display/image_display_template";
import { gsLogoEmblem } from "../../config/brand/brandConfig";

export default function imageDisplayDirective($timeout, LazyLoad) {
  return {
    restrict: "E",
    link,
    scope: {
      image: "=",
      webp: "=",
      watermark: "@",
      altText: "=",
    },
    template: imgDisplayTemplate,
  };

  function link(scope, elem, attrs) {
    scope.gsLogoEmblem = gsLogoEmblem;

    $timeout(function () {
      LazyLoad.set();
    });
  }
}

imageDisplayDirective.$inject = ["$timeout", "LazyLoad"];
