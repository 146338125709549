import dashboardModulesApp from "./gs-dashboard-modules";
import { uiFeatureGate, getGames } from "../config/utils/utilsConfig";

const dashboardGamerApp = angular.module("gamerSenseiGamerDashboard", [
  dashboardModulesApp.name,
]);

// Service setup
import gamerNotificationPreferenceApiService from "../services/gamers/gamer-notification-preferences";

dashboardGamerApp.factory(
  "GamerNotificationPreferenceApi",
  gamerNotificationPreferenceApiService
);

// Routing Setup
import ChatsListController from "../controllers/dashboard/user/chats/chats-list-controller";
import ChatsListTemplate from "../templates/dashboard/user/chats/chats_list_template";

import ChatsPageController from "../controllers/dashboard/user/chats/chats-page-controller";
import ChatsPageTemplate from "../templates/dashboard/user/chats/chats_page_template";

import GamerAccountContactController from "../controllers/dashboard/gamer/account/gamer-account-contact-controller";
import GamerAccountContactTemplate from "../templates/dashboard/gamer/account/gamer_account_contact_template";

import GamerAccountNotificationsController from "../controllers/dashboard/gamer/account/gamer-account-notifications-controller";
import GamerAccountNotificationsTemplate from "../templates/dashboard/gamer/account/gamer_account_notifications_template";

import GamerAccountPaymentController from "../controllers/dashboard/gamer/account/gamer-account-payment-controller";
import GamerAccountPaymentTemplate from "../templates/dashboard/gamer/account/gamer_account_payment_template";

import GamerAccountProfileController from "../controllers/dashboard/gamer/account/gamer-account-profile-controller";
import GamerAccountProfileTemplate from "../templates/dashboard/gamer/account/gamer_account_profile_template";

import GamerDashboardContainerController from "../controllers/dashboard/gamer/gamer-dashboard-container-controller";
import GamerDashboardContainerTemplate from "../templates/dashboard/gamer/gamer_dashboard_container_template";

import GamerDashboardLandingTemplate from "../templates/dashboard/gamer/gamer_dashboard_landing_template";

import GamerLessonsCalendarController from "../controllers/dashboard/gamer/lessons/gamer-lessons-calendar-controller";
import GamerLessonsCalendarTemplate from "../templates/dashboard/gamer/lessons/gamer_lessons_calendar_template";

import GamerLessonsBookingCalendarController from "../controllers/dashboard/gamer/lessons/gamer-lessons-booking-calendar-controller";
import GamerLessonsBookingCalendarTemplate from "../templates/dashboard/gamer/lessons/gamer_lessons_booking_calendar_template";

import GamerLessonsBookingLandingController from "../controllers/dashboard/gamer/lessons/gamer-lessons-booking-landing-controller";
import GamerLessonsBookingLandingTemplate from "../templates/dashboard/gamer/lessons/gamer_lessons_booking_landing_template";

import GamerLessonsNotesListController from "../controllers/dashboard/gamer/lessons/gamer-lessons-notes-list-controller";
import GamerLessonsNotesListTemplate from "../templates/dashboard/gamer/lessons/gamer_lessons_notes_list_template";

import GamerLessonsReviewsController from "../controllers/dashboard/gamer/lessons/gamer-lessons-reviews-controller";
import GamerLessonsReviewsTemplate from "../templates/dashboard/gamer/lessons/gamer_lessons_reviews_template";

import GamerWalletController from "../controllers/dashboard/gamer/wallet/gamer-wallet-controller";

import GamerWalletHistoryController from "../controllers/dashboard/gamer/wallet/gamer-wallet-history-controller";
import WalletHistoryTemplate from "../templates/dashboard/user/wallet/wallet_history_template";

import GamerWalletRedeemGiftCardController from "../controllers/dashboard/gamer/wallet/gamer-wallet-redeem-gift-card-controller";
import GamerWalletRedeemGiftCardTemplate from "../templates/dashboard/gamer/wallet/gamer_wallet_redeem_gift_card_template";

import GamerWalletRedeemSessionController from "../controllers/dashboard/gamer/wallet/gamer-wallet-redeem-session-template";
import GamerWalletRedeemSessionTemplate from "../templates/dashboard/gamer/wallet/gamer_wallet_redeem_session_template";

import GamerWalletSessionHistoryController from "../controllers/dashboard/gamer/wallet/gamer-wallet-session-history-controller";
import GamerWalletSessionHistoryTemplate from "../templates/dashboard/gamer/wallet/gamer_wallet_session_history_template";

import GamerWalletSubscriptionsController from "../controllers/dashboard/gamer/wallet/gamer-wallet-subscriptions-controller";
import GamerWalletSubscriptionsTemplate from "../templates/dashboard/gamer/wallet/gamer_wallet_subscriptions_template";

import GamerWalletSummaryController from "../controllers/dashboard/gamer/wallet/gamer-wallet-summary-controller";
import GamerWalletSummaryTemplate from "../templates/dashboard/gamer/wallet/gamer_wallet_summary_template";

import LessonCardListController from "../controllers/dashboard/user/lessons/lessons-card-list-controller";
import LessonCardListTemplate from "../templates/dashboard/user/lessons/lessons_card_list_template";

import LessonsProposeTimeController from "../controllers/dashboard/user/lessons/lessons-propose-time-controller";
import LessonsProposeTimeTemplate from "../templates/dashboard/user/lessons/lessons_propose_time_template";

import LessonsNotesPageController from "../controllers/dashboard/user/lessons/lessons-notes-page-controller";
import LessonsNotesPageTemplate from "../templates/dashboard/user/lessons/lessons_notes_page_template";

import VerifyUserEmailController from "../controllers/dashboard/user/verify-user-email-controller";
import VerifyUserEmailTemplate from "../templates/dashboard/user/verify_user_email_template";

// constant setup for controllers
const constantName = "clientTokenPath";
const constantValue = "/api/web/braintree_tokens";

dashboardGamerApp
  .config(configGamerDashboard)
  .constant(constantName, constantValue);

// Routing setup
function configGamerDashboard($stateProvider, $qProvider) {
  $qProvider.errorOnUnhandledRejections(false);
  $stateProvider
    .state("gamer_dashboard", {
      abstract: true,
      url: "/gamer/dashboard",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        pulledGames: getGames,
      },
      controller: GamerDashboardContainerController,
      controllerAs: "vm",
      template: GamerDashboardContainerTemplate,
    })
    .state("gamer_dashboard.root", {
      url: "/",
      redirectTo: "gamer_dashboard.landing",
    })
    .state("gamer_dashboard.landing", {
      url: "",
      resolve: {
        ActionableCount: ActionableCount,
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerLessonsCalendarController,
      controllerAs: "vm",
      template: GamerDashboardLandingTemplate,
      ncyBreadcrumb: {
        label: "Dashboard",
      },
    })
    // LESSONS SECTION
    .state("gamer_dashboard.lessons", {
      abstract: true,
      url: "/lessons",
      defaultChild: "gamer_dashboard.wallet.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
    })
    .state("gamer_dashboard.lessons.root", {
      url: "",
      redirectTo: "gamer_dashboard.lessons.list",
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.lessons.list", {
      url: "/list?date&status&game&sensei&lesson",
      resolve: {
        ActionableCount: ActionableCount,
        UIFeatureGate: uiFeatureGate,
      },
      controller: LessonCardListController,
      controllerAs: "vm",
      template: LessonCardListTemplate,
      ncyBreadcrumb: {
        label: "List",
        parent: "gamer_dashboard.lessons.root",
      },
    })
    .state("gamer_dashboard.lessons.calendar", {
      url: "/calendar",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerLessonsCalendarController,
      controllerAs: "vm",
      template: GamerLessonsCalendarTemplate,
      ncyBreadcrumb: {
        label: "Calendar",
        parent: "gamer_dashboard.lessons.root",
      },
    })
    .state("gamer_dashboard.lessons.book_lesson_landing", {
      url: "/book",
      resolve: { UIFeatureGate: uiFeatureGate, pulledGames: getGames },
      controller: GamerLessonsBookingLandingController,
      controllerAs: "vm",
      template: GamerLessonsBookingLandingTemplate,
      ncyBreadcrumb: {
        label: "Session Selection",
        parent: "gamer_dashboard.lessons.root",
      },
    })
    .state("gamer_dashboard.lessons.book_lesson_calendar", {
      url: "/book/:tokenTemplateId?sensei_id",
      template: GamerLessonsBookingCalendarTemplate,
      controller: GamerLessonsBookingCalendarController,
      controllerAs: "vm",
      resolve: { UIFeatureGate: uiFeatureGate, pulledGames: getGames },
      ncyBreadcrumb: {
        label: "Book a Lesson",
        parent: "gamer_dashboard.lessons.root",
      },
    })
    .state("gamer_dashboard.lesson", {
      url: "/lesson",
      redirectTo: "gamer_dashboard.lessons",
    })
    .state("gamer_dashboard.lessons.propose_lesson_time", {
      url: "/propose-lesson-time",
      controller: LessonsProposeTimeController,
      controllerAs: "vm",
      template: LessonsProposeTimeTemplate,
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.lessons.my_reviews", {
      url: "/my-reviews",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerLessonsReviewsController,
      controllerAs: "vm",
      template: GamerLessonsReviewsTemplate,
      ncyBreadcrumb: {
        label: "My Reviews",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.lessons.sensei_notes", {
      url: "/notes",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerLessonsNotesListController,
      controllerAs: "vm",
      template: GamerLessonsNotesListTemplate,
      ncyBreadcrumb: {
        label: "Sensei Notes",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.lessons.sensei_note_page", {
      url: "/notes/:lessonId",
      controller: LessonsNotesPageController,
      controllerAs: "vm",
      template: LessonsNotesPageTemplate,
      ncyBreadcrumb: {
        label: "{{$stateParams.lessonId}}",
        parent: "gamer_dashboard.lessons.sensei_notes",
      },
    })
    .state("gamer_dashboard.lessons.sensei_chats", {
      url: "/chats",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: ChatsListController,
      controllerAs: "vm",
      template: ChatsListTemplate,
      ncyBreadcrumb: {
        label: "Sensei Chats",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.lessons.sensei_chat_page", {
      url: "/chat/:lessonId",
      controller: ChatsPageController,
      controllerAs: "vm",
      template: ChatsPageTemplate,
      ncyBreadcrumb: {
        label: "{{$stateParams.lessonId}}",
        parent: "gamer_dashboard.lessons.sensei_chats",
      },
    })
    // ACCOUNT SECTION
    .state("gamer_dashboard.profile", {
      url: "/profile",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerAccountProfileController,
      controllerAs: "vm",
      template: GamerAccountProfileTemplate,
      ncyBreadcrumb: {
        label: "Profile",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.contact_information", {
      url: "/contact-info",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerAccountContactController,
      controllerAs: "vm",
      template: GamerAccountContactTemplate,
      ncyBreadcrumb: {
        label: "Contact Information",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.notifications", {
      url: "/notifications",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerAccountNotificationsController,
      controllerAs: "vm",
      template: GamerAccountNotificationsTemplate,
      ncyBreadcrumb: {
        label: "Notifications",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.payment", {
      url: "/payment",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerAccountPaymentController,
      controllerAs: "vm",
      template: GamerAccountPaymentTemplate,
      ncyBreadcrumb: {
        label: "Payment Options",
        parent: "gamer_dashboard.landing",
      },
    })
    // WALLET
    .state("gamer_dashboard.wallet", {
      abstract: true,
      url: "/wallet",
      defaultChild: "gamer_dashboard.wallet.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: GamerWalletController,
      controllerAs: "vm",
    })
    .state("gamer_dashboard.wallet.root", {
      url: "",
      redirectTo: "gamer_dashboard.wallet.summary",
      ncyBreadcrumb: {
        label: "Wallet",
        parent: "gamer_dashboard.landing",
      },
    })
    .state("gamer_dashboard.wallet.summary", {
      url: "/",
      controller: GamerWalletSummaryController,
      controllerAs: "vm",
      template: GamerWalletSummaryTemplate,
      ncyBreadcrumb: {
        label: "Summary",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    .state("gamer_dashboard.wallet.tokens", {
      url: "/sessions",
      controller: GamerWalletSessionHistoryController,
      controllerAs: "vm",
      template: GamerWalletSessionHistoryTemplate,
      ncyBreadcrumb: {
        label: "Sessions",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    .state("gamer_dashboard.wallet.subscriptions", {
      url: "/subscriptions",
      controller: GamerWalletSubscriptionsController,
      controllerAs: "vm",
      template: GamerWalletSubscriptionsTemplate,
      ncyBreadcrumb: {
        label: "Subscriptions",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    .state("gamer_dashboard.wallet.redeem_gift_card", {
      url: "/gift-code",
      controller: GamerWalletRedeemGiftCardController,
      controllerAs: "vm",
      template: GamerWalletRedeemGiftCardTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      ncyBreadcrumb: {
        label: "Redeem Gift Code",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    .state("gamer_dashboard.wallet.redeem_session_token", {
      url: "/session-code",
      controller: GamerWalletRedeemSessionController,
      controllerAs: "vm",
      template: GamerWalletRedeemSessionTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      ncyBreadcrumb: {
        label: "Redeem Gift Code",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    .state("gamer_dashboard.wallet.history", {
      url: "/history?date",
      controller: GamerWalletHistoryController,
      controllerAs: "vm",
      template: WalletHistoryTemplate,
      ncyBreadcrumb: {
        label: "History",
        parent: "gamer_dashboard.wallet.root",
      },
    })
    // VERIFY EMAIL
    .state("gamer_dashboard.verify_email", {
      url: "/verify",
      controller: VerifyUserEmailController,
      controllerAs: "vm",
      template: VerifyUserEmailTemplate,
      ncyBreadcrumb: {
        label: "Verify Email",
        parent: "gamer_dashboard.landing",
      },
    });
}

configGamerDashboard.$inject = ["$stateProvider", "$qProvider"];

// Lesson Counts
function ActionableCount(ActionableLessons) {
  var config = { params: { status: "actionable" } };
  return ActionableLessons.getCounts(config)
    .then((res) => {
      return res.data.count;
    })
    .catch(angular.noop);
}
ActionableCount.$inject = ["ActionableLessons"];

export default dashboardGamerApp;
