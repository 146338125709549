export default function tokenService($q, $http) {
  var tokenTemplateInventoriesEndpoint = "/api/web/token_template_inventories";
  var tokenBundleInventoriesEndpoint = "/api/web/token_bundle_inventories";

  var service = {
    loading: false,
    tokens: {},
    tokenBundles: {},
    selectedTokens: {},
    tokenTiers: {},
    tokenTypeSet: false,
    tokenTypes: {},
    tokenTierIconList: [
      {
        slug: "samurai",
        displayOrder: 2,
      },
      {
        slug: "ronin",
        displayOrder: 3,
      },
      {
        slug: "shogun",
        displayOrder: 1,
      },
    ],
    tierLowestPrices: {},
    config: {
      game: "",
      tier: "",
      type: "",
    },
    getLoadingStatus: getLoadingStatus,
    setLoadingStatus: setLoadingStatus,
    getConfig: getConfig,
    setConfig: setConfig,
    getTokens: getTokens,
    getTypes: getTypes,
    getTiers: getTiers,
    getSelectedTokens: getSelectedTokens,
    //addSubsToTokens: addSubsToTokens,
    setTokens: setTokens,
    getTokenBundles: getTokenBundles,
    setTokenBundles: setTokenBundles,
    fetchTokenInventories: fetchTokenInventories,
    fetchSpecificTokenTemplateInventories:
      fetchSpecificTokenTemplateInventories,
    fetchSenseisOnCurrentToken: fetchSenseisOnCurrentToken,
    areThereAnyLegendaryCoaches: areThereAnyLegendaryCoaches,
    getLegendaryCoaches: getLegendaryCoaches,
    getTierLowestPrices: getTierLowestPrices,
    setTierLowestPrices: setTierLowestPrices,
  };

  window.token = service;

  return service;

  // Service functions

  function getLoadingStatus() {
    return service.loading;
  }

  function setLoadingStatus(status) {
    service.loading = status;
  }

  function getConfig() {
    return service.config;
  }

  function setConfig(data, type) {
    if (type) {
      service.config = {
        ...service.config,
        [type]: data,
      };
    } else {
      service.config = data;
    }
  }

  function getTokens() {
    return service.tokens;
  }

  function getTypes() {
    return service.tokenTypes;
  }

  function getTiers() {
    return service.tokenTiers;
  }

  function getSelectedTokens() {
    return service.selectedTokens;
  }

  function setTokens(data, reload) {
    var deferred = $q.defer();
    if (!service.loading) service.loading = true;

    if (reload || areThereChangesThatRequireReload(data)) {
      service
        .fetchTokenInventories(data)
        .then((res) => {
          var tokenObj = formatTokensToObj(res.data.token_template_inventories);
          service.tokens = tokenObj;
          // set token types
          if (doesTypeNeedSoftUpdate(data)) {
            service.tokenTypes = formatTokenTypesToObj(
              res.data.token_template_inventories
            );
          } else if (doesTypeNeedHardUpdate(data)) {
            service
              .fetchTokenInventories({ game: data.game })
              .then((typeRes) => {
                service.tokenTypes = formatTokenTypesToObj(
                  typeRes.data.token_template_inventories
                );
              });
          }
          if (!service.tokenTypeSet) service.tokenTypeSet = true;

          // set tier data
          service.tokenTiers = formatTokenTiersToObj(
            res.data.token_template_inventories
          );
          service.loading = false;
          service.config = {
            game: data.game || "",
            type: data.tokenType || "",
            tier: data.tokenTier || "",
          };
          deferred.resolve(tokenObj);
        })
        .catch((res) => {
          service.tokens = {};
          service.tokenTypes = {};
          service.tokenTypeSet = false;
          service.config = {
            game: data.game || "",
            type: data.tokenTier ? data.tokenType : "",
            tier: "",
          };
          service.loading = false;
          deferred.reject(res);
        });
    } else {
      service.loading = false;
      deferred.resolve(service.tokens);
    }
    return deferred.promise;
  }

  function getTokenBundles() {
    return service.tokenBundles;
  }

  function setTokenBundles(data, reload) {
    var deferred = $q.defer();

    if (reload || areThereChangesThatRequireReload(data)) {
      service
        .fetchTokenInventories(data, true)
        .then((res) => {
          var tokenBundleObj = formatTokenBundlesToObj(
            res.data.token_bundle_inventories
          );
          service.tokenBundles = tokenBundleObj;
          deferred.resolve(tokenBundleObj);
        })
        .catch((res) => {
          service.tokenBundles = {};
          deferred.reject(res);
        });
    } else {
      deferred.resolve(service.tokenBundles);
    }
    return deferred.promise;
  }

  function fetchTokenInventories(data, isBundle) {
    const endpoint = isBundle
      ? tokenBundleInventoriesEndpoint
      : tokenTemplateInventoriesEndpoint;
    const game = data.game || "";
    const tier = data.tokenTier || "";
    const type = data.tokenType || "";
    const sensei = data.senseiId || "";

    return $http.get(
      endpoint +
        "?" +
        "game=" +
        game +
        "&token_tier=" +
        tier +
        "&token_type=" +
        type +
        "&sensei_id=" +
        sensei
    );
  }

  function areThereAnyLegendaryCoaches() {
    var legendaryCoaching = false;
    angular.forEach(service.tokens, (token) => {
      if (token.token_tier.includes("legendary")) {
        legendaryCoaching = true;
      }
    });

    return legendaryCoaching;
  }

  function getLegendaryCoaches() {
    var currentTokens = service.tokens;

    return Object.keys(currentTokens)
      .filter((key) => key.includes("legendary"))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: currentTokens[key],
        };
      }, {});
  }

  function getTierLowestPrices() {
    return service.tierLowestPrices;
  }

  function setTierLowestPrices(gameId) {
    $http
      .get(`/api/web/tier_details${gameId ? "?game=" + gameId : ""}`)
      .then((res) => {
        var tierList = res.data.token_tiers;
        var initialValue = {};
        service.tierLowestPrices = tierList.reduce((obj, item) => {
          return {
            ...obj,
            [item.name]: {
              ...item,
            },
          };
        }, initialValue);
      })
      .catch(angular.noop);
  }

  // Internal functions
  function fetchSpecificTokenTemplateInventories(id) {
    return $http.get(tokenTemplateInventoriesEndpoint + "/" + id);
  }

  function fetchSenseisOnCurrentToken(id) {
    return $http.get("/api/web/sensei_token_tier?token_template_id=" + id);
  }

  function areThereChangesThatRequireReload(data) {
    return (
      data.game !== service.config.game ||
      data.tokenType !== service.config.type ||
      data.tokenTier !== service.config.tier
    );
  }

  function doesTypeNeedSoftUpdate(data) {
    if (
      data.game &&
      service.config.type === "" &&
      Object.keys(service.tokenTypes).length === 0 &&
      !data.tokenType &&
      !service.tokenTypeSet
    ) {
      return true;
    }

    return false;
  }

  function doesTypeNeedHardUpdate(data) {
    if (
      data.game &&
      ((Object.keys(service.tokenTypes).length && service.tokenTypeSet) ||
        (Object.keys(service.tokenTypes).length === 0 && !service.tokenTypeSet))
    ) {
      return true;
    }

    return false;
  }

  function formatTokensToObj(tokens, qty) {
    var initialValue = {};
    return tokens.reduce((obj, item) => {
      return {
        ...obj,
        [`${item.token_tier}-${item.token_type}-${item.id}`]: {
          ...item,
          displayOrder: getTierDisplayOrder(item.token_tier),
          quantity: qty ? qty : 1,
        },
      };
    }, initialValue);
  }

  function formatTokenBundlesToObj(bundles, qty) {
    var initialValue = {};
    return bundles.reduce((obj, item) => {
      return {
        ...obj,
        [`bundle ${item.id}: ${item.name}`]: {
          ...item,
          quantity: qty ? qty : 1,
        },
      };
    }, initialValue);
  }

  function formatTokenTypesToObj(tokens) {
    var initialValue = {};
    var availTypeList = [
      ...new Set(
        tokens.map((token) => {
          return token.token_type_data;
        })
      ),
    ];

    return availTypeList.reduce((obj, type) => {
      return {
        ...obj,
        [type.slug]: { ...type, displayOrder: getTypeDisplayOrder(type.slug) },
      };
    }, initialValue);
  }

  function formatTokenTiersToObj(tokens) {
    const obj = {};

    for (let tier of tokens) {
      const legendary = tier.token_tier.includes("legendary");

      if (legendary && !obj["legendary"]) {
        obj["legendary"] = {
          slug: "legendary",
          name: "Legendary",
          displayOrder: 0,
        };
      } else if (!legendary && !obj[tier.token_tier]) {
        obj[tier.token_tier] = {
          slug: tier.token_tier,
          name: tier.token_tier_data.name,
          displayOrder: getTierDisplayOrder(tier.token_tier),
        };
      }
    }

    return obj;
  }

  function getTierDisplayOrder(slug) {
    if (slug.includes("shogun")) {
      return 1;
    } else if (slug.includes("samurai")) {
      return 2;
    } else if (slug.includes("ronin")) {
      return 3;
    } else {
      return 4;
    }
  }

  function getTypeDisplayOrder(slug) {
    if (slug.includes("masterclass")) {
      return 0;
    } else if (slug.includes("one-on-one")) {
      return 1;
    } else if (slug.includes("skills-assessment")) {
      return 3;
    } else if (slug.includes("team")) {
      return 4;
    }
  }
}

tokenService.$inject = ["$q", "$http"];
