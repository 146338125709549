export default function transactionHistoriesService($http) {

  var service = {
    currencyHistory: currencyHistory
  };

  return service;

  ///////////

  function currencyHistory(config) {
    return $http.get('/api/web/currency_transaction_histories', config);
  }
}

transactionHistoriesService.$inject = ['$http'];