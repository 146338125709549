import multiSelectTemplate from "../../templates/directives/display/multi_select_template";

export default function multiSelectDirective($rootScope) {
  return {
    restrict: "E",
    scope: {
      selectLabel: "=",
      selectLabelClass: "=",
      disableSelectAll: "=",
      selectTitle: "=",
      selectItems: "=",
      selectLimit: "=",
      showItems: "=",
      itemCallback: "&", //optional
      getSelectedCallback: "&", //optional
    },
    template: multiSelectTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.selectedItems = [];

    for (let idx in scope.selectItems) {
      var item = scope.selectItems[idx];
      if (item.selected) scope.selectedItems.push(item);
    }

    scope.openDropDownFlag = false;

    $rootScope.$on("clearMultiSelects", () => {
      scope.selectedItems = [];
    });

    $rootScope.$on("removeIndividual", (event, args) => {
      if (args.label === scope.selectLabel) {
        const currentIndex = scope.selectedItems.findIndex(
          (item) => item.name === args.item
        );

        scope.selectedItems.splice(currentIndex, 1);
      }
    });

    scope.editDropDownToggle = function () {
      scope.openDropDownFlag = !scope.openDropDownFlag;
    };

    if (scope.getSelectedCallback) {
      for (let i in scope.selectItems) {
        const setSelected = scope.getSelectedCallback({
          option: scope.selectItems[i],
        });

        if (setSelected) {
          scope.selectItems[i].selected = true;
          scope.selectedItems.push(scope.selectItems[i]);
        }
      }
    }

    scope.allowSelectAll = function () {
      if (
        scope.disableSelectAll ||
        (scope.selectLimit && scope.selectLimit < scope.selectItems.length)
      ) {
        return false;
      } else {
        return true;
      }
    };

    scope.currentlyAtLimit = function () {
      return (
        scope.selectLimit && scope.selectLimit === scope.selectedItems.length
      );
    };

    scope.isDropDownOpen = function () {
      return scope.openDropDownFlag;
    };

    scope.updateAllItems = function () {
      if (scope.selectedItems.length >= scope.selectItems.length) {
        scope.selectedItems = [];
        angular.forEach(scope.selectItems, function (item, idx) {
          scope.selectItems[idx].selected = false;
          if (scope.itemCallback) scope.itemCallback({ arg1: item });
        });
      } else {
        angular.forEach(scope.selectItems, function (item, idx) {
          if (!scope.selectItems[idx].selected) {
            scope.selectItems[idx].selected = true;
            scope.selectedItems.push(item);
            if (scope.itemCallback) scope.itemCallback({ arg1: item });
          }
        });
      }
    };

    scope.selectedItemLabelsCombined = () => {
      const selected = scope.selectedItems;
      const selectedList = selected.map((item) => item.name).join(", ");

      return selectedList;
    };

    scope.updateSelectedItem = function (item) {
      var removeIndex = -1;

      angular.forEach(scope.selectItems, function (itemObj, itemIdx) {
        if (itemObj.name === item.name) {
          if (item.selected) {
            scope.selectItems[itemIdx].selected = false;
            angular.forEach(
              scope.selectedItems,
              function (selectedItemObj, selectedItemIdx) {
                if (selectedItemObj.name === itemObj.name) {
                  removeIndex = selectedItemIdx;
                }
              }
            );
          } else {
            if (scope.selectedItems.length >= scope.selectLimit) return;
            scope.selectItems[itemIdx].selected = true;
            scope.selectedItems.push(item);
          }
        }
      });

      if (removeIndex > -1) {
        scope.selectedItems.splice(removeIndex, 1);
        removeIndex = -1;
      }

      if (scope.itemCallback) scope.itemCallback({ arg1: item });
    };
  }
}

multiSelectDirective.$inject = ["$rootScope"];
