export default function playerLandingPageService($http) {

  var service = {
    getPlayerAsSensei: getPlayerAsSensei,
    getEsportTeam: getEsportTeamData,
    getAllEsportTeam: getAllEsportTeam
  };

  return service;

  ///////////

  function getPlayerAsSensei(slug) {
    return $http.get('/api/web/users/'+slug+'/as_landing_page_sensei');
  }

  function getEsportTeamData(id) {
    return $http.get('/api/web/esport_teams/' + id);
  }

  function getAllEsportTeam() {
    return $http.get('/api/web/esport_teams');
  }

}

playerLandingPageService.$inject = ['$http'];
