export default function senseiScheduleService($http) {
  var scheduleEndpoint = "/api/web/sensei_availabilities";
  var bufferEndpoint = "/api/web/buffer_hours";
  var schedule = {
    get: fetchSenseiSchedule,
    post: postSenseiSchedule,
    getBuffer: fetchSenseiScheduleBuffer,
    postBuffer: postSenseiScheduleBuffer,
  };

  return schedule;

  //

  function fetchSenseiSchedule(timezone) {
    return $http.get(scheduleEndpoint + "?time_zone=" + timezone);
  }

  function postSenseiSchedule(data) {
    return $http.post(scheduleEndpoint, data);
  }

  function fetchSenseiScheduleBuffer(data) {
    return $http.get(bufferEndpoint);
  }

  function postSenseiScheduleBuffer(buffer_time) {
    return $http.post(bufferEndpoint, { buffer_time: buffer_time });
  }
}

senseiScheduleService.$inject = ["$http"];
