import dashboardModulesApp from "./gs-dashboard-modules";
import { uiFeatureGate, getGames } from "../config/utils/utilsConfig";

const dashboardBusinessApp = angular.module("gamerSenseiBusinessDashboard", [
  dashboardModulesApp.name,
]);

// constant setup for controllers
const constantName = "clientTokenPath";
const constantValue = "/api/web/braintree_tokens";

// controllers and templates
import BusinessDashboardContainerController from "../controllers/dashboard/business/business-dashboard-container-controller";
import BusinessDashboardContainerTemplate from "../templates/dashboard/business/business_dashboard_container_template";

import BusinessDashboardLandingController from "../controllers/dashboard/business/business-dashboard-landing-controller";
import BusinessDashboardLandingTemplate from "../templates/dashboard/business/business_dashboard_landing_template";

import BusinessLessonsCalendarController from "../controllers/dashboard/business/lessons/business-lessons-calendar-controller";
import BusinessLessonsCalendarTemplate from "../templates/dashboard/business/lessons/business_lessons_calendar_template";

import BusinessLessonsBookingLandingController from "../controllers/dashboard/business/lessons/business-lessons-booking-landing-controller";
import BusinessLessonsBookingLandingTemplate from "../templates/dashboard/business/lessons/business_lessons_booking_landing_template";

import BusinessLessonsBookingCalendarController from "../controllers/dashboard/business/lessons/business-lessons-booking-calendar-controller";
import BusinessLessonsBookingCalendarTemplate from "../templates/dashboard/business/lessons/business_lessons_booking_calendar_template";

import BusinessLessonsReviewsController from "../controllers/dashboard/business/lessons/business-lessons-reviews-controller";
import BusinessLessonsReviewsTemplate from "../templates/dashboard/business/lessons/business_lessons_reviews_template";

import BusinessLessonsNotesListController from "../controllers/dashboard/business/lessons/business-lessons-notes-list-controller";
import BusinessLessonsNotesListTemplate from "../templates/dashboard/business/lessons/business_lessons_notes_list_template";

import BusinessAccountContactController from "../controllers/dashboard/business/account/business-account-contact-controller";
import BusinessAccountContactTemplate from "../templates/dashboard/business/account/business_account_contact_template";

import BusinessAccountNotificationsController from "../controllers/dashboard/business/account/business-account-notifications-controller";
import BusinessAccountNotificationsTemplate from "../templates/dashboard/business/account/business_account_notifications_template";

import BusinessAccountProfileController from "../controllers/dashboard/business/account/business-account-profile-controller";
import BusinessAccountProfileTemplate from "../templates/dashboard/business/account/business_account_profile_template";

import BusinessWalletController from "../controllers/dashboard/business/wallet/business-wallet-controller";

import BusinessWalletPaymentController from "../controllers/dashboard/business/wallet/business-wallet-payment-controller";
import BusinessWalletPaymentTemplate from "../templates/dashboard/business/wallet/business_wallet_payment_template";

import BusinessWalletPurchaseController from "../controllers/dashboard/business/wallet/business-wallet-purchase-controller";
import BusinessWalletPurchaseTemplate from "../templates/dashboard/business/wallet/business_wallet_purchase_template";

import BusinessWalletRedeemSessionController from "../controllers/dashboard/business/wallet/business-wallet-redeem-session-template";
import BusinessWalletRedeemSessionTemplate from "../templates/dashboard/business/wallet/business_wallet_redeem_session_template";

import LessonsProposeTimeController from "../controllers/dashboard/user/lessons/lessons-propose-time-controller";
import LessonsProposeTimeTemplate from "../templates/dashboard/user/lessons/lessons_propose_time_template";

import LessonsNotesPageController from "../controllers/dashboard/user/lessons/lessons-notes-page-controller";
import LessonsNotesPageTemplate from "../templates/dashboard/user/lessons/lessons_notes_page_template";

import VerifyUserEmailController from "../controllers/dashboard/user/verify-user-email-controller";
import VerifyUserEmailTemplate from "../templates/dashboard/user/verify_user_email_template";

dashboardBusinessApp
  .config(configBusinessDashboard)
  .constant(constantName, constantValue);

function configBusinessDashboard($stateProvider, $qProvider) {
  $qProvider.errorOnUnhandledRejections(false);
  $stateProvider
    .state("business_dashboard", {
      abstract: true,
      url: "/business/dashboard",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        pulledGames: getGames,
      },
      controller: BusinessDashboardContainerController,
      controllerAs: "vm",
      template: BusinessDashboardContainerTemplate,
    })
    .state("business_dashboard.root", {
      url: "/",
      redirectTo: "business_dashboard.landing",
    })
    .state("business_dashboard.landing", {
      url: "",
      resolve: {
        ActionableCount: ActionableCount,
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessDashboardLandingController,
      controllerAs: "vm",
      template: BusinessDashboardLandingTemplate,
      ncyBreadcrumb: {
        label: "Dashboard",
      },
    })
    // LESSONS SECTION
    .state("business_dashboard.lessons", {
      abstract: true,
      url: "/lessons",
      defaultChild: "business_dashboard.lessons.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
    })
    .state("business_dashboard.lessons.root", {
      url: "",
      redirectTo: "business_dashboard.lessons.calendar",
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.lessons.calendar", {
      url: "/calendar",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessLessonsCalendarController,
      controllerAs: "vm",
      template: BusinessLessonsCalendarTemplate,
      ncyBreadcrumb: {
        label: "Calendar",
        parent: "business_dashboard.lessons.root",
      },
    })
    .state("business_dashboard.lessons.book_lesson_landing", {
      url: "/book",
      resolve: { UIFeatureGate: uiFeatureGate, pulledGames: getGames },
      controller: BusinessLessonsBookingLandingController,
      controllerAs: "vm",
      template: BusinessLessonsBookingLandingTemplate,
      ncyBreadcrumb: {
        label: "Session Selection",
        parent: "business_dashboard.lessons.root",
      },
    })
    .state("business_dashboard.lessons.book_lesson_calendar", {
      url: "/book/:tokenTemplateId?sensei_id",
      controller: BusinessLessonsBookingCalendarController,
      controllerAs: "vm",
      template: BusinessLessonsBookingCalendarTemplate,
      resolve: { UIFeatureGate: uiFeatureGate, pulledGames: getGames },
      ncyBreadcrumb: {
        label: "Book a Lesson",
        parent: "business_dashboard.lessons.root",
      },
    })
    .state("business_dashboard.lesson", {
      url: "/lesson",
      redirectTo: "business_dashboard.lessons",
    })
    .state("business_dashboard.lessons.propose_lesson_time", {
      url: "/propose-lesson-time",
      controller: LessonsProposeTimeController,
      controllerAs: "vm",
      template: LessonsProposeTimeTemplate,
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.lessons.my_reviews", {
      url: "/my-reviews",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessLessonsReviewsController,
      controllerAs: "vm",
      template: BusinessLessonsReviewsTemplate,
      ncyBreadcrumb: {
        label: "My Reviews",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.lessons.sensei_notes", {
      url: "/notes",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessLessonsNotesListController,
      controllerAs: "vm",
      template: BusinessLessonsNotesListTemplate,
      ncyBreadcrumb: {
        label: "Sensei Notes",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.lessons.sensei_note_page", {
      url: "/notes/:lessonId",
      controller: LessonsNotesPageController,
      controllerAs: "vm",
      template: LessonsNotesPageTemplate,
      ncyBreadcrumb: {
        label: "{{$stateParams.lessonId}}",
        parent: "business_dashboard.lessons.sensei_notes",
      },
    })

    // ACCOUNT SECTION
    .state("business_dashboard.profile", {
      url: "/profile",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessAccountProfileController,
      controllerAs: "vm",
      template: BusinessAccountProfileTemplate,
      ncyBreadcrumb: {
        label: "Profile",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.contact_information", {
      url: "/contact-info",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessAccountContactController,
      controllerAs: "vm",
      template: BusinessAccountContactTemplate,
      ncyBreadcrumb: {
        label: "Contact Information",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.notifications", {
      url: "/notifications",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessAccountNotificationsController,
      controllerAs: "vm",
      template: BusinessAccountNotificationsTemplate,
      ncyBreadcrumb: {
        label: "Notifications",
        parent: "business_dashboard.landing",
      },
    })
    // WALLET
    .state("business_dashboard.wallet", {
      abstract: true,
      url: "/wallet",
      defaultChild: "business_dashboard.wallet.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessWalletController,
      controllerAs: "vm",
    })
    .state("business_dashboard.wallet.root", {
      url: "",
      redirectTo: "business_dashboard.wallet.summary",
      ncyBreadcrumb: {
        label: "Wallet",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.wallet.summary", {
      url: "/",
      controller: BusinessWalletPaymentController,
      controllerAs: "vm",
      template: BusinessWalletPaymentTemplate,
      ncyBreadcrumb: {
        label: "Summary",
        parent: "business_dashboard.wallet.root",
      },
    })
    .state("business_dashboard.wallet.purchase", {
      url: "/purchase",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessWalletPurchaseController,
      controllerAs: "vm",
      template: BusinessWalletPurchaseTemplate,
      ncyBreadcrumb: {
        label: "Payment Options",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.wallet.payment", {
      url: "/payment",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: BusinessWalletPaymentController,
      controllerAs: "vm",
      template: BusinessWalletPaymentTemplate,
      ncyBreadcrumb: {
        label: "Payment Options",
        parent: "business_dashboard.landing",
      },
    })
    .state("business_dashboard.wallet.redeem_session_token", {
      url: "/session-code",
      controller: BusinessWalletRedeemSessionController,
      controllerAs: "vm",
      template: BusinessWalletRedeemSessionTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      ncyBreadcrumb: {
        label: "Redeem Gift Code",
        parent: "business_dashboard.wallet.root",
      },
    })
    // VERIFY EMAIL
    .state("business_dashboard.verify_email", {
      url: "/verify",
      controller: VerifyUserEmailController,
      controllerAs: "vm",
      template: VerifyUserEmailTemplate,
      ncyBreadcrumb: {
        label: "Verify Email",
        parent: "business_dashboard.landing",
      },
    });
}

configBusinessDashboard.$inject = ["$stateProvider", "$qProvider"];

// Lesson Counts
function ActionableCount(ActionableLessons) {
  var config = { params: { status: "actionable" } };
  return ActionableLessons.getCounts(config)
    .then((res) => {
      return res.data.count;
    })
    .catch(angular.noop);
}
ActionableCount.$inject = ["ActionableLessons"];

export default dashboardBusinessApp;
