export default function blogManagerService($http) {
  var endpoint = "https://gamer-sensei-blog-api.herokuapp.com/api";
  var manager = {
    loading: false,
    config: {},
    articles: [],
    setRecentBlogs,
    getRecentBlogs,
    doBlogsExist,
  };

  return manager;

  // Service Functions
  function setRecentBlogs(data) {
    return $http.get(`${endpoint}/${data || ""}`).then((res) => {
      manager.articles = res.data.data;
      manager.config = res.data.meta;
    });
  }

  function getRecentBlogs() {
    return manager.articles;
  }

  function doBlogsExist() {
    return manager.articles.length;
  }
}

blogManagerService.$inject = ["$http"];
