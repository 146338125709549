import calendarModalTemplate from "../../../templates/directives/popup/modal/calendar_modal_template";

export default function calendarModalDirective(
  $window,
  $state,
  EventTrackingManager
) {
  return {
    restrict: "E",
    scope: {
      modal: "=",
    },
    template: calendarModalTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.eventTracking = EventTrackingManager;
    scope.closeModal = function () {
      scope.modal.openedModal = false;
    };

    scope.displayUrl = (dateParams) => {
      let url = `/${scope.modal.userType}/dashboard/lessons/list?date=${dateParams}`;

      return url;
    };
  }
}

calendarModalDirective.$inject = ["$window", "$state", "EventTrackingManager"];
