import reusableApp from "./gs-reusable";

const responsiveTemplateApp = angular.module("gamerSenseiResponsiveTemplate", [
  reusableApp.name,
]);

// set up directives
import resizeDirective from "../directives/data/resize-directive";
import responsiveTemplateDirective from "../directives/display/responsive-template-directive";

responsiveTemplateApp.directive("resize", resizeDirective);
responsiveTemplateApp.directive(
  "responsiveTemplate",
  responsiveTemplateDirective
);

// set up services
import resizeService from "../services/ux/resize-service";

responsiveTemplateApp.factory("ResizeService", resizeService);

export default responsiveTemplateApp;
