export default function modalManagerService($window, $document) {

  var manager = {
    eventCreated: false,
    setOpenBG,
    setCloseBG,
    setEventListener
  };

  return manager;

  /// Internal Functions
  function setOpenBG () {
    const scrollY = $document[0].documentElement.style.getPropertyValue("--scroll-y");
    const html = $document[0].documentElement;
    angular.element($document[0].querySelector("html")).addClass("open-modal");
    html.style.top = `-${ scrollY }`;
  }

  function setCloseBG () {
    const html = $document[0].documentElement;
    const scrollY = html.style.top;
    angular.element($document[0].querySelector("html")).removeClass("open-modal");
    $window.scrollTo(0, parseInt(scrollY || "0") * -1);
  }

  function setEventListener () {
    if (!manager.eventCreated) {
      $window.addEventListener("scroll", () => {
        $document[0].documentElement.style.setProperty("--scroll-y", `${ $window.scrollY }px`);
        manager.eventCreated = true;
      });
    }
  }

}

modalManagerService.$inject = ["$window", "$document"];