import moment from "moment-timezone";
import proposalModalTemplate from "../../../templates/directives/popup/modal/proposal_modal_template";
import {
  calendarIcon,
  fastTimeIcon,
  messageIcon,
} from "../../../config/icons/iconConfig";

export default function proposalModalDirective(
  $rootScope,
  $timeout,
  $state,
  AvailabilityPropose,
  TokenBookingService
) {
  return {
    restrict: "E",
    scope: {
      proposalModal: "=",
      times: "=",
      deltaMinutes: "=",
      loading: "=",
      user: "=",
      setLoader: "&",
      proposalSuccess: "&",
      proposalFail: "&",
    },
    link: link,
    template: proposalModalTemplate,
  };

  function link(scope, elem, attr) {
    scope.icon = { calendarIcon, fastTimeIcon, messageIcon };
    scope.sessionProposal = {
      selectedDate: moment(),
      proposalTimeZone: moment.tz.guess(),
      sessionProposalNotes: "",
    };

    // display functions
    scope.isProposalModalOpen = () => {
      if (scope.proposalModal && Object.keys(scope.proposalModal).length) {
        return true;
      }

      return false;
    };
    scope.closeProposalModal = () => {
      scope.proposalModal = {};
      scope.sessionProposal = {
        selectedDate: moment(),
        proposalTimeZone: moment.tz.guess(),
        sessionProposalNotes: "",
      };
    };

    // round function to moment for rounding to next 30
    // ie XX:00 or XX:30
    moment.fn.roundNext30Min = function () {
      var intervals = Math.floor(this.minutes() / 30);
      if (this.minutes() % 30 != 0) {
        intervals++;
      }
      if (intervals == 2) {
        this.add(1, "hours");
        intervals = 0;
      }
      this.minutes(intervals * 30);
      this.seconds(0);
      return this;
    };

    scope.nearestTime = moment()
      .roundNext30Min()
      .add(scope.deltaMinutes, "minutes");

    scope.upcomingProposalTime = [
      moment(scope.nearestTime).format("hh:mm A"),
      moment(scope.nearestTime).format("HH:mm"),
    ];
    var today = moment();
    scope.dateSet = today.format("MMMM D, YYYY");
    scope.timeZoneZ = moment.tz(moment.tz.guess()).format("z");

    scope.timeCheck = function (date) {
      if (typeof date === "string") {
        date = moment(date, "MMMM D, YYYY");
      }

      if (moment().isSame(date, "day")) {
        angular
          .element(document.querySelector(".time-pick"))
          .children("option")
          .each(() => {
            if (
              moment(this.value, "HH:mm").isSame(
                moment(scope.upcomingProposalTime[1], "HH:mm")
              )
            ) {
              if (!this.selected) this.selected = true;
              return false;
            } else {
              this.disabled = true;
            }
          });
      } else {
        angular
          .element(document.querySelector(".time-pick"))
          .children("option")
          .prop("disabled", false);
      }
    };

    scope.addTZLabel = (time) => {
      return time + " " + scope.timeZoneZ;
    };

    scope.timeHasChanged = () => {
      $rootScope.timeNotSaved = true;
    };

    //bookable hours get
    scope.checkBookableHours = () => {
      if ($state.current.name === "gamer_dashboard_booking.propose_time") {
        var proposed_date;
        if (typeof scope.search.date === "string")
          // For testing deprecation purpose
          proposed_date = moment(scope.search.date).format("YYYY-MM-DD");
        else
          proposed_date = moment(
            scope.search.date,
            "yyyy-MM-ddTHH:mm:ss.sssZ"
          ).format("YYYY-MM-DD");

        const params = {
          params: {
            user_id: scope.chosenSensei.user_id,
            duration_hours: scope.params.sessionDuration,
            proposed_date: proposed_date,
            proposed_hour: scope.timeParams[0][1],
            proposed_time_zone: scope.search.timeZone,
          },
        };

        AvailabilityPropose.getSenseiAvailability(params)
          .then((results) => {
            scope.search.bookable_hours = results.data.bookable_hours;
          })
          .catch(angular.noop);
      }
    };

    scope.proposeTimeInit = () => {
      scope.sessionProposal.selectedTime = scope.upcomingProposalTime;
      $timeout(() => {
        scope.timeCheck(scope.sessionProposal.selectedDate);
        scope.checkBookableHours();
      });
    };
    scope.proposeTimeInit();

    // submit proposal
    scope.submitProposalSession = () => {
      if (scope.setLoader) scope.setLoader({ status: true });

      TokenBookingService.senseiScheduleProposal(
        scope.user,
        scope.proposalModal,
        scope.sessionProposal
      )
        .then(() => {
          if (scope.setLoader) scope.setLoader({ status: false });
          scope.closeProposalModal();

          if (scope.proposalSuccess) {
            scope.proposalSuccess({
              student: scope.proposalModal.studentScreenName,
            });
          }
        })
        .catch((err) => {
          if (scope.setLoader) scope.setLoader({ status: false });

          if (scope.proposalFail)
            scope.proposalFail({ errors: err.data.errors });
        });
    };

    scope.$watch(
      () => scope.sessionProposal.selectedDate,
      (newDate, oldDate) => {
        if (newDate) {
          scope.timeCheck(newDate);
          scope.timeHasChanged();
          angular.forEach(scope.times, (time, id) => {
            scope.addTZLabel(time[0]);
          });
        }
      },
      true
    );
  }
}

proposalModalDirective.$inject = [
  "$rootScope",
  "$timeout",
  "$state",
  "AvailabilityPropose",
  "TokenBookingService",
];
