export default function missingRegion(game) {
  const gameHasRegions = game.regions.length > 0;
  const userMissingRegion = !!!game.region;

  // user is missing region if the game allows regions and the user does not have one
  if (gameHasRegions && userMissingRegion) {
    return true;
  } else {
    return false;
  }
}
