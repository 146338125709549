import navTemplate from "../../templates/directives/navigation/main_nav_bar_template";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";
import {
  cartIcon,
  corsairIcon,
  elGatoIcon,
  hamburgerIcon,
  originIcon,
  scuffIcon,
} from "../../config/icons/iconConfig";

export default function mainNavBarDirective(
  $window,
  $location,
  $stateParams,
  $anchorScroll,
  User,
  EventTrackingManager,
  CartManager,
  FlexiTokenService,
  FeatureGate
) {
  return {
    restrict: "E",
    scope: {
      games: "=",
    },
    link: link,
    template: navTemplate,
  };

  function link(scope, elem, attrs) {
    scope.userService = User;
    scope.cart = CartManager;
    scope.flexi = FlexiTokenService;
    scope.noPayments = false;
    scope.eventTracking = EventTrackingManager;
    scope.gsLogoHorizontal = gsLogoHorizontal;
    scope.icons = {
      cartIcon,
      corsairIcon,
      elGatoIcon,
      hamburgerIcon,
      originIcon,
      scuffIcon,
    };

    FeatureGate.check().then((res) => {
      scope.noPayments =
        res.data.ui_feature_gates.final_balance_protection_measures_activated;
    });

    scope.$on("userDataSet", (event, args) => {
      if (args?.userData && !scope.user) {
        scope.user = args.userData;
      }
    });

    scope.isMobileMenuOpen = false;
    scope.isProfileMenuOpen = false;

    scope.gameLinks = scope.games.reduce((arr, item) => {
      if (item.active) {
        arr.push({
          title: item.name,
          slug: `${item.slug}-coaching`,
          order: item.displayOrder,
          mobileOrder: item.displayOrder,
        });
      }
      return arr;
    }, []);

    scope.menu = [
      {
        title: "How It Works",
        slug: "how-it-works",
        order: 1,
        mobileOrder: 1,
        submenu: [
          {
            title: "Getting Started",
            slug: "how-it-works",
            order: 1,
          },
          {
            title: "Assessments",
            slug: "assessments",
            order: 2,
          },
          {
            title: "One-On-One Coaching",
            slug: "one-on-one-coaching",
            order: 3,
          },
          {
            title: "Team Coaching",
            slug: "team-coaching",
            order: 4,
          },
        ],
      },
      {
        title: "Resources",
        slug: "schools",
        order: 2,
        mobileOrder: 2,
        submenu: [
          {
            title: "Schools",
            slug: "schools",
            order: 1,
          },
          {
            title: "Parents",
            slug: "parents",
            order: 2,
          },
        ],
      },
    ];

    scope.connectEvent = function (event, name) {
      event.preventDefault();
      var href = event.target.href;
      scope.eventTracking.pushEventOnly(name);
      $window.location.href = href;
    };

    scope.logoutEvent = function (event) {
      event.preventDefault();

      scope.eventTracking.pushEventOnly("accountLogout");
      $window.location.href = "/users/sign_out";
    };

    scope.toggleMobileMenu = function () {
      scope.isMobileMenuOpen = !scope.isMobileMenuOpen;
    };
    scope.toggleProfileMenu = function () {
      scope.isProfileMenuOpen = !scope.isProfileMenuOpen;
    };

    scope.formatBuyUrl = function (url) {
      var refParam = $location.search() && $location.search().ref;
      var stateRef = $stateParams.slug;

      if (refParam) {
        return `${url}?ref=${refParam}`;
      } else if (stateRef) {
        return `${url}?ref=${stateRef}`;
      }

      return url;
    };

    scope.isUser = function () {
      return (
        scope.userService.getId() &&
        (scope.userService.isGamer() || scope.userService.isSensei())
      );
    };

    scope.getDashboardLink = function () {
      if (scope.userService.isSensei()) {
        return "/sensei/dashboard";
      } else if (scope.userService.isBusiness()) {
        return "/business/dashboard";
      } else if (scope.userService.isGamer()) {
        return "/gamer/dashboard";
      }
    };

    scope.isGamerOrVisitor = function () {
      return !scope.isUser() || scope.userService.isGamer();
    };

    scope.isActiveLocation = function (linkPath) {
      var currentPath = $window.location.pathname;

      return linkPath === currentPath;
    };

    scope.isMenuActive = function (menuItem) {
      var currentPath = $window.location.pathname;
      switch (menuItem) {
        case "How It Works":
          return (
            currentPath === "/how-it-works" ||
            currentPath === "/assessments" ||
            currentPath === "/one-on-one-coaching" ||
            currentPath === "/team-coaching"
          );
        case "Resources":
          return currentPath === "/colleges" || currentPath === "/parents";
        case "Buy Sessions":
          return currentPath === "/games" || currentPath.includes("games");
        default:
          return false;
      }
    };

    scope.skipToMainContent = function () {
      $anchorScroll("main");
      var el = $window.document.getElementById("main");
      if (!/^(?:a|select|input|button|textarea)$/i.test(el.tagName)) {
        el.tabIndex = -1;
      }
      el.focus();
    };
  }
}

mainNavBarDirective.$inject = [
  "$window",
  "$location",
  "$stateParams",
  "$anchorScroll",
  "User",
  "EventTrackingManager",
  "CartManager",
  "FlexiTokenService",
  "FeatureGate",
];
