import { gsLogoEmblem } from "../../../config/brand/brandConfig";

export default class PartnerConnectMatcherinoController {
  constructor(
    $scope,
    $rootScope,
    $stateParams,
    User,
    OmnibarPointer,
    MetaTagsService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "matcherino-landing";
    this.userId = $scope.currentId;
    this.role = $scope.role;
    // angular providers
    this.stateParams = $stateParams;
    // services
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.gsLogoEmblem = gsLogoEmblem;
    // boolean
    this.isSignUp = true;
    this.userCalled = false;
    this.userMatcherinoKey = null;

    this.initMatcherino();
    this.initUser();
    this.initMetaTags(MetaTagsService);
  }
  // init methods
  initMatcherino() {
    if (!this.stateParams.matcherino_key) {
      this.omnibarPointer.pushErrorByMessage(
        "A Matcherino key is needed in order to verify the account."
      );
    }
  }
  initUser() {
    if (this.userId) {
      this.userService.setCurrentId(this.userId);
      this.userService.setRole(this.role);
      this.userService
        .getUser(true)
        .then((userData) => {
          this.currentUser = userData;
          this.userCalled = true;
          this.userMatcherinoKey = this.setMatcherinoKey(userData);
        })
        .catch(angular.noop);
    }
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Matcherino | Gamer Sensei";
    const metaDescription =
      "Get ready to win more tournaments with Gamer Sensei.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  // action methods
  switchForm() {
    this.eventTracking.pushEventOnly(
      this.isSignUp ? "accountLoginOpen" : "accountRegistrationOpen"
    );

    this.isSignUp = !this.isSignUp;
  }

  finishLogin(newUser, role) {
    this.userCalled = true;
    this.userMatcherinoKey = this.setMatcherinoKey(newUser);
  }

  finishRegister(newUser) {
    this.userCalled = true;
    this.userMatcherinoKey = this.setMatcherinoKey(newUser);
  }

  buildDashboardLink() {
    if (this.currentUser?.sensei) {
      return "/sensei/dashboard/verify";
    }

    return "/gamer/dashboard/verify";
  }

  // internal methods
  setMatcherinoKey(user) {
    if (user.sensei) {
      return user.sensei.matcherino_key;
    } else if (user.gamer) {
      return user.gamer.matcherino_key;
    }
  }
}

PartnerConnectMatcherinoController.$inject = [
  "$scope",
  "$rootScope",
  "$stateParams",
  "User",
  "OmnibarPointer",
  "MetaTagsService",
  "EventTrackingManager",
];
