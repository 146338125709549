import selectExistingCardPartial from "../../templates/includes/payment/payment_select_existing_card_partial";
import selectNewCardPartial from "../../templates/includes/payment/payment_select_new_card_partial";
import selectPaypalPartial from "../../templates/includes/payment/payment_select_paypal_partial";
import selectAmazonPartial from "../../templates/includes/payment/payment_select_amazon_partial";
import {
  creditCardIcon,
  paypalIcon,
  amazonPayIcon,
} from "../../config/icons/iconConfig";

export default class CheckoutPaymentController {
  constructor(
    $q,
    $scope,
    $timeout,
    $rootScope,
    $location,
    $state,
    stripe,
    CartManager,
    OmnibarPointer,
    User,
    Payment,
    EventTrackingManager,
    UIFeatureGate,
    clientTokenPath
  ) {
    this.stripeKey = $scope.stripePublishableKey;
    // angular providers
    this.$q = $q;
    this.$state = $state;
    this.root = $rootScope;
    // services
    this.stripe = stripe;
    this.cart = CartManager;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.paymentService = Payment;
    this.selectExistingCardPartial = selectExistingCardPartial;
    this.selectNewCardPartial = selectNewCardPartial;
    this.selectPaypalPartial = selectPaypalPartial;
    this.selectAmazonPartial = selectAmazonPartial;
    this.icon = { creditCardIcon, paypalIcon, amazonPayIcon };
    this.creditCards = [];
    this.creditCardForm = {};
    this.addPayment = false;
    this.promoText =
      $location.search() && $location.search().ref
        ? $location.search().ref
        : "";
    this.promoAttached = false;
    this.cardId = false;
    this.cardErrors = {};
    this.loading = false;
    this.loadingCheckout = false;
    this.makingPayment = false;
    this.disabledSubmitButton = false;
    this.ctaCopy = "Continue";
    this.currentUser = {};
    this.userService = User;
    this.shownAmazonButton = false;
    this.clientTokenPath = clientTokenPath;

    this.allowAmazonPay = UIFeatureGate.data.ui_feature_gates.amazon_pay;
    this.ifPromoAvail = UIFeatureGate.data.ui_feature_gates.gsv2_promo_codes;
    this.paymentsOff =
      UIFeatureGate.data.ui_feature_gates.final_balance_protection_measures_activated;

    if ($location.search() && $location.search().ref) {
      this.togglePromo(true, this.promoText);
    }

    this.paypalOptions = {
      onPaymentMethodReceived: (payload) =>
        $timeout(() => {
          this.packagePurchase();
        }, 1000),
      onCancelled: () =>
        $timeout(() => {
          this.cart.setPaymentServiceConfig({
            amazon_pay: { void: true },
            credit_card: { void: true },
            paypal: { void: true },
          });
        }, 1000),
      onUnsupported: () => {
        this.paypalError = "Authorization failed";
      },
    };

    this.initUser();
    angular.element(() => this.initPage());
  }

  // init methods
  initUser() {
    if (!this.root.currentId) this.$state.go("checkout-v2.access");

    const watchUser = this.root.$watch(
      () => this.userService._userData,
      (newVal) => {
        if (newVal) {
          this.updateUser();
          watchUser();
        }
      }
    );
  }
  initPage() {
    this.cart.setPaymentTab("card");
    this.eventTracking.pushViewEvent("Checkout");
  }

  // display methods
  shortenYear(card) {
    const year = card.expiration_year;

    return year.substr(year.length - 2);
  }
  paymentButtonText() {
    if (this.cardId || this.cart.getPaymentTab() === "paypal") {
      return "Continue";
    } else {
      return "Add Payment Method";
    }
  }
  paymentHighlight(id) {
    return this.cardId == id;
  }
  invalidFormInput(input) {
    if (this.creditCardForm.$submitted) return input.$invalid;
  }

  getSelectedCard(id) {
    const length = this.creditCards ? this.creditCards.length : 0;
    let returnCard = null;

    for (let i = 0; i < length; i++) {
      if (this.creditCards[i].id === parseInt(id)) {
        returnCard = this.creditCards[i];
        break;
      }
    }

    return returnCard;
  }

  areThereMoreCreditsThanCost() {
    const credits = this.cart.getSpendableCredits();
    const sub = this.cart.getCartSubTotal();
    const service = this.cart.getServiceFee() ? this.cart.getServiceFee() : 0;

    return credits && credits >= sub + service;
  }

  isBuyButtonDisabled() {
    const availCredits = this.areThereMoreCreditsThanCost();

    if (this.makingPayment) {
      return true;
    } else if (availCredits) {
      return false;
    }
    return !this.cart.isPaymentMethodActive();
  }

  // action methods
  selectPaymentTab(type) {
    this.cart.setPaymentTab(type);
    this.packagePurchase();
  }

  purchaseCartContents() {
    this.makingPayment = true;

    this.cart
      .processCartPayment(this.currentUser)
      .then((_res) => {
        this.makingPayment = false;
        this.$state.go("checkout-v2.success");
      })
      .catch((err) => {
        this.makingPayment = false;
        let subbedItem = false;
        const cartItems = this.cart.getCartItems();
        const isPaypal = !this.cart.getPaymentServiceConfig().paypal.void;

        angular.forEach(cartItems, (item) => {
          if (item.subscribed && !subbedItem) {
            subbedItem = true;
          }
        });

        if (subbedItem && isPaypal) {
          this.omnibarPointer.pushErrorByMessage(
            "Subscription purchases can not be made with PayPal at the moment. Please use a credit card if you wish to continue. We are working to resolve this issue."
          );
        } else {
          this.omnibarPointer.pushErrorByHash(err.data.errors);
        }
      });
  }

  packagePurchase() {
    this.loading = true;
    this.loadingCheckout = true;
    this.paypalError = null;

    // paypal purchase
    if (this.cart.getPaymentTab() === "paypal") {
      const paypalNonce = document.querySelector(
        'input[name="payment_method_nonce"]'
      );

      if (paypalNonce?.value) {
        this.cart.setPaymentServiceConfig({
          amazon_pay: { void: true },
          credit_card: { void: true },
          paypal: { nonce: paypalNonce.value },
        });
      }
    }

    // amazon purchase (not active -- todo?)
    else if (this.cart.getPaymentTab() === "amazonPay") {
      if (window.orderReferenceId) {
        this.cart.setPaymentServiceConfig({
          credit_card: { void: true },
          paypal: { void: true },
          amazon_pay: {
            amazon_order_reference_id: window.orderReferenceId,
          },
        });
      } else {
        this.amazonError = "Please log in to Amazon and select a payment";
      }
    }

    // existing card payment
    else if (this.cardId) {
      const cardData = this.getSelectedCard(this.cardId);

      this.cart.setPaymentServiceConfig({
        paypal: { void: true },
        amazon_pay: { void: true },
        credit_card: {
          credit_card_id: cardData.id,
          credit_card_name: cardData.credit_card_name,
          credit_card_last_four: cardData.credit_card_last_four,
          credit_card_type: cardData.credit_card_type,
          expiration_month: cardData.expiration_month,
          postal_code: cardData.postal_code,
          expiration_year: cardData.expiration_year,
        },
      });
    }

    // submit new credit card
    else if (this.cart.getPaymentTab() === "card") {
      this.creditCardForm.$setSubmitted();

      if (this.creditCardForm.$valid) {
        this.getCreditCard()
          .then((_card) => {
            this.updateUser(true).then((_res) => {
              const cardData = this.getSelectedCard(this.cardId);

              this.cart.setPaymentServiceConfig({
                paypal: { void: true },
                amazon_pay: { void: true },
                credit_card: {
                  credit_card_id: cardData.id,
                  credit_card_name: cardData.credit_card_name,
                  credit_card_last_four: cardData.credit_card_last_four,
                  credit_card_type: cardData.credit_card_type,
                  expiration_month: cardData.expiration_month,
                  postal_code: cardData.postal_code,
                  expiration_year: cardData.expiration_year,
                },
              });
              this.loading = false;
            });
          })
          .catch(angular.noop);
      } else {
        this.loading = false;
      }
    }

    this.eventTracking.pushCheckoutDetailsV2(
      "checkoutOption",
      this.cart.getCartItems(),
      this.cart.getPaymentDetails(),
      2,
      this.cart.getPaymentTab()
    );

    this.loadingCheckout = false;
  }

  togglePromo(add, promoCode) {
    if (!add) {
      this.promoText = "";
    }

    this.cart.togglePromo(add, promoCode).then(
      (res) => {
        this.promoAttached = add ? true : false;
        if (add) {
          const omniTooltipParams = {
            title: "Success!",
            body: `<p>You have successfully applied the promo code ${promoCode}</p>`,
          };

          this.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            persist: true,
            timer: true,
          });
        }
      },
      (err) => {
        this.promoAttached = false;
        this.promoText = "";
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      }
    );
  }

  switchPaymentTab(tab) {
    this.cart.setPaymentTab(tab);
    this.disabledSubmitButton = tab === "other";

    this.ctaCopy =
      tab === "paypal" || tab === "other" ? "Continue" : "Add Payment Method";

    if (tab === "amazonPay" && !this.shownAmazonButton) {
      //running showAmazonPayButton multiple times will produce multiple buttons
      this.shownAmazonButton = true;
      showAmazonPayButton();
    }
  }

  selectCard() {
    this.addPayment = false;
    this.ctaCopy = "Continue";
    this.disabledSubmitButton = false;
  }

  selectUserCard(id) {
    if (this.cardId !== id) {
      this.cardId = id;
      this.packagePurchase();
    }
  }

  useAnotherPayment() {
    this.addPayment = true;
    this.cardId = false;
    this.ctaCopy = "";
    this.loading = false;
    this.disabledSubmitButton = false;

    this.cart.setPaymentServiceConfig({
      amazon_pay: { void: true },
      credit_card: { void: true },
      paypal: { void: true },
    });
  }

  // internal methods
  updateUser(force) {
    const deferred = this.$q.defer();
    let updateType;

    if (!this.loadingCheckout) {
      this.loadingCheckout = true;
    }

    if (force) {
      updateType = this.userService.getUserByIdAndRole(
        this.currentUser.id,
        "gamer",
        force
      );
    } else {
      updateType = this.userService.getUser();
    }

    updateType.then(
      (userData) => {
        this.currentUser = userData;
        this.creditCards = userData.credit_cards;

        if (this.creditCards && this.creditCards.length === 0) {
          this.cardId = false;
          this.addPayment = true;
        } else {
          this.cardId = this.creditCards[0].id;
          this.addPayment = false;
          this.packagePurchase();
        }
        this.disabledSubmitButton = false;
        this.loadingCheckout = false;
        deferred.resolve(userData);
      },
      (err) => {
        deferred.reject(err);
      }
    );

    return deferred.promise;
  }

  getCreditCard() {
    this.stripe.setPublishableKey(this.stripeKey);
    const creditCardParams = {
      number: this.creditCardForm.cardNumber.$viewValue,
      name: this.creditCardForm.name.$viewValue,
      exp_month: this.creditCardForm.expMonth.$viewValue,
      exp_year: this.creditCardForm.expYear.$viewValue,
      cvc: this.creditCardForm.security.$viewValue,
      address_zip: this.creditCardForm.zipcode.$viewValue,
    };

    return this.$q((resolve, reject) => {
      this.stripe.card
        .createToken(creditCardParams)
        .then((res) => {
          const card = {
            stripe_card_token: res.id,
            credit_card_name: res.card.name,
            credit_card_last_four: res.card.last4,
            credit_card_type: res.card.brand,
            expiration_month: res.card.exp_month,
            expiration_year: res.card.exp_year,
            postal_code: res.card.address_zip,
          };

          this.paymentService
            .createCreditCard({ credit_card: card })
            .then((res) => {
              this.updateUser();
              resolve(res);
            })
            .catch((err) => {
              this.omnibarPointer.pushErrorByHash(err.data.errors);
              this.disabledSubmitButton = false;
              reject(err.data.errors);
            });
        })
        .catch((err) => {
          this.omnibarPointer.pushErrorByMessage(err.message);
          this.disabledSubmitButton = false;
          reject(err.message);
        });
    });
  }

  isActiveCard() {
    const cardNumber = this.creditCardForm.cardNumber.$viewValue;

    if (cardNumber && cardNumber.length) {
      switch (cardNumber[0]) {
        case "3":
          return "americanExpress";
        case "4":
          return "visa";
        case "5":
          return "mastercard";
        case "6":
          return "discover";
      }
    }
  }
}

CheckoutPaymentController.$inject = [
  "$q",
  "$scope",
  "$timeout",
  "$rootScope",
  "$location",
  "$state",
  "stripe",
  "CartManager",
  "OmnibarPointer",
  "User",
  "Payment",
  "EventTrackingManager",
  "UIFeatureGate",
  "clientTokenPath",
];
