import moment from "moment-timezone";
import {
  availableEventClick,
  bookingAvailabilityObject,
  bookingEventRender,
} from "../../../../config/dashboard/dashboardConfig";
import {
  calendarIcon,
  discordIcon,
  skypeIcon,
} from "../../../../config/icons/iconConfig";

export default class BusinessLessonsBookingCalendarController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    OmnibarPointer,
    TokenBookingService,
    User,
    pulledGames,
    EventTrackingManager
  ) {
    this.timeZoneList = $scope.timezones;
    this.userId = $scope.currentId;
    // angular providers
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    this.root = $rootScope;
    // services
    this.userService = User;
    this.OmnibarPointer = OmnibarPointer;
    this.tokenBooking = TokenBookingService;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.calendarIcon = calendarIcon;
    this.games = pulledGames.data.games;
    // booleans
    this.newAvailabilitiesSet = false;
    this.loadingAvails = true;
    this.endpointCalled = false;
    this.senseiEndpointCalled = true;
    this.bookingLesson = false;
    this.senseiFilterOpened = false;
    this.senseiSelectOpened = false;
    // time
    this.today = moment();
    this.startOfDay = this.today.clone().startOf("day");
    this.assumedTimeZone = moment.tz.guess();
    // numbers
    this.limitTo = 5;
    // to be set
    this.availableTimeZone = null;
    this.selectedSensei = null;
    this.senseiModel = $stateParams.sensei_id ? $stateParams.sensei_id : "all";
    // arrays
    this.currentEvents = [];
    this.currentUser = {};
    this.bookedTimes = {};
    this.communicationInfoReminder = {
      show: false,
      completed: false,
    };

    this.initCalendarConfig();
    this.initCalendarEvents();

    this.initSelectedTokenTemplate({
      onDepositSet: () => this.setSelectedGame(),
      afterDepositLoad: () =>
        this.initUser({
          tokenAvailabilities: (currentDeposit) => {
            this.setTokenBookingAvailabilities(currentDeposit, {
              buildCalendar: () => this.buildNewCalendarList(),
            });
          },
        }),
    });

    $scope.$on("$destroy", () => {
      this.tokenBooking.scheduleReset();
    });
  }

  // init methods
  initSelectedTokenTemplate(callbacks) {
    const { tokenTemplateId } = this.$stateParams;

    const watchDeposits = this.root.$watch(
      () => this.tokenBooking.deposits,
      (newVal) => {
        if (newVal && Object.keys(newVal).length) {
          let selected;

          // set selected item if current item matches tokenTemplateId from url
          angular.forEach(newVal, (item) => {
            if (item.template_id == tokenTemplateId) {
              selected = item;
            }
          });

          if (
            Object.keys(this.tokenBooking.getSelectedTokenDeposit()).length ===
            0
          ) {
            this.tokenBooking.setSelectedTokenDeposit(selected);
            if (
              Object.keys(this.tokenBooking.getGame()).length === 0 &&
              callbacks?.onDepositSet
            ) {
              callbacks.onDepositSet();
            }
          }

          if (callbacks?.afterDepositLoad) callbacks.afterDepositLoad();
          watchDeposits();
        }
      }
    );
  }

  // config object
  initCalendarConfig() {
    this.bookingConfig = {
      editable: false,
      eventOverlap: false,
      slotEventOverlap: false,
      slotDuration: "00:30:00",
      snapDuration: "00:30:00",
      nowIndicator: true,
      header: {
        left: "prev title next",
        right: "agendaWeek, today",
      },
      defaultView: "agendaWeek",
      allDaySlot: false,
      height: "auto",
      validRange: (nowDate) => {
        return {
          start: this.startOfDay,
          end: this.startOfDay.clone().add(2, "weeks"),
        };
      },
      timeFormat: "h(:mm)a",
      eventClick: availableEventClick.bind(this),
      eventRender: bookingEventRender.bind(this),
    };
  }
  initCalendarEvents() {
    this.$timeout(() => {
      angular.element("#bizBook").fullCalendar({
        ...this.bookingConfig,
      });
    });
  }

  // set user
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("gamer");
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.communicationInfoReminder.show =
          this.currentUser.communication_services.length === 0;
        const currentDeposit = this.tokenBooking.getSelectedTokenDeposit();

        if (callbacks?.tokenAvailabilities)
          callbacks.tokenAvailabilities(currentDeposit);
      })
      .catch(angular.noop);
  }

  // token booking methods
  bookLesson() {
    this.bookingLesson = true;

    if (Object.keys(this.tokenBooking.getGame()).length === 0) {
      this.setSelectedGame();
    }

    this.tokenBooking
      .scheduleSelectedAvailability(
        this.currentUser,
        this.tokenBooking.getSensei().id,
        this.vodURL,
        this.assumedTimeZone
      )
      .then(() => {
        this.eventTracking.pushLessonManagementV2(
          "scheduleLesson",
          this.tokenBooking.getSelectedAvailability(),
          this.currentUser,
          this.tokenBooking.getSensei(),
          this.tokenBooking.getSelectedTokenDeposit()
        );
        this.tokenBooking.setTokenDeposits();

        this.$timeout(() => {
          this.bookingLesson = false;
          this.$state.go("business_dashboard.lessons.calendar");
        }, 0);
      })
      .catch((err) => {
        this.bookingLesson = false;
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  selectSenseiToFilter(sensei) {
    if (sensei === "all") {
      this.$state.go(
        "business_dashboard.lessons.book_lesson_calendar",
        {
          tokenTemplateId: this.$stateParams.tokenTemplateId,
          sensei_id: null,
        },
        {
          reload: true,
        }
      );
    } else {
      this.$state.go(
        "business_dashboard.lessons.book_lesson_calendar",
        {
          tokenTemplateId: this.$stateParams.tokenTemplateId,
          sensei_id: sensei.id,
        },
        {
          reload: true,
        }
      );
    }
  }

  setSelectedGame() {
    this.tokenBooking.setGame(this.games);
  }

  areThereNoBookableTimes(avails) {
    let noBookableTimes = true;

    angular.forEach(avails, (avail, date) => {
      const hasTimeAvail = Object.values(avail).some((x) => {
        return x.length;
      });

      if (hasTimeAvail) {
        noBookableTimes = false;
      }
    });

    return noBookableTimes;
  }

  areThereBookableTimes() {
    const avails = this.tokenBooking.getAvailabilities();
    const areTimesAvail = !this.areThereNoBookableTimes(avails);

    if (this.$stateParams.sensei_id) {
      return this.senseiEndpointCalled && this.endpointCalled && areTimesAvail;
    }
    return this.endpointCalled && areTimesAvail;
  }

  areThereSenseisAvailable() {
    const availSenseis = this.tokenBooking.getAvailableSenseis();
    return Object.keys(availSenseis).length;
  }

  areAvailabilitiesEmpty() {
    return (
      this.endpointCalled &&
      !this.areThereBookableTimes() &&
      this.tokenBooking.getSelectedTokenDeposit()
    );
  }

  isThisNotAUserDeposit() {
    return (
      this.endpointCalled &&
      !this.areThereBookableTimes() &&
      !this.tokenBooking.getSelectedTokenDeposit()
    );
  }

  isThereASelectedAvailability() {
    return Object.keys(this.tokenBooking.getSelectedAvailability()).length;
  }

  closeModal() {
    this.vodURL = "";
    this.tokenBooking.setSelectedAvailability({});
  }

  selectSensei(sensei) {
    this.tokenBooking.setSensei(sensei);
  }

  closeBookingModal() {
    this.tokenBooking.setSelectedAvailability({});
    this.selectedSensei = null;
    this.tokenBooking.setSensei({});
  }

  toggleSenseiSelect() {
    this.senseiSelectOpened = !this.senseiSelectOpened;
  }

  toggleSenseiFilter() {
    this.senseiFilterOpened = !this.senseiFilterOpened;
  }

  setTokenBookingAvailabilities(currentDeposit, callbacks) {
    const { tokenTemplateId } = this.$stateParams;

    this.tokenBooking
      .setAvailabilities(
        tokenTemplateId,
        this.today,
        currentDeposit && currentDeposit.duration_minutes
      )
      .then(
        (res) => {
          this.endpointCalled = true;
          if (this.areThereNoBookableTimes(res.data)) {
            this.loadingAvails = false;
          }
          if (callbacks?.buildCalendar) callbacks.buildCalendar();
        },
        (_err) => {
          this.loadingAvails = false;
          this.endpointCalled = true;
        }
      );
  }

  isDepositPureSkillGGCompatible() {
    return (
      this.tokenBooking.getGame().slug === "counter-strike-global-offensive"
    );
  }

  // display methods
  getCommunicationIcon(medium) {
    return medium === "discord" ? discordIcon : skypeIcon;
  }

  isScheduleDisabled() {
    return (
      this.communicationInfoReminder &&
      this.communicationInfoReminder.show &&
      !this.communicationInfoReminder.completed
    );
  }

  // calendar methods
  buildNewCalendarList() {
    const { sensei_id, tokenTemplateId } = this.$stateParams;

    //if (!sensei_id) return;

    this.loadingAvails = true;
    this.tokenBooking
      .fetchAvailabilityEndpoint(
        tokenTemplateId,
        this.today,
        this.tokenBooking.getSelectedTokenDeposit().duration_minutes,
        sensei_id
      )
      .then((res) => {
        angular.element("#bizBook").fullCalendar("removeEvents");

        const avails = res.data.bookable_hours;
        const events = [];

        angular.forEach(avails, (availsOnDate, onDate) => {
          angular.forEach(availsOnDate, (availSenseis, onHour) => {
            if (availSenseis.length) {
              const currentDate = moment(onDate + " " + onHour, "YYYY-MM-DD H");
              const durationMins =
                this.tokenBooking.getSelectedTokenDeposit().duration_minutes;

              const obj = bookingAvailabilityObject(
                availSenseis,
                currentDate,
                durationMins
              );

              events.push(...obj);
            }
          });
        });

        angular.element("#bizBook").fullCalendar("renderEvents", events, true);

        this.senseiEndpointCalled = true;
        if (this.loadingAvails) this.loadingAvails = false;
      })
      .catch((err) => console.error(err));
  }

  // formatting methods
  formatLongDate() {
    const dateToFormat = this.tokenBooking.getSelectedAvailability();
    return dateToFormat.start.format("dddd, MMMM Do");
  }

  formatTimeRange() {
    const dateToFormat = this.tokenBooking.getSelectedAvailability();
    const startTime = dateToFormat.start.format("h:mma");
    const endTime = moment(startTime, "h:mma")
      .clone()
      .add(dateToFormat.duration, "m")
      .format("h:mma");

    return `${startTime} - ${endTime}`;
  }
}

BusinessLessonsBookingCalendarController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$stateParams",
  "$timeout",
  "OmnibarPointer",
  "TokenBookingService",
  "User",
  "pulledGames",
  "EventTrackingManager",
];
