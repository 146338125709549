export default class SenseiCodeOfConductPageController {
  constructor(MetaTagsService) {
    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Sensei Code of Conduct | Gamer Sensei";
    const metaDescription =
      "Gamer Sensei's Code of Conduct ensures all students are welcomed and can thrive.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

SenseiCodeOfConductPageController.$inject = ["MetaTagsService"];
