export default class SenseiWalletHistoryController {
  constructor($scope, $state, User, TransactionHistories, OmnibarPointer) {
    this.userId = $scope.currentId;
    this.role = "sensei";
    // angular providers
    this.$state = $state;
    this.$watch = $scope.$watch;
    // services
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.transactionHistory = TransactionHistories;
    // numbers
    this.currentPage = 1;
    // to be set
    this.matchDate = null;
    // objects and arrays
    this.currentUser = {};
    this.sensei = {};
    this.historyResults = {};
    this.historyTableHeader = {
      date: {
        title: "Date",
        isActive: true,
        isSortable: true,
      },
      description: {
        title: "Description",
        isActive: false,
        isSortable: false,
      },
      type: {
        title: "Type",
        isActive: false,
        isSortable: false,
      },
      status: {
        title: "Status",
        isActive: false,
        isSortable: false,
      },
      amount: {
        title: "Amount",
        isActive: false,
        isSortable: true,
      },
    };
    this.historyTableFilter = {
      date: this.$state.params.date || "",
    };

    this.initStateParams();
    this.initUser({
      onSuccess: (userData) => {
        const senseiData = userData.sensei;

        this.sensei = senseiData;
        this.currencyId = senseiData.sensei_currency_account.id;
      },
      walletHistory: (page) => this.pullWalletHistory(page),
    });
  }

  // init methods
  initStateParams() {
    this.$watch(
      () => this.historyTableFilter.date,
      (newDate, oldDate) => {
        if (newDate && moment(newDate, "MMMM D, YYYY", true).isValid()) {
          this.matchDate = moment(newDate, "MMMM D, YYYY").format("YYYY-MM-DD");
          this.addStateParams();
        } else {
          this.matchDate = null;
        }
      },
      true
    );
  }
  initUser(callbacks) {
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.matchDate = this.$state.params.date;

        if (callbacks?.onSuccess) callbacks.onSuccess(userData);
        if (callbacks?.walletHistory) callbacks.walletHistory(this.currentPage);
      })
      .catch((err) => this.handleError(err.data.errors));
  }

  // fetch methods
  pullWalletHistory(page) {
    const historyParams = {
      params: {
        account_id: this.sensei.sensei_currency_account.id,
        account_type: this.role,
        page: page,
        date: this.matchDate,
      },
    };

    this.transactionHistory
      .currencyHistory(historyParams)
      .then((res) => {
        this.pages = res.data.pages;
        this.historyResults = res.data.transactions;

        if (this.currentPage !== page) this.currentPage = page;
      })
      .catch((err) => this.handleError(err.data.errors));
  }

  // action methods
  clickOffDateFilter() {
    if (this.historyTableFilter.date) this.historyTableFilter.date = "";
    this.matchDate = null;
    this.addStateParams();
  }

  // display methods
  areThereFilters() {
    return !!Object.keys(this.historyTableFilter).length;
  }

  // internal methods
  addStateParams() {
    this.$state.go("sensei_dashboard.wallet.history", {
      date: this.matchDate,
    });
  }
  handleError(message) {
    this.omnibarPointer.pushErrorByHash(message);
  }
}

SenseiWalletHistoryController.$inject = [
  "$scope",
  "$state",
  "User",
  "TransactionHistories",
  "OmnibarPointer",
];
