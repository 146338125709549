export default function gameMetricProfileService ($http) {
  var service = {
    user: getGamerMetricProfile,
    visitor: getVisitorMetricProfile,
    gameMetrics: getGameMetrics
  };

  return service;

  ///////////

  function getGamerMetricProfile(guid) {
    return $http.get('/api/web/users/' + guid + '/game_metric_profile');
  }

  function getVisitorMetricProfile(uuid) {
    return $http.get('/api/web/visitors/' + uuid + '/game_metric_profile');
  }

  function getGameMetrics(gmpid, config) {
    return $http.get('/api/web/game_metric_profiles/' + gmpid + '/game_metrics', config);
  }
}

gameMetricProfileService.$inject = ['$http'];