export default class GamerWalletSubscriptionsController {
  constructor(
    $scope,
    User,
    UserSubscriptionsService,
    SenseiBenefitListService
  ) {
    this.userService = User;
    this.userSubs = UserSubscriptionsService;
    this.benefits = SenseiBenefitListService;
    this.completedGrab = false;
    this.confirmationModalOpened = false;
    this.selectedTokenForRefund = {};
    this.today = moment();

    this.userService.setCurrentId($scope.currentId);
    this.userService.setRole("gamer");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.userSubs
          .setSubscriptions("gamer", userData.gamer.id)
          .then((res) => {
            this.completedGrab = true;
          })
          .catch((err) => {
            this.completedGrab = true;
          });
      })
      .catch(angular.noop);

    this.getSenseiBenefitList = function (id) {
      this.benefits.setSubscriptionItems(id);
    };

    this.doesUserHaveAnySubscriptions = function () {
      return this.completedGrab && this.userSubs.getSubscriptions().length;
    };
  }
}

GamerWalletSubscriptionsController.$inject = [
  "$scope",
  "User",
  "UserSubscriptionsService",
  "SenseiBenefitListService",
];
