export default function paymentService($http) {

  var service = {
    createCreditCard: createCreditCard,
    deleteCreditCard: deleteCreditCard,
    xsollaToken: xsollaToken
  };

  return service;

  ///////////

  function createCreditCard(data) {
    return $http.post('/api/web/credit_cards', data);
  }

  function deleteCreditCard(id) {
    return $http.delete('/api/web/credit_cards/' + id);
  }

  function xsollaToken(data) {
    return $http.post('/api/web/xsolla_pay_station_tokens/', data);
  }
}

paymentService.$inject = ['$http'];
