export default function senseiReviewService($http) {

  var service = {
    get: getSenseiReview,
    create: createSenseiReview,
    flag: flagSenseiReview,
    chooseTestimonial: chooseTestimonial
  };

  return service;

  ///////////

  function getSenseiReview(config) {
    return $http.get('/api/web/sensei_reviews', config);
  }

  function createSenseiReview(config) {
    return $http.post('/api/web/sensei_reviews/', config);
  }

  function flagSenseiReview(id) {
    return $http.put('/api/web/sensei_reviews/' + id + '/flag');
  }

  function chooseTestimonial(id) {
    return $http.put('/api/web/sensei_reviews/' + id + '/choose_testimonial');
  }

}

senseiReviewService.$inject = ['$http'];