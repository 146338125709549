import LessonCardTemplate from "../../../templates/directives/dashboard/lessons/lesson_card_template";
import paymentLessonCardPartial from "../../../templates/includes/payment/payment_lesson_card";
import {
  discordIcon,
  gearIcon,
  skypeIcon,
  walletIcon,
} from "../../../config/icons/iconConfig";

export default function lessonCardDirective(
  $state,
  Dashboard,
  LessonDiscard,
  DirectTrainingRequestSchedule,
  SenseiTrainingRequestManager,
  $timeout,
  OmnibarPointer,
  EventTrackingManager,
  CartManager,
  TokenService
) {
  return {
    restrict: "EA",
    scope: {
      manager: "=",
      session: "=",
      cancelModal: "=",
      isExtendLessonRequestAvailable: "=",
      noticeReturned: "=",
      reviewedSensei: "=",
      openSenseiReviewModal: "&openSenseiReviewModal",
      isStoreValueAvailable: "=",
      isLessonArrivalAvailable: "=?",
      // Still use parent controller methods
      openGamerCancelReschedule: "&",
      openGamerImportantInformation: "&",
      openReproposeModal: "&openReproposeModal",
    },
    template: LessonCardTemplate,
    link: function (scope, element, attrs) {
      // Button disable toggle
      scope.actionDisable = false;
      scope.cart = CartManager;
      scope.tokenManager = TokenService;
      scope.eventTracking = EventTrackingManager;
      scope.paymentLessonCardPartial = paymentLessonCardPartial;
      scope.proposalModal = {};
      scope.icon = {
        gearIcon,
        walletIcon,
      };

      // Hover remove active class and change change status
      element.on("mouseleave", () => {
        if (scope.session.unread) {
          scope.session.unread = false;
          scope.manager.dismissUnread(scope.session.getId());
        }
      });

      scope.getCommunicationIcon = (medium) => {
        return medium === "discord" ? discordIcon : skypeIcon;
      };

      scope.buildSessionTitle = (session) => {
        var time;
        if (session.sessionDurationHours === 0.5) {
          time = "30-Minute";
        } else {
          time = `${session.sessionDurationHours}-Hour`;
        }
        if (session.tokenTypeName) {
          return `${time} ${session.gameName} ${session.tokenTypeName} Session on ${session.dateString} at ${session.timeString} ${session.tzString}`;
        }
        return `${time} ${session.gameName} Lesson on ${session.dateString} at ${session.timeString} ${session.tzString}`;
      };

      scope.isConferenceAvailable = (session) => {
        if (scope.senseiConnectExists) {
          if (scope.isLessonArrivalAvailable && session.bothUsersAreReady) {
            return true;
          } else if (
            !scope.isLessonArrivalAvailable &&
            session.conferenceCTAPostLessonAvailableUntil &&
            session.conferenceCTAPreLessonAvailableAt
          ) {
            var today = moment();
            var start_time = moment(
              session.conferenceCTAPreLessonAvailableAt,
              "YYYY-MM-DDTHH:mm:ssZ"
            );
            var end_time = moment(
              session.conferenceCTAPostLessonAvailableUntil,
              "YYYY-MM-DDTHH:mm:ssZ"
            );

            return (
              today.isSameOrAfter(start_time) && today.isSameOrBefore(end_time)
            );
          }
        }
        return false;
      };

      scope.joinLessonConference = (session) => {
        var confapp = session.conferenceAppOff ? "off" : "";
        if (session.perspective === "gamer") {
          scope.eventTracking.pushEvent("Launch Sensei Connect", {
            category: "Gamer Launch Sensei Connect",
            label: "Gamer Launch Sensei Connect",
          });
          $state.go("gamer_dashboard.lessons.list.conference", {
            lesson_uid: session.uuid,
            confapp: confapp,
          });
        } else if (session.perspective === "sensei") {
          scope.eventTracking.pushEvent("Launch Sensei Connect", {
            category: "Sensei Launch Sensei Connect",
            label: "Sensei Launch Sensei Connect",
          });
          $state.go("sensei_dashboard.lessons.list.conference", {
            lesson_uid: session.uuid,
            confapp: confapp,
          });
        }
      };

      // Functions
      scope.statusClass = (status) => {
        if (
          status === "Confirmed" ||
          status === "Under 5 minutes" ||
          status.indexOf("In Progress") !== -1
        ) {
          return "card-notice-green";
        } else if (status === "Requested") {
          return "card-notice-blue";
        } else if (status === "New Time" || status === "New Session Proposal") {
          return "card-notice-red";
        }
      };
      scope.openSenseiNotes = (session) => {
        $state.go("gamer_dashboard.lessons.sensei_note_page", {
          lessonId: session.request.id,
        });
      };
      scope.rateSession = (session, $event) => {
        session.rated = true;
        session.rating_by_user = $event.rating;
        $timeout(() => {
          session.rated = false;
        }, 3000);
        if (session.isGamer()) {
          Dashboard.sensei_training_request_gamer_review(session.reviewId, {
            rating: session.rating_by_user,
          });
        } else {
          Dashboard.sensei_training_request_sensei_review(session.reviewId, {
            rating: session.rating_by_user,
          });
        }
      };
      scope.acceptDirectBookingSchedule = (session) => {
        if (scope.actionDisable) return;
        scope.actionDisable = true;
        if (session.isGamer()) {
          DirectTrainingRequestSchedule.gamerAccept(session.requestScheduleId)
            .then((res) => {
              SenseiTrainingRequestManager.addOrUpdateToActionable(
                res.data.sensei_training_request
              );
              // Dismiss error
              scope.actionDisable = false;
            })
            .catch((err) => {
              // Show error
              if (
                err.data.errors &&
                err.data.errors.direct_booking_schedule_update_error ===
                  "No Tokens available"
              ) {
                scope.tokenManager
                  .fetchSpecificTokenTemplateInventories(
                    session.request.token_template_id
                  )
                  .then((res) => {
                    scope.cart.addCartItem(
                      {
                        ...res.data.token_template_inventory,
                        sensei_proposed_lesson: "accepted",
                        sensei_proposed_lesson_data: {
                          data: session,
                        },
                      },
                      "single"
                    );
                  });
              }
              errorHandler(err.data);
            });
        } else {
          DirectTrainingRequestSchedule.senseiAccept(session.requestScheduleId)
            .then((res) => {
              SenseiTrainingRequestManager.addOrUpdateToActionable(
                res.data.sensei_training_request
              );
              // Dismiss error
              scope.actionDisable = false;
            })
            .catch((err) => {
              // Show error
              errorHandler(err.data);
            });
        }
      };
      scope.cancelModal.cancelSession = (session) => {
        scope.cancelModal.toggle();
        scope.cancelExpiredSession(session);
        scope.cancelModal.session = null;
      };

      scope.cancelExpiredSession = (session) => {
        if (scope.actionDisable) return;
        scope.actionDisable = true;
        LessonDiscard.gamerCancel(session.id)
          .then((res) => {
            SenseiTrainingRequestManager.moveToFrozenByRequest(
              res.data.sensei_training_request
            );
            // Dismiss error
            scope.actionDisable = false;
          })
          .catch((err) => {
            // Show error
            errorHandler(err.data);
          });
      };
      scope.rejectExpiredSession = (session) => {
        if (scope.actionDisable) return;
        scope.actionDisable = true;
        LessonDiscard.senseiReject(session.id)
          .then((res) => {
            SenseiTrainingRequestManager.moveToFrozenByRequest(
              res.data.sensei_training_request
            );
            // Dismiss error
            scope.actionDisable = false;
          })
          .catch((err) => {
            // Show error
            errorHandler(err.data);
          });
      };
      scope.isExtendableLessonRequest = (session) => {
        return (
          session.status == "accepted_and_in_progress" &&
          scope.isExtendLessonRequestAvailable &&
          !scope.isStoreValueAvailable
        );
      };

      function errorHandler(err) {
        scope.actionDisable = false;
        OmnibarPointer.pushErrorByHash(err.data.errors);
      }
    },
  };
}

lessonCardDirective.$inject = [
  "$state",
  "Dashboard",
  "LessonDiscard",
  "DirectTrainingRequestSchedule",
  "SenseiTrainingRequestManager",
  "$timeout",
  "OmnibarPointer",
  "EventTrackingManager",
  "CartManager",
  "TokenService",
];
