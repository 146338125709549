import ExtendLessonsRequestTemplate from "../../../templates/directives/dashboard/lessons/extend_lesson_request_template";

export default function extendLessonRequestDirective(
  stripe,
  LessonExtension,
  SenseiTrainingRequestManager,
  OmnibarPointer
) {
  return {
    restrict: "E",
    scope: {
      lesson: "=",
      noticeReturned: "=",
    },
    link: link,
    template: ExtendLessonsRequestTemplate,
  };

  function link(scope, elem, attrs) {
    var extensionPriceInDollars = scope.lesson.hourlyRate / 100;
    scope.actionDisable = false;

    scope.errorLink =
      'Please go to your <a ui-sref="gamer_dashboard.wallet.purchase" href="gamer/dashboard/wallet/purchase">Wallet</a> to purchase more currency.';

    if (scope.lesson.perspective == "gamer") {
      scope.extensionTitle =
        "You will be charged $" +
        extensionPriceInDollars +
        " for this 1 hour extension";
    } else {
      scope.extensionTitle =
        "The Gamer will be charged $" +
        extensionPriceInDollars +
        " for this 1 hour extension.";
    }

    scope.request = function () {
      var paymentParams = getPaymentParams(
        scope.stripePublishableKey,
        scope.lesson
      );
      var data = {
        sensei_training_request_id: scope.lesson.id,
        lesson_extension_request: {
          proposal_perspective: scope.lesson.perspective,
        },
        credit_card: paymentParams.creditCardParams,
        paypal: paymentParams.payPalParams,
        virtual_currency: paymentParams.virtualCurrencyParams,
      };
      scope.actionDisable = true;
      LessonExtension.requestExtension(data).then(
        (res) => {
          var responseRequest =
            res.data.lesson_extension_request.sensei_training_request;
          scope.lesson.requestedExtensionRequester = scope.lesson.perspective;
          scope.lesson.eligibleForExtension = false;
          scope.lesson.showExtendLesson = false;
          scope.lesson.gamer_extension_amounts =
            responseRequest.gamer_extension_amounts;
          scope.actionDisable = false;
        },
        (err) => {
          OmnibarPointer.pushErrorByHash(err.data.errors, {
            appendString: scope.errorLink,
          });
          scope.actionDisable = false;
        }
      );
    };

    scope.dismiss = function () {
      scope.lesson.showExtendLesson = false;
    };

    scope.reject = function () {
      var extensionIndexParams = {
        params: {
          sensei_training_request_id: scope.lesson.id,
          status: "requested",
        },
      };
      LessonExtension.getExtensions(extensionIndexParams).then(
        (res) => {
          var extensionId = res.data.lesson_extension_requests[0].id;
          LessonExtension.rejectExtension(extensionId).then(
            (res) => {
              var responseRequest =
                res.data.lesson_extension_request.sensei_training_request;
              scope.lesson.request.gamer_extension_amounts =
                responseRequest.gamer_extension_amounts;
              scope.lesson.request.session_duration_hours =
                responseRequest.session_duration_hours;

              SenseiTrainingRequestManager.addOrUpdateToActionable(
                scope.lesson.request
              );
              scope.lesson.showExtendLesson = false;
              scope.lesson.requestedExtensionRequester = null;
              scope.lesson.eligibleForExtension = false;
            },
            (err) => {
              OmnibarPointer.pushErrorByHash(err.errors);
            }
          );
        },
        (err) => {
          OmnibarPointer.pushErrorByHash(err.errors);
        }
      );
    };

    scope.accept = function () {
      var paymentParams = getPaymentParams(
        scope.stripePublishableKey,
        scope.lesson
      );
      var acceptData = {
        credit_card: paymentParams.creditCardParams,
        paypal: paymentParams.payPalParams,
        virtual_currency: paymentParams.virtualCurrencyParams,
      };
      var extendAcceptButton = angular.element(
        document.querySelector("#extend-accept")
      );
      scope.actionDisable = true;

      var extensionIndexParams = {
        params: {
          sensei_training_request_id: scope.lesson.id,
          status: "requested",
        },
      };
      LessonExtension.getExtensions(extensionIndexParams).then(
        (res) => {
          var extensionId = res.data.lesson_extension_requests[0].id;
          LessonExtension.acceptExtension(extensionId, acceptData).then(
            (res) => {
              scope.actionDisable = false;
              var responseRequest =
                res.data.lesson_extension_request.sensei_training_request;
              scope.lesson.request.requestedExtensionRequester =
                scope.lesson.request.perspective;
              scope.lesson.request.requested_extension_requester = null;
              scope.lesson.request.gamer_extension_amounts =
                responseRequest.gamer_extension_amounts;
              scope.lesson.request.session_duration_hours =
                responseRequest.session_duration_hours;

              SenseiTrainingRequestManager.addOrUpdateToActionable(
                scope.lesson.request
              );
              scope.lesson.eligibleForExtension = true;
              scope.lesson.showExtendLesson = false;
            },
            (err) => {
              OmnibarPointer.pushErrorByHash(err.data.errors, {
                appendString: scope.errorLink,
              });
              scope.actionDisable = false;
            }
          );
        },
        (err) => {
          scope.actionDisable = false;
          OmnibarPointer.pushErrorByHash(err.data.errors);
        }
      );
    };
  }

  function getPaymentParams(stripePublishableKey, lesson) {
    stripe.setPublishableKey(stripePublishableKey);

    var creditCardParams = { void: true };
    var payPalParams = { void: true };
    var virtualCurrencyParams = { void: true };
    if (lesson.perspective == "gamer") {
      if (lesson.gamerChargedAmountInCurrency) {
        virtualCurrencyParams = {
          currency_extension_hourly_rate:
            lesson.pertinent_lesson_fee / lesson.sessionDurationHours,
        };
      } else if (lesson.creditCard) {
        creditCardParams = { credit_card_id: lesson.creditCard.id };
      } else if (lesson.payPal) {
        var paypalNonce = document.querySelector(
          'input[name="payment_method_nonce"]'
        ).value;
        payPalParams = {
          nonce: paypalNonce,
          net_price: lesson.hourlyRate / 100,
        };
      }
    }
    return {
      creditCardParams: creditCardParams,
      payPalParams: payPalParams,
      virtualCurrencyParams: virtualCurrencyParams,
    };
  }
}

extendLessonRequestDirective.$inject = [
  "stripe",
  "LessonExtension",
  "SenseiTrainingRequestManager",
  "OmnibarPointer",
];
