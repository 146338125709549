import reusableApp from "./gs-reusable";
import accessApp from "./gs-access";
import responsiveTemplateApp from "./gs-responsive-template";
import { uiFeatureGate } from "../config/utils/utilsConfig";

const partnerApp = angular.module("gamerSenseiPartnerConnectPages", [
  "ngResource",
  "ngRoute",
  "ui.router.compat",
  reusableApp.name,
  accessApp.name,
  responsiveTemplateApp.name,
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
]);

// Directive setup
import slickDirective from "../directives/data/slick-directive";
partnerApp
  .constant("slickCarouselConfig", {
    method: {},
    event: {},
  })
  .directive("slick", slickDirective);

import PartnerConnectContainerController from "../controllers/promotion/partner-connect/partner-connect-container-controller";
import PartnerConnectContainerTemplate from "../templates/promotion/partner_connect/partner_connect_container_template";
import PartnerConnectMatcherinoController from "../controllers/promotion/partner-connect/partner-connect-matcherino-controller";
import PartnerConnectMatcherinoTemplate from "../templates/promotion/partner_connect/partner_connect_matcherino_template";

partnerApp.config(configPartnerConnectPages);

function configPartnerConnectPages(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("partner_connect_landing", {
      abstract: true,
      controller: PartnerConnectContainerController,
      controllerAs: "vm",
      template: PartnerConnectContainerTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("partner_connect_landing.matcherino", {
      url: "/matcherino?matcherino_key",
      controller: PartnerConnectMatcherinoController,
      controllerAs: "vm",
      template: PartnerConnectMatcherinoTemplate,
      resolve: {
        VisitorGeoLocale: getVisitorGeoLocale,
      },
    });
}

configPartnerConnectPages.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

loadGames.$inject = ["Game"];
function loadGames(Game) {
  var gameQueryParams = {
    params: {
      status: "active",
    },
  };
  return Game.query(gameQueryParams);
}

export default partnerApp;
