import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class PasswordRecoverySubmitPageController {
  constructor(
    $rootScope,
    $window,
    $location,
    $timeout,
    AccessControl,
    PasswordReset,
    passwordRecoveryCheck,
    OmnibarPointer,
    MetaTagsService
  ) {
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;

    $rootScope.bodylayout = "recovery submit";

    this.isPasswordRecoveryVisible =
      passwordRecoveryCheck.data.ui_feature_gates.password_recovery;
    this.omnibarPointer = OmnibarPointer;
    this.password = {};
    this.recoverToken = $location.search().reset_password_token;

    var metaTitle = "Change You Gamer Sensei Password";
    var metaDescription = "Change your password.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });

    $timeout(() => {
      if (!this.isPasswordRecoveryVisible) {
        window.location = "/";
      }
    }, 0);

    this.validate = function (valid) {
      if (!valid) {
        OmnibarPointer.pushErrorByMessage(
          "Something went wrong. Please check the errors below."
        );
      }
      return valid;
    };

    this.requestSubmit = function () {
      if (AccessControl.areAnyRequiredCharactersMissing(this.password.submit)) {
        OmnibarPointer.pushErrorByMessage(
          "Your password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters"
        );
      } else {
        var pwData = {
          new_password: this.password.submit,
          new_password_confirmation: this.password.confirm,
          reset_password_token: this.recoverToken,
        };
        PasswordReset.submit(pwData)
          .then((res) => {
            PasswordReset.setStatus("submitted");
            $timeout(() => {
              $window.location.href = "/sign_in/new";
            }, 0);
          })
          .catch((err) => {
            if (err.data.errorCode) {
              OmnibarPointer.pushErrorByMessage(
                `${err.data.errorCode}: ${err.data.errorMessage}`
              );
            } else {
              OmnibarPointer.pushErrorByHash(err.data.errors);
            }
          });
      }
    };
  }
}

PasswordRecoverySubmitPageController.$inject = [
  "$rootScope",
  "$window",
  "$location",
  "$timeout",
  "AccessControl",
  "PasswordReset",
  "passwordRecoveryCheck",
  "OmnibarPointer",
  "MetaTagsService",
];
