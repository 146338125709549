export default function programContactService($http) {
  const service = {
    sendProgramContactInfo,
  };

  return service;

  ///

  function sendProgramContactInfo(data) {
    return $http.post("/api/web/program_contact_forms", data);
  }
}

programContactService.$inject = ["$http"];
