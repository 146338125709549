import accessApp from "./gs-access";
import reusableApp from "./gs-reusable";
import { uiFeatureGate, getGames } from "../config/utils/utilsConfig";

const giftCardPurchaseApp = angular.module("gamerSenseiGiftCard", [
  "ngResource",
  "ui.router.compat",
  accessApp.name,
  reusableApp.name,
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "braintree-angular",
  "angular-stripe",
]);

// routing setup
import GiftCardContainerController from "../controllers/gift_card/gift-card-container-controller";
import GiftCardContainerTemplate from "../templates/gift_card/gift_card_container_template";

import GiftCardLandingController from "../controllers/gift_card/gift-card-landing-controller";
import GiftCardLandingTemplate from "../templates/gift_card/gift_card_landing_template";

import GiftCardAccountController from "../controllers/gift_card/gift-card-account-controller";
import GiftCardAccountTemplate from "../templates/gift_card/gift_card_access_template";

import GiftCardPaymentController from "../controllers/gift_card/gift-card-payment-controller";
import GiftCardPaymentTemplate from "../templates/gift_card/gift_card_payment_template";

import GiftCardConfirmationController from "../controllers/gift_card/gift-card-confirmation-controller";
import GiftCardConfirmationTemplate from "../templates/gift_card/gift_card_confirmation_template";

giftCardPurchaseApp
  .config(configStyleGuide)
  .constant("clientTokenPath", "/api/web/braintree_tokens");

function configStyleGuide(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
      ecommerce: true,
    },
  };

  $stateProvider
    .state("gift-cards", {
      abstract: true,
      template: GiftCardContainerTemplate,
      controller: GiftCardContainerController,
      controllerAs: "vm",
      resolve: {
        activeGames: getGames,
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("gift-cards.landing", {
      url: "/gift-cards",
      template: GiftCardLandingTemplate,
      controller: GiftCardLandingController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("gift-cards.account", {
      url: "/gift-cards/account-info",
      template: GiftCardAccountTemplate,
      controller: GiftCardAccountController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("gift-cards.payment", {
      url: "/gift-cards/payment",
      template: GiftCardPaymentTemplate,
      controller: GiftCardPaymentController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("gift-cards.confirmation", {
      url: "/gift-cards/confirmation",
      template: GiftCardConfirmationTemplate,
      controller: GiftCardConfirmationController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    });
}

configStyleGuide.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

export default giftCardPurchaseApp;
