const getHours = () => {
  return Array.from({ length: 24 }, (_, i) => i).reduce((r, hour) => {
    const hourTime = moment({ hour, minute: 0 });
    r.push({
      display: hourTime.format("h:mm A"),
      value: hourTime.clone().format("HH:mm"),
    });
    return r;
  }, []);
};

export default getHours;
