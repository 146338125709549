export default function responsiveTemplateDirective(
  ResizeService,
  $compile,
  $timeout
) {
  return {
    restrict: "E",
    scope: true,
    link: link,
    //template: $compile(`<div ng-bind-html="template.url | toHtml"></div>`),
  };

  function link(scope, elem, attr) {
    const mobile = attr.mobile;
    const desktop = attr.desktop;
    const setWidth = 1020;

    scope.templates = [
      { name: mobile, url: mobile },
      { name: desktop, url: desktop },
    ];

    scope.templateStatus =
      ResizeService.currentWidth > setWidth ? "desktop" : "mobile";

    if (scope.templateStatus === "desktop") {
      elem.append($compile(desktop)(scope));
    } else {
      elem.append($compile(mobile)(scope));
    }

    scope.$on("width:updated", (event, data) => {
      $timeout(() => {
        if (scope.templateStatus === "desktop" && data <= setWidth) {
          scope.templateStatus = "mobile";
          elem.empty().append($compile(mobile)(scope));
        } else if (scope.templateStatus === "mobile" && data > setWidth) {
          scope.templateStatus = "desktop";
          elem.empty().append($compile(desktop)(scope));
        }
      }, 0);
    });
  }
}

responsiveTemplateDirective.$inject = ["ResizeService", "$compile", "$timeout"];
