import oneOnOneBgImg from "../../images/sections/gs_one_on_one_coaching.png";
import oneOnOneBgWebP from "../../images/sections/gs_one_on_one_coaching.webp";
import nicoThePicoImg from "../../images/backgrounds/origen_nicothepico_poe";
import {
  checkListIcon,
  communicationIcon,
  teamIcon,
  nextIcon,
} from "../../config/icons/iconConfig";

export default class OneOnOneCoachingPageController {
  constructor(MetaTagsService, TestimonialUtils) {
    this.oneOnOneBgImg = oneOnOneBgImg;
    this.oneOnOneBgWebP = oneOnOneBgWebP;
    this.nicoThePicoImg = nicoThePicoImg;
    this.icons = { checkListIcon, communicationIcon, teamIcon, nextIcon };
    this.testimonials = TestimonialUtils.customers;
    this.testimonialConfig = {
      enabled: true,
      dots: false,
      arrows: false,
      speed: 600,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      method: {},
    };
    this.whoGetsTeamCoaching = {
      header: '<h4 class="text-light">Who gets coaching?</h4>',
      body: "<p>Everyone!</p><p>Whether you're a weekend warrior playing casually or a burgeoning pro looking to min/max your way to stardom, our coaches will work with you to realize your specific goals.</p><p>Gaming is for everyone, and a personalized lesson plan from our expert Sensei helps you use your time more effectively so you can find success faster.</p>",
      position: "left",
      transparentBg: true,
      cta: {
        text: "Book your session",
        url: "/games",
      },
    };

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "One-On-One Coaching | Gamer Sensei";
    const metaDescription =
      "Take your skills to the next level with a professional coach from Gamer Sensei.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

OneOnOneCoachingPageController.$inject = [
  "MetaTagsService",
  "TestimonialUtils",
];
