import userCombinedNameTemplate from "../../templates/directives/users/user_combined_name_template";

export default function userCombinedNameDirective($timeout) {
  function link(scope, elem, attrs) {
    if (scope.fullName) {
      splitUserName(scope.fullName);

      $timeout(function () {
        var ifFirst = scope.firstName ? scope.firstName : "";
        var ifScreen = scope.screenName ? " '" + scope.screenName + "' " : " ";
        var ifLast = scope.lastName ? scope.lastName : "";
        scope.combinedName = ifFirst + ifScreen + ifLast;
      });
    }

    function splitUserName(usersName) {
      var full = usersName.split(/\s+/),
        first = full.slice(0, -1).join(" "),
        last = full.pop();
      if (first) {
        scope.firstName = first;
        scope.lastName = last;
      } else {
        scope.firstName = last;
      }
    }
  }
  return {
    restrict: "E",
    scope: {
      fullName: "=",
      screenName: "=",
      combinedName: "=?",
    },
    link: link,
    template: userCombinedNameTemplate,
  };
}

userCombinedNameDirective.$inject = ["$timeout"];
