export default function resizeDirective($window, ResizeService) {
  return {
    restrict: "AE",
    scope: {},
    link: link,
  };

  function link(scope, elem, attr) {
    scope.resizeService = ResizeService;

    function onResize() {
      if (scope.resizeService.currentWidth !== $window.innerWidth) {
        scope.resizeService.setCurrentWidth($window.innerWidth);
        scope.$digest();
      }
    }

    function cleanUp() {
      angular.element($window).off("resize", onResize);
    }

    angular.element($window).on("resize", onResize);
    scope.$on("$destroy", cleanUp);
  }
}

resizeDirective.$inject = ["$window", "ResizeService"];
