export default class SenseiWalletCashoutController {
  constructor(
    $rootScope,
    $state,
    User,
    CurrencyPayout,
    TransactionHistories,
    OmnibarPointer,
    UIFeatureGate,
    Account
  ) {
    this.userId = $rootScope.currentId;
    this.role = "sensei";
    // providers
    this.state = $state;
    this.userService = User;
    this.root = $rootScope;
    //services
    this.account = Account;
    this.currency = CurrencyPayout;
    this.transactionHistory = TransactionHistories;
    this.omnibarPointer = OmnibarPointer;
    // integer
    this.currentPage = 1;
    this.historyResults = {};
    this.historyTableHeader = {
      date: {
        title: "Date",
        isActive: true,
        isSortable: false,
      },
      description: {
        title: "Description",
        isActive: false,
        isSortable: false,
      },
      type: {
        title: "Type",
        isActive: false,
        isSortable: false,
      },
      status: {
        title: "Status",
        isActive: false,
        isSortable: false,
      },
      amount: {
        title: "Amount",
        isActive: false,
        isSortable: true,
      },
    };

    this.noResults = {
      header: "No history",
    };

    this.isStoreValueAvailable =
      UIFeatureGate.data.ui_feature_gates.points_to_store_value;

    this.initStateWatcher();
    this.initUser({
      onSuccess: () => this.initCurrencyHistory(),
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.sensei = this.currentUser.sensei;

        if (callbacks?.onSuccess) callbacks.onSuccess();
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  initCurrencyHistory() {
    const historyParams = {
      params: {
        account_id: this.sensei.sensei_currency_account.id,
        account_type: "sensei",
        page: this.currentPage,
      },
    };

    this.transactionHistory
      .currencyHistory(historyParams)
      .then((res) => {
        this.pages = res.data.pages;
        this.historyResults = res.data.transactions;
      })
      .catch((err) => this.omnibarPointer.pushErrorByHash(err.data.errors));
  }

  initStateWatcher() {
    this.root.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams, options) => {
        const shouldModalOpen = toState.modal;

        if (this.isModalOpen && !shouldModalOpen) {
          this.closeCashoutModal(true);
        } else if (shouldModalOpen && !this.isModalOpen) {
          this.openCashoutModal();
        }
      }
    );
  }

  // action methods
  cashoutConfirm() {
    const min_threshold = 1000 * 60;
    const rounded = new Date(
      Math.round(new Date().getTime() / min_threshold) * min_threshold
    );
    const sha_hash = sha256(rounded.toString());
    const payoutParams = {
      currency_payout: {
        virtual_currency_amount:
          this.sensei.sensei_currency_account.payoutable_balance,
        virtual_currency_conversion_rate:
          this.sensei.sensei_currency_account.currency_conversion_rate,
        request_stamp: sha_hash,
      },
    };

    this.currencyPayout
      .payout(payoutParams)
      .then((res) => {
        this.currentUser.sensei.sensei_currency_account =
          res.data.sensei_currency_account;
        this.sensei = this.currentUser.sensei;
        this.closeCashoutModal();
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  saveDeelAccountHandle() {
    const updateParams = {
      user: {
        sensei_paypal_handle: this.sensei.paypal_handle,
      },
    };

    this.account
      .update(this.userId, updateParams)
      .then((res) => {
        this.deelHandleModalOpen = false;

        const omniTooltipParams = {
          title: "Handle Updated!",
          body: "<p>Settings have been successfully saved!</p>",
        };
        this.omnibarPointer.pushTooltip(omniTooltipParams);
      })
      .catch((res) => {
        this.omnibarPointer.pushErrorByHash(res.errors);
      });
  }

  openCashoutModal(state) {
    this.isModalOpen = true;
    angular.element(document.querySelector("html")).addClass("open-modal");
    if (state) this.state.go(state);
  }

  closeCashoutModal(forceCloseModal) {
    const forceClose = forceCloseModal === true;
    if (!forceClose) this.state.go("^", { location: "replace" }); // Go to parent state

    this.isModalOpen = false;
    this.disableButton = false;
    this.omnibarPointer.resetOmnibar();

    angular.element(document.querySelector("html")).removeClass("open-modal");
  }

  openUpdateModal() {
    this.deelHandleModalOpen = true;
  }

  // display methods
  cashoutableBalance() {
    if (!this.sensei.sensei_currency_account) return 0;
    if (this.isStoreValueAvailable)
      return this.sensei.sensei_currency_account.payoutable_balance;
    return (
      this.sensei.sensei_currency_account.payoutable_balance *
      this.sensei.sensei_currency_account.currency_conversion_rate
    );
  }

  returnPaypalAccount() {
    return this.sensei.paypal_handle || "CLICK HERE TO ADD";
  }

  // internal methods
  errorHandler(err) {
    this.disableButton = false;
    this.disableConfirmButton = false;
    this.omnibarPointer.pushErrorByHash(err.data.errors);
  }
}

SenseiWalletCashoutController.$inject = [
  "$rootScope",
  "$state",
  "User",
  "CurrencyPayout",
  "TransactionHistories",
  "OmnibarPointer",
  "UIFeatureGate",
  "Account",
];
