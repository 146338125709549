import senseiReviewModalTemplate from "../../../templates/directives/popup/modal/sensei_review_modal_template";

export default function senseiReviewModalDirective(
  $cookies,
  SenseiReviewService,
  OmnibarPointer
) {
  return {
    restrict: "E",
    scope: {
      modal: "=",
      lesson: "=",
      reviewParams: "=?",
    },
    template: senseiReviewModalTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    var starDescriptions = ["Terrible", "Not Good", "Okay", "Great", "Amazing"];
    scope.modal = scope.modal || {};
    scope.starDescription = "";
    scope.reviewParams = scope.reviewParams || { stars: -1, description: "" };

    scope.submitReview = function () {
      SenseiReviewService.create(getReviewParams())
        .then((res) => {
          scope.dismiss();
          var omniTooltipParams = {
            title: "Your review has been submitted. Thanks!",
            body: "",
          };
          scope.lesson.has_reviewed = true;
          OmnibarPointer.pushTooltip(omniTooltipParams, { timer: true });
        })
        .catch(angular.noop);
    };

    scope.dismiss = function () {
      $cookies.put("senseiReviewDismiss", scope.lesson.id);
      scope.reviewParams = { stars: -1, description: "" };
      scope.modal.openedModal = false;
    };

    scope.changeRating = function ($event) {
      scope.reviewParams.stars = $event.rating;
      scope.starDescription = starDescriptions[$event.rating - 1];
    };

    function getReviewParams() {
      var returnParams = {
        summary: description(scope.reviewParams.description),
        body: description(scope.reviewParams.description),
        star_rating: scope.reviewParams.stars,
        status: "pending",
        reviewee_id: scope.lesson.senseiId || scope.lesson.sensei_id,
      };

      return returnParams;

      function description(text) {
        if (text.trim().length) {
          return text;
        } else {
          return null;
        }
      }
    }
  }
}
senseiReviewModalDirective.$inject = [
  "$cookies",
  "SenseiReviewService",
  "OmnibarPointer",
];
