import { title as privacyTitle } from "../../config/legal/privacy-policy-text";
import senseiSignUpBg from "../../images/backgrounds/35221435325_2890dc036c_o.jpg";
import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";
import {
  communityIcon,
  gearIcon,
  happyIcon,
} from "../../config/icons/iconConfig";

export default class SenseiSignUpPageController {
  constructor(
    $scope,
    $anchorScroll,
    $rootScope,
    $window,
    AccessControl,
    LazyLoad,
    $timeout,
    OmnibarPointer,
    UIFeatureGate,
    MetaTagsService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "sensei_sign_up";
    this.gigya = $scope.isGigyaOn;
    // angularjs providers
    this.$window = $window;
    this.$timeout = $timeout;
    // services
    this.access = AccessControl;
    this.eventTracking = EventTrackingManager;
    this.omnibarPointer = OmnibarPointer;
    // imported and injected
    this.privacyTitle = privacyTitle;
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;
    this.senseiSignUpBg = senseiSignUpBg;
    this.icons = {
      communityIcon,
      gearIcon,
      happyIcon,
    };
    // booleans
    this.formSubmit = false;
    // objects and arrays
    this.registerParams = {
      agreeTerms: false,
    };
    this.senseiHero = {
      header: '<h1 class="text-center text-light">Become a Sensei</h1>',
      body: '<p class="text-center text-prominent">Make money doing what you love. Discover the satisfaction of sharing your skills and help students achieve their goals.</p>',
      position: "center",
    };
    this.senseiFAQ = [
      {
        question:
          "I already have a job, can I just do a few hours in my spare time?",
        answer: `<p>Totally. You pick your schedule. You can make yourself available for as little or as much time as you want.</p>`,
      },
      {
        question: "What information do you need from me to apply?",
        answer: `<p>When you apply, we have you provide gaming details, account names, and your social media information. We use this information to evaluate you as a teacher and help match you with future students.</p>`,
      },
      {
        question:
          "I currently coach informally, can I schedule these lessons with Gamer Sensei?",
        answer: `<p>Absolutely. We give you a direct booking page so any students you currently have can schedule lessons with you on Gamer sensei. We also provide you promotional codes to reward you for referring your students and give you assets to help you market your coaching services independently.</p>`,
      },
    ];

    // feature gates
    this.signupRevampCheck = UIFeatureGate.data.ui_feature_gates.signup_revamp;

    this.initMetaTags(MetaTagsService);

    $timeout(() => this.initPage(LazyLoad, $anchorScroll), 0);
  }

  // init methods
  initPage(LazyLoad, $anchorScroll) {
    LazyLoad.set();
    $anchorScroll("scroll");
  }
  initMetaTags(MetaTagsService) {
    const metaTitle = "Become a Sensei With Gamer Sensei";
    const metaDescription =
      "Become a coach on Gamer Sensei, the world's premier esports coaching platform.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  // actions
  connectEvent(event, name) {
    if (event) event.preventDefault();

    const href = event.target.href;
    this.eventTrackingManager.pushEventOnly(name);
    this.$window.location.href = href;
  }
}

SenseiSignUpPageController.$inject = [
  "$scope",
  "$anchorScroll",
  "$rootScope",
  "$window",
  "AccessControl",
  "LazyLoad",
  "$timeout",
  "OmnibarPointer",
  "UIFeatureGate",
  "MetaTagsService",
  "EventTrackingManager",
];
