import {
  testCommunication,
  testPhoneNumber,
} from "../../../../config/utils/utilsConfig";
import { infoIcon } from "../../../../config/icons/iconConfig";

export default class GamerAccountNotificationsController {
  constructor(
    $scope,
    $timeout,
    User,
    OmnibarPointer,
    GamerNotificationPreferenceApi,
    EventTrackingManager
  ) {
    this.userId = $scope.currentId;
    // angular providers
    this.timeout = $timeout;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.gamerNotificationPreference = GamerNotificationPreferenceApi;
    // injected and imported
    this.infoIcon = infoIcon;
    this.notifications = {};
    this.notificationId;

    this.saveFlagTime = 2000;

    this.isSchedulingSaved = false;
    this.isChatSaved = false;
    this.isOtherSaved = false;
    this.isContactSaved = false;

    this.initUser(User);
  }

  // init methods
  initUser(User) {
    User.setCurrentId(this.userId);
    User.setRole("gamer");
    User.getUser(true)
      .then((userData) => {
        this.currentUser = userData;

        this.notificationId = this.currentUser.gamer_notification_preference_id;
        this.gamerNotificationPreference
          .show(this.notificationId)
          .then((res) => {
            this.populateNotifications(res.data.gamer_notification_preference);
          });
      })
      .catch(angular.noop);
  }

  // action methods
  updateNotification(notification, context) {
    if (!notification.disable) {
      const params = { gamer_notification_preference: {} };
      params["gamer_notification_preference"][notification.key] =
        notification.toggle;
      this.gamerNotificationPreference
        .update(this.notificationId, params)
        .then((res) => {
          this.populateNotifications(res.data.gamer_notification_preference);
          this.buildNotificationToPush(notification);

          if (context === "scheduling") {
            this.triggerSchedulingFlag();
          } else if (context === "other") {
            this.triggerOtherFlag();
          } else if (context === "contact") {
            this.triggerContactFlag();
          } else if (context === "chat") {
            this.triggerChatFlag();
          }
        })
        .catch((err) => {
          this.omnibarPointer.pushErrorByHash(err.data.errors);
        });
    }
  }

  // display methods
  populateNotifications(notifications) {
    this.notifications.scheduling = [
      {
        name: "Lesson(s) requested",
        key: "notify_lesson_requested",
        toggle: notifications.notify_lesson_requested,
        disable: false,
      },
      {
        name: "1-hour lesson reminder",
        key: "notify_one_hour_lesson_reminder",
        toggle: notifications.notify_one_hour_lesson_reminder,
        disable: false,
      },
      {
        name: "Lesson reschedule request",
        key: "notify_lesson_reshedule_request",
        toggle: notifications.notify_lesson_reshedule_request,
        disable: false,
      },
      {
        name: "Lesson confirmation",
        key: "notify_lesson_confirmation",
        toggle: notifications.notify_lesson_confirmation,
        disable: false,
      },
      {
        name: "Lesson completed",
        key: "notify_lesson_completed",
        toggle: notifications.notify_lesson_completed,
        disable: false,
      },
      {
        name: "Lesson rejection",
        key: "notify_lesson_rejection",
        toggle: notifications.notify_lesson_rejection,
        disable: false,
      },
    ];

    this.notifications.chat = [
      {
        name: "New Messages",
        key: "notify_new_message",
        toggle: notifications.notify_new_message,
        disable: false,
      },
    ];

    this.notifications.other = [
      {
        name: "Newsletter/Promotions",
        key: "notify_promotion",
        toggle: notifications.notify_promotion,
        disable: false,
      },
    ];

    this.notifications.contactMethods = [
      {
        name: "Email",
        key: "contact_via_email",
        activated: true,
        toggle: true,
        disable: false,
        default: this.currentUser.email,
      },
      {
        name: "Discord",
        key: "contact_via_discord",
        activated: testCommunication(
          "discord",
          this.currentUser.communication_services
        ),
        toggle: notifications.contact_via_discord,
        disable: false,
      },
      {
        name: "SMS",
        key: "contact_via_sms",
        activated: testPhoneNumber(this.currentUser.phone_number),
        toggle: notifications.contact_via_sms,
        disable: false,
      },
    ];
  }
  triggerSchedulingFlag() {
    this.isSchedulingSaved = true;
    this.timeout(() => {
      this.isSchedulingSaved = false;
    }, this.saveFlagTime);
  }

  triggerChatFlag() {
    this.isChatSaved = true;
    this.timeout(() => {
      this.isChatSaved = false;
    }, this.saveFlagTime);
  }

  triggerOtherFlag() {
    this.isOtherSaved = true;
    this.timeout(() => {
      this.isOtherSaved = false;
    }, this.saveFlagTime);
  }

  triggerContactFlag() {
    this.isContactSaved = true;
    this.timeout(() => {
      this.isContactSaved = false;
    }, this.saveFlagTime);
  }

  // internal methods
  buildNotificationToPush(notification) {
    var event = null;
    switch (notification.key) {
      case "notify_promotion":
        event = {
          name: notification.toggle
            ? "newsletterSignup"
            : "newsletterUnsubscribe",
          data: {
            customerEmail: this.currentUser.email,
            marketingConsent: notification.toggle ? true : false,
            emailCaptureLocation: "dashboard-notifications-settings",
          },
        };
        break;
      case "contact_via_sms":
        event = {
          name: notification.toggle ? "smsSignup" : "smsUnsubscribe",
          data: {
            phoneNumber: this.currentUser.phone_number,
            smsConsent: notification.toggle ? true : false,
            country: this.currentUser.country_code,
            smsCaptureLocation: "dashboard-notifications-settings",
          },
        };
        break;
      default:
        break;
    }
    if (event) {
      this.eventTracking.pushNotifyChanges(event.name, event.data);
    }
  }
}

GamerAccountNotificationsController.$inject = [
  "$scope",
  "$timeout",
  "User",
  "OmnibarPointer",
  "GamerNotificationPreferenceApi",
  "EventTrackingManager",
];
