import coachProductProfileApp from "./gs-sensei-public-profile";
import reusableApp from "./gs-reusable";
import responsiveTemplateApp from "./gs-responsive-template";
import { uiFeatureGate, getGames } from "../config/utils/utilsConfig";

const adaptiveSearchApp = angular.module("gamerSenseiAdaptiveSenseisSearch", [
  coachProductProfileApp.name,
  responsiveTemplateApp.name,
  reusableApp.name,
  "ngResource",
  "ngCookies",
  "ui.router",
  "ui.router.state.events",
  "ncy-angular-breadcrumb",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "star-rating",
  "ngMaterial",
  "ngAnimate",
  "ngMessages",
]);

import SenseiProductPageController from "../controllers/senseis/sensei-product-page-controller";
import SenseiProductPageTemplate from "../templates/senseis/sensei_product_page_container_template";

adaptiveSearchApp.config(configAdaptiveSenseiSearch);

function configAdaptiveSenseiSearch(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
      ecommerce: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("adaptive_sensei_search", {
      url: "/senseis/searches?game",
      controller: ($window, $stateParams) => {
        let redirectURL = "/games";

        if ($stateParams?.game) {
          redirectURL = `/games/${$stateParams.game}`;
        }

        $window.location.href = redirectURL;
      },
    })
    .state("senseis", {
      url: "/senseis/:id",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        activeGames: getGames,
      },
      controller: SenseiProductPageController,
      controllerAs: "vm",
      template: SenseiProductPageTemplate,
      ncyBreadcrumb: {
        label: "{{vm.sensei.combinedName}}",
        parent: "adaptive_sensei_search({game: vm.senseiGameSlug})",
      },
    });
}
configAdaptiveSenseiSearch.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

export default adaptiveSearchApp;
