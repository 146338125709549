import reusableApp from "./gs-reusable";
import accessApp from "./gs-access";
import responsiveTemplateApp from "./gs-responsive-template";
import {
  uiFeatureGate,
  getGames,
  getVisitorGeoLocale,
} from "../config/utils/utilsConfig";

const giftCardLandingApp = angular.module("gamerSenseiGiftCardLandingPages", [
  "ngResource",
  "ngRoute",
  "ui.router.compat",
  reusableApp.name,
  accessApp.name,
  responsiveTemplateApp.name,
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
]);

// Directive setup
import slickDirective from "../directives/data/slick-directive";
giftCardLandingApp
  .constant("slickCarouselConfig", {
    method: {},
    event: {},
  })
  .directive("slick", slickDirective);

// routing setup
import GiftCardLandingContainerController from "../controllers/promotion/gift-landing/gift-card-landing-container-controller";
import GiftCardLandingContainerTemplate from "../templates/promotion/gift_landing/gift_card_landing_container_template";
import CorsairPartnerGiftCardPageController from "../controllers/promotion/gift-landing/corsair-partner-gift-card-page-controller";
import CorsairPartnerGiftCardPageTemplate from "../templates/promotion/gift_landing/corsair_landing_page_template";

giftCardLandingApp.config(configCorsairMarketingPages);

function configCorsairMarketingPages(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("gift_card_landing", {
      abstract: true,
      controller: GiftCardLandingContainerController,
      controllerAs: "vm",
      template: GiftCardLandingContainerTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("gift_card_landing.corsair", {
      url: "/corsair",
      controller: CorsairPartnerGiftCardPageController,
      controllerAs: "vm",
      template: CorsairPartnerGiftCardPageTemplate,
      resolve: {
        pulledGames: getGames,
        UIFeatureGate: uiFeatureGate,
        VisitorGeoLocale: getVisitorGeoLocale,
      },
    });
}

configCorsairMarketingPages.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

export default giftCardLandingApp;
