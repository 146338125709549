export default function tokenRedemptionService($http) {
  var manager = {
    getTokenOptions: getTokenOptions,
    redeemToken: redeemToken,
  };

  function getTokenOptions(data) {
    return $http.get("/api/web/retailer_token_redemptions/token_options", {
      params: data,
    });
  }

  function redeemToken(data) {
    return $http.post("/api/web/retailer_token_redemptions", data);
  }

  return manager;
}

tokenRedemptionService.$inject = ["$http"];
