export default class GamerWalletRedeemSessionController {
  constructor($state, OmnibarPointer, TokenRedemptionService) {
    // angular providers
    this.$state = $state;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.tokenRedemption = TokenRedemptionService;
    // booleans
    this.submitting = false;
    this.selectGameMenuExpanded = false;
    // to be set
    this.selectedTokenOption = null;
  }

  // action methods
  expandSelectGameMenu() {
    this.selectGameMenuExpanded = !this.selectGameMenuExpanded;
  }

  selectOption(option) {
    this.selectedTokenOption = option;
    this.selectGameMenuExpanded = false;
  }

  redeemTokenOrGetOptions() {
    if (this.selectedTokenOption) {
      this.redeemSessionToken();
    } else {
      this.getTokenOptions();
    }
  }

  redeemSessionToken() {
    this.submitting = true;

    this.sha1TimeStamp().then((msg) => {
      const sessionTokenParams = {
        retailer_token_redemption: {
          token_redemption_code: this.sessionToken,
          redemption_stamp: msg,
          token_template_id: (this.selectedTokenOption || {}).id,
        },
      };

      this.tokenRedemption
        .redeemToken(sessionTokenParams)
        .then((res) => {
          this.submitting = false;
          this.$state.go(
            "gamer_dashboard.wallet.summary",
            {},
            { reload: true }
          );
        })
        .catch((err) => {
          this.submitting = false;
          this.omnibarPointer.pushErrorByHash(err.data.errors);
        });
    });
  }

  // fetch methods
  getTokenOptions() {
    this.submitting = true;

    const sessionTokenParams = {
      token_redemption_code: this.sessionToken,
    };

    this.tokenRedemption
      .getTokenOptions(sessionTokenParams)
      .then((res) => {
        this.submitting = false;

        if (res.status == 200) {
          this.flexiTokenSelectOptions = res.data.flexi_token_options;
        } else if (res.status == 204) {
          this.redeemSessionToken();
        }
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  // internal methods
  sha1TimeStamp() {
    const msgUint8 = new TextEncoder().encode(Date.now());

    return new Promise(function (resolve) {
      crypto.subtle.digest("SHA-1", msgUint8).then(function (hashBuffer) {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");

        resolve(hashHex);
      });
    });
  }
}

GamerWalletRedeemSessionController.$inject = [
  "$state",
  "OmnibarPointer",
  "TokenRedemptionService",
];
