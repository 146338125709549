export default function buildSenseiProfile(user) {
  const createdSensei = {
    id: user.id,
    senseiId: user.sensei.id,
    senseiCoachId: user.sensei.games[0].sensei_coach_id,
    fullName: user.full_name,
    screenName: user.screen_name,
    coachingStartDate: user.sensei.start_date,
    sessionsCompleted: user.sensei.lessons_completed,
    description: user.sensei.description,
    hourlyRate: user.sensei.games[0].hourly_rate_in_currency,
    ratingAverage: user.sensei.composite_review_star_rating || 5,
    image: user.profile_photo_url_square,
    languages: user.sensei.languages,
    totalReviews: user.sensei.review_count,
    rank: user.sensei.games[0].rank,
    rankBadgeUrl: user.sensei.games[0].rank_badge_url,
    slug: user.slug,
    communicationServices: user.communication_services,
    thirdPartyLabel: user.sensei.games[0].third_party_profile_source_label,
    thirdPartyLink: user.sensei.games[0].third_party_profile_link,
    tokenTierName:
      user.sensei.token_tier_name ||
      user.sensei.token_templates[0].token_tier_data.name,
  };

  //set achievements
  createdSensei.achievements = user.sensei.games[0].coaching_achievements.map(
    function (value) {
      return value.achievement;
    }
  );

  //build sensei's game
  const senseiGame = user.sensei.games[0];
  createdSensei.game = {
    slug: senseiGame.slug,
    name: senseiGame.name,
    id: senseiGame.id,
  };

  return createdSensei;
}
