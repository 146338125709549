import faqAccTemplate from "../../templates/directives/data/faq_accordion_template";

export default function faqAccordionService() {
  return {
    restrict: "E",
    scope: {
      faq: "=",
    },
    link: link,
    template: faqAccTemplate,
  };

  function link(scope, elem, attr) {
    scope.openIndex = -1;

    scope.clickQuestion = function (index) {
      if (scope.openIndex === index) {
        scope.openIndex = -1;
      } else {
        scope.openIndex = index;
      }
    };
  }
}
