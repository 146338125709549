import moment from "moment-timezone";
import { buildSenseiProfile } from "../../config/utils/utilsConfig";
import senseiDesktopTemplate from "../../templates/senseis/sensei_product_page_desktop_template";
import senseiMobileTemplate from "../../templates/senseis/sensei_product_page_mobile_template";
import senseiProductTabsPartial from "../../templates/includes/senseis/sensei_product_tabs_partial";
import {
  calendarIcon,
  checkIcon,
  checkListIcon,
  discordIcon,
  infoIcon,
  skypeIcon,
  starIcon,
  subscribeIcon,
  trophyIcon,
  verifiedIcon,
} from "../../config/icons/iconConfig";

export default class SenseiProductPageController {
  constructor(
    $scope,
    $rootScope,
    $stateParams,
    $interval,
    User,
    LazyLoad,
    activeGames,
    OmnibarPointer,
    TokenService,
    TokenSubscriptionService,
    SenseiBenefitListService,
    UserSubscriptionsService,
    CartManager,
    Availability,
    MetaTagsService,
    EventTrackingManager,
    UIFeatureGate
  ) {
    // scope/internal
    $rootScope.bodylayout = "sensei";
    $scope.isSenseiProductPage = true;
    this.userId = $scope.currentId;
    this.role = $scope.role;
    this.timezones = $scope.timezones;
    this.root = $rootScope;
    this.interval = $interval;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.userService = User;
    this.cart = CartManager;
    this.tokenManager = TokenService;
    this.tokenSubs = TokenSubscriptionService;
    this.senseiSubs = SenseiBenefitListService;
    this.userSubs = UserSubscriptionsService;
    // injected and imported
    this.activeGames = activeGames.data.games;
    this.senseiDesktopTemplate = senseiDesktopTemplate;
    this.senseiMobileTemplate = senseiMobileTemplate;
    this.senseiProductTabsPartial = senseiProductTabsPartial;
    this.icons = {
      calendarIcon,
      checkIcon,
      checkListIcon,
      discordIcon,
      infoIcon,
      skypeIcon,
      starIcon,
      subscribeIcon,
      trophyIcon,
      verifiedIcon,
    };
    // strings
    this.selectedSessionGroup = "single";
    // booleans
    this.displayTokenTierBundles = false;
    this.readmore = false;
    this.senseiModalOpened = false;
    this.achievementModalOpened = false;
    this.shutDownModal = false;
    // time
    this.tz = moment.tz.guess();
    this.tzAbbr = moment.tz(this.tz).zoneAbbr();
    this.gamingAttributes = this.populateGamingAttributes();
    // arrays
    this.currentTierBundles = [];
    //build sensei
    this.senseiData = $scope.user;
    this.sensei = buildSenseiProfile($scope.user);
    this.tokenParams = {
      game: this.sensei.game.slug,
      senseiId: this.sensei.senseiId,
    };
    this.senseiGameName = this.sensei.game.name;
    this.senseiGameSlug = this.sensei.game.slug;

    this.subscriptionsOn =
      UIFeatureGate.data.ui_feature_gates.gs_version_3_subscriptions;
    this.paymentsOff =
      UIFeatureGate.data.ui_feature_gates.final_balance_protection_measures_activated;

    this.initUser();
    this.initTimezone();

    this.initMetaTags(MetaTagsService);
    this.initAvailability($stateParams, Availability);
    this.initTokens({
      setBundles: this.initBundles(),
      setSubscriptions: this.initSubscriptions(),
    });

    angular.element(() => this.initSenseiProductPage(LazyLoad));
  }

  // init methods
  initTokens(callbacks) {
    this.tokenManager
      .setTokens(this.tokenParams)
      .then((res) => {
        this.senseiTokens = res;
        this.selectedSenseiProduct = Object.values(this.senseiTokens)[0];

        if (callbacks?.setBundles) callbacks.setBundles();
        if (callbacks?.setSubscriptions) callbacks.setSubscriptions();
      })
      .catch(angular.noop);
  }
  initBundles() {
    this.tokenManager.setTokenBundles(this.tokenParams);

    const watchBundles = this.root.$watch(
      () => this.tokenManager.tokenBundles,
      (newVal) => {
        if (newVal && Object.keys(newVal).length) {
          angular.forEach(newVal, (bundle, key) => {
            if (this.doesBundleMatch(bundle)) {
              this.currentTierBundles.push(bundle);
              if (!this.displayTokenTierBundles)
                this.displayTokenTierBundles = true;
            }
          });

          watchBundles();
        }
      }
    );
  }

  initSubscriptions() {
    if (this.subscriptionsOn) {
      this.senseiSubs.setSubscriptionItems(this.sensei.senseiId);

      const checkTokensAndBundles = this.interval(() => {
        const tokensLoaded = this.sensei.tokenTierName?.includes("Legendary")
          ? Object.keys(this.senseiTokens).length
          : Object.keys(this.senseiTokens).length &&
            this.currentTierBundles.length;

        if (tokensLoaded) {
          this.tokenSubs
            .setSubscriptionList()
            .then((allSubs) => {
              angular.forEach(this.senseiTokens, (obj, key) => {
                const subs = Object.values(allSubs)
                  .filter((sub) => sub.token_template.id === obj.id)
                  .map((item) => {
                    item.subscribed = false;
                    return item;
                  });

                if (subs) {
                  obj.subscription = subs;
                  obj.subscribed = false;
                }
              });

              angular.forEach(this.currentTierBundles, (arr, index) => {
                const sub = allSubs[`bundle-${arr.id}`];

                if (sub) {
                  arr.subscription = [...arr.subscription, sub];
                  arr.subscribed = true;
                }
              });
            })
            .catch(angular.noop);

          this.interval.cancel(checkTokensAndBundles);
        }
      }, 500);
    }
  }
  initAvailability($stateParams, Availability) {
    Availability.getSenseiGeneralAvailabilities({
      params: { sensei_slug: $stateParams.id, time_zone: this.tz },
    })
      .then((res) => {
        this.sensei.generalAvailabilities = res.data.specific_availabilities;
        const findAvails = Object.values(
          res.data.specific_availabilities
        ).filter(function (arr, id) {
          return arr[0] !== "Not Available";
        });

        this.areThereAnyAvailabilities = findAvails.length;
      })
      .catch(angular.noop);
  }
  initUser() {
    if (this.userId !== false) {
      this.userService
        .getUserByIdAndRole(this.userId, this.role)
        .then((userData) => {
          this.currentUser = userData;
          this.userSubs
            .setSubscriptions("gamer", userData.gamer.id)
            .then((res) => {
              this.completedGrab = true;
            })
            .catch((err) => {
              this.completedGrab = true;
            });
        })
        .catch(angular.noop);
    }
  }
  initMetaTags(MetaTagsService) {
    const metaTitle = `${this.senseiData.sensei.games[0].name} Coaching and Lessons from ${this.senseiData.screen_name} | Gamer Sensei`;
    const metaDescription = `Receive professional training from ${this.senseiData.screen_name} on Gamer Sensei to improve your skills in ${this.senseiData.sensei.games[0].name}.`;

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initSenseiProductPage(LazyLoad) {
    LazyLoad.set();
    this.eventTracking.pushViewEvent("Coach Page");
    this.eventTracking.pushEventObject({
      event: "coachPageDetails",
      coachName: this.sensei.fullName,
      coachImage: this.sensei.image,
      coachRating: this.sensei.ratingAverage,
      coachDescription: this.sensei.description,
      gameName: this.sensei.game.name,
    });
  }
  initTimezone() {
    for (let i = 0; i < this.timezones.length; i++) {
      if (this.timezones[i][1] === this.tz) {
        this.tzName = this.timezones[i][0];
        break;
      }
    }
  }

  // sensei setup methods
  isSensei() {
    return this.role === "sensei";
  }
  doAvailabilitiesExist() {
    return (
      this.sensei &&
      this.sensei.generalAvailabilities &&
      this.areThereAnyAvailabilities
    );
  }

  setSenseiProductToSelected(token) {
    this.selectedSenseiProduct = token;
  }

  isSenseiProductSelected(token, type) {
    return token.id === this.selectedSenseiProduct?.id;
  }

  getBuyButtonText() {
    const buttonType = this.selectedSenseiProduct?.subscribed
      ? "Subscribe To"
      : "Buy";

    let buttonText = buttonType;

    if (this.selectedSessionGroup === "single") {
      buttonText = `${buttonType} ${this.selectedSenseiProduct?.display_name}`;
    } else if (this.selectedSessionGroup === "bundle") {
      buttonText = `${buttonType} ${this.selectedSenseiProduct.name}`;
    }

    return buttonText;
  }

  // subscription methods
  isUserSubscribed() {
    const subs = this.userSubs.getSubscriptions();
    const senseiPlan = this.senseiSubs.getSenseiSubscriptionPlans();

    const userSubbed = subs.filter(function (sub) {
      return sub.subscription_plan_name === senseiPlan.plan_name;
    });

    return userSubbed.length;
  }
  isSubscriptionAvail() {
    return (
      this.subscriptionsOn && this.selectedSenseiProduct?.subscription?.length
    );
  }
  isThereAGeneralSenseiPlan() {
    const plan = Object.keys(
      this.senseiSubs.getSenseiSubscriptionPlans()
    ).length;

    return this.subscriptionsOn && plan;
  }
  // toggle sub
  applyProductSubscription() {
    if (this.selectedSenseiProduct.subscribed) {
      this.selectedSenseiProduct.subscribed = false;
      this.selectedSenseiProduct.subscription.map((item) => {
        if (item.subscribed) item.subscribed = false;

        return item;
      });
    } else if (this.selectedSenseiProduct?.subscription?.length > 1) {
      this.openSubListModal();
    } else {
      this.selectedSenseiProduct.subscribed = true;
      this.selectedSenseiProduct.subscription[0].subscribed = true;
    }
  }
  applySubscriptionFromList(selected) {}
  isSubscribeButtonDisabled() {
    const cart = Object.values(this.cart.getCartItems());

    if (cart.length === 0 && this.disableSubscribeButton) {
      this.disableSubscribeButton = false;
    }

    if (this.isUserSubscribed()) {
      this.disableSubscribeButton = true;
    } else {
      cart.filter(function (item, index) {
        if (
          this.sensei.senseiId === item.sensei_id &&
          item.plan_name &&
          !this.disableSubscribeButton
        ) {
          this.disableSubscribeButton = true;
        } else if (
          item.plan_name &&
          this.sensei.senseiId !== item.sensei_id &&
          this.displayTokenTierBundles
        ) {
          this.disableSubscribeButton = false;
        }
      });
    }

    return this.disableSubscribeButton;
  }

  // action methods
  toggleReadMore() {
    this.readmore = !this.readmore;
  }
  openSenseiModal() {
    this.senseiModalOpened = true;
  }
  closeSenseiModal() {
    this.senseiModalOpened = false;
  }
  openAchievementModal() {
    this.achievementModalOpened = true;
  }
  closeAchievementModal() {
    this.achievementModalOpened = false;
  }
  openSubListModal() {
    this.subListModalOpened = true;
  }
  closeSubListModal() {
    this.subListModalOpened = false;
  }
  addCartItem(subscription) {
    if (this.paymentsOff) {
      this.shutDownModal = true;
      return;
    }
    if (subscription) {
      this.selectedSenseiProduct.subscription.map((item) => {
        if (item.id === subscription.id) {
          item.subscribed = true;
        } else {
          item.subscribed = false;
        }

        return item;
      });
      this.selectedSenseiProduct.subscribed = true;
    }

    const itemWithSensei = {
      ...this.selectedSenseiProduct,
      sensei: this.sensei,
    };

    this.cart.addCartItem(itemWithSensei, this.selectedSessionGroup);
    if (this.subListModalOpened) this.closeSubListModal();
    if (this.senseiModalOpened) this.closeSenseiModal();
  }

  addSubToCart() {
    if (!this.isSubscribeButtonDisabled()) {
      this.disableSubscribeButton = true;
      this.cart.addCartItem(
        this.senseiSubs.getSenseiSubscriptionPlans(),
        "plan"
      );
    }
  }

  // display methods
  isBuyButtonVisible() {
    return (
      this.selectedSenseiProduct && this.selectedSenseiProduct.base_student_cost
    );
  }

  selectSessionGroup(group) {
    this.selectedSessionGroup = group;
    let filteredObjOnChange;

    if (group === "bundle") {
      filteredObjOnChange = Object.values(this.currentTierBundles).filter(
        (obj, key) => {
          return key === 0;
        }
      );
    } else {
      filteredObjOnChange = Object.values(this.senseiTokens);
    }

    this.selectedSenseiProduct = filteredObjOnChange[0];
  }

  getCost(type) {
    const newToken = this.selectedSenseiProduct.subscribed
      ? this.selectedSenseiProduct.subscription
      : this.selectedSenseiProduct;

    if (type === "base") {
      return newToken.base_student_cost;
    } else if (type === "advertised") {
      return newToken.advertised_student_cost;
    }
  }

  getSubSavingsPercentage(item) {
    if (!item.subscription) return "";

    const maxItem = item.subscription.reduce((prev, current) => {
      return prev &&
        this.getPercentageForSavings(item, prev) >
          this.getPercentageForSavings(item, current)
        ? prev
        : current;
    });

    const percent = this.getPercentageForSavings(item, maxItem);
    const recurs = maxItem.recurring_unit;

    return `${recurs}ly and save ${Math.ceil(percent)}%`;
  }

  getPercentageForSavings(item, subItem) {
    return (
      ((item.base_student_cost -
        subItem.base_student_cost / subItem.recurring_cycle_number) /
        item.base_student_cost) *
      100
    );
  }

  isThisANewProduct(token) {
    const createDate = moment(token.created_at);
    const today = moment();

    const diff = today.diff(createDate, "months");

    return diff < 1;
  }

  isThereAPriceReduction(token) {
    const newToken = token?.subscribed ? token.subscription : token;

    return (
      newToken?.advertised_student_cost &&
      newToken?.advertised_student_cost > newToken?.base_student_cost
    );
  }

  getYearsCoaching() {
    const begin = moment(this.sensei.coachingStartDate, "YYYY-MM-DD");

    return moment().diff(begin, "years");
  }

  generalAvailabilityTimesCheck(dayTimes) {
    if (dayTimes.length === 1 && dayTimes[0] === "12:00a - 12:00a") {
      return "Anytime";
    } else if (dayTimes.length === 0) {
      return "Not Available";
    }

    return dayTimes.join(",<br>");
  }

  buildDateLabel(date) {
    return moment(date, "YYYY-MM-DD").format("ddd M/D");
  }

  getTierName() {
    const tier = this.sensei.tokenTierName;

    if (tier?.toLowerCase().includes("legendary")) {
      return "Legendary";
    }

    return tier;
  }

  tierToLowerCase() {
    const tier = this.sensei.tokenTierName;

    return tier?.toLowerCase();
  }

  doesBundleMatch(bundle) {
    const tier = this.getTierName();
    let bundleType = false;

    if (
      tier === "Legendary" &&
      bundle.token_tier.toLowerCase().includes("legendary")
    ) {
      bundleType = true;
    } else if (bundle.token_tier === tier) {
      bundleType = true;
    }

    return bundleType;
  }

  // still needed?
  populateGamingAttributes() {
    let returnAttributes = [];
    const validGamingAttributes = ["Lane"];
    const attributeSelections = this.sensei?.gaming_attribute_selections;

    for (let i in attributeSelections) {
      if (validGamingAttributes.indexOf(i) > -1) {
        returnAttributes = returnAttributes.concat(attributeSelections[i]);
      }
    }

    return returnAttributes;
  }
}

SenseiProductPageController.$inject = [
  "$scope",
  "$rootScope",
  "$stateParams",
  "$interval",
  "User",
  "LazyLoad",
  "activeGames",
  "OmnibarPointer",
  "TokenService",
  "TokenSubscriptionService",
  "SenseiBenefitListService",
  "UserSubscriptionsService",
  "CartManager",
  "Availability",
  "MetaTagsService",
  "EventTrackingManager",
  "UIFeatureGate",
];
