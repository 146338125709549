export default function lessonExtensionService($http) {

  var service = {
    requestExtension: requestExtension,
    acceptExtension: acceptExtension,
    rejectExtension: rejectExtension,
    getExtensions: getExtensions
  };

  return service;

  ///////////

  function requestExtension(data) {
    return $http.post('/api/web/lesson_extension_requests', data);
  }

  function acceptExtension(id,data) {
    return $http.put('/api/web/lesson_extension_requests/'+id+'/accept', data);
  }

  function rejectExtension(id) {
    return $http.put('/api/web/lesson_extension_requests/'+id+'/reject');
  }

  function getExtensions(data) {
    return $http.get('/api/web/lesson_extension_requests/', data);
  }

}

lessonExtensionService.$inject = ['$http'];