import { title as termsTitle } from "../../config/legal/terms-of-service-text";
import { title as privacyTitle } from "../../config/legal/privacy-policy-text";
import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class ReferralLandingPageController {
  constructor(
    $window,
    $scope,
    $timeout,
    $filter,
    LazyLoad,
    pulledGames,
    OmnibarPointer,
    AccessControl,
    ReferralPolicy,
    MetaTagsService,
    EventTrackingManager
  ) {
    this.navBar = navBar;
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.termsTitle = termsTitle;
    this.privacyTitle = privacyTitle;

    if ($scope.referral_policy_id) {
      loadNewGamerBenefits($scope.referral_policy_id);
    }

    var metaTitle = "Sign up to Take Lessons With Gamer Sensei";
    var metaDescription =
      "Sign up with Gamer Sensei, the world's premier esports coaching platform servicing your personal journey in competitive gaming.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });

    this.verifiableType = "ReferralAttempt";
    this.verifiableId = $scope.referral_attempt_id;
    this.referrerScreenName = $scope.referrer_screen_name;
    this.ourTitles = pulledGames.data.games;
    this.OmnibarPointer = OmnibarPointer;
    this.partnerSignup = {
      year: 2017,
      month: 1,
      day: 1,
    };
    if (this.ourTitles[0]) {
      this.partnerSignup.game = this.ourTitles[0].id;
    }
    this.passPattern = AccessControl.passPattern;
    this.years = AccessControl.getPast100Years();
    this.months = AccessControl.getMonths();
    this.getDays = (month, year) => {
      var date = new Date(year, month, 0);
      var totalDays = date.getDate();
      var daysInMonth = [];
      for (let i = 1; i <= totalDays; i++) {
        daysInMonth.push(i);
      }
      return daysInMonth;
    };
    this.disabledButton = false;
    this.openSmsModal = false;

    $scope.recaptchaResults = (token) => {
      $scope.captchaToken = token;
      $scope.completeRegistration();
    };
    $window.recaptchaResults = $scope.recaptchaResults;

    $scope.recaptchaSetupError = () => {
      $scope.noRecaptcha = true;
    };
    $window.recaptchaSetupError = $scope.recaptchaSetupError;

    this.registerGamer = ($event, form) => {
      this.disabledButton = true;
      this.OmnibarPointer.resetOmnibar();
      if (!this.agreeTerms) {
        this.OmnibarPointer.pushErrorByMessage(
          "You must agree to terms to sign up"
        );
        this.disabledButton = false;
        return;
      }
      if (
        AccessControl.areAnyRequiredCharactersMissing(
          this.partnerSignup.password
        )
      ) {
        this.OmnibarPointer.pushErrorByMessage(
          "Your password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters"
        );
        $scope.formSubmit = false;
        return;
      }
      if (
        !AccessControl.isValidBirthday({
          year: this.selectedYear,
          month: this.selectedMonth,
        })
      ) {
        $scope.$broadcast("openAgeGate", true);
        this.disabledButton = false;
        return;
      }
      if (isNotValidEmail(this.partnerSignup.email)) {
        this.OmnibarPointer.pushErrorByMessage("Invalid email format");
        this.disabledButton = false;
        return;
      }

      this.disabledButton = false;
      if ($scope.noRecaptcha || !$scope.isGigyaOn) {
        $scope.completeRegistration();
      } else {
        grecaptcha.execute();
      }
    };

    $scope.completeRegistration = () => {
      var postParams = {
        ...this.registerGamerParams(),
      };
      if ($scope.captchaToken) {
        postParams.captcha = $scope.captchaToken;
      }
      AccessControl.registerGamer(postParams)
        .then((res) => {
          var eventParams = {
            ...this.registerGamerParams().user,
            id: res.data.user.id,
            gigya_uid: res.data.user.gigya_uid,
            brand: res.data.user.brand,
            brand_domain: res.data.user.brand_domain,
          };
          EventTrackingManager.pushUserConnect(
            "accountRegistration",
            eventParams,
            "student"
          );
          this.verifyNeeded = true;
          this.newUser = res.data.user;
          this.disabledButton = false;
        })
        .catch((err) => {
          this.disabledButton = false;
          if ($scope.captchaToken) grecaptcha.reset();
          if (err.data.errorCode) {
            this.OmnibarPointer.pushErrorByMessage(
              `${err.data.errorCode}: ${err.data.errorMessage}`
            );
          } else {
            this.OmnibarPointer.pushErrorByHash(err.data.errors);
          }
        });
    };

    this.completeVerifyAndSendUserToDashboard = () => {
      if ($scope.sendVerifyEvent) {
        EventTrackingManager.pushVerifiedUser($scope.newUser, true);
      }
      $timeout(() => {
        if ($scope.referral_policy_perspective !== "sensei") {
          $window.location.href = "/games/";
        } else if ($scope.referral_policy_perspective === "sensei") {
          $window.location.href = "/senseis/" + $scope.referrer_slug;
        }
      }, 1000);
    };

    $timeout(() => {
      LazyLoad.set();

      var watchCaptcha = $scope.$watch(
        () => {
          return $window.grecaptcha;
        },
        (_newVal) => {
          if (
            $window.grecaptcha &&
            typeof $window.grecaptcha.render === "function"
          ) {
            grecaptcha.render("recaptcha-check", {
              sitekey: AccessControl.invisibleSiteKey,
              callback: recaptchaResults,
              "error-callback": recaptchaSetupError,
              size: "invisible",
              theme: "dark",
            });
            watchCaptcha();
          }
        }
      );
    }, 0);

    //
    function loadNewGamerBenefits(referralPolicyId) {
      ReferralPolicy.getById(referralPolicyId)
        .then((res) => {
          let refereePolicyRules;
          if ($scope.referral_policy_perspective === "gamer") {
            refereePolicyRules = getRewardPolicyRulesByContext(
              "referee",
              res.data.referral_policy.referral_policy_rules
            );
          } else if ($scope.referral_policy_perspective === "sensei") {
            refereePolicyRules = getRewardPolicyRulesByReward(
              500,
              res.data.referral_policy.referral_policy_rules
            );
          }

          this.newGamerRegistrationReward =
            getRegistrationReward(refereePolicyRules);
        })
        .catch(angular.noop);
    }

    function isNotValidEmail(email) {
      return email.indexOf("+") > -1;
    }
    function getRewardPolicyRulesByContext(role, referralPolicyRules) {
      return referralPolicyRules.filter((policy) => {
        return policy.context === role;
      });
    }
    function getRewardPolicyRulesByReward(reward, referralPolicyRules) {
      return referralPolicyRules.filter((policy) => {
        return policy.reward_amount === reward;
      });
    }
    function getRegistrationReward(refereeRules) {
      for (let i in refereeRules) {
        if (refereeRules[i].reward_trigger === "registration") {
          refereeRules[i].rewardAmountInCash = $filter("currency")(
            refereeRules[i].reward_amount / 100.0,
            "$",
            2
          );
          return refereeRules[i];
        }

        if (
          refereeRules[i].reward_trigger === "first-lesson-bundle-purchase" &&
          $scope.referral_policy_perspective === "sensei"
        )
          return refereeRules[i];
      }
      return null;
    }

    this.registerGamerParams = () => {
      var params = {
        user: {
          email: this.partnerSignup.email,
          password: this.partnerSignup.password,
          screen_name: this.partnerSignup.screenName,
          general_communication_opt_in: this.agreeNotifications,
          agree_to_terms: this.agreeTerms,
          age_verified: AccessControl.isValidBirthday({
            year: this.selectedYear,
            month: this.selectedMonth,
          }),
        },
        signup_attribution: {
          attributed_to: "organic",
        },
      };
      return params;
    };
  }
}

ReferralLandingPageController.$inject = [
  "$window",
  "$scope",
  "$timeout",
  "$filter",
  "LazyLoad",
  "pulledGames",
  "OmnibarPointer",
  "AccessControl",
  "ReferralPolicy",
  "MetaTagsService",
  "EventTrackingManager",
];
