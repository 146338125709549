export default function saveUserInfoReminder(
  gameInfoReminder,
  userGamerId,
  updateUser,
  callbacks
) {
  const gameId = gameInfoReminder.game_id;

  const updateParams = {
    gamer: {
      game_ids: [gameId],
      in_game_communications: [
        {
          game_id: gameId,
          in_game_id: gameInfoReminder.username.current,
        },
      ],
      game_ranks: [
        {
          game_id: gameId,
          rank: gameInfoReminder.rank.current,
        },
      ],
    },
  };

  updateUser(userGamerId, updateParams)
    .then((res) => {
      if (callbacks?.onSuccess) {
        callbacks.onSuccess();
      }
    })
    .catch((err) => {
      if (callbacks?.onError) {
        callbacks.onError(err);
      }
    });
}
