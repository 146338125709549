export default function senseiNotificationPreferenceApiService($http) {
  var service = {
    show: show,
    update: update,
  };

  return service;

  ///////////

  function show(id) {
    return $http.get("/api/web/sensei_notification_preferences/" + id);
  }

  function update(id, data) {
    return $http.put("/api/web/sensei_notification_preferences/" + id, data);
  }
}

senseiNotificationPreferenceApiService.$inject = ["$http"];
