export default function lessonRescheduleService($http) {

  var service = {
    gamerRescheduleRequest: gamerLessonRescheduleRequest,
    gamerAcceptRequest: gamerLessonAcceptRequest,
    senseiRescheduleRequest: senseiLessonRescheduleRequest,
    senseiAcceptRequest: senseiLessonAcceptRequest,
  };

  return service;

  ///////////

  function gamerLessonRescheduleRequest(data) {
    return $http.post('/api/web/gamer_recurring_lessons_reschedule_requests', data);
  }
  function gamerLessonAcceptRequest(recurring_lessons_reschedule_request_id) {
    return $http.put('/api/web/gamer_recurring_lessons_reschedule_requests/' + recurring_lessons_reschedule_request_id + '/accept');
  }
  function senseiLessonRescheduleRequest(data) {
    return $http.post('/api/web/sensei_recurring_lessons_reschedule_requests', data);
  }
  function senseiLessonAcceptRequest(recurring_lessons_reschedule_request_id) {
    return $http.put('/api/web/sensei_recurring_lessons_reschedule_requests/' + recurring_lessons_reschedule_request_id + '/accept');
  }

}

lessonRescheduleService.$inject = ['$http'];
