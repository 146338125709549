const paymentApp = angular.module("gamerSenseiPayment", [
  "ngCookies",
  "dibari.angular-ellipsis",
  "duScroll",
]);

// service setup
import CurrencyPayoutService from "../services/users/currency-cashout";

paymentApp.factory("CurrencyPayout", CurrencyPayoutService);

paymentApp.run([
  "$rootScope",
  "$state",
  "$stateParams",
  function ($rootScope, $state, $stateParams) {
    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
  },
]);

export default paymentApp;
