import navBar from "../../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../../config/brand/brandConfig";

export default class GiftCardLandingContainerController {
  constructor($scope, User, OmnibarPointer) {
    this.userId = $scope.currentId;
    this.role = $scope.role;
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;
    this.userService = User;
    this.OmnibarPointer = OmnibarPointer;
  }

  //create user
  initUser() {
    if (this.userId) {
      this.userService.setCurrentId(this.userId);
      this.userService.setRole(this.role);
      this.userService
        .getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }
}

GiftCardLandingContainerController.$inject = [
  "$scope",
  "User",
  "OmnibarPointer",
];
