import SenseiReviewsTemplate from "../../templates/directives/sensei/sensei_reviews_template";

export default function senseiReviewsDirective(SenseiReviewService) {
  return {
    restrict: "E",
    scope: {
      sensei: "=",
      totalReviews: "=?",
      senseiProductPage: "=?",
    },
    template: SenseiReviewsTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.reviewCount = {
      oneStar: 0,
      twoStar: 0,
      threeStar: 0,
      fourStar: 0,
      fiveStar: 0,
    };

    scope.senseiReviews = [];
    scope.showReviewSection = false;
    scope.showMoreReviews = false;
    scope.featuredReview = null;
    scope.histogramData = false;
    scope.hasTestimonial = false;

    //pagination
    scope.last = 1;
    scope.first = 1;
    scope.current = 1;
    scope.pages = 0;

    var featuredReviewParams = {
      params: {
        reviewee_id: scope.sensei.id,
        is_testimonial: true,
      },
    };

    SenseiReviewService.get(featuredReviewParams)
      .then((featuredResp) => {
        var featuredReview = featuredResp.data.sensei_reviews;
        if (featuredReview.length === 1) {
          var featuredParentReview = featuredResp.data.sensei_reviews[0];
          var updatedReviewsLength =
            featuredParentReview.updated_reviews.length;
          if (updatedReviewsLength > 0) {
            scope.featuredReview =
              featuredParentReview.updated_reviews[updatedReviewsLength - 1];
          } else {
            scope.featuredReview = featuredParentReview;
          }
          scope.hasTestimonial = true;
        }

        scope.setCurrent(scope.first);
      })
      .catch(angular.noop);

    scope.setCurrent = (page) => {
      if (!scope.senseiProductPage) {
        scope.senseiReviews = [];
      }

      var senseiReviewParams = {
        params: {
          reviewee_id: scope.sensei.id,
          page: page,
          status: "approved",
          is_testimonial: false,
        },
      };

      SenseiReviewService.get(senseiReviewParams)
        .then((res) => {
          if (res.data.sensei_reviews.length > 0) {
            scope.current = page;

            if (page > 1 && scope.senseiProductPage) {
              var newReviews = properSenseiReviews(res.data.sensei_reviews);
              newReviews.map((value) => {
                scope.senseiReviews.push(value);
              });
            } else {
              scope.senseiReviews = properSenseiReviews(
                res.data.sensei_reviews
              );
            }

            // Move first review to the featured review as a default if sensei doesn't have a featured review
            if (!scope.featuredReview && page === 1) {
              scope.featuredReview = scope.senseiReviews[0];
              scope.senseiReviews.splice(0, 1);
            }
            scope.last = res.data.pages;
            scope.pages = res.data.pages;
            scope.showReviewSection = true;
          }
        })
        .catch(angular.noop);
    };

    scope.getWidthStyle = (number) => {
      return { width: `${number}%` };
    };

    scope.reviewUpdatedAt = (review) => {
      if (review !== null && review.updated_at) {
        return moment(review.updated_at).format("MM/DD/YY");
      }
    };

    scope.toggleShowMore = () => {
      if (!scope.showMoreReviews) {
        scope.showMoreReviews = true;
      } else {
        scope.setCurrent(1);
        scope.showMoreReviews = false;
      }
    };

    scope.getRange = (num) => {
      var range = [];
      for (let i = 0; i < num; i++) {
        range.push(i);
      }
      return range;
    };

    // This function is completely necessary but it cleans up the html quite a bit.
    function properSenseiReviews(reviews) {
      for (let x in reviews) {
        var updatedReviewLength = reviews[x].updated_reviews.length;
        if (updatedReviewLength > 0) {
          reviews[x].updatedReview =
            reviews[x].updated_reviews[updatedReviewLength - 1];
        }
      }
      return reviews;
    }
  }
}

senseiReviewsDirective.$inject = ["SenseiReviewService"];
