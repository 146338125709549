import LessonArrivalTemplate from "../../../templates/directives/dashboard/lessons/lesson_arrival_template";

export default function lessonArrivalDirective(
  $timeout,
  DirectTrainingRequestSchedule,
  OmnibarPointer,
  $window
) {
  return {
    restrict: "E",
    scope: {
      session: "=",
    },
    template: LessonArrivalTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.OmnibarPointer = OmnibarPointer;
    const checkInEligible =
      new Date(scope.session.request.participant_check_in_eligible_at) -
      new Date();
    const checkInNotEligible =
      new Date(scope.session.request.participant_check_in_ineligible_at) -
      new Date();
    const lessonFinished =
      new Date(scope.session.request.finishes_at) - new Date();
    const lessonStarted =
      new Date(scope.session.request.starts_at) - new Date();

    scope.isLessonEligibleToStart = function () {
      if (checkInEligible <= 900000 && !scope.isLessonTimeOver()) {
        return true;
      }
      return false;
    };

    scope.isLessonIneligibleToStart = function () {
      if (checkInNotEligible <= 2700000) {
        return true;
      }
      return false;
    };

    scope.isLessonTimeOver = function () {
      if (lessonFinished <= 0) {
        return true;
      }
      return false;
    };

    scope.hasStartTimePassed = function () {
      if (lessonStarted < 0) {
        return true;
      }
      return false;
    };

    scope.areBothPlayersReadyForLesson = function () {
      return scope.session.bothUsersAreReady;
    };

    scope.lessonStatusText = function () {
      if (scope.hasStartTimePassed && !scope.isLessonTimeOver) {
        return "This lesson was scheduled to begin already!";
      } else if (!scope.session.isUserReady) {
        return "This lesson is scheduled to begin soon!";
      } else if (scope.session.bothUsersAreReady) {
        return "This lesson has begun!";
      }

      return null;
    };

    scope.session.presence =
      scope.session.request.direct_training_request_schedule.direct_training_request_presence;

    if (scope.session.presence) {
      scope.session.gamerArriveTime = scope.session.presence.gamer_arrives_at;
      scope.session.gamerLeaveTime = scope.session.presence.gamer_disengages_at;
      scope.session.senseiArriveTime = scope.session.presence.sensei_arrives_at;
      scope.session.senseiLeaveTime =
        scope.session.presence.sensei_disengages_at;
    }

    $timeout(function () {
      checkIfUsersAreReady();
    }, 0);

    scope.engageLesson = function (e) {
      if (!window.DailyIframe.supportedBrowser().supported) {
        scope.OmnibarPointer.pushErrorByMessage(
          "Browser does not support embedded video calls."
        );
        return;
      }

      scope.session.bothUsersAreReady = false;
      let lessonEngagement;
      const dtrpID =
        scope.session.request.direct_training_request_schedule
          .direct_training_request_presence.id;
      const dailyURL = scope.session.request.daily_co_room_url;
      const ownerToken = scope.session.request.owner_key;

      if (!scope.session.bothUsersAreReady && scope.session.isGamer()) {
        if (!scope.session.isUserReady) {
          lessonEngagement =
            DirectTrainingRequestSchedule.gamerHasArrived(dtrpID);
        }
      } else if (!scope.session.bothUsersAreReady && scope.session.isSensei()) {
        if (!scope.session.isUserReady) {
          lessonEngagement =
            DirectTrainingRequestSchedule.senseiHasArrived(dtrpID);
        }
      }

      $window.open(dailyURL, "_blank");

      if (!scope.session.isUserReady) {
        lessonEngagement
          .then((res) => {
            setUpNewArrivalScope(res);
          })
          .catch((err) => {
            scope.OmnibarPointer.pushErrorByHash(err.data.errors);
          });
      }
    };

    scope.returnButtonText = function () {
      if (scope.session.bothUsersAreReady || scope.session.isUserReady) {
        return "Rejoin lesson";
      } else {
        return "Start Lesson";
      }
    };

    scope.returnGamerText = function () {
      if (scope.session.isGamer()) {
        if (scope.session.isUserReady) {
          return "You are ready!";
        } else {
          return "You are not ready";
        }
      } else {
        if (scope.session.isGamerReady) {
          return "Gamer is ready!";
        } else {
          return "Gamer is not ready";
        }
      }
    };

    scope.returnSenseiText = function () {
      if (scope.session.isSensei()) {
        if (scope.session.isUserReady) {
          return "You are ready!";
        } else {
          return "You are not ready";
        }
      } else {
        if (scope.session.isSenseiReady) {
          return "Sensei is ready!";
        } else {
          return "Sensei is not ready";
        }
      }
    };

    function setUpNewArrivalScope(res) {
      scope.session.presence = res.data.direct_training_request_presence;
      scope.session.gamerArriveTime =
        res.data.direct_training_request_presence.gamer_arrives_at;
      scope.session.gamerLeaveTime =
        res.data.direct_training_request_presence.gamer_disengages_at;
      scope.session.senseiArriveTime =
        res.data.direct_training_request_presence.sensei_arrives_at;
      scope.session.senseiLeaveTime =
        res.data.direct_training_request_presence.sensei_disengages_at;
      checkIfUsersAreReady();
    }

    function checkIfUsersAreReady() {
      if (
        scope.session.presence &&
        ((scope.session.gamerArriveTime && !scope.session.gamerLeaveTime) ||
          moment(scope.session.gamerArriveTime).isAfter(
            moment(scope.session.gamerLeaveTime)
          ))
      ) {
        scope.session.isGamerReady = true;
        if (scope.session.isGamer()) {
          scope.session.isUserReady = true;
          if (
            scope.session.presence &&
            ((scope.session.senseiArriveTime &&
              !scope.session.senseiLeaveTime) ||
              moment(scope.session.senseiArriveTime).isAfter(
                moment(scope.session.senseiLeaveTime)
              ))
          ) {
            scope.session.bothUsersAreReady = true;
          }
        } else {
          scope.session.isOtherPartyReady = true;
        }
      } else {
        scope.isGamerReady = false;
        if (scope.session.isGamer()) {
          scope.session.isUserReady = false;
        } else {
          scope.session.isOtherPartyReady = false;
        }
      }

      if (
        scope.session.presence &&
        ((scope.session.senseiArriveTime && !scope.session.senseiLeaveTime) ||
          moment(scope.session.senseiArriveTime).isAfter(
            moment(scope.session.senseiLeaveTime)
          ))
      ) {
        scope.session.isSenseiReady = true;
        if (scope.session.isSensei()) {
          scope.session.isUserReady = true;
          if (
            scope.session.presence &&
            ((scope.session.gamerArriveTime && !scope.session.gamerLeaveTime) ||
              moment(scope.session.gamerArriveTime).isAfter(
                moment(scope.session.gamerLeaveTime)
              ))
          ) {
            scope.session.isGamerReady = true;
            scope.session.bothUsersAreReady = true;
          }
        } else {
          scope.session.isOtherPartyReady = true;
        }
      } else {
        scope.isSenseiReady = false;
        if (scope.session.isSensei()) {
          scope.session.isUserReady = false;
        } else {
          scope.session.isOtherPartyReady = false;
        }
      }
    }
  }
}

lessonArrivalDirective.$inject = [
  "$timeout",
  "DirectTrainingRequestSchedule",
  "OmnibarPointer",
  "$window",
];
