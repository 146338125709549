export default class AboutPageController {
  constructor(MetaTagsService) {
    this.aboutHero = {
      header: '<h1 class="text-center text-light">GAMER SENSEI</h1>',
      body: "<p class=\"text-center text-prominent\">The world's premier esports coaching platform servicing your personal journey in competitive gaming. We're building better gamers by connecting them to an expansive list of professional, fully-vetted and background-checked esports coaches, which we call Sensei.</p>",
      position: "center",
    };

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "About | Gamer Sensei";
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

AboutPageController.$inject = ["MetaTagsService"];
