import uploadPhotoLoadingPartial from "../../../../templates/includes/access/upload_photo_loading_partial";
import {
  isProfileValid,
  missingRank,
  missingRegion,
  missingUsername,
  populateGameIds,
  saveUserInfoReminder,
  userInfoReminder,
} from "../../../../config/utils/utilsConfig";
import { gearIcon, checkListIcon } from "../../../../config/icons/iconConfig";

export default class GamerAccountProfileController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $state,
    User,
    UserProfile,
    OmnibarPointer,
    GamePublisher,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "gamer profile";
    this.userId = $scope.currentId;
    this.role = "gamer";
    // angularjs injections
    this.$state = $state;
    this.$window = $window;
    this.$timeout = $timeout;
    this.root = $rootScope;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.userService = User;
    this.userProfile = UserProfile;
    this.gamePublisher = GamePublisher;
    // imported functionality
    this.isProfileValid = isProfileValid;
    this.uploadPhotoLoadingPartial = uploadPhotoLoadingPartial;
    this.gameInfoReminder = userInfoReminder();
    this.icon = { gearIcon, checkListIcon };
    // strings
    this.helperText = "Edit your profile and settings";
    // booleans
    this.combineName = false;
    this.showUploadPhoto = false;
    this.validPhoto = false;
    // objects and arrays
    this.gameIds = [];
    this.publisherConnections = {};
    this.communicationInfoReminder = {
      show: false,
      completed: false,
    };

    // Save Game Info
    this.gameInfoHeader = () => {
      if (this.gameInfoReminder.completed) {
        return "Thanks!";
      } else {
        return `Who are you?!`;
      }
    };

    this.gameInfoText = () => {
      if ($scope.gameInfoCompleted) {
        return "You can add or edit your game information any time below!";
      } else {
        return "Fill out your game information so your sensei can get in touch with you!";
      }
    };

    this.$window.publisherSigninSuccess = (publisher) => {
      this.gamePublisher.trackSigninSuccess(publisher);
      this.refreshPublisherConnections();
      this.publisherConnections[publisher] = true;
    };

    this.initUser({
      onSuccess: () => {
        $scope.currentUser = this.currentUser;

        this.userProfilePicSetup();
        this.setUpGameData(this.currentUser.games);
        this.setUpGamerInfoReminder();

        this.root.firstRun = this.currentUser.first_run;
      },
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);

    this.userService
      .getUser()
      .then((userData) => {
        this.currentUser = userData;
        this.communicationInfoReminder.show =
          userData.communication_services.length === 0;

        if (callbacks?.onSuccess) {
          callbacks.onSuccess();
        }

        this.omniTooltipSuccessPopup();
      })
      .catch(angular.noop);
  }

  // methods for editing and saving profile
  uploadPhotoToggle() {
    document.getElementById("uploadPhotoInput").click();
  }

  // organize userParams and push to submit
  updateGamer() {
    this.loadingProfileSave = true;
    const gameRanks = [];
    const inGameCommunications = [];

    angular.forEach(this.currentUser.games, (game) => {
      const gameRank = {
        game_id: game.id,
        rank: game.rank,
      };

      const inGameCommunication = {
        game_id: game.id,
        in_game_id: game.in_game_id,
        region: game.region,
      };

      gameRanks.push(gameRank);
      inGameCommunications.push(inGameCommunication);
    });

    const updateParams = {
      gamer: {
        first_name: this.currentUser.first_name,
        last_name: this.currentUser.last_name,
        full_name:
          this.currentUser.first_name + " " + this.currentUser.last_name,
        screen_name: this.currentUser.screen_name,
        description: this.currentUser.description,
        game_ids: this.gameIds,
        profile_pic: this.profilePic,
        game_ranks: gameRanks,
        in_game_communications: inGameCommunications,
      },
    };

    if (this.gameIds.length === 0) {
      this.omnibarPointer.pushErrorByMessage(
        "Oops! Please select at least one game you want to master."
      );
    } else {
      this.saveUserProfile(this.currentUser.gamer.id, updateParams);
    }
  }

  // save profile
  saveUserProfile(gamer_id, params) {
    return this.userProfile
      .updateGamer(gamer_id, params)
      .then((res) => {
        const userEvent = {
          firstName: this.currentUser.first_name,
          lastName: this.currentUser.last_name,
          screenName: this.currentUser.screen_name,
          games: this.currentUser.game_description,
        };

        this.root.$broadcast("uploadProfilePic", {
          profilePic: this.profilePic,
        });

        this.eventTracking.pushProfileUpdate(userEvent, this.currentUser);

        this.$timeout(() => {
          this.loadingProfileSave = false;
          this.$window.scrollTo(0, 0);
          this.userService.setSaveStatus(true);
          this.$state.reload();
        }, 100);
      })
      .catch((err) => {
        this.loadingProfileSave = false;
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  saveGamerInfoReminder() {
    saveUserInfoReminder(
      this.gameInfoReminder,
      this.currentUser.gamer.id,
      this.userProfile.updateGamer,
      {
        onSuccess: () => {
          this.userService.setSaveStatus(true);
          this.$state.reload();
        },
        onError: (err) => {
          this.omnibarPointer.pushErrorByHash(err.data.errors);
        },
      }
    );
  }

  toggleGameSelect() {
    this.editGamesFlag = !this.editGamesFlag;
  }

  // internal methods
  refreshPublisherConnections() {
    return this.gamePublisher
      .refreshPublisherConnections(this.userId)
      .then((publisherConnections) => {
        const publishers = Object.keys(publisherConnections);

        for (let i in publishers) {
          const publisher = publishers[i];
          this.publisherConnections[publisher] =
            publisherConnections[publisher];
        }
      })
      .catch(angular.noop);
  }
  setUpGameData(gameSelections) {
    //ids of selected games
    this.gameIds = populateGameIds(gameSelections);

    this.$timeout(() => {
      this.refreshPublisherConnections();

      //check each game, add selected class if already chosen by gamer
      angular.forEach(
        angular.element(document.querySelectorAll("[data-game-id]")),
        (item) => {
          const gameId = parseInt(item.attributes["data-game-id"].value);

          if (this.gameIds.indexOf(gameId) > -1) {
            angular.element(item).addClass("selected-game");
          }
        }
      );
    });
  }
  setUpGamerInfoReminder() {
    const games = this.currentUser.games;

    if (
      games.length > 0 &&
      (missingRank(games[0]) ||
        missingRegion(games[0]) ||
        missingUsername(games[0]))
    ) {
      const game = games[0];

      this.gameInfoReminder = {
        show: true,
        game_id: game.id,
        rank: {
          show: game.ranks.length > 0,
          label: game.rank_label,
          current: game.rank,
          data: game.ranks,
        },
        region: {
          show: game.regions.length > 0,
          label: "Region",
          current: game.region,
          data: game.regions,
        },
        username: {
          show: true,
          label: game.in_game_id_label,
          current: game.in_game_id,
        },
      };
    }
  }
  userProfilePicSetup() {
    this.root.$watch(
      () => this.userService.tempProfilePic,
      (newVal) => {
        if (this.userService.getProfilePic()) {
          this.userImage = this.userService.getProfilePic();
        } else {
          this.userImage = this.currentUser.profile_photo;
        }
      },
      true
    );
  }
  omniTooltipSuccessPopup() {
    if (this.currentUser.omni_tooltips["/gamer/dashboard"]) {
      this.omnibarPointer.pushTooltip(
        this.currentUser.omni_tooltips["/gamer/dashboard"][0]
      );
    } else if (this.userService.getSaveStatus()) {
      const omniTooltipParams = {
        title: "Profile updated",
        body: "<p>You have successfully saved your profile.</p>",
      };
      this.omnibarPointer.pushTooltip(omniTooltipParams, {
        tooltipCallback: false,
        persist: true,
        timer: true,
      });
      this.userService.setSaveStatus(false);
    }
  }
}

GamerAccountProfileController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "$state",
  "User",
  "UserProfile",
  "OmnibarPointer",
  "GamePublisher",
  "EventTrackingManager",
];
