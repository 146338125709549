export default function actionableLessonService($http) {
  const service = {
    getCounts: getCounts,
    getUnviewed: getUnviewed,
    dismissUnviewed: dismissUnviewed,
  };

  return service;

  ///////////
  function getCounts(config) {
    return $http.get("/api/web/actionable_lesson_counts", config);
  }

  function getUnviewed() {
    return $http.get("/api/web/unviewed_actionable_lessons_counts");
  }

  function dismissUnviewed(id) {
    return $http.put(
      "/api/web/users/" + id + "/dismiss_unviewed_actionable_lessons"
    );
  }
}

actionableLessonService.$inject = ["$http"];
