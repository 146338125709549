export default function directTrainingRequestScheduleService($http) {
  var services = {
    gamerAccept: gamerAccept,
    senseiAccept: senseiAccept,
    gamerPropose: gamerPropose,
    senseiPropose: senseiPropose,
    gamerHasArrived: gamerHasArrived,
    senseiHasArrived: senseiHasArrived,
    gamerHasLeft: gamerHasLeft,
    senseiHasLeft: senseiHasLeft
  };

  return services;

  function gamerAccept(directBookingScheduleId) {
    return $http({method: 'PUT', url: '/api/web/direct_booking_schedules/' + directBookingScheduleId + '/gamer_accept'});
  }

  function senseiAccept(directBookingScheduleId) {
    return $http({method: 'PUT', url: '/api/web/direct_booking_schedules/' + directBookingScheduleId + '/sensei_accept'});
  }

  function gamerPropose(directBookingScheduleId, data) {
    return $http({method: 'PUT', url: '/api/web/direct_booking_schedules/' + directBookingScheduleId + '/gamer_propose', data: data});
  }

  function senseiPropose(directBookingScheduleId, data) {
    return $http({method: 'PUT', url: '/api/web/direct_booking_schedules/' + directBookingScheduleId + '/sensei_propose', data: data});
  }

  function gamerHasArrived(direct_training_request_presence_id) {
    return $http.put('/api/web/direct_training_request_presences/' + direct_training_request_presence_id + '/gamer_arrives')
  }

  function gamerHasLeft(direct_training_request_presence_id) {
    return $http.put('/api/web/direct_training_request_presences/' + direct_training_request_presence_id + '/gamer_disengages')
  }

  function senseiHasArrived(direct_training_request_presence_id) {
    return $http.put('/api/web/direct_training_request_presences/' + direct_training_request_presence_id + '/sensei_arrives')
  }

  function senseiHasLeft(direct_training_request_presence_id) {
    return $http.put('/api/web/direct_training_request_presences/' + direct_training_request_presence_id + '/sensei_disengages')
  }
}

directTrainingRequestScheduleService.$inject = ['$http'];
