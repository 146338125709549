export default function cartManagerService(
  $q,
  $http,
  $httpParamSerializerJQLike,
  $window,
  EventTrackingManager
) {
  var manager = {
    loading: false,
    items: {},
    config: {
      display: false,
      status: "empty",
      checkoutStep: null,
      promo: "",
      payment: {
        service: {
          credit_card: { void: true },
          paypal: { void: true },
          amazon_pay: { void: true },
        },
        tab: null,
      },
      orderId: null,
    },
    total: {},
    getStatus: getStatus,
    setStatus: setStatus,
    getPaymentServiceConfig: getPaymentServiceConfig,
    setPaymentServiceConfig: setPaymentServiceConfig,
    getCartDisplay: getCartDisplay,
    setCartDisplay: setCartDisplay,
    getCheckoutStep: getCheckoutStep,
    setCheckoutStep: setCheckoutStep,
    getCartItems: getCartItems,
    setCartItems: setCartItems,
    addCartItem: addCartItem,
    removeCartItem: removeCartItem,
    changeCartItemQuantity: changeCartItemQuantity,
    removeAllCartItems: removeAllCartItems,
    getPromo: getPromo,
    getPromoTotal: getPromoTotal,
    togglePromo: togglePromo,
    getPaymentTab: getPaymentTab,
    setPaymentTab: setPaymentTab,
    getPaymentDetails: getPaymentDetails,
    getServiceFee: getServiceFee,
    getSpendableCredits: getSpendableCredits,
    getGiftCardAmountSpent: getGiftCardAmountSpent,
    getCartSubTotal: getCartSubTotal,
    getCartTotal: getCartTotal,
    getOrderId: getOrderId,
    processCartPayment: processCartPayment,
    checkForCartContents: checkForCartContents,
    canCartClose: canCartClose,
    isPaymentMethodActive: isPaymentMethodActive,
    doesCartIncludeSenseiProposalResponse:
      doesCartIncludeSenseiProposalResponse,
  };

  if (checkForCartContents()) {
    manager.items = JSON.parse($window.localStorage.getItem("cart"));
    calculateTokenTotal(null, null, manager.items);
    setStatus("itemsInCart");
  }

  return manager;

  // Service functions
  function getStatus() {
    return manager.config.status;
  }

  function setStatus(status) {
    /* possible statuses
      - empty
      - itemsInCart
      - checkout
      - complete
    */
    manager.config.status = status;
  }

  function getPaymentServiceConfig() {
    return manager.config.payment.service;
  }

  function setPaymentServiceConfig(obj) {
    manager.config.payment.service = obj;
  }

  function getCartDisplay() {
    return manager.config.display;
  }

  function setCartDisplay(boolean, dontRestrict) {
    var htmlEl = document.querySelector("html");
    manager.config.display = boolean;
    if (boolean === true && dontRestrict !== true) {
      htmlEl.classList.add("checkout-open");
    } else {
      htmlEl.classList.remove("checkout-open");
    }
  }

  function getCheckoutStep() {
    return manager.config.checkoutStep;
  }

  function setCheckoutStep(step) {
    manager.config.checkoutStep = step;
  }

  function getCartItems() {
    return manager.items;
  }

  function setCartItems(items) {
    manager.items = items;
  }

  function addCartItem(item, type, qty) {
    const name = item.name || item.plan_name;
    const isSub = item.plan_name || (item.name && item.subscribed);
    const sub = isSub ? item.subscription.find((sub) => sub.subscribed) : null;

    if (manager.items[sub?.plan_name ?? name]) {
      if (isSub && sub?.plan_name) {
        manager.items[sub.plan_name].quantity = 1;
        manager.items[sub.plan_name].senseiId = item.sensei
          ? item.sensei.senseiId
          : null;
      } else if (isSub && item.plan_name) {
        manager.items[item.plan_name].quantity = 1;
        manager.items[item.plan_name].senseiId = item.sensei
          ? item.sensei.senseiId
          : null;
      } else if (name) {
        manager.items[name].quality = manager.items[name].quality + 1;
        manager.items[name].senseiId = item.sensei
          ? item.sensei.senseiId
          : null;
      }

      if (!manager.items[sub?.plan_name ?? name].itemType) {
        manager.items[sub?.plan_name ?? name].itemType = type;
      }
    } else if (!manager.items[sub?.plan_name ?? name]) {
      manager.items[sub?.plan_name ?? name] = {
        ...item,
        itemType: type,
        quantity: qty ? qyt : 1,
      };
    }

    if (manager.config.status === "empty") {
      manager.setStatus("itemsInCart");
    }
    calculateTokenTotal(
      "addToCart",
      { ...item, itemType: type, quantity: qty ? qty : 1 },
      manager.items
    );
    manager.setCartDisplay(true);
    $window.localStorage.setItem("cart", JSON.stringify(manager.items));
  }

  function removeCartItem(item) {
    var planName = item.name || item.plan_name;
    if (manager.items[planName]) {
      delete manager.items[planName];
    }
    if (isCartEmpty()) {
      if (manager.canCartClose()) {
        manager.setCartDisplay(false);
      }
      manager.setStatus("empty");
      $window.localStorage.removeItem("cart");
      manager.total = {};
      EventTrackingManager.pushAddOrRemoveFromCartV2(
        "removeFromCart",
        item,
        {}
      );
      EventTrackingManager.pushCartDetailsV2(
        manager.getCartItems(),
        manager.getPaymentDetails()
      );
    } else {
      $window.localStorage.setItem("cart", JSON.stringify(manager.items));
      calculateTokenTotal("removeFromCart", item, manager.items);
    }
  }

  function changeCartItemQuantity(item, newQty) {
    var planName = item.name || item.plan_name;
    if (manager.items[planName]) {
      var eventName = item.quantity > newQty ? "removeFromCart" : "addToCart";
      manager.items[planName] = { ...item, quantity: newQty };
      $window.localStorage.setItem("cart", JSON.stringify(manager.items));
      calculateTokenTotal(eventName, { ...item, quantity: 1 }, manager.items);
    }
  }

  function removeAllCartItems() {
    if (manager.canCartClose()) {
      manager.setCartDisplay(false);
    }
    manager.items = {};
    manager.setStatus("empty");
    manager.total = {};
    $window.localStorage.removeItem("cart");
  }

  function getPromo() {
    return manager.config.promo;
  }

  function getPromoTotal() {
    return manager.total && manager.total.code_based_discount;
  }

  function togglePromo(add, promo) {
    var deferred = $q.defer();
    manager.config.promo = add ? promo : "";

    calculateTokenTotal(null, null, manager.items).then(
      (res) => {
        deferred.resolve(res);
      },
      (err) => {
        manager.config.promo = "";
        deferred.reject(err);
      }
    );

    return deferred.promise;
  }

  function getSpendableCredits() {
    return manager.total.points_applicable;
  }

  function getGiftCardAmountSpent() {
    return manager.total.gift_card_amount_applied;
  }

  function getPaymentTab() {
    return manager.config.payment.tab;
  }

  function setPaymentTab(tab) {
    manager.config.payment.tab = tab;
  }

  function getPaymentDetails() {
    return manager.total;
  }

  function getServiceFee() {
    return manager.total.service_fee;
  }

  function getCartSubTotal() {
    let amount = 0;

    if (
      Array.isArray(manager.total.sensei_tokens) &&
      manager.total.sensei_tokens
    ) {
      for (let payment of manager.total.sensei_tokens) {
        amount = amount + payment.net_cost;
      }
    }
    if (
      Array.isArray(manager.total.token_bundles) &&
      manager.total.token_bundles
    ) {
      for (let payment of manager.total.token_bundles) {
        amount = amount + payment.net_cost;
      }
    }
    if (
      Array.isArray(manager.total.token_subscription_plans) &&
      manager.total.token_subscription_plans
    ) {
      for (let payment of manager.total.token_subscription_plans) {
        amount = amount + payment.net_cost;
      }
    }

    return amount;
  }

  function getCartTotal() {
    return manager.total.amount;
  }

  function getOrderId() {
    return manager.config.orderId;
  }

  function processCartPayment(user) {
    const deferred = $q.defer();
    const singleTokens = [];
    const bundleTokens = [];
    const subTokens = [];

    angular.forEach(manager.items, (item) => {
      if (item.subscribed && item.subscription?.length) {
        const subscription = item.subscription.find((sub) => sub.subscribed);
        subTokens.push({
          plan_id: subscription.id,
          count: item.quantity,
          accept_terms: true,
        });
      } else if (item.itemType === "plan") {
        subTokens.push({
          plan_id: item.id,
          accept_terms: true,
        });
      } else if (item.itemType === "single") {
        singleTokens.push({
          count: item.quantity,
          token_template_id: item.id,
          sensei_id: item.sensei ? item.sensei.senseiId : null,
          sensei_training_request_id: item.sensei_training_request_id || null,
        });
      } else if (item.itemType === "bundle") {
        bundleTokens.push({
          count: item.quantity,
          token_bundle_id: item.id,
          sensei_id: item.sensei ? item.sensei.senseiId : null,
          sensei_training_request_id: item.sensei_training_request_id || null,
        });
      }
    });

    const data = {
      ...manager.config.payment.service,
      currency_spend: {
        points_used: manager.total.points_applicable
          ? manager.total.points_applicable
          : 0,
      },
      token_templates: singleTokens,
      token_bundles: bundleTokens,
      subscriptions: subTokens,
      user: {
        email: user.email,
        screen_name: user.screen_name,
      },
    };

    if (manager.getPromo()) {
      data.offer_code = manager.getPromo();
    }

    $http
      .post("/api/web/token_deposit_payments", data)
      .then((res) => {
        setStatus("complete");
        manager.config.orderId =
          res.data.token_deposit_payment.order_confirmation_id;

        $window.localStorage.removeItem("cart");
        deferred.resolve(res);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function checkForCartContents() {
    return !isCartEmpty() || $window.localStorage.getItem("cart") != null;
  }

  function canCartClose() {
    const currentStatus = manager.getStatus();

    return currentStatus !== "complete" && currentStatus !== "checkout";
  }

  function isPaymentMethodActive() {
    let active = true;
    const payConfig = manager.getPaymentServiceConfig();
    const payTab = manager.getPaymentTab();

    if (payConfig.amazon_pay.void && payTab === "amazonPay") {
      active = false;
    }
    if (payConfig.paypal.void && payTab === "paypal") {
      active = false;
    }
    if (payConfig.credit_card.void && payTab === "card") {
      active = false;
    }

    return active;
  }

  function doesCartIncludeSenseiProposalResponse() {
    let proposals = false;

    angular.forEach(manager.items, (item) => {
      if (item.sensei_proposed_lesson) {
        proposals = true;
      }
    });

    return proposals;
  }

  // Internal functions
  function isCartEmpty() {
    return Object.keys(manager.items).length === 0;
  }

  function calculateTokenTotal(event, current, tokens) {
    const deferred = $q.defer();
    const purchaseChargesEndpoint = "/api/web/token_purchase_charges";
    const params = { token_templates: [], token_bundles: [] };

    if (manager.getPromo()) {
      params.offer_code = manager.getPromo();
    }

    if (tokens) {
      const arrSingle = [];
      const arrBundle = [];
      const arrSub = [];

      angular.forEach(tokens, (token) => {
        if (token.subscribed && token.subscription.length) {
          const subscription = token.subscription.find((sub) => sub.subscribed);
          arrSub.push({
            plan_id: subscription.id,
            count: token.quantity,
          });
        } else if (token.itemType === "plan") {
          arrSub.push({ plan_id: token.id, count: 1 });
        } else if (token.itemType === "single") {
          arrSingle.push({
            token_template_id: token.id,
            count: token.quantity,
          });
        } else if (token.itemType === "bundle") {
          arrBundle.push({ count: token.quantity, token_bundle_id: token.id });
        }
      });

      params.token_templates = arrSingle;
      params.token_bundles = arrBundle;
      params.token_subscription_plans = arrSub;
    }

    $http
      .get(purchaseChargesEndpoint, {
        params: params,
        paramSerializer: "$httpParamSerializerJQLike",
      })
      .then(
        (res) => {
          manager.total = res.data;
          if (event) {
            EventTrackingManager.pushAddOrRemoveFromCartV2(
              event,
              current,
              tokens
            );
            EventTrackingManager.pushCartDetailsV2(
              manager.getCartItems(),
              manager.getPaymentDetails()
            );
          }
          deferred.resolve(res);
        },
        (error) => {
          deferred.reject(error);
        }
      );

    return deferred.promise;
  }
}

cartManagerService.$inject = [
  "$q",
  "$http",
  "$httpParamSerializerJQLike",
  "$window",
  "EventTrackingManager",
];
