import DashboardTableTemplate from "../../templates/directives/dashboard/dashboard_table_template";
import { nextIcon } from "../../config/icons/iconConfig";

export default function dashboardTableDirective($state, $filter) {
  return {
    restrict: "E",
    scope: {
      tableHeader: "=",
      tableResults: "=",
      advanced: "=",
      order: "=",
      limit: "=",
      pages: "=",
      currentPage: "=",
      link: "=",
      noResults: "=",
      seeMoreText: "=",
      buttonActions: "=",
    },
    link: link,
    template: DashboardTableTemplate,
  };

  function link(scope, elem, attr) {
    scope.reverse = false;
    scope.descVisible = {}; // setup description display
    scope.nextIcon = nextIcon;

    scope.displayAmount = function (amount) {
      return $filter("currency")(amount / 100, "$", 2);
    };

    scope.getButtonClasses = function (classes) {
      return classes;
    };

    scope.generateTableDataCell = function (row, key) {
      switch (key) {
        case "amount":
          return scope.displayAmount(row[key]);
        default:
          return row[key];
      }
    };

    scope.clickable = function (key) {
      return key === "buttons";
    };

    scope.sortTableResults = function (key, th) {
      if (!th.isSortable) return;

      if (th.isActive) {
        scope.reverse = !scope.reverse;
      } else {
        var oldKey = scope.order.replace("-", "");
        scope.tableHeader[oldKey].isActive = false;
        scope.order = `-${key}`;
        th.isActive = true;
        scope.reverse = false;
      }
    };

    scope.getCellClass = function (key) {
      return `cell-${key.toLowerCase()}`;
    };
  }
}

dashboardTableDirective.$inject = ["$state", "$filter"];
