import { calendarIcon, settingsIcon, walletIcon } from "../../icons/iconConfig";

const sidebarMenuBusiness = () => {
  const menu = [
    {
      name: "Sessions",
      icon: calendarIcon,
      dockLink: "business_dashboard.lessons.list",
      submenu: lessonSubMenu(),
    },
    {
      name: "Wallet",
      icon: walletIcon,
      dockLink: "business_dashboard.wallet.summary",
      submenu: walletSubmenu(),
    },
    {
      name: "Preferences",
      icon: settingsIcon,
      dockLink: "business_dashboard.profile",
      submenu: preferencesSubmenu(),
    },
  ];

  return menu;
};

function preferencesSubmenu() {
  var submenuItems = [
    {
      displayOrder: 1,
      name: "User Profile",
      state: "business_dashboard.profile",
    },
    {
      displayOrder: 2,
      name: "Contact Information",
      state: "business_dashboard.contact_information",
    },
    {
      displayOrder: 3,
      name: "Notification Settings",
      state: "business_dashboard.notifications",
    },
  ];

  return submenuItems;
}

function walletSubmenu() {
  var submenuItems = [
    {
      displayOrder: 0,
      name: "Bulk Purchase Sessions",
      state: "business_dashboard.wallet.purchase",
    },
    {
      displayOrder: 1,
      name: "Payment Options",
      state: "business_dashboard.wallet.payment",
    },
    {
      displayOrder: 2,
      name: "Redeem Session Token",
      state: "business_dashboard.wallet.redeem_session_token",
    },
  ];

  return submenuItems;
}

function lessonSubMenu() {
  var submenuItem = [
    {
      displayOrder: 1,
      name: "Book a Session",
      state: "business_dashboard.lessons.book_lesson_landing",
      defaultActiveState: true,
    },
    {
      displayOrder: 2,
      name: "Calendar View",
      state: "business_dashboard.lessons.calendar",
      defaultActiveState: true,
    },
    {
      displayOrder: 4,
      name: "Reviews",
      state: "business_dashboard.lessons.my_reviews",
    },
    {
      displayOrder: 5,
      name: "Session Notes",
      state: "business_dashboard.lessons.sensei_notes",
    },
  ];

  return submenuItem;
}

export default sidebarMenuBusiness;
