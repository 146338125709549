import moment from "moment-timezone";
import {
  setBusinessMetaTitle,
  sidebarMenuBusiness,
  userIsOnProfile,
} from "../../../config/dashboard/dashboardConfig";

export default class BusinessDashboardContainerController {
  constructor(
    $scope,
    $state,
    $location,
    $anchorScroll,
    $rootScope,
    UserOS,
    DirectTrainingRequestSchedule,
    SenseiTrainingRequestManager,
    OmnibarPointer,
    User,
    TokenBookingService,
    FlexiTokenService,
    MetaTagsService,
    NotificationManager,
    EventTrackingManager,
    pulledGames
  ) {
    UserOS.setOS($scope.os_windows);
    UserOS.setWinIsa($scope.windows_isa);

    this.userId = $scope.currentId;
    this.role = "gamer";
    this.currentStateName = $state.current.name;
    this.games = pulledGames.data.games;
    this.userService = User;
    this.tokenBooking = TokenBookingService;
    this.OmnibarPointer = OmnibarPointer;
    this.notifications = NotificationManager;
    this.eventTracking = EventTrackingManager;
    this.flexi = FlexiTokenService;

    $scope.isGamer = true;
    $scope.userDashboard = true;
    $scope.proposeToPerson = null;
    $rootScope.proposeTimeSubsequentStepLink = "business/dashboard";

    this.gamerMenu = sidebarMenuBusiness();

    this.showUserNotVerifiedDisplay = () => {
      return (
        this.currentUser &&
        !this.currentUser.is_email_verified &&
        $state.current.name !== "business_dashboard.verify_email" &&
        $scope.isGigyaOn
      );
    };

    SenseiTrainingRequestManager.setRole("gamer");
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;

    $rootScope.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams, options) => {
        $anchorScroll();
        var newMetaTitle = setBusinessMetaTitle(toState.name);
        MetaTagsService.setTags({
          title: newMetaTitle,
          "og:title": newMetaTitle,
          "twitter:title": newMetaTitle,
        });
        if (userIsOnProfile(toState.url)) {
          this.eventTracking.pushViewEvent("Profile");
        } else {
          this.eventTracking.pushViewEvent("Dashboard");
        }
      }
    );

    if (userIsOnProfile($state.current.url)) {
      this.eventTracking.pushViewEvent("Profile");
    } else {
      this.eventTracking.pushViewEvent("Dashboard");
    }

    this.initUser({
      onSuccess: (userData) => {
        this.currentUser = userData;
        this.tokenBooking.setTokenDeposits();
        this.notifications.setNotifications();
        this.flexi.setFlexiRetailerId(userData.gamer.token_retailer_id);

        if (this.currentUser.omni_tooltips["/gamer/dashboard"]) {
          this.OmnibarPointer.pushTooltip(
            this.currentUser.omni_tooltips["/gamer/dashboard"][0],
            { persist: true }
          );

          $anchorScroll();
        }
      },
      onError: (err) => {
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
      },
    });
    this.initMetaTags(MetaTagsService);

    angular.element(() => {
      const bizParams = { params: { is_business: true } };
      this.flexi.setFlexiTokens(bizParams);
    });
  }

  // init methods
  initMetaTags(MetaTagsService) {
    const metaTitle = setBusinessMetaTitle(this.currentStateName);
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser(true)
      .then((userData) => {
        if (callbacks?.onSuccess) callbacks.onSuccess(userData);
      })
      .catch((err) => {
        if (callbacks?.onError) callbacks.onError(err);
      });
  }
}

BusinessDashboardContainerController.$inject = [
  "$scope",
  "$state",
  "$location",
  "$anchorScroll",
  "$rootScope",
  "UserOS",
  "DirectTrainingRequestSchedule",
  "SenseiTrainingRequestManager",
  "OmnibarPointer",
  "User",
  "TokenBookingService",
  "FlexiTokenService",
  "MetaTagsService",
  "NotificationManager",
  "EventTrackingManager",
  "pulledGames",
];
