import sessionOfferingSelectPartial from "../../../../templates/includes/sessions/session_offering_select_partial";
import {
  dollarSignIcon,
  loadingIcon,
} from "../../../../config/icons/iconConfig";
import { isObjectEmpty } from "../../../../config/utils/utilsConfig";

export default class SenseiAccountSessionOfferingsController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    User,
    OmnibarPointer,
    SessionTypes,
    SenseiSessionsManager,
    ModalManager
  ) {
    this.userId = $scope.currentId;
    this.standardCommission = $scope.standardCommission;
    $rootScope.bodylayout = "sensei settings";
    // angular providers
    this.timeout = $timeout;
    // services
    this.sessions = SenseiSessionsManager;
    this.sessionTypes = SessionTypes;
    this.omnibarPointer = OmnibarPointer;
    this.userService = User;
    this.modalManager = ModalManager;
    // injected and imported
    this.isObjectEmpty = (obj) => isObjectEmpty(obj);
    this.sessionOfferingSelectPartial = sessionOfferingSelectPartial;
    this.loadingIcon = loadingIcon;
    this.dollarSignIcon = dollarSignIcon;
    // arrays and objects
    this.modal = {
      open: false,
      label: "Create a new session",
    };
    this.statusModal = {
      open: false,
      type: "reactivate",
      product: "session",
      item: null,
    };

    // price setup
    this.priceMin = 12;
    this.defaultPriceMax = 1000;
    this.priceMax = this.getPriceMax();
    this.maxLength = 150;
    this.priceRegex = /^(\d{1,4}|\d{0,4}\.\d{1,2})$/i;

    this.initForm();
    this.initUser({
      sessionOfferings: () => {
        const token = this.currentUser.sensei.token_templates.find(
          (template) => {
            return template.token_type === "one-on-one-training";
          }
        );
        this.templateTier = token.token_tier_data;
        this.userGame = this.currentUser.sensei?.games[0];
        this.initSessions();

        this.sessions.setOfferedSessions(
          this.currentUser.sensei.token_templates
        );
        this.sessions.setWithdrawnSessions(this.currentUser.sensei.id);
      },
      firstRunUpdate: (userData) => {
        $rootScope.firstRun = userData.first_run;
      },
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("sensei");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        const commission =
          userData.sensei?.commission > 0
            ? userData.sensei.commission
            : this.standardCommission;
        this.senseiEarn = (100 - commission) / 100;

        if (callbacks?.sessionOfferings) callbacks.sessionOfferings();
        if (callbacks?.firstRunUpdate) callbacks.firstRunUpdate(userData);

        if (this.currentUser.omni_tooltips["/sensei/dashboard"]) {
          this.omnibarPointer.pushTooltip(
            this.currentUser.omni_tooltips["/sensei/dashboard"][0]
          );
        }
      })
      .catch(angular.noop);
  }
  initForm() {
    this.newSessionName = "";
    this.newSessionPrice = "";
    this.newSessionDesc = "";
    this.newSessionLength = 60;
    this.newSessionActive = true;
    this.newSessionTemplateQty = 1;
    this.newSessionProductQty = 1;
    this.newSessionProductRecurring = "month";
    this.newSessionAddedTemplates = [];
    this.newSessionPriceSale = null;
  }

  async initSessions() {
    this.sessionTypes.setSessionTypes().then((res) => {
      const types = res.data.token_types;
      this.newSessionType = types.find(
        (type) => type.slug === "one-on-one-training"
      );
    });

    await this.sessions.setSessions();
    await this.sessions.setBundles();
    await this.sessions.setSubscriptions();

    const key = Object.keys(this.buildSubscriptionOptions())[0];
    this.newSessionProduct = this.buildSubscriptionOptions()[key];
  }

  // cta methods
  changePrice(price, type) {
    let newPrice;

    if (type === "subtract") {
      newPrice = price > this.priceMin ? price - 1 : price;
    } else if (type === "add") {
      newPrice = price < this.getPriceMax() ? price + 1 : price;
    }

    return newPrice;
  }
  changeStandardPrice(math) {
    this.newSessionPrice = this.changePrice(this.newSessionPrice, math);
  }
  changeSalePrice(math) {
    this.newSessionPriceSale = this.changePrice(this.newSessionPriceSale, math);
  }

  openSessionModal(type, create, sale) {
    this.modal = {
      open: true,
      create,
      sale,
      type: this.getModalType(type, create),
      label: this.getModalLabel(type, create),
    };
    this.modalManager.setOpenBG();
  }

  editSessionModal(session, sale) {
    switch (session.productType) {
      case "session":
        return this.editSingleSessionModal(session, sale);
      case "bundle":
        return this.editBundleModal(session, sale);
      default:
        return this.editSingleSessionModal(session, sale);
    }
  }

  editSingleSessionModal(session, sale) {
    this.editMode = true;
    const types = this.sessionTypes.getSessionTypes();

    if (session.productType === "subscription") {
      const products = this.buildSubscriptionOptions();
      const sessionId = session.token_template_id;
      const bundleId = session.token_bundle_id;

      this.newSessionName = session.plan_name;
      this.newSessionWelcome = session.welcome_message;
      this.newSessionProductQty = session.recurring_cycle_number;
      this.newSessionProductRecurring = session.recurring_unit;

      if (sessionId) {
        this.newSessionProduct = products[`session_id_${sessionId}`];
      } else if (bundleId) {
        this.newSessionProduct = products[`bundle_id_${bundleId}`];
      } else {
        const key = Object.keys(products)[0];
        this.newSessionProduct = products[key];
      }
    } else {
      this.newSessionName = session.name;
      this.newSessionLength = session.duration_minutes;
      this.newSessionType = types.find(
        (type) => type.slug === session.token_type_data?.slug
      );
    }

    this.newSessionPrice = session.advertised_student_cost / 100;
    this.newSessionPriceSale =
      !session.ongoingSale || sale
        ? null
        : this.getSalePrice(
            session.base_student_cost,
            session.advertised_student_cost
          );

    this.newSessionDesc = session.description;
    this.currentSelectedId = session.id;

    this.openSessionModal(session.productType, false, sale);
  }

  editBundleModal(bundle, sale) {
    this.editMode = true;
    this.newSessionName = bundle.name;
    this.newSessionPrice = bundle.advertised_student_cost / 100;
    this.newSessionPriceSale =
      !bundle.ongoingSale || sale
        ? null
        : this.getSalePrice(
            bundle.base_student_cost,
            bundle.advertised_student_cost
          );
    this.newSessionAddedTemplates = bundle.token_bundle_items.map((item) => {
      item.name = this.sessions.getSessionName(
        "session",
        item.token_template_id
      );

      return item;
    });
    this.newSessionDesc = bundle.description;
    this.currentSelectedId = bundle.id;

    this.openSessionModal("bundle", false, sale);
  }

  closeSessionModal() {
    this.modal = { ...this.modal, open: false };
    this.currentSelectedId = null;
    this.editMode = false;
    this.initForm();
    this.modalManager.setCloseBG();
  }

  openStatusModal(item) {
    this.statusModal = {
      open: true,
      item,
      label: this.getNewStatusLabel(item),
    };
    this.modalManager.setOpenBG();
  }

  closeStatusModal() {
    this.statusModal = { ...this.statusModal, open: false, item: null };
    this.initForm();
    this.modalManager.setCloseBG();
  }

  addSession() {
    const params = this.buildSessionParams();

    let sessionAction;
    switch (this.modal.type) {
      case "create_session":
        sessionAction = this.sessions.createSession(params);
        break;
      case "update_session":
        sessionAction = this.sessions.changeSession(
          this.currentSelectedId,
          params
        );
        break;
      case "create_bundle":
        sessionAction = this.sessions.createBundle(params);
        break;
      case "update_bundle":
        sessionAction = this.sessions.changeBundle(
          this.currentSelectedId,
          params
        );
        break;
      case "create_subscription":
        sessionAction = this.sessions.createSubscription(params);
        break;
      case "update_subscription":
        sessionAction = this.sessions.changeSubscription(
          this.currentSelectedId,
          params
        );
        break;
      default:
        sessionAction = this.sessions.createSession(params);
        break;
    }

    sessionAction
      .then((res) => {
        let omniTooltipParams = {
          title: this.getOmnibarLabel(),
          body: this.getOmnibarMessage(),
        };

        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          timer: true,
        });

        this.closeSessionModal();
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  confirmActiveState(item) {
    this.newSessionName = item.plan_name ? item.plan_name : item.name;
    this.newSessionLength = item.duration_minutes;
    this.newSessionPrice = item.advertised_student_cost / 100;
    this.newSessionDesc = item.description;
    this.currentSelectedId = item.id;

    let updateActiveStatus;
    if ("publicly_saleable" in item) {
      const types = this.sessionTypes.getSessionTypes();
      this.newSessionActive = !item.publicly_saleable;
      this.newSessionType = types.find(
        (type) => type.slug === item.token_type_data?.slug
      );

      updateActiveStatus = this.sessions.changeSession(
        item.id,
        this.buildSessionParams()
      );
    } else if ("publicly_purchasable" in item) {
      const products = this.buildSubscriptionOptions();
      const sessionId = item.token_template_id;
      const bundleId = item.token_bundle_id;

      this.newSessionActive = !item.publicly_purchasable;
      this.newSessionProductQty = item.recurring_cycle_number;
      this.newSessionProductRecurring = item.recurring_unit;
      this.newSessionWelcome = item.welcome_message;

      if (sessionId) {
        this.newSessionProduct = products[`session_id_${sessionId}`];
      } else if (bundleId) {
        this.newSessionProduct = products[`bundle_id_${bundleId}`];
      } else {
        const key = Object.keys(products)[0];
        this.newSessionProduct = products[key];
      }

      updateActiveStatus = this.sessions.changeSubscription(
        item.id,
        this.buildSessionParams()
      );
    } else if ("active" in item) {
      this.newSessionActive = !item.active;

      updateActiveStatus = this.sessions.changeBundle(
        item.id,
        this.buildSessionParams()
      );
    }

    updateActiveStatus
      .then((res) => {
        let omniTooltipParams = {
          title: `${this.statusModal.item.productType} updated`,
          body: this.newSessionActive
            ? `<p>This ${this.statusModal.item.productType} is now a part of your offerings again.</p>`
            : `<p>This ${this.statusModal.item.productType} has been removed from your offerings.</p>`,
        };

        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          timer: true,
        });

        this.closeStatusModal();
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  addNewSessionItem() {
    const newItem = {
      ...this.newSessionTemplate,
      quantity: this.newSessionTemplateQty,
    };

    if (this.editMode) {
      this.sessions
        .createBundleItem(this.currentSelectedId, newItem)
        .then((res) => {
          this.newSessionAddedTemplates.unshift(newItem);

          this.newSessionTemplate = null;
          this.newSessionTemplateQty = 1;

          const omniTooltipParams = {
            title: `Bundle session updated`,
            body: `${newItem.name} now a part of this bundle session.`,
          };

          this.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            timer: true,
          });
        })
        .catch((err) => this.omnibarPointer.pushErrorByHash(err.data.errors));
    } else {
      this.newSessionAddedTemplates.unshift(newItem);

      this.newSessionTemplate = null;
      this.newSessionTemplateQty = 1;
    }
  }

  removeNewSessionItem(template) {
    const index = this.newSessionAddedTemplates.indexOf(template);

    if (this.editMode) {
      this.sessions
        .deleteBundleItem(template)
        .then((res) => this.newSessionAddedTemplates.splice(index, 1));
    } else {
      this.newSessionAddedTemplates.splice(index, 1);
    }
  }

  // display methods
  buildSubscriptionOptions() {
    const sessions = this.sessions.getSessions();
    const prodSessions = Object.keys(sessions).reduce(
      (a, c) => ((a[`session_${c}`] = sessions[c]), a),
      {}
    );
    const bundles = this.sessions.getBundles();
    const prodBundles = Object.keys(bundles).reduce(
      (a, c) => ((a[`bundle_${c}`] = bundles[c]), a),
      {}
    );

    return {
      ...prodSessions,
      ...prodBundles,
    };
  }
  buildSessionParams() {
    const price = this.newSessionPrice * 100;
    const salePrice = this.newSessionPriceSale
      ? this.newSessionPriceSale * 100
      : price;

    const modalType = this.statusModal.open
      ? this.statusModal.item.productType
      : this.modal.type;

    const objName = this.getSessionTypeKey(modalType);
    let params = {
      [`token_${objName}`]: {
        advertised_student_cost: price,
        base_student_cost: salePrice,
        description: this.newSessionDesc,
        sensei_id: this.currentUser.sensei.id,
      },
    };

    if (modalType.includes("session")) {
      // specific params for individual templates
      params = {
        [`token_${objName}`]: {
          ...params[`token_${objName}`],
          name: this.newSessionName,
          display_name: this.newSessionName,
          game_id: this.userGame.id,
          token_type_id: this.newSessionType.id,
          token_tier_id: this.templateTier.id,
          publicly_saleable: this.newSessionActive,
          expiration_days: 90,
          duration_minutes: this.newSessionLength,
        },
      };
    } else if (modalType.includes("bundle")) {
      // specific params for bundles
      params = {
        [`token_${objName}`]: {
          ...params[`token_${objName}`],
          name: this.newSessionName,
          active: this.newSessionActive,
        },
      };
    } else if (modalType.includes("subscription")) {
      // specific params for subs
      params = {
        [`token_${objName}`]: {
          ...params[`token_${objName}`],
          plan_name: this.newSessionName,
          recurring_unit: this.newSessionProductRecurring,
          recurring_cycle_number: this.newSessionProductQty,
          publicly_purchasable: this.newSessionActive,
          expiration_days: 180,
          welcome_message: this.newSessionWelcome,
        },
      };

      if (this.newSessionProduct.productType === "session") {
        params[`token_${objName}`].token_template_id =
          this.newSessionProduct.id;
      } else if (this.newSessionProduct.productType === "bundle") {
        params[`token_${objName}`].token_bundle_id = this.newSessionProduct.id;
      }
    }

    if (modalType.includes("create_bundle")) {
      const tokenBundleItems = this.newSessionAddedTemplates.map((template) => {
        return { token_template_id: template.id, quantity: template.quantity };
      });

      params = {
        [`token_${objName}`]: {
          ...params[`token_${objName}`],
        },
        token_bundle_items: tokenBundleItems,
      };
    }

    return params;
  }

  getSalePrice(baseCost, advertisedCost) {
    if (baseCost >= advertisedCost) return advertisedCost / 100;

    return baseCost / 100;
  }

  getSaleLabel(item) {
    if (item.base_student_cost !== item.advertised_student_cost) {
      return "Remove Sale";
    }

    return "Create Sale";
  }

  getCurrentSession() {
    const current = this.getProductInUse();
    const name = current.name;

    return name;
  }

  getModalLabel(type, create) {
    const createType = create ? "Create a new" : "Update";

    return this.modal.sale ? `${type} sale` : `${createType} ${type}`;
  }

  getModalType(type, create) {
    const prependLabel = create ? "create" : "update";

    return `${prependLabel}_${type}`;
  }

  getOmnibarLabel() {
    switch (this.modal.type) {
      case "session_create":
        return "Session created!";
      case "session_update":
        return "Session updated!";
      case "bundle_create":
        return "Bundle created!";
      case "bundle_update":
        return "Bundle updated!";
      case "subscription_create":
        return "Subscription created!";
      case "subscription_update":
        return "Subscription updated!";
      default:
        return "Session updated!";
    }
  }

  getOmnibarMessage() {
    switch (this.modal.type) {
      case "session_create":
        return `<p>This session is now a part of your offerings.</p>`;
      case "session_update":
        return `<p>This session has been updated.</p>`;
      case "bundle_create":
        return `<p>This bundle is now a part of your offerings.</p>`;
      case "bundle_update":
        return `<p>This bundle has been updated.</p>`;
      case "subscription_create":
        return `<p>This subscription is now a part of your offerings.</p>`;
      case "subscription_update":
        return `<p>This subscription has been updated.</p>`;
      default:
        return `<p>This session has been updated.</p>`;
    }
  }

  getSessionTypeKey(key) {
    if (key.includes("subscription")) {
      return "subscription_plan";
    } else if (key.includes("bundle")) {
      return "bundle";
    } else {
      return "template";
    }
  }

  getBundleItemErrorText() {
    if (this.isThereNotEnoughBundleItems()) {
      return "There must be at least 2 sessions on a bundle.";
    }
    if (this.isThereNoBundleItemSubmission()) {
      return "Don't forget to add your session.";
    }

    return null;
  }

  isThisASaleInput() {
    const current = this.getProductInUse();

    if (
      this.modal.sale &&
      current.base_student_cost === current.advertised_student_cost
    ) {
      return true;
    }

    return false;
  }

  isThisARemoveSaleInput() {
    const current = this.getProductInUse();

    if (
      this.modal.sale &&
      current.base_student_cost !== current.advertised_student_cost
    ) {
      return true;
    }

    return false;
  }

  isThisASingleSessionInput() {
    if (this.modal.sale) return false;
    if (this.modal.type.includes("session")) return true;

    return false;
  }

  isThisABundleInput() {
    if (this.modal.sale) return false;

    if (this.modal.type.includes("bundle")) return true;

    return false;
  }

  isThisASubscriptionInput() {
    if (this.modal.sale) return false;

    if (this.modal.type.includes("subscription")) return true;

    return false;
  }

  isAddButtonDisabled() {
    return (
      !this.newSessionTemplateQty > 0 || !this.newSessionTemplate?.display_name
    );
  }

  isSaveButtonDisabled() {
    if (this.modal.type.includes("bundle")) {
      return (
        this.newSession.$invalid ||
        this.newSessionAddedTemplates.length === 0 ||
        this.isThereNotEnoughBundleItems()
      );
    }

    return this.newSession.$invalid;
  }

  isThereNotEnoughBundleItems() {
    return (
      this.newSessionAddedTemplates.length === 1 &&
      this.newSessionAddedTemplates[0].quantity === 1
    );
  }

  isThereNoBundleItemSubmission() {
    return (
      (this.newSession.template.$valid || this.newSession.qty.$valid) &&
      (this.newSession.template.$touched || this.newSession.qty.$touched) &&
      (!this.newSession.template.$pristine || !this.newSession.qty.$pristine) &&
      (this.newSession.template.$dirty || this.newSession.qty.$dirty) &&
      this.newSessionTemplate?.display_name &&
      this.newSessionTemplateQty >= 1
    );
  }

  isThereAPriceReduction(session) {
    return session.base_student_cost < session.advertised_student_cost;
  }

  showTextCount() {
    const charsTyped = this.newSession.description.$viewValue;
    return `${charsTyped?.length ?? 0}/${this.maxLength}`;
  }

  displayTextWarning() {
    const charsTyped = this.newSession.description.$viewValue;

    return this.maxLength - charsTyped?.length <= 20;
  }

  isNotActive(item) {
    return (
      !item.publicly_saleable && !item.active && !item.publicly_purchasable
    );
  }

  getNewStatusLabel(item) {
    if ("publicly_saleable" in item) {
      return item.publicly_saleable ? "deactivate" : "reactivate";
    } else if ("publicly_purchasable" in item) {
      return item.publicly_purchasable ? "deactivate" : "reactivate";
    } else if ("active" in item) {
      return item.active ? "deactivate" : "reactivate";
    }

    return "activate";
  }

  getSessionNameFromBundleItem(item) {
    const currentSessions = this.sessions.getSessions();

    return currentSessions[`id_${item.token_template_id}`]
      ? currentSessions[`id_${item.token_template_id}`].name
      : "";
  }

  // internal methods
  checkIfSessionExists() {
    if (!this.newSessionAddedTemplates.length) return false;

    const match = this.newSessionAddedTemplates.find((item) => {
      return item.id === this.newSessionTemplate.id;
    });

    return !!match;
  }

  getPriceMax() {
    return this.newSessionPriceSale
      ? this.newSessionPrice
      : this.defaultPriceMax *
          (this.newSessionProductQty ?? 1) *
          (this.newSessionTemplateQty ?? 1);
  }

  getProductInUse() {
    let items;

    if (this.modal.type.includes("session")) {
      items = this.sessions.getSessions();
    } else if (this.modal.type.includes("bundle")) {
      items = this.sessions.getBundles();
    } else {
      items = this.sessions.getSubscriptions();
    }

    const current = items[`id_${this.currentSelectedId}`];

    return current;
  }
}

SenseiAccountSessionOfferingsController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "User",
  "OmnibarPointer",
  "SessionTypes",
  "SenseiSessionsManager",
  "ModalManager",
];
