export default function referralPolicyService($http) {
  var referralService = {
    getByStatus: function(config) {
      return $http.get('/api/web/referral_policies', config);
    },
    getById: function(id, data) {
      return $http.get('/api/web/referral_policies/' + id, data);
    },
    getStats: function(data) {
      return $http.post('/api/web/referer_referral_statistics', data);
    },
    launch: function(data) {
      return $http.post('/api/web/referral_policy_launches', data);
    }
  };
  return referralService;
}

referralPolicyService.$inject = ['$http'];