import navBar from "../../../templates/includes/nav/simple_nav_bar";
import { gsLogoStacked } from "../../../config/brand/brandConfig";

export default class PartnerConnectContainerController {
  constructor($scope, $window, User, OmnibarPointer, UIFeatureGate) {
    this.userId = $scope.currentId;
    this.role = $scope.role;
    this.navBar = navBar;
    this.gsLogoStacked = gsLogoStacked;
    this.OmnibarPointer = OmnibarPointer;
    this.userService = User;

    this.matcherinoReady =
      UIFeatureGate.data.ui_feature_gates.matcherino_landing;

    if (!this.matcherinoReady) {
      $window.location.href = "/";
    }

    this.initUser();
  }

  initUser() {
    if (this.userId) {
      this.userService.setCurrentId(this.userId);
      this.userService.setRole(this.role);
      this.userService
        .getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }
}
