export default function favoritesService($http) {

  var service = {
    addFavorite: addFavorite,
    removeFavorite: removeFavorite,
    checkFavorite: checkFavorite
  };

  return service;

  ///////////

  function addFavorite(data) {
    return $http.post('/api/web/gamer_favorited_senseis', data);
  }

  function removeFavorite(id) {
    return $http.delete('/api/web/gamer_favorited_senseis/' + id);
  }

  function checkFavorite(sensei_id, gamer_id, game_name) {
    return $http.get('/api/web/gamer_favorited_senseis?sensei_id=' + sensei_id + '&gamer_id=' + gamer_id + '&game_name=' + game_name);
  }

}

favoritesService.$inject = ['$http'];
