import dashboardModulesApp from "./gs-dashboard-modules";
import { uiFeatureGate, getGames } from "../config/utils/utilsConfig";

const dashboardSenseiApp = angular.module("gamerSenseiSenseiDashboard", [
  dashboardModulesApp.name,
]);

// controllers and templates
import ChatsListController from "../controllers/dashboard/user/chats/chats-list-controller";
import ChatsListTemplate from "../templates/dashboard/user/chats/chats_list_template";

import ChatsPageController from "../controllers/dashboard/user/chats/chats-page-controller";
import ChatsPageTemplate from "../templates/dashboard/user/chats/chats_page_template";

import SenseiAccountContactController from "../controllers/dashboard/sensei/account/sensei-acount-contact-controller";
import SenseiAccountContactTemplate from "../templates/dashboard/sensei/account/sensei_account_contact_template";

import SenseiAccountNotificationsController from "../controllers/dashboard/sensei/account/sensei-account-notifications-controller";
import SenseiAccountNotificationsTemplate from "../templates/dashboard/sensei/account/sensei_account_notifications_template";

import SenseiAccountProfileController from "../controllers/dashboard/sensei/account/sensei-account-profile-controller";
import SenseiAccountProfileTemplate from "../templates/dashboard/sensei/account/sensei_account_profile_template";

import SenseiAccountScheduleController from "../controllers/dashboard/sensei/account/sensei-account-schedule-controller";
import SenseiAccountScheduleTemplate from "../templates/dashboard/sensei/account/sensei_account_schedule_template";

import SenseiAccountSessionOfferingsController from "../controllers/dashboard/sensei/account/sensei-acount-session-offerings-controller";
import SenseiAccountSessionOfferingsTemplate from "../templates/dashboard/sensei/account/sensei_account_session_offerings_template";

import SenseiAccountSubscriptionPlansController from "../controllers/dashboard/sensei/account/sensei-acount-subscription-plans-controller";
import SenseiAccountSubscriptionPlansTemplate from "../templates/dashboard/sensei/account/sensei_account_subscription_plans_template";

import SenseiDashboardContainerController from "../controllers/dashboard/sensei/sensei-dashboard-container-controller";
import SenseiDashboardContainerTemplate from "../templates/dashboard/sensei/sensei_dashboard_container_template";

import SenseiDashboardLandingTemplate from "../templates/dashboard/sensei/sensei_dashboard_landing_template";

import SenseiLessonsCalendarController from "../controllers/dashboard/sensei/lessons/sensei-lessons-calendar-controller";
import SenseiLessonsCalendarTemplate from "../templates/dashboard/sensei/lessons/sensei_lessons_calendar_template";

import SenseiLessonsNotesListController from "../controllers/dashboard/sensei/lessons/sensei-lessons-notes-list-controller";
import SenseiLessonsNotesListTemplate from "../templates/dashboard/sensei/lessons/sensei_lessons_notes_list_template";

import SenseiLessonsReviewsController from "../controllers/dashboard/sensei/lessons/sensei-lessons-reviews-controller";
import SenseiLessonsReviewsTemplate from "../templates/dashboard/sensei/lessons/sensei_lessons_reviews_template";

import SenseiLessonsStudentHistoryController from "../controllers/dashboard/sensei/lessons/sensei-lessons-student-history-controller";
import SenseiLessonsStudentHistoryTemplate from "../templates/dashboard/sensei/lessons/sensei_lessons_student_history_template";

import SenseiLessonsSubscribersController from "../controllers/dashboard/sensei/lessons/sensei-lessons-subscriber-controller";
import SenseiLessonsSubscribersTemplate from "../templates/dashboard/sensei/lessons/sensei_lessons_subscribers_template";

import SenseiWalletCashoutController from "../controllers/dashboard/sensei/wallet/sensei-wallet-cashout-controller";
import SenseiWalletCashoutLandingTemplate from "../templates/dashboard/sensei/wallet/sensei_wallet_cashout_landing_template";
import SenseiWalletCashoutConfirmTemplate from "../templates/dashboard/sensei/wallet/sensei_wallet_cashout_confirm_template";

import SenseiWalletController from "../controllers/dashboard/sensei/wallet/sensei-wallet-controller";

import SenseiWalletHistoryController from "../controllers/dashboard/sensei/wallet/sensei-wallet-history-controller";
import SenseiWalletHistoryTemplate from "../templates/dashboard/user/wallet/wallet_history_template";

import LessonCardListController from "../controllers/dashboard/user/lessons/lessons-card-list-controller";
import LessonCardListTemplate from "../templates/dashboard/user/lessons/lessons_card_list_template";

import LessonsProposeTimeController from "../controllers/dashboard/user/lessons/lessons-propose-time-controller";
import LessonsProposeTimeTemplate from "../templates/dashboard/user/lessons/lessons_propose_time_template";

import LessonsNotesPageController from "../controllers/dashboard/user/lessons/lessons-notes-page-controller";
import LessonsNotesPageTemplate from "../templates/dashboard/user/lessons/lessons_notes_page_template";

import VerifyUserEmailController from "../controllers/dashboard/user/verify-user-email-controller";
import VerifyUserEmailTemplate from "../templates/dashboard/user/verify_user_email_template";

dashboardSenseiApp.config(configSenseiDashboard);

function configSenseiDashboard($stateProvider, $qProvider) {
  $qProvider.errorOnUnhandledRejections(false);
  $stateProvider
    .state("sensei_dashboard", {
      abstract: true,
      url: "/sensei/dashboard",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        pulledGames: getGames,
      },
      controller: SenseiDashboardContainerController,
      controllerAs: "vm",
      template: SenseiDashboardContainerTemplate,
    })
    .state("sensei_dashboard.root", {
      url: "/",
      redirectTo: "sensei_dashboard.landing",
    })
    .state("sensei_dashboard.landing", {
      url: "",
      resolve: {
        ActionableCount: ActionableCount,
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsCalendarController,
      controllerAs: "vm",
      template: SenseiDashboardLandingTemplate,
      ncyBreadcrumb: {
        label: "Dashboard",
      },
    })
    // LESSONS SECTION
    .state("sensei_dashboard.lessons.root", {
      url: "",
      redirectTo: "sensei_dashboard.lessons.list",
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons", {
      abstract: true,
      url: "/lessons",
      defaultChild: "sensei_dashboard.wallet.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
    })
    .state("sensei_dashboard.lessons.list", {
      url: "/list?date&status&game&gamer",
      resolve: {
        ActionableCount: ActionableCount,
        UIFeatureGate: uiFeatureGate,
      },
      controller: LessonCardListController,
      controllerAs: "vm",
      template: LessonCardListTemplate,
      ncyBreadcrumb: {
        label: "List",
        parent: "sensei_dashboard.lessons.root",
      },
    })
    .state("sensei_dashboard.lessons.calendar", {
      url: "/calendar",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsCalendarController,
      controllerAs: "vm",
      template: SenseiLessonsCalendarTemplate,
      ncyBreadcrumb: {
        label: "Calendar",
        parent: "sensei_dashboard.lessons.root",
      },
    })
    .state("sensei_dashboard.lessons.my_reviews", {
      url: "/my-reviews",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsReviewsController,
      controllerAs: "vm",
      template: SenseiLessonsReviewsTemplate,
      ncyBreadcrumb: {
        label: "My Reviews",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons.sensei_notes", {
      url: "/notes?studentName&tokenType",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsNotesListController,
      controllerAs: "vm",
      template: SenseiLessonsNotesListTemplate,
      ncyBreadcrumb: {
        label: "Sensei Notes",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons.sensei_note_page", {
      url: "/notes/:lessonId",
      controller: LessonsNotesPageController,
      controllerAs: "vm",
      template: LessonsNotesPageTemplate,
      ncyBreadcrumb: {
        label: "{{$stateParams.lessonId}}",
        parent: "sensei_dashboard.lessons.sensei_notes",
      },
    })
    .state("sensei_dashboard.lessons.sensei_chats", {
      url: "/chats",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: ChatsListController,
      controllerAs: "vm",
      template: ChatsListTemplate,
      ncyBreadcrumb: {
        label: "Sensei Chats",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons.sensei_chat_page", {
      url: "/chat/:lessonId",
      controller: ChatsPageController,
      controllerAs: "vm",
      template: ChatsPageTemplate,
      ncyBreadcrumb: {
        label: "{{$stateParams.lessonId}}",
        parent: "sensei_dashboard.lessons.sensei_chats",
      },
    })
    .state("sensei_dashboard.lessons.propose_lesson_time", {
      url: "/propose-lesson-time",
      controller: LessonsProposeTimeController,
      controllerAs: "vm",
      template: LessonsProposeTimeTemplate,
      ncyBreadcrumb: {
        label: "Lessons",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons.student_history", {
      url: "/students?studentName",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsStudentHistoryController,
      controllerAs: "vm",
      template: SenseiLessonsStudentHistoryTemplate,
      ncyBreadcrumb: {
        label: "My Students",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.lessons.subscribers", {
      url: "/subscribers?studentName&plan",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiLessonsSubscribersController,
      controllerAs: "vm",
      template: SenseiLessonsSubscribersTemplate,
      ncyBreadcrumb: {
        label: "My Students",
        parent: "sensei_dashboard.landing",
      },
    })
    // WALLET SECTION
    .state("sensei_dashboard.wallet", {
      abstract: true,
      url: "/wallet",
      defaultChild: "sensei_dashboard.wallet.root",
      template: '<div class="view-wrapper" ui-view=""></div>',
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiWalletController,
      controllerAs: "vm",
    })
    .state("sensei_dashboard.wallet.root", {
      url: "",
      redirectTo: "sensei_dashboard.wallet.cashout",
      ncyBreadcrumb: {
        label: "Wallet",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.wallet.cashout", {
      url: "/",
      controller: SenseiWalletCashoutController,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controllerAs: "vm",
      template: SenseiWalletCashoutLandingTemplate,
      ncyBreadcrumb: {
        label: "Cashout",
        parent: "sensei_dashboard.wallet.root",
      },
    })
    .state("sensei_dashboard.wallet.cashout.confirm", {
      url: "/confirm",
      template: SenseiWalletCashoutConfirmTemplate,
      forcePreviousState: ["sensei_dashboard.wallet.cashout"],
      modal: true,
    })
    .state("sensei_dashboard.wallet.history", {
      url: "/history?date",
      controller: SenseiWalletHistoryController,
      controllerAs: "vm",
      template: SenseiWalletHistoryTemplate,
      ncyBreadcrumb: {
        label: "History",
        parent: "sensei_dashboard.wallet.root",
      },
    })
    // ACCOUNT SECTION
    .state("sensei_dashboard.profile", {
      url: "/profile",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountProfileController,
      controllerAs: "vm",
      template: SenseiAccountProfileTemplate,
      ncyBreadcrumb: {
        label: "Profile",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.contact_information", {
      url: "/contact-info",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountContactController,
      controllerAs: "vm",
      template: SenseiAccountContactTemplate,
      ncyBreadcrumb: {
        label: "Contact Information",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.availability", {
      url: "/availability",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountScheduleController,
      controllerAs: "vm",
      template: SenseiAccountScheduleTemplate,
      ncyBreadcrumb: {
        label: "Schedule",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.session_offerings", {
      url: "/offerings",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountSessionOfferingsController,
      controllerAs: "vm",
      template: SenseiAccountSessionOfferingsTemplate,
      ncyBreadcrumb: {
        label: "Session Offerings",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.subscription_plans", {
      url: "/subscription-plans",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountSubscriptionPlansController,
      controllerAs: "vm",
      template: SenseiAccountSubscriptionPlansTemplate,
      ncyBreadcrumb: {
        label: "Subscription Plans",
        parent: "sensei_dashboard.landing",
      },
    })
    .state("sensei_dashboard.notifications", {
      url: "/notifications",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controller: SenseiAccountNotificationsController,
      controllerAs: "vm",
      template: SenseiAccountNotificationsTemplate,
      ncyBreadcrumb: {
        label: "Notifications",
        parent: "sensei_dashboard.landing",
      },
    })
    // VERIFY EMAIL
    .state("sensei_dashboard.verify_email", {
      url: "/verify",
      controller: VerifyUserEmailController,
      controllerAs: "vm",
      template: VerifyUserEmailTemplate,
      ncyBreadcrumb: {
        label: "Verify Email",
        parent: "sensei_dashboard.landing",
      },
    });
}

configSenseiDashboard.$inject = ["$stateProvider", "$qProvider"];

// Lesson Counts
function ActionableCount(ActionableLessons) {
  var config = { params: { status: "actionable" } };
  return ActionableLessons.getCounts(config)
    .then((res) => {
      return res.data.count;
    })
    .catch(angular.noop);
}
ActionableCount.$inject = ["ActionableLessons"];

export default dashboardSenseiApp;
