import moment from "moment-timezone";
import {
  setGamerMetaTitle,
  sidebarMenuGamer,
  userIsOnProfile,
} from "../../../config/dashboard/dashboardConfig";

export default class GamerDashboardContainerController {
  constructor(
    $scope,
    $state,
    $location,
    $anchorScroll,
    $rootScope,
    UserOS,
    DirectTrainingRequestSchedule,
    SenseiTrainingRequestManager,
    OmnibarPointer,
    User,
    TokenBookingService,
    MetaTagsService,
    NotificationManager,
    EventTrackingManager,
    pulledGames,
    UIFeatureGate
  ) {
    this.gigya = $scope.isGigyaOn;
    this.userId = $scope.currentId;
    this.role = "gamer";
    this.winOS = $scope.os_windows;
    this.winISA = $scope.windows_isa;
    // angular providers
    this.state = $state;
    this.$anchorScroll = $anchorScroll;
    this.currentStateName = $state.current.name;
    // services
    this.userService = User;
    this.userOS = UserOS;
    this.tokenBooking = TokenBookingService;
    this.omnibarPointer = OmnibarPointer;
    this.notifications = NotificationManager;
    this.eventTracking = EventTrackingManager;
    this.strManager = SenseiTrainingRequestManager;
    // injected and imported
    this.games = pulledGames.data.games;

    this.subscriptionsOn =
      UIFeatureGate.data.ui_feature_gates.gs_version_3_subscriptions;

    $scope.isGamer = true;
    $scope.userDashboard = true;
    $scope.proposeToPerson = null;
    $rootScope.proposeTimeSubsequentStepLink = "gamer/dashboard";

    this.gamerMenu = sidebarMenuGamer(this.subscriptionsOn);

    this.strManager.setRole(this.role);
    this.initBrowser();
    this.initEventTracking($state.current.url);
    this.initMetaTags(MetaTagsService);
    this.initStateChange($anchorScroll, $rootScope, MetaTagsService);

    this.initUser({
      firstRunCheck: (firstRun) => {
        $rootScope.firstRun = firstRun;
      },
      omniTooltipCheck: (userAlert) => this.omniTooltipCheck(userAlert),
      setTokenBooking: () => this.tokenBooking.setTokenDeposits(),
      setNotifications: () => this.notifications.setNotifications(),
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;

        if (callbacks?.firstRunCheck)
          callbacks.firstRunCheck(userData.first_run);
        if (callbacks?.omniTooltipCheck)
          callbacks.omniTooltipCheck(
            userData.omni_tooltips["/gamer/dashboard"]
          );
        if (callbacks?.setTokenBooking) callbacks.setTokenBooking();
        if (callbacks?.setNotifications) callbacks.setNotifications();
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }
  initEventTracking(currentUrl) {
    if (userIsOnProfile(currentUrl)) {
      this.eventTracking.pushViewEvent("Profile");
    } else {
      this.eventTracking.pushViewEvent("Dashboard");
    }
  }
  initMetaTags(MetaTagsService) {
    const metaTitle = setGamerMetaTitle(this.currentStateName);
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initStateChange($anchorScroll, $rootScope, MetaTagsService) {
    $rootScope.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams, options) => {
        $anchorScroll();
        this.currentStateName = toState.name;
        const newMetaTitle = setGamerMetaTitle(toState.name);
        MetaTagsService.setTags({
          title: newMetaTitle,
          "og:title": newMetaTitle,
          "twitter:title": newMetaTitle,
        });
        if (userIsOnProfile(toState.url)) {
          this.eventTracking.pushViewEvent("Profile");
        } else {
          this.eventTracking.pushViewEvent("Dashboard");
        }
      }
    );
  }
  initBrowser() {
    this.userOS.setOS(this.winOS);
    this.userOS.setWinIsa(this.winISA);
  }

  // internal methods
  omniTooltipCheck(userAlert) {
    if (userAlert) {
      this.omnibarPointer.pushTooltip(userAlert[0], { persist: true });
      this.$anchorScroll();
    }
  }
  showUserNotVerifiedDisplay() {
    return (
      this.currentUser &&
      !this.currentUser.is_email_verified &&
      this.currentStateName !== "gamer_dashboard.verify_email" &&
      this.gigya
    );
  }
}

GamerDashboardContainerController.$inject = [
  "$scope",
  "$state",
  "$location",
  "$anchorScroll",
  "$rootScope",
  "UserOS",
  "DirectTrainingRequestSchedule",
  "SenseiTrainingRequestManager",
  "OmnibarPointer",
  "User",
  "TokenBookingService",
  "MetaTagsService",
  "NotificationManager",
  "EventTrackingManager",
  "pulledGames",
  "UIFeatureGate",
];
