import cartTemplate from "../../templates/directives/cart/cart_drawer_template.html";

export default function cartDrawerDirective(
  $location,
  $stateParams,
  CartManager
) {
  return {
    restrict: "E",
    scope: {
      games: "=",
      user: "=",
    },
    template: cartTemplate,
    link: (scope, elem, attrs) => {
      scope.cart = CartManager;
      scope.adjustCartQuantity = function (calc, item) {
        var newQty = item.quantity;
        var qtyChange = true;
        if (calc === "add") {
          newQty = newQty + 1;
        } else if (calc === "subtract") {
          qtyChange = newQty - 1 === 0 ? false : true;
          newQty = newQty > 1 ? newQty - 1 : 1;
        }
        if (qtyChange) {
          scope.cart.changeCartItemQuantity(item, newQty);
        }
      };

      scope.isCheckoutDisabled = function () {
        if (scope.user && scope.user.sensei) {
          return true;
        }

        return false;
      };

      scope.canQtyChange = function (item) {
        return (
          scope.cart.getStatus() !== "complete" &&
          !item.token_type?.includes("masterclass") &&
          !item.subscribed &&
          item.itemType !== "plan"
        );
      };

      scope.getSubscription = function (item) {
        return item.subscription.find((sub) => sub.subscribed);
      };

      scope.getSubscriptionName = function (item) {
        const subscription = scope.getSubscription(item);

        return subscription.plan_name;
      };

      scope.getSubscriptionRecurText = function (item) {
        const subscription = scope.getSubscription(item);

        return `${
          subscription.recurring_cycle_number === 1
            ? "once a"
            : `${subscription.recurring_cycle_number}x a`
        } ${subscription.recurring_unit}`;
      };

      scope.getSubscriptionCost = function (item) {
        const subscription = scope.getSubscription(item);
        const baseCost = (subscription.base_student_cost * item.quantity) / 100;

        return `$${baseCost} / ${subscription.recurring_unit}ly`;
      };

      scope.getCurrentGameName = function (game) {
        var gameName = scope.games.filter((res) => {
          return res.slug === game || res.name === game;
        });
        return gameName && gameName[0].name;
      };

      scope.getDisplayCartSubTotal = function () {
        return scope.cart.getCartSubTotal() !== scope.cart.getCartTotal();
      };

      scope.showTierOnCartItem = function (tier) {
        if (tier.toLowerCase().includes("skill")) {
          return false;
        }
        return true;
      };

      scope.getCartItemTier = function (item) {
        if (item.token_tier || item.token_tier_data) {
          var tierName = item.token_tier_data
            ? item.token_tier_data.name
            : item.token_tier;

          if (tierName.toLowerCase().includes("legendary")) {
            return "Legendary";
          }

          return tierName;
        }

        return null;
      };

      scope.getCost = function (item, type) {
        const newItem = item.subscribed ? scope.getSubscription(item) : item;
        if (type === "advertised") {
          return newItem.advertised_student_cost;
        } else if (type === "base") {
          return newItem.base_student_cost;
        }
      };

      scope.showCartCloseButton = function () {
        return scope.cart.canCartClose();
      };

      scope.formatCheckoutUrl = function (url) {
        var refParam = $location.search() && $location.search().ref;
        var stateRef = $stateParams.slug;

        if (refParam) {
          return `${url}?ref=${refParam}`;
        } else if (stateRef) {
          return `${url}?ref=${stateRef}`;
        }

        return url;
      };
    },
  };
}

cartDrawerDirective.$inject = ["$location", "$stateParams", "CartManager"];
