import howItWorksImg from "../../images/sections/gs_how_it_works";
import whoGetsTeamCoachingImg from "../../images/backgrounds/handshake";
import {
  checkListIcon,
  communicationIcon,
  teamIcon,
  nextIcon,
} from "../../config/icons/iconConfig";

export default class TeamCoachingPageController {
  constructor(MetaTagsService, TestimonialUtils) {
    this.howItWorksImg = howItWorksImg;
    this.icons = { checkListIcon, communicationIcon, teamIcon, nextIcon };
    this.testimonials = TestimonialUtils.customers;
    this.testimonialConfig = {
      enabled: true,
      dots: false,
      arrows: false,
      speed: 600,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      method: {},
    };
    this.whoGetsTeamCoaching = {
      image: whoGetsTeamCoachingImg,
      header: '<h4 class="text-light">Who gets team coaching?</h4>',
      body: '<p>Looking to increase your win rate or go pro with friends? Team Coaching is for you.</p><p>Your personal Sensei will work closely with your squad to determine specific goals and solutions for your team dynamics and skills.</p><p>We work with colleges, clubs, and official organizations, too! For more on our collegiate offerings, please <a href="/colleges" title="Gamer Sensei\'s college offerings">CLICK HERE</a></p>',
      position: "right",
      transparentBg: true,
      cta: {
        text: "Book your session",
        url: "/games",
      },
    };

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Team Coaching | Gamer Sensei";
    const metaDescription =
      "Take your skills to the next level with a professional coach from Gamer Sensei.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

TeamCoachingPageController.$inject = ["MetaTagsService", "TestimonialUtils"];
