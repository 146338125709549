import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class GamesLandingContainerController {
  constructor($rootScope, $window, $state, pulledGames, OmnibarPointer) {
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;
    this.OmnibarPointer = OmnibarPointer;
    this.games = pulledGames.data.games;
    this.displayFullMenu = false;
    this.displaySimpleMenu = true;

    // decide menu on state change
    $rootScope.$on(
      "$stateChangeSuccess",
      function (event, toState, toParams, fromState, fromParams, options) {
        $window.scrollTo(0, 0);
        this.checkMenuDisplay(toState.name);
      }
    );

    // decide menu on container load
    this.checkMenuDisplay($state.current.name);
  }

  checkMenuDisplay(stateName) {
    // hide full menu if on individual game page
    if (stateName === "games_landing.all") {
      this.displayFullMenu = true;
      this.displaySimpleMenu = false;
    } else {
      this.displayFullMenu = false;
      this.displaySimpleMenu = true;
    }
  }
}

GamesLandingContainerController.$inject = [
  "$rootScope",
  "$window",
  "$state",
  "pulledGames",
  "OmnibarPointer",
];
