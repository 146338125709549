import uploadPhotoLoadingPartial from "../../../../templates/includes/access/upload_photo_loading_partial";
import {
  doEntriesMatch,
  getSelectedAttributesCategories,
  isProfileValid,
} from "../../../../config/utils/utilsConfig";

export default class SenseiAccountProfileController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $state,
    User,
    SenseiProfile,
    OmnibarPointer,
    UIFeatureGate,
    GamePublisher,
    LocaleSelection,
    GamingAttributes
  ) {
    $rootScope.bodylayout = "sensei profile";
    $rootScope.role = "sensei";
    this.userId = $scope.currentId;
    this.role = "sensei";
    // angularjs injections
    this.state = $state;
    this.window = $window;
    this.root = $rootScope;
    this.timeout = $timeout;
    this.featureGate = UIFeatureGate;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.userService = User;
    this.gamePublisher = GamePublisher;
    this.senseiProfile = SenseiProfile;
    this.gamingAttributesService = GamingAttributes;
    // create autocomplete functionality
    this.allLanguages = LocaleSelection.allLanguages;
    // imported functionality
    this.isProfileValid = isProfileValid;
    this.uploadPhotoLoadingPartial = uploadPhotoLoadingPartial;
    // strings
    this.helperText = "Edit your profile and settings";
    // booleans
    this.loggedInAsAdmin = $scope.logged_in_as_admin;
    this.combineName = false;
    this.validPhoto = false;
    // objects and arrays
    this.gamingAttributes = [];
    this.gameIds = [];
    this.publisherConnections = {};
    // feature gates
    this.isSenseiDiscountEnrollAvailable =
      this.featureGate.data.ui_feature_gates.sensei_discount_enroll;
    this.isStoreValueAvailable =
      this.featureGate.data.ui_feature_gates.points_to_store_value;
    this.canSenseiAddLanguages =
      this.featureGate.data.ui_feature_gates.sensei_add_languages;
    this.senseiEditGamingAttributes =
      this.featureGate.data.ui_feature_gates.sensei_edit_gaming_attributes;

    this.disabledProfileItem = function () {
      return !this.loggedInAsAdmin && this.activeProfile;
    };

    this.window.publisherSigninSuccess = (publisher) => {
      this.gamePublisher.trackSigninSuccess(publisher);
      this.refreshPublisherConnections();
      this.publisherConnections[publisher] = true;
    };

    this.initUser({
      profilePicSetup: () => this.userProfilePicSetup(),
      gamingAttributes: (currentUserGame) =>
        this.getGamingAttributes(currentUserGame),
      firstRun: (userData) => {
        $rootScope.firstRun = userData.first_run;

        if ($rootScope.firstRun) this.setUpGameData(userData.sensei.games);
      },
      omnibar: () => {
        if (this.currentUser.omni_tooltips["/sensei/dashboard"]) {
          this.omnibarPointer.pushTooltip(
            this.currentUser.omni_tooltips["/sensei/dashboard"][0]
          );
        } else if (this.userService.getSaveStatus()) {
          const omniTooltipParams = {
            title: "Profile updated",
            body: "<p>You have successfully saved your profile.</p>",
          };
          this.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            persist: true,
            timer: true,
          });
          this.userService.setSaveStatus(false);
        }
      },
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.activeProfile = this.currentUser.sensei.status === "active";
        this.currentUser.languages = [];
        this.senseiStartDate = moment(
          userData.sensei.start_date,
          "YYYY-MM-DD"
        ).format("MMMM D, YYYY");

        if (callbacks?.profilePicSetup) callbacks.profilePicSetup();

        if (callbacks?.gamingAttributes)
          callbacks.gamingAttributes(userData.sensei.games[0]);

        if (userData.first_run && callbacks?.firstRun)
          callbacks.games(userData);

        if (callbacks?.omnibar) callbacks.omnibar();
      })
      .catch(angular.noop);
  }

  // methods for editing and saving profile
  uploadPhotoToggle() {
    document.getElementById("uploadPhotoInput").click();
  }

  // save profile
  updateSensei() {
    this.loadingProfileSave = true;
    const sensei_id = this.currentUser.sensei.id;

    if (this.currentUser.games.length === 0) {
      this.omnibarPointer.pushErrorByMessage(
        "Oops! Please select the game you would like to coach."
      );
      return;
    } else if (this.currentUser.firstRun && !this.profilePic) {
      this.omnibarPointer.pushErrorByMessage(
        "Oops! Please upload a profile photo."
      );
      return;
    }

    const params = this.saveSenseiProfileParams();

    return this.senseiProfile
      .update(sensei_id, params)
      .then((res) => {
        this.root.$broadcast("uploadProfilePic", {
          profilePic: this.profilePic,
        });

        this.timeout(() => {
          this.loadingProfileSave = false;
          this.window.scrollTo(0, 0);
          this.userService.setSaveStatus(true);
          this.state.reload();
        }, 100);
      })
      .catch((err) => {
        this.loadingProfileSave = false;
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  saveSenseiProfileParams() {
    const sortedLanguages = [];

    for (let language in this.currentUser.sensei.languages) {
      sortedLanguages.push(this.currentUser.sensei.languages[language].slug);
    }

    const senseiProfileParams = {
      id: this.currentUser.sensei.id,
      game_id: this.currentUser.games[0].id,
      sensei: {
        first_name: this.currentUser.first_name,
        last_name: this.currentUser.last_name,
        full_name:
          this.currentUser.first_name + " " + this.currentUser.last_name,
        screen_name: this.currentUser.screen_name,
        hourly_rate: this.currentUser.hourly_rate,
        description: this.currentUser.description,
        profile_pic: this.profilePic,
        achievements: this.currentUser.achievements,
        third_party_profile_link: this.currentUser.third_party_profile_link,
        languages: sortedLanguages,
        in_game_communications: [
          {
            game_id: this.currentUser.games[0].id,
            in_game_id: this.currentUser.games[0].in_game_id,
            region: this.currentUser.games[0].region,
          },
        ],
        game_ranks: [
          {
            game_id: this.currentUser.games[0].id,
            rank: this.currentUser.games[0].rank,
          },
        ],
        start_date: moment(this.senseiStartDate, "MMMM D, YYYY").format(
          "YYYY-MM-DD"
        ),
      },
    };

    if (this.gamingAttributes.length > 0) {
      senseiProfileParams["sensei"]["gaming_attribute_selections"] =
        getSelectedAttributesCategories(this.gamingAttributes);
    }

    if (this.isSenseiDiscountEnrollAvailable) {
      delete senseiProfileParams["hourly_rate"];
    }

    return senseiProfileParams;
  }

  // add/remove additional items (language/achievements/etc)
  newAddedItem(addedItem, listOfItems, type) {
    if (addedItem.length) {
      if (doEntriesMatch(addedItem, listOfItems)) {
        return this.returnItemError("error", type);
      } else if (type === "language") {
        let found = false;

        for (let item in this.allLanguages) {
          if (
            this.allLanguages[item].name.toLowerCase() ===
            addedItem.toLowerCase()
          ) {
            found = true;
            this.returnItemError("clear", type);
            listOfItems.unshift(this.allLanguages[item]);
          }
        }

        if (!found) {
          return (this.languageError = "This language isn't available.");
        }
      } else {
        listOfItems.unshift(addedItem);
        this.returnItemError("clear", type);
      }

      addedItem = "";
    }
  }

  removeAddedItem(addedItem, listOfItems) {
    const index = listOfItems.indexOf(addedItem);

    listOfItems.splice(index, 1);

    if (listOfItems.length === 0) {
      listOfItems = [];
    }
  }

  selectedItemChange() {
    this.newAddedItem(
      this.currentUser.newLanguage,
      this.currentUser.sensei.languages,
      "language"
    );
    this.currentUser.newLanguage = "";
  }

  toggleGameSelect() {
    this.editGamesFlag = !this.editGamesFlag;
  }
  isGameDota2(slug) {
    return slug === "dota-2";
  }

  // language display for selection
  queryLanguage(query) {
    const results = query
      ? this.allLanguages.format(this.createFilterFor(query))
      : "";

    return results;
  }

  // Internal functions
  getGamingAttributes(currentUserGame) {
    if (currentUserGame && currentUserGame.third_party_profile_link) {
      this.currentUser.third_party_profile_source_label =
        currentUserGame.third_party_profile_source_label;
      this.currentUser.third_party_profile_link =
        currentUserGame.third_party_profile_link;
    }

    if (this.senseiEditGamingAttributes && currentUserGame) {
      this.gamingAttributesService
        .get({ params: { game_id: currentUserGame.id } })
        .then((res) => {
          this.gamingAttributes = this.formatGamingAttributes(
            res.data[currentUserGame.id]
          );
          if (currentUserGame.slug === "league-of-legends") {
            this.gamingAttributes[0].limit = this.getLeagueOfLegendsLaneLimit(
              this.currentUser.sensei.tier_by_hours_coached
            );
          }
        })
        .catch(angular.noop);
    }
  }

  refreshPublisherConnections() {
    this.showPublisherConnectRequired = false;
    return this.gamePublisher
      .refreshPublisherConnections(this.userId)
      .then((connections) => {
        const publishers = Object.keys(connections);

        for (let i in publishers) {
          const publisher = publishers[i];
          this.publisherConnections[publisher] = connections[publisher];
        }
      })
      .catch(angular.noop);
  }

  setUpGameData(gameSelections) {
    //ids of selected games
    this.gameIds = populateGameIds(gameSelections);

    this.timeout(() => {
      this.refreshPublisherConnections();

      //check each game, add selected class if already chosen by sensei
      angular.forEach(
        angular.element(document.querySelectorAll("[data-game-id]")),
        (item) => {
          const gameId = parseInt(item.attributes["data-game-id"].value);

          if (this.gameIds.indexOf(gameId) > -1) {
            angular.element(item).addClass("selected-game");
          }
        }
      );
    });
  }

  userProfilePicSetup() {
    this.root.$watch(
      () => this.userService.tempProfilePic,
      (newVal) => {
        if (this.userService.getProfilePic()) {
          this.userImage = this.userService.getProfilePic();
        } else {
          this.userImage = this.currentUser.profile_photo;
        }
      },
      true
    );
  }

  returnItemError(status, type) {
    if (status === "clear") {
      if (type === "language") {
        this.languageError = null;
      } else {
        this.achievementError = null;
      }
    } else {
      if (type === "language") {
        this.languageError = "Language has already been added.";
      } else {
        this.achievementError = "Achievement has already been added.";
      }
    }
  }

  createFilterFor(query) {
    const lowercaseQuery = query.toLowerCase();

    return function filterFn(language) {
      return language.name.toLowerCase().indexOf(lowercaseQuery) === 0;
    };
  }

  formatGamingAttributes(attributeCategories) {
    const displayCategories = [];
    const validAttributes = ["Platform", "Lane"];

    for (let i in attributeCategories) {
      const isAllowed = validAttributes.indexOf(i) > -1;

      if (isAllowed) {
        const attributeCategory = attributeCategories[i];
        const displayCategory = {
          title: i + "s", // Todo: make plural process more sophisticated
          name: i,
          attributes: [],
        };

        const currentAttributes =
          this.currentUser.sensei.gaming_attribute_selections[i] || [];

        for (let j in attributeCategory) {
          const attribute = attributeCategory[j];
          const isSelected = currentAttributes.indexOf(attribute) > -1;

          displayCategory.attributes.push({
            name: attribute,
            selected: isSelected,
          });
        }

        displayCategories.push(displayCategory);
      }
    }

    return displayCategories;
  }

  getLeagueOfLegendsLaneLimit(tier_by_hours_coached) {
    if (tier_by_hours_coached === 1) {
      return 2;
    } else if (tier_by_hours_coached === 2) {
      return 3;
    } else {
      return 5;
    }
  }
}

SenseiAccountProfileController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "$state",
  "User",
  "SenseiProfile",
  "OmnibarPointer",
  "UIFeatureGate",
  "GamePublisher",
  "LocaleSelection",
  "GamingAttributes",
];
