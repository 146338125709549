import {
  uiFeatureGate,
  getGames,
  getVisitorGeoLocale,
} from "../config/utils/utilsConfig";
import reusableApp from "./gs-reusable";
import accessApp from "./gs-access";

const gameApp = angular.module("gamerSenseiGameLandingPages", [
  "ngResource",
  "ngMaterial",
  "ui.router.compat",
  "star-rating",
  "720kb.datepicker",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  reusableApp.name,
  accessApp.name,
]);

// Directive setup
import slickDirective from "../directives/data/slick-directive";
gameApp
  .constant("slickCarouselConfig", {
    method: {},
    event: {},
  })
  .directive("slick", slickDirective);

// Routing Setup
import GamesLandingContainerController from "../controllers/games/games-landing-container-controller";
import GamesLandingContainerTemplate from "../templates/games/games_landing_container_template";

import GamesLandingAllController from "../controllers/games/games-landing-all-controller";
import GamesLandingAllTemplate from "../templates/games/games_landing_all_template";

import GamesLandingPageController from "../controllers/games/games-landing-page-controller";
import GamesLandingPageTemplate from "../templates/games/games_landing_page_template";

gameApp.config(configGameLandingPages).run(changeStateTo);

function configGameLandingPages(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("games_landing", {
      abstract: true,
      url: "/games",
      resolve: {
        pulledGames: getGames,
      },
      template: GamesLandingContainerTemplate,
      controller: GamesLandingContainerController,
      controllerAs: "vm",
    })
    .state("games_landing.root", {
      url: "/",
      redirectTo: "games_landing.all",
    })
    .state("games_landing.all", {
      url: "",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        pulledGames: getGames,
      },
      controller: GamesLandingAllController,
      controllerAs: "vm",
      template: GamesLandingAllTemplate,
    })
    .state("games_landing.all_redirect", {
      url: "/all",
      redirectTo: "games_landing.all",
    })

    .state("games_landing.page", {
      url: "/:title",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        VisitorGeoLocale: getVisitorGeoLocale,
        pulledGames: getGames,
      },
      controller: GamesLandingPageController,
      controllerAs: "vm",
      template: GamesLandingPageTemplate,
    });
}
configGameLandingPages.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

// allow to redirect
changeStateTo.$inject = ["$rootScope", "$state"];
function changeStateTo($rootScope, $state) {
  $rootScope.$on("$stateChangeStart", function (evt, to, params) {
    if (to.redirectTo) {
      evt.preventDefault();
      $state.go(to.redirectTo, params, { location: "replace" });
    }
  });
}

export default gameApp;
