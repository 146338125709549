import reusableApp from "./gs-reusable";
import {
  uiFeatureGate,
  getVisitorGeoLocale,
} from "../config/utils/utilsConfig";

const accessApp = angular.module("gamerSenseiAccessPages", [
  "ngResource",
  "ngRoute",
  "ui.router.compat",
  reusableApp.name,
  "ngMaterial",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
]);

// Directive setup
import acceptTermsDirective from "../directives/user/accept-terms-directive";
import userAccessDirective from "../directives/user/user-access-directive";

accessApp.directive("acceptTerms", acceptTermsDirective);
accessApp.directive("userAccess", userAccessDirective);

// Service setup
import passwordResetService from "../services/users/password-reset";

accessApp.factory("PasswordReset", passwordResetService);

// Routing Setup
import DailyCoSessionController from "../controllers/access/daily-co-session-controller";
import DailyCoSessionTemplate from "../templates/access/daily_co_session_template";

import GamerSignUpPageController from "../controllers/access/gamer-signup-page-controller";
import GamerSignUpPageTemplate from "../templates/access/gamer_signup_template";

import PasswordRecoveryPageController from "../controllers/access/password-recovery-request-page-controller";
import PasswordRecoveryPageTemplate from "../templates/access/password_recovery_request_template";

import PasswordRecoverySubmitPageController from "../controllers/access/password-recovery-submit-page-controller";
import PasswordRecoverySubmitPageTemplate from "../templates/access/password_recovery_submit_template";

import ReferralLandingPageController from "../controllers/access/referral-landing-page-controller";
import ReferralLandingPageTemplate from "../templates/access/referral_signup_template";

import SenseiSignUpPageController from "../controllers/access/sensei-signup-page-controller";
import SenseiSignUpPageTemplate from "../templates/access/sensei_signup_template";

import SignInPageController from "../controllers/access/sign-in-page-controller";
import SignInPageTemplate from "../templates/access/sign_in_template";

accessApp.config(configAccess);

function configAccess(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider
) {
  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);

  $stateProvider
    .state("daily_co_session", {
      url: "/session/:room_key",
      controller: DailyCoSessionController,
      controllerAs: "vm",
      template: DailyCoSessionTemplate,
    })
    .state("gamer_sign_up", {
      url: "/gamer_sign_ups/new",
      controller: GamerSignUpPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
        VisitorGeoLocale: getVisitorGeoLocale,
      },
      template: GamerSignUpPageTemplate,
    })
    .state("sensei_sign_up", {
      url: "/sensei_sign_ups/new",
      controller: SenseiSignUpPageController,
      controllerAs: "vm",
      resolve: {
        VisitorGeoLocale: getVisitorGeoLocale,
        UIFeatureGate: uiFeatureGate,
      },
      template: SenseiSignUpPageTemplate,
    })
    .state("referral_registration", {
      url: "/referral/:campaign/registrations",
      controller: ReferralLandingPageController,
      controllerAs: "vm",
      resolve: {
        signupRevampCheck: signupRevampCheck,
        VisitorGeoLocale: getVisitorGeoLocale,
        UIFeatureGate: uiFeatureGate,
      },
      template: ReferralLandingPageTemplate,
    })
    .state("sign_in", {
      url: "/sign_in/new?email&league_coaching_user",
      controller: SignInPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: SignInPageTemplate,
    })
    .state("password_recovery_new", {
      url: "/password/recovery/new",
      controller: PasswordRecoveryPageController,
      controllerAs: "vm",
      resolve: {
        passwordRecoveryCheck: passwordRecoveryCheck,
        UIFeatureGate: uiFeatureGate,
      },
      template: PasswordRecoveryPageTemplate,
    })
    .state("password_recovery_edit", {
      url: "/password/recovery/edit",
      template: PasswordRecoverySubmitPageTemplate,
      controller: PasswordRecoverySubmitPageController,
      controllerAs: "vm",
      resolve: {
        passwordRecoveryCheck: passwordRecoveryCheck,
        UIFeatureGate: uiFeatureGate,
      },
    });
}
configAccess.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
];

passwordRecoveryCheck.$inject = ["FeatureGate"];
function passwordRecoveryCheck(FeatureGate) {
  return FeatureGate.check({ params: { gate_name: "password_recovery" } });
}

signupRevampCheck.$inject = ["FeatureGate"];
function signupRevampCheck(FeatureGate) {
  return FeatureGate.check({ params: { gate_name: "signup_revamp" } });
}

export default accessApp;
