export default class BusinessLessonsBookingLandingController {
  constructor($scope, User, TokenBookingService, pulledGames) {
    this.userId = $scope.currentId;
    // injected and imported
    this.games = pulledGames.data.games;
    // services
    this.userService = User;
    this.tokenBooking = TokenBookingService;
    // time
    this.today = moment();
    // strings
    this.recentSenseiDesc =
      "There are currently no sessions available. Here are some of your recently completed sessions.";
    // objects and arrays
    this.communicationInfoReminder = {
      show: false,
      completed: false,
    };

    this.initUser();
  }

  initUser() {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("gamer");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.communicationInfoReminder.show =
          this.currentUser.communication_services.length === 0;
      })
      .catch(angular.noop);
  }

  filterTokenDeposits() {
    const obj = {};
    const tokenList = this.tokenBooking.deposits;

    angular.forEach(tokenList, function (deposit) {
      const isMasterClass = deposit.type.includes("masterclass");

      if (!isMasterClass) {
        const key = deposit.token_item_id
          ? `${deposit.token_item_id}-${deposit.tier}-${deposit.game}-${
              deposit.sensei_id ? deposit.sensei_id : 0
            }`
          : `${deposit.template_id}-${deposit.tier}-${deposit.game}-${
              deposit.sensei_id ? deposit.sensei_id : 0
            }`;

        obj[key] = deposit;
      }
    });

    return obj;
  }

  areThereNoTokenDeposits() {
    return (
      this.tokenBooking.haveTokenDepositsBeenSet() &&
      Object.keys(this.tokenBooking.getTokenDeposits()).length === 0
    );
  }

  doTokenDepositsExist() {
    return Object.keys(this.tokenBooking.getTokenDeposits()).length;
  }
}

BusinessLessonsBookingLandingController.$inject = [
  "$scope",
  "User",
  "TokenBookingService",
  "pulledGames",
];
