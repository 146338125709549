import reusableApp from "./gs-reusable";
import accessApp from "./gs-access";
import responsiveTemplateApp from "./gs-responsive-template";
import {
  getGames,
  uiFeatureGate,
  getVisitorGeoLocale,
} from "../config/utils/utilsConfig";

const marketingApp = angular.module("gamerSenseiLandingPages", [
  "ngResource",
  "ngRoute",
  "ui.router",
  reusableApp.name,
  responsiveTemplateApp.name,
  accessApp.name,
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "star-rating",
]);

// Directive setup
import slickDirective from "../directives/data/slick-directive";
marketingApp
  .constant("slickCarouselConfig", {
    method: {},
    event: {},
  })
  .directive("slick", slickDirective);

// Routing setup
import MarketingContainerController from "../controllers/marketing/marketing-container-controller";
import MarketingContainerTemplate from "../templates/marketing/marketing_container_template.html";

import HomePageController from "../controllers/marketing/home-page-controller";
import HomePageTemplate from "../templates/marketing/home_page_template.html";

import PrivacyPageController from "../controllers/marketing/privacy-page-controller";
import PrivacyPageTemplate from "../templates/marketing/privacy_policy_page_template";

import PrivacySupplementPageController from "../controllers/marketing/privacy-supplement-page-controller";
import PrivacySupplementPageTemplate from "../templates/marketing/privacy_policy_supplement_page_template";

import TermsPageController from "../controllers/marketing/terms-page-controller";
import TermsPageTemplate from "../templates/marketing/terms_of_use_page_template";

import CookiesPageController from "../controllers/marketing/cookies-page-controller";
import CookiesPageTemplate from "../templates/marketing/cookies_page_template";

import DisclaimerPageController from "../controllers/marketing/disclaimer-controller";
import DisclaimerPageTemplate from "../templates/marketing/disclaimer_page_template";

import HowItWorksPageController from "../controllers/marketing/how-it-works-page-controller";
import HowItWorksPageTemplate from "../templates/marketing/how_it_works_page_template";

import AboutPageController from "../controllers/marketing/about-page-controller";
import AboutPageTemplate from "../templates/marketing/about_page_template.html";

import SenseiCodeOfConductPageController from "../controllers/marketing/sensei-code-of-conduct-page-controller";
import SenseiCodeOfConductPageTemplate from "../templates/marketing/sensei_code_of_conduct_page_template";

import CollegesPageController from "../controllers/marketing/colleges-page-controller";
import CollegesPageTemplate from "../templates/marketing/colleges_page_template";

import ParentsPageController from "../controllers/marketing/parents-page-controller";
import ParentsPageTemplate from "../templates/marketing/parents_page_template";

import AssessmentsPageController from "../controllers/marketing/assessments-page-controller";
import AssessmentsPageTemplate from "../templates/marketing/assessments_page_template";

import TeamCoachingPageController from "../controllers/marketing/team-coaching-page-controller";
import TeamCoachingPagePageTemplate from "../templates/marketing/team_coaching_page_template";

import OneOnOneCoachingPageController from "../controllers/marketing/one-on-one-coaching-page-controller";
import OneOnOneCoachingPageTemplate from "../templates/marketing/one_on_one_coaching_page_template";

import ContactPageController from "../controllers/marketing/contact-page-controller";
import ContactPageTemplate from "../templates/marketing/contact_page_template";

import PressPageController from "../controllers/marketing/press-page-controller";
import PressPageTemplate from "../templates/marketing/press_page_template";

marketingApp.config(configLanding);

function configLanding(
  $stateProvider,
  $locationProvider,
  $urlMatcherFactoryProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("marketing", {
      abstract: true,
      controller: MarketingContainerController,
      controllerAs: "vm",
      template: MarketingContainerTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
        pulledGames: getGames,
      },
    })
    .state("marketing.home_page", {
      url: "/?welcome",
      controller: HomePageController,
      controllerAs: "vm",
      template: HomePageTemplate,
      resolve: {
        pulledGames: getGames,
        UIFeatureGate: uiFeatureGate,
        VisitorGeoLocale: getVisitorGeoLocale,
      },
    })
    .state("marketing.privacy_policy", {
      url: "/privacy-policy",
      controller: PrivacyPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: PrivacyPageTemplate,
    })
    .state("marketing.privacy_policy_supplement", {
      url: "/privacy-policy-supplement",
      controller: PrivacySupplementPageController,
      controllerAs: "vm",
      template: PrivacySupplementPageTemplate,
    })
    .state("marketing.terms_of_use", {
      url: "/terms-of-use",
      controller: TermsPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: TermsPageTemplate,
    })
    .state("marketing.cookies", {
      url: "/cookies-and-similar-technologies",
      controller: CookiesPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: CookiesPageTemplate,
    })
    .state("marketing.disclaimer", {
      url: "/disclaimers",
      controller: DisclaimerPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: DisclaimerPageTemplate,
    })
    .state("marketing.how_it_works", {
      url: "/how-it-works",
      controller: HowItWorksPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: HowItWorksPageTemplate,
    })
    .state("marketing.about", {
      url: "/about",
      controller: AboutPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: AboutPageTemplate,
    })
    .state("marketing.sensei_code_of_conduct", {
      url: "/sensei-code-of-conduct",
      controller: SenseiCodeOfConductPageController,
      controllerAs: "vm",
      template: SenseiCodeOfConductPageTemplate,
    })
    .state("marketing.schools", {
      url: "/schools",
      controller: CollegesPageController,
      controllerAs: "vm",
      resolve: { pulledGames: getGames },
      template: CollegesPageTemplate,
    })
    .state("marketing.parents", {
      url: "/parents",
      controller: ParentsPageController,
      controllerAs: "vm",
      template: ParentsPageTemplate,
    })
    .state("marketing.assessments", {
      url: "/assessments",
      controller: AssessmentsPageController,
      controllerAs: "vm",
      template: AssessmentsPageTemplate,
    })
    .state("marketing.team_coaching", {
      url: "/team-coaching",
      controller: TeamCoachingPageController,
      controllerAs: "vm",
      template: TeamCoachingPagePageTemplate,
    })
    .state("marketing.one_on_one_coaching", {
      url: "/one-on-one-coaching",
      controller: OneOnOneCoachingPageController,
      controllerAs: "vm",
      template: OneOnOneCoachingPageTemplate,
    })
    .state("marketing.contact", {
      url: "/contact",
      controller: ContactPageController,
      controllerAs: "vm",
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      template: ContactPageTemplate,
    })
    .state("marketing.press", {
      url: "/press",
      controller: PressPageController,
      controllerAs: "vm",
      template: PressPageTemplate,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
    });
}
configLanding.$inject = [
  "$stateProvider",
  "$locationProvider",
  "$urlMatcherFactoryProvider",
  "$analyticsProvider",
];

marketingApp.run([
  "$anchorScroll",
  function ($anchorScroll) {
    $anchorScroll.yOffset = 50;
  },
]);

export default marketingApp;
