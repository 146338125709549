import acceptTermsTemplate from "../../templates/directives/users/accept_terms_template";

export default function acceptTermsDirective(AccessControl, OmnibarPointer) {
  return {
    restrict: "E",
    scope: {
      opened: "=",
      userParams: "=",
      login: "&",
    },
    link: link,
    template: acceptTermsTemplate,
  };

  function link(scope, elem, attrs) {
    scope.omnibarPointer = OmnibarPointer;

    scope.submitTerms = function () {
      AccessControl.terms(scope.userParams)
        .then((res) => {
          scope.opened = false;
          scope.login();
        })
        .catch((err) => {
          scope.opened = false;
          if (err.data && err.data.errorCode === "206006") {
            scope.login();
          } else {
            scope.omnibarPointer.pushErrorByMessage(
              "Oops! Something went wrong. If the problem persists, contact support."
            );
          }
        });
    };
  }
}

acceptTermsDirective.$inject = ["AccessControl", "OmnibarPointer"];
