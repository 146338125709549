const metaTitles = {
  "gamer_dashboard.landing": "Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.book_lesson_landing":
    "Lessons | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.book_lesson_calendar":
    "Lessons | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.list": "Lessons | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.calendar":
    "Calendar | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.my_reviews":
    "My Reviews | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.sensei_notes":
    "Sensei Notes | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.lessons.sensei_note_page":
    "Sensei Notes | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.wallet.summary": "Payments | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.wallet.tokens":
    "Purchased Sessions | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.wallet.history": "Payments | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.wallet.redeem_gift_card":
    "Gift Codes | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.wallet.redeem_session_token":
    "Redeem Sessions | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.profile": "Profile | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.contact_information":
    "Contact | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.notifications":
    "Notifications | Gamer Dashboard | Gamer Sensei",
  "gamer_dashboard.payment": "Payments | Gamer Dashboard | Gamer Sensei",
};

const setGamerMetaTitle = (name) => {
  return metaTitles[name] || "Gamer Dashboard | Gamer Sensei";
};

export default setGamerMetaTitle;
