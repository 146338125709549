import filterDataTemplate from "../../templates/directives/data/filter_data_template";

export default function filterDataDirective() {
  return {
    restrict: "E",
    scope: {
      filters: "=",
      dateClose: "&",
    },
    link: link,
    template: filterDataTemplate,
  };

  function link(scope, elem, attr) {}
}
