export default class SenseiWalletController {
  constructor($rootScope, $scope, User) {
    this.currentUser = {};

    User.setCurrentId($scope.currentId);
    User.setRole("sensei");

    User.getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        $scope.currentUser = this.currentUser; // For temp user header
      })
      .catch(angular.noop);

    $rootScope.$on(
      "$stateChangeSuccess",
      function (event, toState, toParams, fromState, fromParams) {
        User.getUser(true)
          .then((userData) => {
            this.currentUser = userData;
            $scope.currentUser = this.currentUser; // For temp user header
          })
          .catch(angular.noop);
      }
    );
  }
}

SenseiWalletController.$inject = ["$rootScope", "$scope", "User"];
