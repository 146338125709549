import verifyTemplate from "../../templates/directives/users/verify_email_template";

export default function verifyEmailDirective(
  $timeout,
  $document,
  VerifyEmailService,
  OmnibarPointer,
  EventTrackingManager
) {
  return {
    restrict: "E",
    scope: {
      loading: "=",
      email: "=",
      sendEvent: "=",
      codeNeeded: "=",
      callback: "&",
    },
    link: link,
    template: verifyTemplate,
  };

  function link(scope, elem, attrs) {
    scope.verifyManager = VerifyEmailService;
    scope.omnibarPointer = OmnibarPointer;
    scope.eventTracking = EventTrackingManager;
    scope.allow = "^[0-9]*$";
    scope.code = [];

    scope.helperText = scope.codeNeeded
      ? "Click the send code button, and then enter the code received in your email below."
      : "Enter the code emailed to you below. Can't find it? Click the resend code button.";
    scope.buttonText = scope.codeNeeded ? "Send code" : "Resend code";

    scope.resendVerificationCode = function () {
      scope.loading = true;
      var params = {
        email: scope.email,
      };

      scope.verifyManager
        .resendEmail(params)
        .then(function () {
          scope.loading = false;
          var omniTooltipParams = {
            title: "Email sent",
            body: `<p>A verification email has been sent to ${scope.email}, please check your spam folder and wait a few moments to ensure that it arrives.</p>`,
          };
          scope.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            persist: true,
            timer: true,
          });
        })
        .catch(function (_err) {
          scope.loading = false;
          scope.omnibarPointer.pushErrorByMessage(
            "Oops! Something went wrong. If this problem persists, please contact support."
          );
        });
    };

    scope.verifyEmail = function () {
      scope.loading = true;
      scope.disable = true;
      var params = {
        email: scope.email,
        code: scope.code.join(""),
      };

      scope.verifyManager
        .verifyEmail(params)
        .then((res) => {
          scope.loading = false;
          var omniTooltipParams = {
            title: "Success",
            body: "<p>Your email has been verified!</p>",
          };
          scope.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            persist: true,
            timer: true,
          });

          if (res.data.is_registered && res.data.is_email_verified) {
            scope.sendEvent = true;
          }

          if (res.data.newsletter_consent) {
            scope.eventTracking.pushNotifyChanges("newsletterSignup", {
              customerEmail: res.data.user_email,
              marketingConsent: true,
              emailCaptureLocation: "user-registration",
            });
          }

          $timeout(function () {
            scope.callback();
          }, 250);
        })
        .catch(function (_err) {
          scope.loading = false;
          scope.disable = false;
          scope.omnibarPointer.pushErrorByMessage(
            "Oops! Something went wrong. Check your code and try again."
          );
        });
    };

    // Listen to paste on the document
    $document[0].addEventListener("paste", function (e) {
      // if the target is a text input
      if (e.target.type === "text") {
        var reg = /^[0-9]*$/;
        var data = e.clipboardData.getData("Text");
        // split clipboard text into single characters
        data = data.split("");
        // find all other text inputs
        [].forEach.call(
          $document[0].querySelectorAll("input.code"),
          (node, index) => {
            // And set input value to the relative character
            if (reg.test(data[index])) {
              scope.code[index] = data[index];
              node.value = data[index];
            }
          }
        );
      }
    });
  }
}

verifyEmailDirective.$inject = [
  "$timeout",
  "$document",
  "VerifyEmailService",
  "OmnibarPointer",
  "EventTrackingManager",
];
