import intlTelInput from "intl-tel-input";
import { communicationServiceParams } from "../../../../config/utils/utilsConfig";
import { getCommunicationMediumIcon } from "../../../../config/icons/iconConfig";

export default class BusinessAccountContactController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $timeout,
    $window,
    User,
    Account,
    OmnibarPointer,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "gamer settings";
    this.userId = $scope.currentId;
    this.role = "gamer";
    // angular providers
    this.$state = $state;
    this.$timeout = $timeout;
    this.$window = $window;
    // services
    this.account = Account;
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.getCommunicationMediumIcon = getCommunicationMediumIcon;
    // string
    this.helperText = "Edit your profile and settings";
    // booleans
    this.combineName = false;
    // objects and arrays
    this.currentChatApps = [];
    this.tempChatApp = {};

    this.initUser({
      telephone: () => this.setupTelephone(),
      firstRunCheck: (firstRun) => {
        $rootScope.firstRun = firstRun;
      },
      omniTooltipCheck: (user) => this.omniTooltipCheck(user),
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.userEvent = {
          phoneNumber: userData.phone_number,
          communicationServices: communicationServiceParams(
            userData.communication_services
          ),
        };
        this.userService.insertUserCommunication(
          this.currentUser,
          this.currentChatApps
        );

        if (callbacks?.telephone) callbacks.telephone();
        if (callbacks?.firstRunCheck) callbacks.firstRunCheck(userData);
        if (callbacks?.omniTooltipCheck) callbacks.omniTooltipCheck(userData);
      })
      .catch(angular.noop);
  }

  // config
  setupTelephone() {
    const input = document.querySelector("#phone_number");
    const iti = intlTelInput(input, {
      utilsScript: "/files/lib/utils.js",
    });

    this.dialCode = () => {
      return `+${iti.getSelectedCountryData().dialCode}`;
    };

    const countryCode = this.currentUser.country_code;

    iti.setNumber(
      this.returnCountryCode(countryCode) + this.currentUser.phone_number
    );

    this.phoneNum = this.currentUser.phone_number;
  }

  // cta methods
  // save settings
  saveSettings() {
    const updateParams = {
      user: {
        in_game_communications: this.currentUser?.in_game_communications || [],
        game_ranks: this.currentUser?.game_ranks || [],
        communication_services: [],
      },
    };

    if (this.currentChatApps.length) {
      for (let k = 0; k < this.currentChatApps.length; k++) {
        if (this.currentChatApps[k]) {
          var chatDataParams = {
            medium: this.currentChatApps[k].medium,
            handle: this.currentChatApps[k].handle,
            remove: this.currentChatApps[k].remove,
          };
          updateParams.user.communication_services.push(chatDataParams);
        }
      }
    }

    if (this.phoneNum) updateParams.user["phone_number"] = this.phoneNum;
    if (this.dialCode()) updateParams.user["country_code"] = this.dialCode();

    this.account
      .update(this.userId, updateParams)
      .then((res) => {
        this.eventTracking.pushContactUpdate(
          this.userEvent,
          this.currentUser,
          this.phoneNum,
          this.currentChatApps
        );
        this.$timeout(() => {
          this.$window.scrollTo(0, 0);
          this.userService.setSaveStatus(true);
          this.$state.reload();
        }, 100);
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  settingsValid(form) {
    if (!form.$valid) {
      const phoneNumberError = form.$error.internationalPhoneNumber;

      if (phoneNumberError) {
        this.omnibarPointer.pushErrorByMessage(
          "Please check Phone Number. Make sure to include Country Code."
        );
      } else {
        this.omnibarPointer.pushErrorByMessage(
          "Please fill in all of the fields below."
        );
      }
    }

    return form.$valid;
  }

  connectToThisService(chatMedium) {
    const currentChat = this.userService.returnCurrentChatService(
      chatMedium,
      this.currentChatApps
    );

    this.userService
      .connectToCommunicationService(currentChat.id)
      .then((res) => {
        currentChat.connected = res.data.communication_service.connected;
        if (chatMedium === "skype") {
          window.location = "skype:GamerSenseiApp?chat";
        } else {
          window.open("https://discord.gg/bxSKTbcWRs", "_blank");
        }
      })
      .catch(angular.noop);
  }

  toggleEditMode(chat) {
    chat.editMode = !chat.editMode;
  }

  updateChatApp(chat) {
    if (
      chat.medium === "discord" &&
      !this.userService.validateDiscord(chat.tempHandle)
    ) {
      OmnibarPointer.resetOmnibar();
      this.omnibarPointer.pushErrorByMessage(
        "Incorrect formatting. Discord usernames should be 2-32 characters and don't allow any special characters other than periods and underscores."
      );
    } else {
      if (chat.handle !== chat.tempHandle) {
        chat.handle = chat.tempHandle;
        chat.changed = true;
      }
      this.toggleEditMode(chat);
    }
  }

  deleteChatApp(chat) {
    chat.remove = true;
    chat.changed = true;
    this.toggleEditMode(chat);
  }

  addNewChatToList(chat) {
    let existingService = false;
    const tempParams = {
      changed: true,
      editMode: false,
      medium: chat.medium,
      handle: chat.handle,
      tempHandle: chat.handle,
      remove: false,
    };

    if (
      tempParams.medium === "discord" &&
      !this.userService.validateDiscord(tempParams.tempHandle)
    ) {
      this.omnibarPointer.pushErrorByMessage(
        "Incorrect formatting. Discord usernames should be 2-32 characters and don't allow any special characters other than periods and underscores."
      );
    } else {
      if (this.currentChatApps.length) {
        for (let c = 0; c < this.currentChatApps.length; c++) {
          if (this.currentChatApps[c].medium === tempParams.medium) {
            this.currentChatApps[c] = tempParams;
            existingService = true;
          }
        }
      }
      if (!existingService) {
        this.currentChatApps.push(tempParams);
      }
    }
  }

  // display methods
  isServiceNotInUse(service) {
    for (let i = 0; i < this.currentChatApps.length; i++) {
      if (
        this.currentChatApps[i].medium === service &&
        this.currentChatApps[i].remove
      ) {
        return true;
      } else if (this.currentChatApps[i].medium === service) {
        return false;
      }
    }
    return true;
  }

  canUserAddService() {
    if (this.currentChatApps.length === 2) {
      for (let s = 0; s < this.currentChatApps.length; s++) {
        if (this.currentChatApps[s].remove === true) {
          return true;
        }
      }
      return false;
    }
    return true;
  }

  isConnectedToService(chat) {
    return this.currentUser.communication_services.length > 0 && chat.connected;
  }

  // internal methods
  omniTooltipCheck(user) {
    const userAlert = user.omni_tooltips["/gamer/dashboard"];
    if (userAlert) {
      this.omnibarPointer.pushTooltip(userAlert[0]);
    } else if (this.userService.getSaveStatus()) {
      const omniTooltipParams = {
        title: "Settings updated",
      };
      if (this.phoneNum) {
        omniTooltipParams.body =
          '<p>SMS notifications are on, to turn them off, visit <a href="/business/dashboard/notifications">your notifications tab</a>.</p>';
      } else {
        omniTooltipParams.body =
          "<p>You have successfully saved your contact information.</p>";
      }
      this.omnibarPointer.pushTooltip(omniTooltipParams, {
        tooltipCallback: false,
        persist: true,
        timer: true,
      });
      this.userService.setSaveStatus(false);
    }
  }

  returnCountryCode(code) {
    return code || this.dialCode();
  }
}

BusinessAccountContactController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$timeout",
  "$window",
  "User",
  "Account",
  "OmnibarPointer",
  "EventTrackingManager",
];
