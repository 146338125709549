import footerTemplate from "../../templates/directives/navigation/main_footer_template";
import {
  discordIcon,
  facebookIcon,
  instagramIcon,
  twitterIcon,
} from "../../config/icons/iconConfig";

export default function mainFooterDirective(
  OmnibarPointer,
  EventTrackingManager
) {
  return {
    restrict: "E",
    link: link,
    template: footerTemplate,
  };

  function link(scope, elem, attrs) {
    scope.eventTracking = EventTrackingManager;
    scope.omnibarPointer = OmnibarPointer;
    scope.currentYear = new Date().getFullYear();
    scope.icons = {
      discordIcon,
      facebookIcon,
      instagramIcon,
      twitterIcon,
    };

    scope.signUpToNewsLetter = function (event) {
      scope.eventTracking.pushNotifyChanges("newsletterSignup", {
        customerEmail: scope.newsletterEmail,
        marketingConsent: true,
        emailCaptureLocation: "footer",
      });

      var omniTooltipParams = {
        title: "Submitted",
        body: "<p>You have signed up to our newsletter.</p>",
      };
      scope.omnibarPointer.pushTooltip(omniTooltipParams, {
        tooltipCallback: false,
        persist: true,
        timer: true,
      });
    };
  }
}

mainFooterDirective.$inject = ["OmnibarPointer", "EventTrackingManager"];
