import moment from "moment-timezone";
import { calendarEventObject } from "../../../config/dashboard/dashboardConfig";

export default class BusinessDashboardLandingController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $timeout,
    $window,
    $anchorScroll,
    $cookies,
    CalendarBasedLessons,
    User,
    RecentLessons,
    SenseiTrainingRequestManager,
    SenseiTrainingRequestAPIService,
    SenseiReviewService
  ) {
    $rootScope.bodylayout = "gamer calendar";
    this.userId = $scope.currentId;
    this.role = "gamer";
    this.environment = process.env.NODE_ENV;
    // angular providers
    this.state = $state;
    this.$scope = $scope;
    this.timeout = $timeout;
    // services
    this.userService = User;
    this.recentLessonService = RecentLessons;
    this.strManager = SenseiTrainingRequestManager;
    this.strAPI = SenseiTrainingRequestAPIService;
    this.senseiReview = SenseiReviewService;
    this.calendarBasedLessons = CalendarBasedLessons;
    // cookies
    this.senseiReviewDismissId = parseInt($cookies.get("senseiReviewDismiss"));
    // numbers
    this.width = $window.innerWidth;
    // booleans
    this.loadingLessons = false;
    //time
    this.today = moment();
    this.timeZone = moment.tz.guess();
    // objects and arrays
    this.currentEvents = null;
    this.mostRecentPastCompletedLesson = null;
    this.reviewParams = null;
    this.senseiReviewModal = {
      openedModal: false,
    };
    this.bundleBackground = { slug: "", defined: false };
    this.calendarEvents = [];
    this.calendarModal = {
      userType: this.role,
      openedModal: false,
    };
    this.confirmedLessons = this.strManager.confirmedLessons;

    //set anchorScroll offset for header
    $anchorScroll.yOffset = 80;

    this.goTo = function (link) {
      window.location = "/senseis/" + link;
    };

    this.initCalendarConfig();
    this.initUser({
      onSuccess: () => {
        this.initRecentLessons();
      },
    });
    this.initCalendarEvents();

    angular.element($window).on("resize", this.onResize($window));
    $scope.$on("$destroy", this.cleanUp($window));
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);

    this.userService.getUser().then((userData) => {
      this.currentUser = userData;
      if (callbacks && callbacks.onSuccess) {
        callbacks.onSuccess();
      }
    });
  }
  initRecentLessons() {
    this.recentLessonService
      .senseis(this.userId, { params: { limit: 3 } })
      .then((res) => {
        this.recentLessons = res.data.recent_senseis;
      })
      .catch(angular.noop);
  }
  initCalendarConfig() {
    this.uiConfig = {
      editable: false,
      eventOverlap: false,
      slotEventOverlap: false,
      slotDuration: "00:15:00",
      snapDuration: "00:15:00",
      header: {
        left: "title",
        center: "",
        right: "viewCalendar",
      },
      customButtons: {
        viewCalendar: {
          text: "View Full Calendar",
          click: () => {
            this.state.go("business_dashboard.lessons.calendar");
          },
        },
      },
      aspectRatio: 1.25,
      allDaySlot: false,
      eventLimit: 1,
      eventRender: this.calendarEventRender.bind(this),
      views: {
        agenda: {
          eventLimit: false,
        },
      },
      eventAfterAllRender: this.calendarAfterEventsRender.bind(this),
    };
  }
  initCalendarEvents() {
    this.timeout(() => {
      angular.element("#businessCalSm").fullCalendar({
        ...this.uiConfig,
        eventSources: [
          {
            url: "/api/web/calendar_based_lessons",
            method: "GET",
            startParam: "starts_at_from_date",
            endParam: "starts_at_end_date",
            data: {
              timezone: this.timeZone,
            },
            success: this.processCalendarData.bind(this),
            error: this.handleCalendarError.bind(this),
          },
        ],
      });
    });
  }

  // calendar functionality
  processCalendarData(data) {
    this.senseiTrainingRequests = data.sensei_training_requests;
    return this.transformToCalendarEvents(this.senseiTrainingRequests);
  }
  handleCalendarError(err) {
    if (this.environment === "development") console.error(err);
  }
  transformToCalendarEvents(trainingRequests) {
    const events = [];

    angular.forEach(trainingRequests, (lessonsOnDate, onDate) => {
      angular.forEach(lessonsOnDate, (lesson, i) => {
        const obj = calendarEventObject(
          lesson,
          this.role,
          onDate,
          this.calendarBasedLessons
        );

        events.push(...[obj]);
      });
    });

    return events;
  }
  addEvents(start, end, timezone, callback) {
    const startDate = start.format("YYYY-MM-DD");
    const endDate = end.format("YYYY-MM-DD");

    if (!this.loadingLessons) {
      this.loadingLessons = true;
    }

    return this.getCalendarLessons(startDate, endDate)
      .then((res) => {
        const events = [];
        let lastLesson = null;

        this.senseiTrainingRequests = res.data.sensei_training_requests;

        angular.forEach(
          this.senseiTrainingRequests,
          (lessonsOnDate, onDate) => {
            angular.forEach(lessonsOnDate, (lesson, i) => {
              const lessonTimeFromNow =
                this.today -
                moment(onDate + " " + lesson.start_time, "YYYY-MM-DD HH:mm");

              if (lessonTimeFromNow > 0) {
                lastLesson = lesson;
              }

              const obj = calendarEventObject(lesson, this.role, onDate);

              events.push(...[obj]);
            });
          }
        );

        this.checkSenseiReviewModal(lastLesson);

        callback(events);
      })
      .catch(angular.noop);
  }

  checkSenseiReviewModal(lastLesson) {
    if (
      lastLesson &&
      (lastLesson.status === "pending_complete" ||
        lastLesson.status === "completed") &&
      senseiReviewDismissId !== lastLesson.id
    ) {
      this.mostRecentPastCompletedLesson = lastLesson;
      this.strAPI
        .show(this.mostRecentPastCompletedLesson.id)
        .then((res) => {
          const str = res.data.sensei_training_request;
          this.mostRecentPastCompletedLesson.senseiId = str.sensei_id;
          this.mostRecentPastCompletedLesson.senseiName =
            str.sensei_screen_name;
          this.mostRecentPastCompletedLesson.senseiImage =
            str.sensei_square_profile_photo_url;

          this.openSenseiReviewModal(this.mostRecentPastCompletedLesson);
        })
        .catch(angular.noop);
    }
  }

  openSenseiReviewModal(lesson, update) {
    const senseiId = lesson.sensei_id;
    const pastReviewParams = {
      params: {
        reviewer_id: this.currentUser.gamer.id,
        reviewee_id: senseiId,
      },
    };
    this.senseiReview
      .get(pastReviewParams)
      .then((reviewResp) => {
        const reviews = reviewResp.data.sensei_reviews;

        if (reviews.length === 0 || update) {
          this.mostRecentPastCompletedLesson = lesson;
          this.mostRecentPastCompletedLesson.senseiImage =
            lesson.square_profile_photo_url;
          this.mostRecentPastCompletedLesson.sensei_name =
            lesson.screen_name || lesson.senseiName;
          if (update) {
            var latestReview = null;
            var updatedReivewsLength = reviews[0].updated_reviews.length;
            if (updatedReivewsLength > 0) {
              latestReview =
                reviews[0].updated_reviews[updatedReivewsLength - 1];
            } else {
              latestReview = reviews[0];
            }
            this.reviewParams = {
              stars: latestReview.star_rating,
              description: latestReview.body,
            };
          }
          this.senseiReviewModal.openedModal = true;
        }
      })
      .catch(angular.noop);
  }

  getCalendarLessons(start, end) {
    const calendarParams = {
      params: {
        starts_at_from_date: start,
        starts_at_end_date: end,
      },
    };

    return this.calendarBasedLessons.getLessons(calendarParams);
  }

  calendarAfterEventsRender(view) {
    if (this.loadingLessons) {
      this.loadingLessons = false;
    }
  }

  //event classes
  calendarEventRender(event, element, view) {
    (element[0].nodeName === "A" ? element : element.find("a")).attr(
      "href",
      "/business/dashboard/lessons/calendar"
    );
    if (event.start.isBefore(moment())) {
      element.addClass("evt-past");
    }
    if (
      event.status === "gamer_cancelled" ||
      event.status === "sensei_rejected"
    ) {
      element.addClass("evt-cancelled");
    } else if (
      event.singleChangeRequest ||
      event.recurringChangeRequest ||
      event.status === "request_expired"
    ) {
      element.addClass("evt-actionable");
    } else if (
      event.status === "pre_awaiting_direct_booking_schedule_confirm" ||
      event.status === "awaiting_direct_booking_schedule_confirm" ||
      event.status === "sensei_proposed_lesson"
    ) {
      element.addClass("evt-pre");
    }
  }

  // browser updates
  onResize($window) {
    if (this.width !== $window.innerWidth) {
      this.width = $window.innerWidth;
      this.$scope.$digest();
    }
  }
  cleanUp($window) {
    angular.element($window).off("resize", this.onResize($window));
  }
}

BusinessDashboardLandingController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$timeout",
  "$window",
  "$anchorScroll",
  "$cookies",
  "CalendarBasedLessons",
  "User",
  "RecentLessons",
  "SenseiTrainingRequestManager",
  "SenseiTrainingRequestAPIService",
  "SenseiReviewService",
];
