import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class SignInPageController {
  constructor(
    $scope,
    $rootScope,
    $window,
    $timeout,
    AccessControl,
    PasswordReset,
    OmnibarPointer,
    MetaTagsService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "sign_in";
    this.referrer = $scope.referrer;
    this.gigya = $scope.isGigyaOn;
    // angular providers
    this.root = $rootScope;
    this.$timeout = $timeout;
    this.$window = $window;
    // services
    this.access = AccessControl;
    this.passwordReset = PasswordReset;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.navBar = navBar;
    this.gsLogoHorizontal = gsLogoHorizontal;
    // objects and arrays
    this.containerClasses = ["card", "card-column"];

    this.initPasswordReset();
    this.initMetaTags(MetaTagsService);
  }

  // init methods
  initMetaTags(MetaTagsService) {
    const metaTitle = "Log in to Your Gamer Sensei Account";
    const metaDescription =
      "Gamer Sensei is the world's premier esports coaching platform and community, connecting gamers with an expansive list of professional, fully vetted esports and gaming coaches.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initPasswordReset() {
    if (this.passwordReset.getStatus()) {
      if (this.passwordReset.getStatus() === "requested") {
        let omniTooltipParams = {
          title: "Thank You!",
          body: "<p>Please check your email to reset your password.</p>",
        };

        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          timer: true,
        });
      } else if (this.passwordReset.getStatus() === "submitted") {
        let omniTooltipParams = {
          title: "Success!",
          body: "<p>You've created a new password.</p>",
        };

        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          timer: true,
        });
      }
      this.passwordReset.setStatus(false);
    }
  }

  finishLogin(newUser, role) {
    if (!newUser.is_email_verified && this.gigya) {
      this.$window.location.href = `/${role}/dashboard/verify`;
    } else {
      this.$window.location.href =
        this.root.referrer && this.root.referrer.substring(0, 9) == "/senseis/"
          ? this.root.referrer
          : `/${role}/dashboard`;
    }
  }

  connectEvent(event, name) {
    if (event) event.preventDefault();

    const href = event.target.href;
    this.eventTracking.pushEventOnly(name);
    this.$window.location.href = href;
  }
}

SignInPageController.$inject = [
  "$scope",
  "$rootScope",
  "$window",
  "$timeout",
  "AccessControl",
  "PasswordReset",
  "OmnibarPointer",
  "MetaTagsService",
  "EventTrackingManager",
];
