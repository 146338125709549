import moment from "moment-timezone";
import { getSelectedAttributesCategories } from "../../../../config/utils/utilsConfig";
import { infoIcon } from "../../../../config/icons/iconConfig";
import angular from "angular";

export default class SenseiAccountScheduleController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    OmnibarPointer,
    User,
    SenseiSchedule,
    SenseiProfile
  ) {
    $rootScope.bodylayout = "sensei availabilities";
    this.timeZoneList = $scope.timezones;
    this.userId = $scope.currentId;
    this.role = "sensei";
    this.environment = process.env.NODE_ENV;
    // angular providers
    this.$timeout = $timeout;
    // services
    this.OmnibarPointer = OmnibarPointer;
    this.senseiSchedule = SenseiSchedule;
    this.senseiProfile = SenseiProfile;
    this.userService = User;
    // imported/injected
    this.infoIcon = infoIcon;
    // booleans
    this.availEndpointCalled = false;
    this.newAvailabilitiesSet = false;
    this.scheduleLoading = false;
    // times
    this.today = moment();
    this.startOfDay = this.today.clone().startOf("day");
    this.assumedTimeZone = moment.tz.guess();
    // to be set
    this.availableTimeZone = null;
    // numbers
    this.senseiBufferHours = 24;
    // objects/arrays
    this.currentUser = {};
    this.bookedTimes = {};
    this.gamingAttributes = [];

    //this.calendarEventSources = [this.addAvailabilities];

    this.initCalendarConfig();
    this.initSenseiSchedule();
    this.initUser();
    this.initCalendarEvents();
    this.initTimeZone();
  }

  // init methods
  initUser() {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.automateSchedule =
          userData.sensei?.automate_availabilities ?? false;
        this.onLeave = userData.sensei?.on_leave ?? false;
      })
      .catch(angular.noop);
  }
  initSenseiSchedule() {
    this.senseiSchedule.getBuffer().then((res) => {
      this.senseiBufferHours = res.data.buffer_time;
    });
  }

  initCalendarConfig() {
    this.scheduleConfig = {
      editable: false,
      selectable: true,
      eventOverlap: false,
      slotEventOverlap: false,
      slotDuration: "01:00:00",
      snapDuration: "01:00:00",
      nowIndicator: true,
      customButtons: {
        helpButton: {
          text: "Help",
          click: () => {
            this.helpModalOpen = true;
          },
        },
      },
      header: {
        left: "title, helpButton",
        center: "",
        right: "prev, next",
      },
      defaultView: "agendaWeek",
      allDaySlot: false,
      height: "auto",
      validRange: (nowDate) => {
        return {
          start: this.startOfDay,
          end: this.startOfDay.clone().add(3, "weeks"),
        };
      },
      select: this.availabilitySelect.bind(this),
      dayClick: this.availabilityDayClick.bind(this),
      eventRender: this.availabilityEventRender.bind(this),
    };
  }

  initCalendarEvents() {
    this.$timeout(() => {
      angular.element("#senseiSched").fullCalendar({
        ...this.scheduleConfig,
        eventSources: [
          {
            url: "/api/web/sensei_availabilities",
            method: "GET",
            data: {
              time_zone: this.assumedTimeZone,
            },
            success: this.processCalendarData.bind(this),
            error: this.handleCalendarError.bind(this),
          },
        ],
      });
    });
  }

  initTimeZone() {
    let found = false;
    //check each timezone option for select
    for (let i = 0, len = this.timeZoneList.length; i < len; i++) {
      //if timezone option matches browser, set as choice
      if (this.timeZoneList[i][1] === this.assumedTimeZone) {
        found = true;
        this.matchedTimeZone = true;
        this.availableTimeZone = this.timeZoneList[i][0];
        break;
      }
    }
    if (!found) {
      this.matchedTimeZone = true;
      this.availableTimeZone = this.assumedTimeZone;
    }
  }

  // cta methods
  saveSenseiSchedule() {
    if (this.newAvailabilitiesSet) {
      let allEvents = [];
      allEvents = angular.element("#senseiSched").fullCalendar("clientEvents");

      const availEvents = allEvents.filter((ev) => {
        return ev.status === "available";
      });

      this.submitNewAvailabilities(availEvents);
    }
  }

  changeScheduleAutomation(type) {
    this.OmnibarPointer.resetOmnibar();
    const sensei_id = this.currentUser.sensei.id;
    let bodyText;

    if (type === "automate") {
      bodyText = `You have turned ${
        this.automateSchedule ? "on" : "off"
      } automated availabilities.`;
    } else if (type === "leave") {
      bodyText = `You have turned ${
        this.onLeave ? "on" : "off"
      } vacation mode.`;
    }

    const params = this.saveSenseiProfileParams();

    return this.senseiProfile
      .update(sensei_id, params)
      .then((res) => {
        var omniTooltipParams = {
          body: bodyText,
        };

        this.OmnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          persist: true,
          timer: true,
        });
      })
      .catch((err) => {
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
        if (type === "automate") this.automateSchedule = !this.automateSchedule;
        if (type === "leave") this.onLeave = !this.onLeave;
      });
  }

  closeHelpModal() {
    this.helpModalOpen = false;
  }
  bufferHoursUpdate() {
    this.senseiSchedule
      .postBuffer(this.senseiBufferHours)
      .then()
      .catch((err) => {
        this.OmnibarPointer.pushErrorByMessage(
          "Oops! Buffer time must be between 1 and 72 hours."
        );
      });
  }

  // calendar methods
  processCalendarData({ availabilities }) {
    const events = [];

    angular.forEach(availabilities, (availabilitiesOnDate, onDate) => {
      // checked booked
      angular.forEach(availabilitiesOnDate.booked_hours, (booked, i) => {
        const bookingStart = onDate + " " + booked.start_time;

        if (this.isNotBeforeToday(moment(bookingStart, "YYYY-MM-DD HH:mm"))) {
          const singleBooking = {
            start: moment(bookingStart, "YYYY-MM-DD HH:mm").toDate(),
            end: moment(bookingStart, "YYYY-MM-DD HH:mm")
              .add(booked.duration_hours, "hours")
              .toDate(),
            oddTime: this.generateOddTime(
              moment(bookingStart, "YYYY-MM-DD HH:mm")
            ),
            availStart:
              moment(bookingStart, "YYYY-MM-DD HH:mm").format("HH") + ":00",
            availEnd:
              moment(bookingStart, "YYYY-MM-DD HH:mm")
                .add(1, "hours")
                .format("HH") + ":00",
            status: "booked",
            stick: true,
            rendering: "background",
            className: ["fc-bg-booked"],
          };

          if (this.bookedTimes[onDate]) {
            this.bookedTimes[onDate].push(singleBooking);
          } else {
            this.bookedTimes[onDate] = [singleBooking];
          }

          events.push(singleBooking);
        }
      });

      // check avails
      angular.forEach(availabilitiesOnDate.available_hours, (hour, i) => {
        const startTime = onDate + " " + hour;

        if (this.isNotBeforeToday(moment(startTime, "YYYY-MM-DD H"))) {
          const singleAvailability = {
            id:
              "avail_" +
              moment(startTime, "YYYY-MM-DD H").format("YYYY_MM_DD_H"),
            start: moment(startTime, "YYYY-MM-DD H").toDate(),
            end: moment(startTime, "YYYY-MM-DD H").add(1, "hours").toDate(),
            stick: false,
            rendering: "background",
            status: "available",
            className: ["fc-bg-available"],
          };

          events.push(singleAvailability);
        }
      });
    });

    return events;
  }

  handleCalendarError(err) {
    if (this.environment === "development") console.error(err);
  }

  addAvailabilities(start, end, timezone, callback) {
    this.scheduleLoading = true;
    const events = [];

    return this.senseiSchedule.get(this.assumedTimeZone).then((res) => {
      angular.forEach(
        res.data.availabilities,
        (availabilitiesOnDate, onDate) => {
          // checked booked
          angular.forEach(availabilitiesOnDate.booked_hours, (booked, i) => {
            var bookingStart = onDate + " " + booked.start_time;

            if (
              this.isNotBeforeToday(moment(bookingStart, "YYYY-MM-DD HH:mm"))
            ) {
              var singleBooking = {
                start: moment(bookingStart, "YYYY-MM-DD HH:mm").toDate(),
                end: moment(bookingStart, "YYYY-MM-DD HH:mm")
                  .add(booked.duration_hours, "hours")
                  .toDate(),
                oddTime: this.generateOddTime(
                  moment(bookingStart, "YYYY-MM-DD HH:mm")
                ),
                availStart:
                  moment(bookingStart, "YYYY-MM-DD HH:mm").format("HH") + ":00",
                availEnd:
                  moment(bookingStart, "YYYY-MM-DD HH:mm")
                    .add(1, "hours")
                    .format("HH") + ":00",
                status: "booked",
                stick: true,
                rendering: "background",
                className: ["fc-bg-booked"],
              };

              if (this.bookedTimes[onDate]) {
                this.bookedTimes[onDate].push(singleBooking);
              } else {
                this.bookedTimes[onDate] = [singleBooking];
              }

              events.push(singleBooking);
            }
          });

          // check avails
          angular.forEach(availabilitiesOnDate.available_hours, (hour, i) => {
            const startTime = onDate + " " + hour;

            if (this.isNotBeforeToday(moment(startTime, "YYYY-MM-DD H"))) {
              const singleAvailability = {
                id:
                  "avail_" +
                  moment(startTime, "YYYY-MM-DD H").format("YYYY_MM_DD_H"),
                start: moment(startTime, "YYYY-MM-DD H").toDate(),
                end: moment(startTime, "YYYY-MM-DD H").add(1, "hours").toDate(),
                stick: false,
                rendering: "background",
                status: "available",
                className: ["fc-bg-available"],
              };

              events.push(singleAvailability);
            }
          });
        }
      );
      this.scheduleLoading = false;
      this.availEndpointCalled = true;
      callback(events);
    });
  }

  availabilityDayClick(date, jsEvent, view) {
    const formattedDate = date.format("YYYY-MM-DD");
    const hour = date.format("H");
    const compare = date.format("HH:mm");

    if (this.doesDateNotHaveEvent(date) && this.isNotInThePast(date)) {
      if (!this.newAvailabilitiesSet) this.newAvailabilitiesSet = true;

      const newEvent = {
        id: "avail_" + date.format("YYYY_MM_DD_H"),
        start: date.format("YYYY-MM-DD HH:mm"),
        end: date.clone().add(1, "hour").format("YYYY-MM-DD HH:mm"),
        duration: 1,
        status: "available",
        rendering: "background",
        className: ["fc-bg-available"],
      };

      angular
        .element("#senseiSched")
        .fullCalendar("renderEvent", newEvent, true);
    } else if (
      !this.doesDateNotHaveEvent(date) &&
      !this.doesDateHaveBookingEvent(date) &&
      this.isNotInThePast(date) &&
      !this.doesBookingExist(this.bookedTimes[formattedDate], compare)
    ) {
      if (!this.newAvailabilitiesSet) this.newAvailabilitiesSet = true;

      angular.element("#senseiSched").fullCalendar("removeEvents", (res) => {
        if (
          res.status === "available" &&
          res.start.format("YYYY-MM-DD H") === formattedDate + " " + hour &&
          this.isNotInThePast(res.start) &&
          res.id === "avail_" + res.start.format("YYYY_MM_DD_H")
        ) {
          return true;
        }
      });
    }
  }

  availabilitySelect(start, end) {
    const duration = moment.duration(end.diff(start));
    const hours = duration.asHours();

    if (hours > 1) {
      this.buildSelectedEvents(start, hours);
    }
  }

  availabilityEventRender(event, element, view) {
    const text = event.start.format("h:mmA");
    const textWrap = text.replace(
      ":00",
      '<span class="hide-in-mobile">:00</span>'
    );

    if (event.status === "available") {
      const dateFormatted = event.start.format("YYYY-MM-DD");
      const startCompare = event.start.format("HH:mm");

      if (
        !this.doesBookingExist(this.bookedTimes[dateFormatted], startCompare)
      ) {
        element.append('<div class="event-text">' + textWrap + "</div>");
      }
    } else if (event.status === "booked") {
      element.append('<div class="event-text">' + textWrap + "</div>");
    }
  }

  // extra methods outside of calendar
  buildSelectedEvents(start, hours) {
    if (hours) {
      if (hours > 1 && !this.scheduleLoading) this.scheduleLoading = true;
      const eventArray = [];

      for (let i = 0; i < hours; i++) {
        const startTime = start.clone();
        const newTime = startTime.add(i, "hours");

        if (
          this.doesDateNotHaveEvent(newTime) &&
          this.isNotInThePast(newTime)
        ) {
          if (!this.newAvailabilitiesSet) this.newAvailabilitiesSet = true;

          const newEvent = {
            id: "avail_" + newTime.format("YYYY_MM_DD_H"),
            start: newTime.format("YYYY-MM-DD HH:mm"),
            end: newTime.add(1, "hour").format("YYYY-MM-DD HH:mm"),
            duration: 1,
            status: "available",
            rendering: "background",
            className: ["fc-bg-available"],
          };

          eventArray.push(newEvent);
        }
      }

      angular
        .element("#senseiSched")
        .fullCalendar("renderEvents", eventArray, true);

      if (this.scheduleLoading) this.scheduleLoading = false;
    }
  }

  submitNewAvailabilities(avails) {
    const availsObj = this.buildAvailsObj(avails);
    this.scheduleLoading = true;

    this.senseiSchedule
      .post({
        time_zone: this.assumedTimeZone,
        availabilities: availsObj,
      })
      .then(() => {
        this.newAvailabilitiesSet = false;
        this.scheduleLoading = false;
        const omniTooltipParams = {
          title: "Schedule updated",
          body: "<p>You have successfully saved your availabilities.</p>",
        };
        this.OmnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          persist: true,
          timer: true,
        });
      })
      .catch((err) => {
        this.newAvailabilitiesSet = false;
        this.scheduleLoading = false;
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  buildAvailsObj(avails) {
    const availsObj = {};

    angular.forEach(avails, (avail, i) => {
      const dateKey = avail.start.format("YYYY-MM-DD");
      const startHour = Number(avail.start.format("H"));

      availsObj[dateKey] = availsObj[dateKey] || { available_hours: [] };
      if (!availsObj[dateKey].available_hours.includes(startHour)) {
        availsObj[dateKey].available_hours.push(startHour);
      }
    });

    return availsObj;
  }

  isNotInThePast(time) {
    const currentDay = time.format("YYYY-MM-DD HH") + ":00";

    return moment(currentDay, "YYYY-MM-DD HH:mm").isSameOrAfter(
      moment(),
      "day"
    );
  }

  isNotBeforeToday(time) {
    var currentDay = moment(time).format("YYYY-MM-DD") + " 00:00";
    return moment(currentDay, "YYYY-MM-DD HH:mm").isSameOrAfter(
      moment(),
      "day"
    );
  }

  doesBookingExist(bookings, compareTime) {
    //compareTime format should be HH:mm
    if (!bookings || bookings.length === 0) return false;

    const event = bookings.filter((booking) => {
      return (
        booking.oddTime > 0 &&
        (booking.availStart === compareTime || booking.availEnd === compareTime)
      );
    });
    return event.length > 0;
  }

  doesDateNotHaveEvent(time) {
    let allEvents = [];
    allEvents = angular.element("#senseiSched").fullCalendar("clientEvents");

    const event = allEvents.filter((ev) => {
      const dateFormat = time.format("MM-DD-YYYY H");
      const eventStart = ev.start.format("MM-DD-YYYY H");

      return eventStart === dateFormat;
    });
    return event.length <= 0;
  }

  doesDateHaveBookingEvent(time) {
    let allEvents = [];
    allEvents = angular.element("#senseiSched").fullCalendar("clientEvents");

    const event = allEvents.filter((ev) => {
      const dateFormat = time.format("MM-DD-YYYY H");
      const eventStart = ev.start.format("MM-DD-YYYY H");

      return ev.status === "booked" && eventStart === dateFormat;
    });
    return event.length > 0;
  }

  generateOddTime(time) {
    var minutes = time.format("m");
    return minutes;
  }

  saveSenseiProfileParams() {
    const sortedLanguages = [];
    for (let language in this.currentUser.sensei.languages) {
      sortedLanguages.push(this.currentUser.sensei.languages[language].slug);
    }

    const senseiProfileParams = {
      id: this.currentUser.sensei.id,
      game_id: this.currentUser.games[0].id,
      sensei: {
        first_name: this.currentUser.first_name,
        last_name: this.currentUser.last_name,
        full_name:
          this.currentUser.first_name + " " + this.currentUser.last_name,
        screen_name: this.currentUser.screen_name,
        hourly_rate: this.currentUser.hourly_rate,
        description: this.currentUser.description,
        profile_pic: this.currentUser.profile_photo,
        achievements: this.currentUser.achievements,
        third_party_profile_link: this.currentUser.third_party_profile_link,
        languages: sortedLanguages,
        in_game_communications: [
          {
            game_id: this.currentUser.games[0].id,
            in_game_id: this.currentUser.games[0].in_game_id,
            region: this.currentUser.games[0].region,
          },
        ],
        game_ranks: [
          {
            game_id: this.currentUser.games[0].id,
            rank: this.currentUser.games[0].rank,
          },
        ],
        start_date: moment(this.senseiStartDate, "MMMM D, YYYY").format(
          "YYYY-MM-DD"
        ),
        automate_availabilities: this.automateSchedule,
        on_leave: this.onLeave,
      },
    };

    if (this.gamingAttributes.length > 0) {
      senseiProfileParams["sensei"]["gaming_attribute_selections"] =
        getSelectedAttributesCategories(this.gamingAttributes);
    }

    if (this.isSenseiDiscountEnrollAvailable) {
      delete senseiProfileParams["hourly_rate"];
    }
    return senseiProfileParams;
  }
}

SenseiAccountScheduleController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "OmnibarPointer",
  "User",
  "SenseiSchedule",
  "SenseiProfile",
];
