import CommunicationTemplate from "../../templates/directives/users/communication_template";
import { messageIcon, checkListIcon } from "../../config/icons/iconConfig";

export default function communicationDirective(
  $window,
  Account,
  User,
  OmnibarPointer,
  EventTrackingManager
) {
  return {
    restrict: "E",
    scope: {
      userId: "=",
      isBusiness: "=",
      communicationInfoReminder: "=",
      refresh: "=",
    },
    link: link,
    template: CommunicationTemplate,
  };

  function link(scope, elem, attrs) {
    scope.omnibarPointer = OmnibarPointer;
    scope.userService = User;
    scope.eventTracking = EventTrackingManager;
    scope.icon = { messageIcon, checkListIcon };

    scope.userService
      .getUser()
      .then((userData) => {
        scope.currentUser = userData;
      })
      .catch(angular.noop);

    scope.communicationHeader = () => {
      if (scope.communicationInfoReminder.completed) {
        return "Thanks!";
      } else {
        return `Where are you?!`;
      }
    };

    scope.communicationText = () => {
      if (scope.communicationInfoReminder.completed) {
        return `You can add or edit your <a class="alt" href="/${
          scope.isBusiness() ? "business" : "gamer"
        }/dashboard/contact-info">contact information</a> any time!`;
      } else {
        return "Fill out your contact information so your sensei can get in touch with you!";
      }
    };

    scope.saveContactInfo = (medium, handle) => {
      if (!medium) {
        return scope.omnibarPointer.pushErrorByMessage(
          "Please Choose a Communication Service"
        );
      }

      if (medium === "discord" && !scope.userService.validateDiscord(handle)) {
        return scope.omnibarPointer.pushErrorByMessage(
          "Incorrect formatting. Discord usernames should be 2-32 characters and don't allow any special characters other than periods and underscores."
        );
      }

      if (!handle) {
        return scope.omnibarPointer.pushErrorByMessage(
          "Please Choose a Username"
        );
      }

      const updateParams = {
        user: {
          communication_services: [{ medium: medium, handle: handle }],
          in_game_communications:
            scope.currentUser?.in_game_communications || [],
          game_ranks: scope.currentUser?.game_ranks || [],
          phone_number:
            scope.currentUser?.phone_number !== "N/A"
              ? scope.currentUser.phone_number
              : "",
          country_code: scope.currentUser?.country_code || "",
        },
      };

      Account.update(scope.userId, updateParams)
        .then((res) => {
          scope.communicationInfoReminder.completed = true;

          scope.eventTracking.pushContactUpdate(
            { phoneNumber: scope.currentUser?.phone_number || "" },
            scope.currentUser,
            "",
            [{ medium, handle, changed: true }]
          );

          if (scope.refresh) $window.location.reload();
        })
        .catch((err) => {
          scope.omnibarPointer.pushErrorByHash(err.data.errors);
        });
    };
  }
}

communicationDirective.$inject = [
  "$window",
  "Account",
  "User",
  "OmnibarPointer",
  "EventTrackingManager",
];
