const metaTitles = {
  "sensei_dashboard.landing": "Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.list":
    "Lesson List | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.calendar":
    "Lesson Calender | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.student_history":
    "Lessons | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.my_reviews":
    "Lesson Reviews | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.sensei_notes":
    "Sensei Notes | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.lessons.sensei_note_page":
    "Sensei Notes | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.wallet.cashout":
    "Cashouts | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.wallet.cashout.confirm":
    "Cashouts | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.wallet.history":
    "Transactions | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.profile": "Edit Profile | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.contact_information":
    "Contact Info | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.availability":
    "Availability | Sensei Dashboard | Gamer Sensei",
  "sensei_dashboard.notifications":
    "Notifications | Sensei Dashboard | Gamer Sensei",
};

const setSenseiMetaTitle = (name) => {
  return metaTitles[name] || "Sensei Dashboard | Gamer Sensei";
};

export default setSenseiMetaTitle;
