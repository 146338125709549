import featuredSenseiTemplate from "../../templates/directives/sensei/featured_sensei_template";
import { nextIcon, loadingIcon } from "../../config/icons/iconConfig";

export default function featuredSenseiDirective(
  $location,
  $stateParams,
  $timeout,
  FeaturedSenseiManager
) {
  return {
    restrict: "E",
    scope: {
      headerTitle: "=",
      games: "=",
      previewModal: "=",
      openFeatureSenseiModal: "&",
    },
    link: link,
    template: featuredSenseiTemplate,
  };

  function link(scope, elem, attrs) {
    scope.featured = FeaturedSenseiManager;
    scope.icon = { nextIcon, loadingIcon };

    const firstGame = scope.games.find(function (game) {
      return game.displayOrder === 1;
    });

    scope.selectedGame = firstGame.slug;

    /* TODO: if 'all games' added
    var allObj = { slug: "all", name: "All Games" };
    scope.selectableGames = [
      ...[allObj],
      ...scope.games
    ];*/

    scope.gameOrder = "+displayOrder";
    scope.featured.setFeaturedGame(scope.selectedGame);

    scope.changeSelectedGame = (gameSlug) => {
      scope.featured.setFeaturedGame(gameSlug);
    };

    scope.getURL = () => {
      const slug = scope.featured.getFeaturedGame();

      if (slug === "all") return scope.formatUrl("/games");

      return scope.formatUrl(`/games/${slug}-coaching`);
    };

    scope.getButtonText = () => {
      const slug = scope.featured.getFeaturedGame();

      if (slug === "all") return "View All Games";

      var selected = scope.games.filter(function (game) {
        return game.slug === slug;
      });

      return `View all ${selected[0].name} Coaches`;
    };

    scope.clickOnCarouselItem = (senseiId) => {
      if (
        scope.previewModal &&
        typeof scope.openFeatureSenseiModal === "function"
      ) {
        return scope.openFeatureSenseiModal({ senseiId });
      }
      return;
    };

    scope.formatUrl = (url) => {
      const refParam = $location.search() && $location.search().ref;
      const stateRef = $stateParams.slug;

      if (refParam) {
        return `${url}?ref=${refParam}`;
      } else if (stateRef) {
        return `${url}?ref=${stateRef}`;
      }

      return url;
    };

    $timeout(() => {
      // ng-click does not work within slick directive, so we need to set click event here
      angular.element(".carousel-click").on("click", (event) => {
        const { senseiId } = event.currentTarget.dataset;
        scope.clickOnCarouselItem(senseiId);
      });
    }, 1000);
  }
}

featuredSenseiDirective.$inject = [
  "$location",
  "$stateParams",
  "$timeout",
  "FeaturedSenseiManager",
];
