export default function localeSelectionService($http) {
  var service = {
    languageDropdownOpened: false,
    selectedLocale: null,
    availableLocales: availableLocales(),
    allLanguages: allLanguages(),
    postSelectedLocale: postSelectedLocale,
    getSelectedLocale: getSelectedLocale,
    matchLocations: matchLocations,
  };

  return service;

  ///////////

  function availableLocales() {
    var locales = [
      {
        code: "en",
        name: "English",
        native: "English",
      },
      {
        code: "de",
        name: "German",
        native: "Deutsch",
      },
      {
        code: "fr",
        name: "French",
        native: "Français",
      },
      {
        code: "zh-cn",
        name: "Chinese",
        native: "中文",
      },
      {
        code: "kr",
        name: "Korean",
        native: "韓國語",
      },
      {
        code: "pl",
        name: "Polish",
        native: "Polski",
      },
      {
        code: "ru",
        name: "Russian",
        native: "Russkiy",
      },
    ];
    return locales;
  }

  function allLanguages() {
    var languages = [
      {
        name: "Welsh",
        slug: "welsh",
      },
      {
        name: "Vietnamese",
        slug: "vietnamese",
      },
      {
        name: "Urdu",
        slug: "urdu",
      },
      {
        name: "Ukrainian",
        slug: "ukrainian",
      },
      {
        name: "Turkish",
        slug: "turkish",
      },
      {
        name: "Thai",
        slug: "thai",
      },
      {
        name: "Tamil",
        slug: "tamil",
      },
      {
        name: "Swedish",
        slug: "swedish",
      },
      {
        name: "Swahili",
        slug: "swahili",
      },
      {
        name: "Spanish (Spain)",
        slug: "spanish-spain",
      },
      {
        name: "Spanish (Latin America)",
        slug: "spanish-latin-america",
      },
      {
        name: "Slovenian",
        slug: "slovenian",
      },
      {
        name: "Slovak",
        slug: "slovak",
      },
      {
        name: "Serbian",
        slug: "serbian",
      },
      {
        name: "Russian",
        slug: "russian",
      },
      {
        name: "Romanian",
        slug: "romanian",
      },
      {
        name: "Portuguese",
        slug: "portuguese",
      },
      {
        name: "Polish",
        slug: "polish",
      },
      {
        name: "Persian",
        slug: "persian",
      },
      {
        name: "Norwegian",
        slug: "norwegian",
      },
      {
        name: "Myanmar",
        slug: "myanmar",
      },
      {
        name: "Mongolian",
        slug: "mongolian",
      },
      {
        name: "Macedonian",
        slug: "macedonian",
      },
      {
        name: "Lithuanian",
        slug: "lithuanian",
      },
      {
        name: "Latvian",
        slug: "latvian",
      },
      {
        name: "Korean",
        slug: "korean",
      },
      {
        name: "Khmer",
        slug: "khmer",
      },
      {
        name: "Japanese",
        slug: "japanese",
      },
      {
        name: "Italian",
        slug: "italian",
      },
      {
        name: "Hungarian",
        slug: "hungarian",
      },
      {
        name: "Hindi",
        slug: "hindi",
      },
      {
        name: "Hebrew",
        slug: "hebrew",
      },
      {
        name: "Gujarati",
        slug: "gujarati",
      },
      {
        name: "Greek",
        slug: "greek",
      },
      {
        name: "German",
        slug: "german",
      },
      {
        name: "French",
        slug: "french",
      },
      {
        name: "Finnish",
        slug: "finnish",
      },
      {
        name: "Estonian",
        slug: "estonian",
      },
      {
        name: "English",
        slug: "english",
      },
      {
        name: "Dutch",
        slug: "dutch",
      },
      {
        name: "Danish",
        slug: "danish",
      },
      {
        name: "Czech",
        slug: "czech",
      },
      {
        name: "Croatian",
        slug: "croatian",
      },
      {
        name: "Chinese (Cantonese)",
        slug: "chinese-cantonese",
      },
      {
        name: "Chinese (Mandarin)",
        slug: "chinese-mandarin",
      },
      {
        name: "Catalan",
        slug: "Catalan",
      },
      {
        name: "Bulgarian",
        slug: "bulgarian",
      },
      {
        name: "Brazilian Portuguese",
        slug: "brazilian-portuguese",
      },
      {
        name: "Bosnian",
        slug: "bosnian",
      },
      {
        name: "Bahasa Malaysia",
        slug: "bahasa-malaysia",
      },
      {
        name: "Bahasa Indonesia",
        slug: "bahasa-indonesia",
      },
      {
        name: "Arabic",
        slug: "arabic",
      },
      {
        name: "American Sign Language",
        slug: "american-sign-language",
      },
    ];
    return languages;
  }

  function postSelectedLocale(data) {
    return $http.post("/api/web/locale_preference_selections", data);
  }

  function getSelectedLocale() {
    return $http.get("/api/web/locale_preference_selections");
  }

  function matchLocations(selected, all) {
    var newSelection = {};
    for (let i in all) {
      var current = all[i];
      if (current.code === selected) {
        newSelection = current.native;
      }
    }
    return newSelection;
  }
}

localeSelectionService.$inject = ["$http"];
