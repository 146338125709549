export default function getEventEndTime(start, end, duration, date) {
  if (end) {
    return date + " " + end;
  } else {
    return moment(date + " " + start, "YYYY-MM-DD HH:mm")
      .add(duration, "h")
      .subtract(1, "m")
      .format("YYYY-MM-DD HH:mm");
  }
}
