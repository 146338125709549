// Importing the images
import ShawsheenTechImage from "../../images/logos/schools/shawsheen_tech.png";
import MinnesotaStateUniversityImage from "../../images/logos/schools/minnesota_state_university.png";
import MessiahCollegeImage from "../../images/logos/schools/messiah_esports.png";
import KennesawStateImage from "../../images/logos/schools/kennesaw_state.png";
import FullSailImage from "../../images/logos/schools/full_sail.png";
import CapitolTechImage from "../../images/logos/schools/capitol_tech_chargers.png";
import AshlandUniversityImage from "../../images/logos/schools/ashland_university.png";

export default function partnerUtilsService() {
  var utils = {
    schools: [
      {
        name: "Shawsheen Valley Technical High School",
        image: ShawsheenTechImage,
        active: true,
      },
      {
        name: "Minnesota State University Mankato",
        image: MinnesotaStateUniversityImage,
        active: true,
      },
      {
        name: "Messiah College",
        image: MessiahCollegeImage,
        active: true,
      },
      {
        name: "Kennesaw State University",
        image: KennesawStateImage,
        active: true,
      },
      {
        name: "Full Sail University",
        image: FullSailImage,
        active: true,
      },
      {
        name: "Capitol Tech",
        image: CapitolTechImage,
        active: true,
      },
      {
        name: "Ashland University",
        image: AshlandUniversityImage,
        active: true,
      },
    ],
  };

  return utils;
}
