export default function visitorService($http) {

  var service = {
    geoLocale: geoLocale
  };

  return service;

  ///////////

  function geoLocale(id) {
    //default to US if no visitor is found
    if (id) {
      return $http.get('/api/web/visitors/' + id + '/geo_locale');
    } else {
      return { data: { geo_locale: { country_code: 'US' } } };
    }
  }
}

visitorService.$inject = ['$http'];