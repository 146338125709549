export default class BusinessWalletPaymentController {
  constructor(
    $scope,
    $rootScope,
    $state,
    User,
    Payment,
    stripe,
    OmnibarPointer
  ) {
    $rootScope.bodylayout = "gamer payment";
    this.userId = $scope.currentId;
    this.role = "gamer";
    this.stripeKey = $scope.stripePublishableKey;
    // angular providers
    this.$state = $state;
    // services
    this.stripe = stripe;
    this.userService = User;
    this.paymentService = Payment;
    this.omnibarPointer = OmnibarPointer;
    // strings
    this.helperText = "Edit your profile and settings";
    // booleans
    this.buttonDisable = false;
    this.combineName = false;

    this.initUser({
      checkFirstRun: (firstRun) => {
        $rootScope.firstRun = firstRun;
      },
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);

    this.userService
      .getUser()
      .then((userData) => {
        this.currentUser = userData;

        if (callbacks?.checkFirstRun)
          callbacks.checkFirstRun(userData.first_run);

        if (this.userService.getSaveStatus()) {
          const omniTooltipParams = {
            title: "Payment updated",
            body: "<p>You have successfully saved your payment settings.</p>",
          };

          this.omnibarPointer.pushTooltip(omniTooltipParams, {
            tooltipCallback: false,
            persist: true,
            timer: true,
          });

          this.userService.setSaveStatus(false);
        }
      })
      .catch(angular.noop);
  }

  // action methods
  deleteCreditCard(card) {
    this.paymentService
      .deleteCreditCard(card.id)
      .then((res) => {
        const index = this.currentUser.credit_cards.indexOf(card);

        this.currentUser.credit_cards.splice(index, 1);
      })
      .catch(angular.noop);
  }

  validateForm(isValid) {
    if (!isValid) {
      this.omnibarPointer.pushErrorByMessage(
        "Please fill in all of the required fields"
      );
    }
    return isValid;
  }

  saveCreditCard() {
    this.buttonDisable = true;
    this.omnibarPointer.resetOmnibar();

    this.stripe.setPublishableKey(this.stripeKey);

    const creditCardParams = {
      number: this.currentUser.card_number,
      name: this.currentUser.card_name,
      exp_month: this.currentUser.card_exp_month,
      exp_year: this.currentUser.card_exp_year,
      cvc: this.currentUser.card_cvv,
      address_zip: this.currentUser.card_zip,
    };

    this.stripe.card
      .createToken(creditCardParams)
      .then((res) => {
        const card = {
          credit_card: {
            stripe_card_token: res.id,
            credit_card_name: res.card.name,
            credit_card_last_four: res.card.last4,
            credit_card_type: res.card.brand,
            expiration_month: res.card.exp_month,
            expiration_year: res.card.exp_year,
            postal_code: res.card.address_zip,
          },
        };
        this.paymentService
          .createCreditCard(card)
          .then((res) => {
            this.userService.setSaveStatus(true);
            this.$state.reload();
          })
          .catch((err) => {
            this.omnibarPointer.pushErrorByHash(err.data.errors);
            this.buttonDisable = false;
          });
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err);
        this.buttonDisable = false;
      });
  }
}

BusinessWalletPaymentController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "User",
  "Payment",
  "stripe",
  "OmnibarPointer",
];
