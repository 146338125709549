import cookies from "../../config/legal/cookies-text";

export default class CookiesPageController {
  constructor(MetaTagsService) {
    this.cookies = cookies;

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Cookies And Similar Technologies Policy | Gamer Sensei";
    const metaDescription =
      "Gamer Sensei's Cookies and Similar Technologies Policy explains data collection process.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

CookiesPageController.$inject = ["MetaTagsService"];
