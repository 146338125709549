/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application', 'data-turbolinks-track': 'reload' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)
//const images = require.context("../images", true);
//const imagePath = (name) => images(name, true);
//const templates = require.context("../templates", true);
import "jquery";
import "moment";
import "fullcalendar";
import "angular";
import "angular-animate";
import "angular-aria";
import "angular-cookies";
import "angular-route";
import "angular-resource";
import "angular-sanitize";
import "angular-material";
import "angular-messages";
import "angular-breadcrumb";
import "angular-elastic";
import "angular-ellipsis";
import "angular-ordinal";
import "angular-socialshare";
import "angular-scroll";
import "angular-stripe";
import "angular-tooltips";
import "angular-utils-pagination";
import "angular-ui-router/release/angular-ui-router";
import "angular-ui-router/release/stateEvents";
import "angular1-star-rating";
import "angularjs-datepicker";
import "ng-file-upload";
import "ng-pattern-restrict";
import "ng-showdown";
import "ngclipboard";
import "angulartics";
import "angulartics-google-analytics";
import "angulartics-google-tag-manager";
import "angularjs-slider";

import "../plugins/slick";
import "../plugins/calendar";
import "../plugins/braintree-angular-gamersensei/dist/braintree-angular";

import "../apps/gs-reusable";
import "../apps/gs-payment";
import "../apps/gs-checkout";
import "../apps/gs-dashboard";
import "../apps/gs-dashboard-modules";
import "../apps/gs-dashboard-gamer";
import "../apps/gs-dashboard-sensei";
import "../apps/gs-dashboard-business";
import "../apps/gs-adaptive-sensei-search";
import "../apps/gs-sensei-public-profile";
import "../apps/gs-game-landing-page";
import "../apps/gs-gift-card";
import "../apps/gs-gift-card-landing-page";
import "../apps/gs-partner-connect-page";
import "../apps/gs-market";
import "../apps/gs-access";

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context("../images", true));
importAll(require.context("../videos", true));
