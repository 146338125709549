import {
  messageIcon,
  facebookIcon,
  twitterIcon,
  instagramIcon,
} from "../../config/icons/iconConfig";

export default class PressPageController {
  constructor(MetaTagsService) {
    this.contact = {
      message: {
        icon: messageIcon,
        text: "Contact us by email",
        url: "mailto:contact@gamersensei.com",
      },
      facebook: {
        icon: facebookIcon,
        text: "Contact us on Facebook",
        url: "https://www.facebook.com/gamersenseiapp/",
      },
      twitter: {
        icon: twitterIcon,
        text: "Contact us on Twitter",
        url: "https://twitter.com/GamerSenseiApp",
      },
      instagram: {
        icon: instagramIcon,
        text: "Contact us on Instagram",
        url: "https://www.instagram.com/gamersenseiapp/",
      },
    };
    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Press | Gamer Sensei";
    const metaDescription =
      "Professional, one-on-one training that boosts your skills in your favorite games.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

PressPageController.$inject = ["MetaTagsService"];
