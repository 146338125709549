export default function metaTagService() {
  var defaultTags = {};
  var tagElements = [];
  var service = {
    setDefaultTags,
    setTags,
  };

  return service;

  // Service functions
  function setDefaultTags(tags) {
    angular.copy(tags, defaultTags);
    setTags({});
  }

  function setTags(tags) {
    clearTags();
    mergeDefaultTags(tags);
    angular.forEach(tags, (content, name) => {
      const tagElement = getTagElement(content, name);

      document.head.appendChild(tagElement);
      tagElements.push(tagElement);
    });
  }

  // Internal functions
  function mergeDefaultTags(tags) {
    angular.forEach(defaultTags, (defaultTagContent, defaultTagName) => {
      if (!tags[defaultTagName]) {
        tags[defaultTagName] = defaultTagContent;
      } else if (defaultTagName === "title") {
        tags["title"] += " - " + defaultTagContent;
      }
    });
    return tags;
  }

  function getTagElement(content, name) {
    if (name == "title") {
      // Special provision for the title element
      var title = document.createElement("title");
      title.textContent = content;
      return title;
    } else {
      // Opengraph uses [property], but everything else uses [name]
      var nameAttr = name.indexOf("og:") === 0 ? "property" : "name";
      var meta = document.createElement("meta");
      meta.setAttribute(nameAttr, name);
      meta.setAttribute("content", content);
      return meta;
    }
  }

  function clearTags() {
    angular.forEach(tagElements, (tagElement) => {
      document.head.removeChild(tagElement);
    });
    tagElements.length = 0;
  }
}
