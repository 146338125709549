import loadingOverlayTemplate from "../../../templates/directives/popup/modal/loading_overlay_template";
import { gsLogoDecorative } from "../../../config/brand/brandConfig";

export default function loadingOverlayDirective() {
  return {
    restrict: "E",
    scope: {
      loadingMessage: "=",
    },
    link: (scope) => {
      scope.gsLogoDecorative = gsLogoDecorative;
    },
    template: loadingOverlayTemplate,
  };
}
