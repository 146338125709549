export default function senseiLeaderboardService($http) {
  const service = {
    getStandings: getStandings,
    getPolicies: getPolicies,
  };

  return service;

  ///////////

  function getStandings(config) {
    return $http.get("/api/web/sensei_leaderboard_standings", config);
  }

  function getPolicies(config) {
    return $http.get("/api/web/sensei_leaderboard_policies", config);
  }
}

senseiLeaderboardService.$inject = ["$http"];
