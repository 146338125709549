export default function trapFocusDirective($timeout) {
  return {
    restrict: "A",
    priority: 1,
    link: function (scope, element, attr) {
      element.focus();
      $timeout(function () {
        var focusableEls = element[0].querySelectorAll(
          `a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])`
        );
        var firstFocusableEl = focusableEls[0];
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        // ReSharper disable once InconsistentNaming
        var KEYCODE_TAB = 9;

        element.bind("keydown", function (event) {
          var isTabPressed =
            event.key === "Tab" ||
            event.which === KEYCODE_TAB ||
            event.keyCode === KEYCODE_TAB;

          if (!isTabPressed) {
            return;
          }

          if (event.shiftKey) {
            /* shift + tab */ if (document.activeElement === firstFocusableEl) {
              lastFocusableEl.focus();
              event.preventDefault();
            }
          } /* tab */ else {
            if (document.activeElement === lastFocusableEl) {
              firstFocusableEl.focus();
              event.preventDefault();
            }
          }
        });
      }, 0);
    },
  };
}

trapFocusDirective.$inject = ["$timeout"];
