export default function userDataService() {
  const userData = function (_userData) {
    this.data = _userData;
    this.data.user_id = _userData.id;
    this.data.firstRun = _userData.first_run;
    this.data.first_name = this.getFirstNameFromFullName(_userData.full_name);
    this.data.last_name = this.getLastNameFromFullName(_userData.full_name);
    this.data.profile_photo = this.getProfilePhotoFromPhotoURL(
      _userData.profile_photo_url_square
    );
    this.data.is_afk = _userData.is_afk;
  };

  userData.prototype.getFirstNameFromFullName = function (full_name) {
    if (full_name === undefined || full_name === null) return;
    const full = full_name.split(/\s+/);

    return full.slice(0, -1).join(" ");
  };

  userData.prototype.getLastNameFromFullName = function (full_name) {
    if (full_name === undefined || full_name === null) return;
    const full = full_name.split(/\s+/);

    return full.pop();
  };

  userData.prototype.getProfilePhotoFromPhotoURL = function (photo_url) {
    if (photo_url === undefined) return;
    if (photo_url.indexOf("icons/anon.png") > -1) return "/assets/" + photo_url;

    return photo_url;
  };

  userData.prototype.getGameDescriptionFromGame = function (_games) {
    const gameNames = [];

    angular.forEach(_games, (game) => {
      gameNames.push(game.name);
    });

    return gameNames.sort().join(", ");
  };

  return userData;
}

userDataService.$inject = [];
