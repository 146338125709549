export default function calendarBasedLessonService($http) {
  const service = {
    getLessons,
    singleChangeRequest,
    recurringChangeRequest,
  };

  return service;

  ///////////

  function singleChangeRequest(lesson, status) {
    return (
      lesson.status === "awaiting_direct_booking_schedule_confirm" &&
      lesson.direct_training_request_schedule_status === status
    );
  }

  function recurringChangeRequest(lesson, status) {
    if (!lesson.recurring_lessons_reschedule_requests) return false;
    return (
      lesson.recurring_lessons_reschedule_requests[0] &&
      lesson.recurring_lessons_reschedule_requests[0].status === status
    );
  }

  function getLessons(config) {
    return $http.get("/api/web/calendar_based_lessons", config);
  }
}

calendarBasedLessonService.$inject = ["$http"];
