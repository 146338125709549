const initialPurchaseParams = (manager) => {
  const params = {
    gift_card: {
      recipient_name: manager.recipientName,
      recipient_email: manager.recipientEmail,
      amount: manager.selectedOption,
      design: manager.selectedDesign.name,
      message: manager.message,
    },
    credit_card: { void: true },
    paypal: { void: true },
    amazon_pay: { void: true },
  };

  if (manager.isGuest) {
    params.guest_gift_card_purchaser = {
      email: manager.purchaserEmail,
      name: manager.purchaserName,
    };
  }

  return params;
};

export default initialPurchaseParams;
