import tos from "../../config/legal/terms-of-service-text";

export default class TermsPageController {
  constructor(MetaTagsService) {
    this.tos = tos;

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Terms and Conditions of Use | Gamer Sensei";
    const metaDescription =
      "Gamer Sensei's Terms of Use governs your use of this website.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

TermsPageController.$inject = ["MetaTagsService"];
