export default function tokenDetailsService() {
  var utils = {
    tierInfo: {
      legendary: {
        description: `
          <span>Masters of their craft who've dedicated their lives to the pursuit of excellence and professionalism in the world of esports. Few rival their level of expertise as they are among the most talented minds in competitive esports.</span>
        `,
        recommended: `
          <div class="text-left"><em class="text-small">Recommended for:</em><br />	
          <span class="text-highlight-alt--darken">Proficient gamers</span> interested in competing at&nbsp;a&nbsp;<span class="text-highlight-alt--darken">professional</span>&nbsp;level<br />
          <span class="text-highlight-alt--darken">Beginner</span> and <span class="text-highlight-alt--darken">Intermediate</span> players ready to jump&nbsp;into&nbsp;the&nbsp;deep&nbsp;end.</div>
        `,
      },
      shogun: {
        label: "Expert Training",
        description: `
          <span itemprop="description">Esports veterans who have competed against some of the greatest talent in their respective games and are recognized for their success on global competition stages. They have extensive coaching experience at the highest levels of play.</span>
        `,
        recommended: `
          <div class="text-left"><em class="text-small">Recommended for:</em><br />	   	
          <span class="text-highlight-alt--darken">Proficient</span> gamers eager to push beyond their limits.<br />
          <span class="text-highlight-alt--darken">Beginner</span> and <span class="text-highlight-alt--darken">Intermediate</span> players who want to start big</div>
        `,
      },
      samurai: {
        label: "Advanced Training",
        description: `
          <span itemprop="description">Trained experts who've earned a distinguished place within the esports community through extensive experience coaching national teams and the next generation of esports pros. These Sensei are committed coaches devoted to coaching students and improving play at all ranks.</span>
        `,
        recommended: `
          <div class="text-left"><em class="text-small">Recommended for:</em><br />	
          <span class="text-highlight-alt--darken">Intermediate</span> players looking to expand their skill sets<br />
          <span class="text-highlight-alt--darken">Beginners</span> wanting to ramp up quickly</div>
        `,
      },
      ronin: {
        label: "Standard Training",
        description: `
          <span itemprop="description">Ronin have achieved the highest ranks in their game of choice and possess a measured aptitude for guiding their students forward. These sensei have proven themselves as qualified teachers in their respective esport titles.</span>
        `,
        recommended: `
          <div class="text-left"><em class="text-small">Recommended for:</em><br />	
          <span class="text-highlight-alt--darken">Beginners</span> just starting their journey<br />
          <span class="text-highlight-alt--darken">Intermediate</span> players who need a strong entry point</div>
        `,
      },
    },
    getTierLabel,
    getTierDescription,
    getTierRecommended,
    isThisNotLegendary,
    isThisNotLegendaryAndSkillAssess,
  };

  return utils;

  ////

  function getTierLabel(tierKey) {
    return utils.tierInfo[tierKey].label;
  }

  function getTierDescription(tierKey) {
    return utils.tierInfo[tierKey].description;
  }

  function getTierRecommended(tierKey) {
    return utils.tierInfo[tierKey].recommended;
  }

  function isThisNotLegendary(tierKey) {
    var legend = tierKey.includes("legendary");

    return !legend;
  }

  function isThisNotLegendaryAndSkillAssess(tier, type) {
    var skill = type.includes("skills-assessment");
    return !skill && utils.isThisNotLegendary(tier);
  }
}
