import sectionImgBgTemplate from "../../templates/directives/display/section_image_background_template";
import { supportsWebP } from "../../config/utils/utilsConfig";

export default function sectionImageBackgroundDirective($timeout, LazyLoad) {
  return {
    restrict: "E",
    link,
    scope: {
      image: "=",
      webp: "=",
      header: "=",
      body: "=",
      cta: "=",
      position: "=",
      transparentBg: "=",
    },
    template: sectionImgBgTemplate,
  };

  function link(scope, elem, attrs) {
    scope.sectionWrapperClasses = () => {
      return `section-block-${scope.position}`;
    };

    scope.getImageToUse = () => {
      if (scope.webp && scope.useWebP) {
        return scope.webp;
      }

      return scope.image;
    };

    supportsWebP().then((supported) => {
      if (supported) {
        scope.useWebP = true;
      } else {
        scope.useWebP = false;
      }
    });

    $timeout(() => {
      LazyLoad.set();
    });
  }
}

sectionImageBackgroundDirective.$inject = ["$timeout", "LazyLoad"];
