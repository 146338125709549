export default class GamerWalletSessionHistoryController {
  constructor(
    $scope,
    $location,
    $window,
    User,
    TokenBookingService,
    UIFeatureGate
  ) {
    this.userId = $scope.currentId;
    // injected and imported
    this.location = $location;
    this.window = $window;
    // services
    this.userService = User;
    this.tokenBooking = TokenBookingService;
    // time
    this.today = moment();
    // boolean
    this.confirmationModalOpened = false;
    this.refundsAvailable =
      UIFeatureGate.data.ui_feature_gates.token_refunds_available;
    // objects and arrays
    this.selectedTokenForRefund = {};
    this.communicationInfoReminder = {
      show: false,
      completed: false,
    };

    this.initUser();
  }

  // init methods
  initUser() {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("gamer");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.tokenBooking.setTokenItemDeposits();
        this.communicationInfoReminder.show =
          this.currentUser.communication_services.length === 0;
      })
      .catch(angular.noop);
  }

  // action methods
  confirmRefund(token) {
    this.confirmationModalOpened = {};

    this.tokenBooking
      .refundToken({
        refund: {
          token_item_id: token.token_item_id,
        },
      })
      .then((res) => {
        this.location.path("/gamer/dashboard/wallet");
        this.window.location.reload();
      })
      .catch(angular.noop);
  }

  cancelRefund() {
    this.confirmationModalOpened = false;
    this.selectedTokenForRefund = {};
  }

  refundToken(token) {
    this.confirmationModalOpened = true;
    this.selectedTokenForRefund = token;
  }

  // display
  areRefundsAvailable(session) {
    return this.refundsAvailable;
  }

  areThereNoTokenDeposits() {
    return (
      this.tokenBooking.haveTokenDepositsBeenSet() &&
      Object.keys(this.tokenBooking.getTokenDeposits()).length === 0
    );
  }

  doTokenDepositsExist() {
    return Object.keys(this.tokenBooking.getTokenDeposits()).length;
  }

  getStatusClass(status) {
    if (status === "expired") {
      return "card-notice-red";
    }

    return "card-notice-green";
  }
}

GamerWalletSessionHistoryController.$inject = [
  "$scope",
  "$location",
  "$window",
  "User",
  "TokenBookingService",
  "UIFeatureGate",
];
