import privacySupplement from "../../config/legal/privacy-policy-supplement-text";

export default class PrivacySupplementPageController {
  constructor(MetaTagsService) {
    this.privacySupplement = privacySupplement;

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Privacy Policy Supplement | Gamer Sensei";
    const metaDescription =
      "Gamer Sensei's Privacy Policy explains who we are, how we collect, share and use personal information about you, and how you can exercise your privacy rights.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

PrivacySupplementPageController.$inject = ["MetaTagsService"];
