import assessmentsImage from "../../images/sections/gs_assessments.jpg";
import {
  checkListIcon,
  communicationIcon,
  teamIcon,
} from "../../config/icons/iconConfig";

export default class AssessmentsPageController {
  constructor(MetaTagsService) {
    this.assessmentsImage = assessmentsImage;
    this.icon = { checkListIcon, communicationIcon, teamIcon };
    this.assessmentsFAQ = [
      {
        question: "Do I need to prepare anything in advance?",
        answer:
          "<p>Not necessarily! If you'd like to bring replays for the Sensei to see as part of the assessment they're more than welcome! Preparing a recorded video of a recent performance in-game is recommended to get the highest quality assessment possible. But all you need is you!</p>",
      },
      {
        question: "What happens after the assessment?",
        answer:
          "<p>Report Card and improvement plan.</p><p>We'd love for you to follow-up with your Sensei to schedule a review. And hopefully you'll already be on your way to booking additional lessons to continue the journey. Remember, the more you book the more you save with our bundle packs.</p>",
      },
    ];

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Assessments | Gamer Sensei";
    const metaDescription =
      "Take your skills to the next level with a professional coach from Gamer Sensei.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

AssessmentsPageController.$inject = ["MetaTagsService"];
