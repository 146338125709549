export default class ChatsListController {
  constructor($rootScope, $state, UIFeatureGate, SenseiTrainingRequestManager) {
    this.root = $rootScope;
    this.$state = $state;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.SenseiTrainingRequestManager.setWithSenseiNotes(null);
    this.SenseiTrainingRequestManager.setWithSenseiChats(true);

    // Feature Gate
    this.isSenseiChatsAvailable =
      UIFeatureGate.data.ui_feature_gates.sensei_chats_available;
    this.isConfirmedLessonBucketAvailable =
      UIFeatureGate.data.ui_feature_gates.confirmed_lesson_bucket;

    if (this.SenseiTrainingRequestManager.isSensei()) {
      this.userType = "sensei";
    } else {
      this.userType = "gamer";
    }
    this.root.bodylayout = "lessons list " + this.userType;

    this.root.$on("strSET", (event, data) => {
      this.allLessons = this.SenseiTrainingRequestManager.allLessons;
    });

    this.initSenseiChatsList(true);
  }

  // init methods
  initSenseiChatsList(force) {
    if (this.isConfirmedLessonBucketAvailable) {
      this.SenseiTrainingRequestManager.isConfirmedLessonBucketAvailable = true;
    }
    this.pageNotification = { show: false };
    this.SenseiTrainingRequestManager.init(force)
      .then(() => {
        this.root.$broadcast("strSET", true);
      })
      .catch(angular.noop);
  }

  // display methods
  buildSessionTitle(session) {
    let time;

    if (session.sessionDurationHours === 0.5) {
      time = "30-Minute";
    } else {
      time = `${session.sessionDurationHours}-Hour`;
    }

    if (session.tokenTypeName) {
      return `${time} ${session.gameName} ${session.tokenTypeName} Session on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
    }

    return `${time} ${session.gameName} Lesson on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
  }

  openChat(lesson) {
    if (this.userType == "gamer") {
      this.$state.go("gamer_dashboard.lessons.sensei_chat_page", {
        lessonId: lesson.request.id,
      });
    } else {
      this.$state.go("sensei_dashboard.lessons.sensei_chat_page", {
        lessonId: lesson.request.id,
      });
    }
  }
}

ChatsListController.$inject = [
  "$rootScope",
  "$state",
  "UIFeatureGate",
  "SenseiTrainingRequestManager",
];
