import ageGateModalTemplate from "../../../templates/directives/popup/modal/age_gate_modal_template";
import { infoIcon } from "../../../config/icons/iconConfig";

export default function ageGateModalDirective(
  AccessControl,
  OmnibarPointer,
  ModalManager
) {
  return {
    restrict: "E",
    scope: {
      user: "=",
      submitCallback: "&", // required callback
    },
    link: link,
    template: ageGateModalTemplate,
  };

  function link(scope, elem, attrs) {
    scope.infoIcon = infoIcon;
    // services
    scope.modalManager = ModalManager;
    scope.access = AccessControl;
    scope.omnibarPointer = OmnibarPointer;
    // booleans
    scope.opened = false;
    scope.under13 = false;
    scope.minor = false;
    // time
    scope.years = scope.access.getPast100Years();
    scope.months = scope.access.getMonths();
    // obj
    scope.parent = { approval: false };

    // when age gate broadcast fires
    scope.$on("openAgeGate", (event, { birthday, isSignUp }) => {
      scope.initModal(); // set modal

      if (isSignUp) {
        scope.initSignUp(birthday); // set existing birthday if user is signing up
      } else if (!isSignUp) {
        scope.initLogin();
      }
    });

    // init functions
    scope.initModal = () => {
      scope.opened = true;
      scope.modalManager.setOpenBG();
      scope.parentalApprovalId = `parental-approval_${scope.$id}`;
    };

    scope.initSignUp = (birthday) => {
      scope.isSignUp = true;
      scope.birthday = birthday;

      if (scope.isAgeBetween13And17(birthday)) {
        scope.minor = true;
      }
      if (!scope.is13orOlder(birthday)) {
        scope.under13 = true;
      }
    };

    scope.initLogin = () => {
      // when user is found
      const watchUser = scope.$watch(
        () => scope.user.email,
        (newVal) => {
          if (newVal) scope.userEmail = newVal;
          watchUser();
        }
      );
    };

    // display funtions
    scope.showUnder13 = () => {
      return scope.under13;
    };
    scope.isParentalConsentNeeded = () => {
      return scope.minor && !scope.under13;
    };

    scope.isSubmitDisabled = () => {
      if (
        !scope.is13orOlder(scope.birthday) ||
        !Number.isInteger(scope.access.getAge("month")) ||
        !Number.isInteger(scope.access.getAge("year")) ||
        (scope.isAgeBetween13And17(scope.birthday) && !scope.parent.approval)
      ) {
        return true;
      }

      return false;
    };

    // age check functions
    scope.is13orOlder = (birthday) => {
      const today = new Date();

      const validBirthday = new Date(
        today.getFullYear() - 13,
        today.getMonth(),
        today.getDate()
      );

      return validBirthday > birthday;
    };

    scope.isAgeBetween13And17 = (birthday) => {
      const today = new Date();

      const lowerAge = new Date(
        today.getFullYear() - 13,
        today.getMonth(),
        today.getDate()
      );

      const upperAge = new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate() - 1
      );

      return lowerAge > birthday && upperAge < birthday;
    };

    // action functions
    scope.submitParentalApproval = () => {
      if (scope.isSignUp) {
        scope.submitCallback({
          parentalApproval: scope.parent.approval,
        });
        scope.opened = false;
        scope.modalManager.setCloseBG();
      } else {
        const ageParams = {
          email: scope.userEmail,
          is_minor: !scope.access.isValidBirthday(),
          birth_date: scope.access.getBirthdayFormat(),
        };

        if (scope.parent?.approval) {
          ageParams.parental_consent = true;
        }

        scope.access
          .confirmAge(ageParams)
          .then((res) => {
            scope.opened = false;
            scope.modalManager.setCloseBG();
            const omniTooltipParams = {
              title: "Success!",
              body: "<p>Thank you for submitting your birthdate.</p>",
            };

            scope.omnibarPointer.pushTooltip(omniTooltipParams, {
              tooltipCallback: false,
              timer: true,
            });
          })
          .then((err) => {
            scope.omnibarPointer.pushErrorByHash(err.data.errors);
          });
      }
    };

    // internal functions
    scope.dateChange = () => {
      scope.birthday = new Date(
        scope.access.getAge("year"),
        scope.access.getAge("month")
      );

      if (
        scope.isAgeBetween13And17(scope.birthday) ||
        !scope.is13orOlder(scope.birthday)
      ) {
        scope.minor = true;
      } else {
        scope.minor = false;
      }

      if (!scope.is13orOlder(scope.birthday)) {
        scope.under13 = true;
      } else {
        scope.under13 = false;
      }
    };

    // init modal
    scope.modalManager.setEventListener();
  }
}

ageGateModalDirective.$inject = [
  "AccessControl",
  "OmnibarPointer",
  "ModalManager",
];
