import moment from "moment-timezone";

export default function senseiTrainingRequestService($http) {
  const SenseiTrainingRequest = function (request, perspective) {
    this.request = request;
    this.setId(request.id);
    this.setUuid(request.uuid);
    this.setRequestScheduleId(
      getRequestScheduleIdByRequestSchedule(
        request.direct_training_request_schedule
      )
    );
    this.setPerspective(perspective);
    this.setAffinity(request.affinity);
    this.setOtherPartyByRequest(request, perspective);
    this.setGameName(request.game_name);
    this.setConferenceAppOff(request.conference_app_off);
    this.setConferenceAvailableAtRequest(
      request.conference_cta_pre_lesson_available_at
    );
    this.setConferenceAvailableUntilRequest(
      request.conference_cta_post_lesson_available_until
    );
    this.setDate(request.starts_at);
    this.setDateString(getDateMap(request.starts_at).dateString);
    this.setTzString(getTimeZone(request.starts_at));
    this.setTimeString(getDateMap(request.starts_at).timeString);
    this.setStatus(request.status);
    this.setStatusText(getStatusTextByRequest(request, perspective));
    this.setSecondHeadline(getSecondHeadlineByRequest(request, perspective));
    this.setBodyStatusText(getBodyStatusTextByRequest(request, perspective));
    this.setTimeInteractionCTAsVisible(
      getTimeInteractionCTAsVisibleByRequest(request, perspective)
    );
    this.setPertinentLessonFee(
      getPertinentLessonFeeByPerspective(request, perspective)
    );
    this.setSessionDurationHours(request.session_duration_hours);
    this.setExpiredSessionEligibleForFurtherInteractions(
      request.expired_session_eligible_for_further_interaction
    );
    this.setImproveOnNotes(request.improve_on_notes);
    this.setTokenTypeName(request.token_type_name);
    this.setExistCTA();
    this.setUnread(request, perspective);
    this.setHourlyRate(request.booking_hourly_rate);
    this.setCreditCard(request.credit_card);
    this.setPayPal(request.paypal_authorization);
    this.setShowDetail(request.status);
    this.setRequestedExtensionRequester(request.requested_extension_requester);
    this.setShowExtendLesson(
      perspective,
      request.requested_extension_requester
    );
    this.setEligibleForExtension(
      request.eligible_for_extension,
      perspective,
      request.requested_extension_requester
    );
    this.setGamerChargedAmount(request.gamer_charged_amount);
    this.setGamerChargedGrossAmount(request.gamer_charged_gross_amount);
    this.setGamerDiscountAmount(request.gamer_discount_amount);
    this.setGamerExtensionAmounts(request.gamer_extension_amounts);
    this.setLessonExtensionAmountsInCurrency(
      request.lesson_extension_amounts_in_currency
    );
    this.setPromoApply(request.promo_apply);
    this.setDiscountText();
    this.setGamerChargedAmountInCurrency(
      request.gamer_charged_amount_in_currency
    );
    this.setSenseiPayoutAmountInCurrency(
      request.sensei_payout_amount_in_currency
    );
    this.setConsolidatedChargedAmount(request, perspective);
    this.setReferralBonusText();
    this.setVODReviewLink(request.vod_review_link);
    if (request.sensei_training_request_review) {
      this.setReviewId(request.sensei_training_request_review.id);
      this.setRatingByUser(getRatingByPerspective(request, perspective));
    }
    this.lessonPaymentDetails = {
      opened: false,
    };
  };

  function getPertinentLessonFeeByPerspective(request, perspective) {
    var originalLessonFee;
    var finalLessonFee;

    if (perspective === "sensei")
      originalLessonFee =
        request.sensei_payout_amount_in_currency != null
          ? request.sensei_payout_amount_in_currency
          : request.sensei_consolidated_payout_amount;
    else
      originalLessonFee =
        request.gamer_charged_amount_in_currency != null
          ? request.gamer_charged_amount_in_currency
          : request.gamer_consolidated_charge_amount;

    if (request.gamer_charged_amount_in_currency)
      finalLessonFee =
        originalLessonFee +
        request.lesson_extension_amounts_in_currency.length *
          Math.round(
            request.booking_hourly_rate / request.sensei_curreny_conversion_rate
          );
    else
      finalLessonFee =
        originalLessonFee +
        request.gamer_extension_amounts.length * request.booking_hourly_rate;

    return finalLessonFee;
  }
  function getDateMap(time) {
    return buildDateMap(new Date(time));
  }
  function getTimeZone(time) {
    return moment
      .tz(moment(time), "yyyy-MM-ddTHH:mm:ss.sssZ", moment.tz.guess())
      .format("z");
  }
  function getRatingByPerspective(request, perspective) {
    if (perspective === "sensei")
      return request.sensei_training_request_review.rating_by_sensei;
    else return request.sensei_training_request_review.rating_by_gamer;
  }
  function getRequestScheduleIdByRequestSchedule(requestSchedule) {
    return requestSchedule && requestSchedule.id ? requestSchedule.id : 0;
  }
  function buildDateMap(date) {
    var dateMonth = date.getMonth() + 1;
    var dateDay = date.getDate();
    var dateString = dateMonth + "/" + dateDay;
    var dateHours = date.getHours();
    //esnures single digit numbers precede with a 0. i.e. 0 = 00, 8 = 08
    var dateMinutes = ("0" + date.getMinutes()).slice(-2);
    var amPm = dateHours > 11 ? "pm" : "am";
    var dateHoursStandard = 0;

    dateHoursStandard = dateHours % 12;
    dateHoursStandard = dateHoursStandard ? dateHoursStandard : 12;

    var timeString =
      dateHoursStandard + ":" + dateMinutes + " " + amPm.toUpperCase();
    var militaryTimeString = dateHours + ":" + dateMinutes;

    return {
      dateString: dateString,
      timeString: timeString,
      militaryTimeString: militaryTimeString,
    };
  }

  function getStatusTextByRequest(request, perspective) {
    switch (request.status) {
      case "awaiting_direct_booking_schedule_confirm":
        var sensei_requested =
          request.direct_training_request_schedule.sensei_proposed_time ===
          request.starts_at;
        if (perspective === "sensei")
          return sensei_requested ? "Requested" : "New Time";
        else return !sensei_requested ? "Requested" : "New Time";
      case "sensei_proposed_lesson":
        var sensei_requested =
          request.direct_training_request_schedule.sensei_proposed_time ===
          request.starts_at;
        if (perspective === "sensei")
          return sensei_requested ? "Requested" : "New Time";
        else return !sensei_requested ? "Requested" : "New Session Proposal";
      case "direct_booking_schedule_confirmed":
        var timeLeft = new Date(request.starts_at) - new Date();
        var expire_in_5_min = timeLeft <= 300000;
        return expire_in_5_min ? "Under 5 minutes" : "Confirmed";
      // future case for awaiting_sensei on demand booking?
      case "accepted_and_in_progress":
        var timeLeft = Math.floor(
          (new Date(request.finishes_at) - new Date()) / 60000
        );
        var subText = "";
        if (request.requested_extension_requester)
          subText = "(Extension Requested)";
        else if (request.gamer_extension_amounts.length) subText = "(Extended)";
        return "In Progress " + subText + " - " + timeLeft + " minutes left.";
      case "request_expired":
        return "Expired";
      case "pending_complete":
      case "completed":
        return "Completed";
      case "gamer_cancelled":
        return "Gamer Cancelled";
      case "sensei_rejected":
        return "Sensei Rejected";
      case "pre_awaiting_direct_booking_schedule_confirm":
        if (perspective === "sensei") return "Awaiting Payment";
        else return "Requested";
      default:
        return "";
    }
  }

  function getSecondHeadlineByRequest(request, perspective) {
    var isSensei = perspective === "sensei";
    switch (request.status) {
      case "awaiting_direct_booking_schedule_confirm":
        // Sensei case
        if (isSensei) {
          if (!request.direct_training_request_schedule.sensei_proposed_time)
            return "";
          if (
            request.direct_training_request_schedule.sensei_proposed_time !=
            request.starts_at
          ) {
            var origProposedDateMap = buildDateMap(
              new Date(
                request.direct_training_request_schedule.sensei_proposed_time
              )
            );
            return (
              "You originally proposed " +
              origProposedDateMap.dateString +
              " at " +
              origProposedDateMap.timeString
            );
          }
        } else {
          // Gamer case
          if (!request.direct_training_request_schedule.gamer_proposed_time)
            return "";
          if (
            request.direct_training_request_schedule.gamer_proposed_time !=
            request.starts_at
          ) {
            var origProposedDateMap = buildDateMap(
              new Date(
                request.direct_training_request_schedule.gamer_proposed_time
              )
            );
            return (
              "You originally proposed " +
              origProposedDateMap.dateString +
              " at " +
              origProposedDateMap.timeString
            );
          }
        }
      default:
        return "";
    }
  }

  function getBodyStatusTextByRequest(request, perspective) {
    var isSensei = perspective === "sensei";
    if (isSensei)
      var body_status_text =
        "You have a " +
        request.game_name +
        " lesson with " +
        request.gamer_screen_name;
    else
      var body_status_text =
        "You have a " +
        request.game_name +
        " lesson with " +
        request.sensei_screen_name;

    switch (request.status) {
      case "pre_awaiting_direct_booking_schedule_confirm":
      case "awaiting_direct_booking_schedule_confirm":
        return body_status_text;
      case "direct_booking_schedule_confirmed":
        var timeLeft = new Date(request.starts_at) - new Date();
        var expire_in_5_min = timeLeft <= 300000;
        body_status_text += expire_in_5_min
          ? " under 5 minutes."
          : " on " + buildDateMap(new Date(request.starts_at)).dateString + ".";
        return body_status_text;
      case "accepted_and_in_progress":
        if (isSensei)
          return (
            "You are currently in a " +
            request.game_name +
            " lesson with " +
            request.gamer_screen_name +
            "."
          );
        else
          return (
            "You are currently in a " +
            request.game_name +
            " lesson with " +
            request.sensei_screen_name +
            "."
          );
      case "request_expired":
        if (request.gamer_screen_name) {
          if (isSensei)
            return (
              "Your " +
              request.game_name +
              " lesson with " +
              request.gamer_screen_name +
              " didn't have the schedule confirmed and has expired."
            );
          else
            return (
              "Your " +
              request.game_name +
              " lesson with " +
              request.sensei_screen_name +
              " didn't have the schedule confirmed and has expired."
            );
        } else {
          return (
            "Your requested " +
            request.game_name +
            " on demand lesson has expired"
          );
        }
      case "pending_complete":
      case "completed":
        if (isSensei)
          return (
            "Lesson Earning Amount: $" + request.sensei_payout_amount / 100
          );
        else
          return (
            "Lesson Charged Amount: $" + request.gamer_charged_amount / 100
          );
      case "gamer_cancelled":
        if (isSensei) return "Gamer cancelled the lesson.";
        else return "You've cancelled the lesson.";
      case "sensei_rejected":
        if (isSensei)
          return "You've rejected the lesson. Please make sure your availabilities are up to date.";
        else
          return "Sensei can not fulfill your lesson at this time. Please search for another sensei.";
      default:
        return body_status_text;
    }
  }

  function getTimeInteractionCTAsVisibleByRequest(request, perspective) {
    var isSensei = perspective === "sensei";
    if (
      request.status === "awaiting_direct_booking_schedule_confirm" ||
      request.status === "sensei_proposed_lesson"
    ) {
      if (isSensei)
        return (
          request.direct_training_request_schedule.sensei_proposed_time !==
          request.starts_at
        );
      else
        return (
          request.direct_training_request_schedule.sensei_proposed_time ===
          request.starts_at
        );
    }
    return false;
  }

  function getFirstNameFromName(full_name) {
    if (!full_name) return "";
    if (full_name.split(" ").length === 1) return full_name;
    return full_name.split(" ").slice(0, -1).join(" ");
  }
  function getLastNameFromName(full_name) {
    if (!full_name) return "";
    var splitted_name_parts = full_name.split(" ");
    if (splitted_name_parts.length === 1) return "";
    return splitted_name_parts[splitted_name_parts.length - 1];
  }

  SenseiTrainingRequest.prototype.setId = function (_id) {
    if (_id) this.id = _id;
  };
  SenseiTrainingRequest.prototype.setUuid = function (_uuid) {
    if (_uuid) this.uuid = _uuid;
  };
  SenseiTrainingRequest.prototype.getId = function () {
    return this.id;
  };
  SenseiTrainingRequest.prototype.getUuid = function () {
    return this.uuid;
  };
  SenseiTrainingRequest.prototype.setRequestScheduleId = function (
    _requestScheduleId
  ) {
    this.requestScheduleId = _requestScheduleId;
  };
  SenseiTrainingRequest.prototype.getRequestScheduleId = function () {
    return this.requestScheduleId;
  };
  SenseiTrainingRequest.prototype.setPerspective = function (_perspective) {
    this.perspective = _perspective;
  };
  SenseiTrainingRequest.prototype.isGamer = function () {
    return this.perspective === "gamer";
  };
  SenseiTrainingRequest.prototype.isSensei = function () {
    return this.perspective === "sensei";
  };
  SenseiTrainingRequest.prototype.isRequestCompleted = function () {
    return this.status === "pending_complete" || this.status === "completed";
  };
  SenseiTrainingRequest.prototype.getPerspective = function () {
    return this.perspective;
  };
  SenseiTrainingRequest.prototype.setAffinity = function (_affinity) {
    this.affinity = _affinity;
  };
  SenseiTrainingRequest.prototype.getAffinity = function () {
    return this.affinity;
  };
  SenseiTrainingRequest.prototype.setOtherPartyByRequest = function (
    request,
    perspective
  ) {
    if (perspective === "sensei") {
      this.setOtherParty({
        screenName: request.gamer_screen_name,
        fullName: request.gamer_full_name,
        firstName: getFirstNameFromName(request.gamer_full_name),
        lastName: getLastNameFromName(request.gamer_full_name),
        communication: request.participants_communication_info.gamer,
        participationHandle:
          request.gamer_in_game_id || request.participation_handle,
        profilePhoto: request.gamer_square_profile_photo_url,
        perspective: "gamer",
      });
    } else {
      this.setOtherParty({
        screenName: request.sensei_screen_name,
        fullName: request.sensei_full_name,
        firstName: getFirstNameFromName(request.sensei_full_name),
        lastName: getLastNameFromName(request.sensei_full_name),
        slug: request.sensei_user_slug,
        communication: request.participants_communication_info.sensei,
        participationHandle: request.sensei_in_game_id,
        profilePhoto: request.sensei_square_profile_photo_url,
        perspective: "sensei",
      });
    }
  };
  SenseiTrainingRequest.prototype.setOtherParty = function (_otherParty) {
    if (_otherParty) this.otherParty = _otherParty;
  };
  SenseiTrainingRequest.prototype.getOtherParty = function () {
    return this.otherParty;
  };
  SenseiTrainingRequest.prototype.setGameName = function (_gameName) {
    this.gameName = _gameName;
  };
  SenseiTrainingRequest.prototype.getGameName = function () {
    return this.gameName;
  };
  SenseiTrainingRequest.prototype.setConferenceAppOff = function (
    _conferenceAppOff
  ) {
    this.conferenceAppOff = _conferenceAppOff;
  };
  SenseiTrainingRequest.prototype.getConferenceAppOff = function () {
    return this.conferenceAppOff;
  };
  SenseiTrainingRequest.prototype.setConferenceAvailableAtRequest = function (
    _conferenceCTAPreLessonAvailableAt
  ) {
    this.conferenceCTAPreLessonAvailableAt = _conferenceCTAPreLessonAvailableAt;
  };
  SenseiTrainingRequest.prototype.getConferenceAvailableAtRequest =
    function () {
      return this.conferenceCTAPreLessonAvailableAt;
    };
  SenseiTrainingRequest.prototype.setConferenceAvailableUntilRequest =
    function (_conferenceCTAPostLessonAvailableUntil) {
      this.conferenceCTAPostLessonAvailableUntil =
        _conferenceCTAPostLessonAvailableUntil;
    };
  SenseiTrainingRequest.prototype.getConferenceAvailableUntilRequest =
    function () {
      return this.conferenceCTAPostLessonAvailableUntil;
    };
  SenseiTrainingRequest.prototype.setDate = function (_date) {
    if (_date) this.date = _date;
  };
  SenseiTrainingRequest.prototype.getDate = function () {
    return this.date;
  };
  SenseiTrainingRequest.prototype.setDateString = function (_dateString) {
    if (_dateString) this.dateString = _dateString;
  };
  SenseiTrainingRequest.prototype.getDateString = function () {
    return this.dateString;
  };
  SenseiTrainingRequest.prototype.setTzString = function (_tzString) {
    if (_tzString) this.tzString = _tzString;
  };
  SenseiTrainingRequest.prototype.getTzString = function () {
    return this.tzString;
  };
  SenseiTrainingRequest.prototype.setTimeString = function (_timeString) {
    if (_timeString) this.timeString = _timeString;
  };
  SenseiTrainingRequest.prototype.getTimeString = function () {
    return this.timeString;
  };
  SenseiTrainingRequest.prototype.setStatus = function (_status) {
    this.status = _status;
  };
  SenseiTrainingRequest.prototype.getStatus = function () {
    return this.status;
  };
  SenseiTrainingRequest.prototype.setStatusText = function (_statusText) {
    this.statusText = _statusText;
  };
  SenseiTrainingRequest.prototype.getStatusText = function () {
    return this.statusText;
  };
  SenseiTrainingRequest.prototype.setSecondHeadline = function (
    _secondHeadline
  ) {
    this.secondHeadline = _secondHeadline;
  };
  SenseiTrainingRequest.prototype.getSecondHeadline = function () {
    return this.secondHeadline;
  };
  SenseiTrainingRequest.prototype.setBodyStatusText = function (
    _bodyStatusText
  ) {
    this.bodyStatusText = _bodyStatusText;
  };
  SenseiTrainingRequest.prototype.getBodyStatusText = function () {
    return this.bodyStatusText;
  };
  SenseiTrainingRequest.prototype.setTimeInteractionCTAsVisible = function (
    _timeInteractionCTAsVisible
  ) {
    this.timeInteractionCTAsVisible = _timeInteractionCTAsVisible;
  };
  SenseiTrainingRequest.prototype.getTimeInteractionCTAsVisible = function () {
    return this.timeInteractionCTAsVisible;
  };
  SenseiTrainingRequest.prototype.setPertinentLessonFee = function (
    _PertinentLessonFee
  ) {
    this.pertinent_lesson_fee = _PertinentLessonFee;
  };
  SenseiTrainingRequest.prototype.getPertinentLessonFee = function () {
    return this.pertinent_lesson_fee;
  };
  SenseiTrainingRequest.prototype.setSessionDurationHours = function (
    _sessionDurationHours
  ) {
    this.sessionDurationHours = _sessionDurationHours;
  };
  SenseiTrainingRequest.prototype.getSessionDurationHours = function () {
    return this.sessionDurationHours;
  };
  SenseiTrainingRequest.prototype.setExpiredSessionEligibleForFurtherInteractions =
    function (_expiredSessionEligibleForFurtherInteractions) {
      this.expiredSessionEligibleForFurtherInteractions =
        _expiredSessionEligibleForFurtherInteractions;
    };
  SenseiTrainingRequest.prototype.getExpiredSessionEligibleForFurtherInteractions =
    function () {
      return this.expiredSessionEligibleForFurtherInteractions;
    };
  SenseiTrainingRequest.prototype.setRatingByUser = function (_ratingByUser) {
    this.rating_by_user = _ratingByUser;
  };
  SenseiTrainingRequest.prototype.getRatingByUser = function () {
    return this.rating_by_user;
  };
  SenseiTrainingRequest.prototype.setReviewId = function (_reviewId) {
    this.reviewId = _reviewId;
  };
  SenseiTrainingRequest.prototype.getReviewId = function () {
    return this.reviewId;
  };
  SenseiTrainingRequest.prototype.setImproveOnNotes = function (
    _improveOnNotes
  ) {
    this.improveOnNotes = _improveOnNotes;
  };
  SenseiTrainingRequest.prototype.getImproveOnNotes = function () {
    return this.improveOnNotes;
  };
  SenseiTrainingRequest.prototype.setSenseiNotes = function () {
    return this.setSenseiNotes;
  };
  SenseiTrainingRequest.prototype.setTokenTypeName = function (_tokenTypeName) {
    this.tokenTypeName = _tokenTypeName;
  };
  SenseiTrainingRequest.prototype.getTokenTypeName = function () {
    return this.tokenTypeName;
  };
  SenseiTrainingRequest.prototype.setExistCTA = function () {
    this.existCTA =
      this.getTimeInteractionCTAsVisible() ||
      (this.getExpiredSessionEligibleForFurtherInteractions() &&
        this.statusText === "Expired");
  };
  SenseiTrainingRequest.prototype.setHourlyRate = function (_hourlyRate) {
    this.hourlyRate = _hourlyRate;
  };
  SenseiTrainingRequest.prototype.setCreditCard = function (_creditCard) {
    this.creditCard = _creditCard;
  };
  SenseiTrainingRequest.prototype.setPayPal = function (_payPal) {
    this.payPal = _payPal;
  };
  SenseiTrainingRequest.prototype.setRequestedExtensionRequester = function (
    _requester
  ) {
    this.requestedExtensionRequester = _requester;
  };
  SenseiTrainingRequest.prototype.setEligibleForExtension = function (
    _eligibleForExtension,
    perspective,
    requester
  ) {
    if (typeof requester === "string" && requester !== perspective)
      this.eligibleForExtension = true;
    else this.eligibleForExtension = _eligibleForExtension;
  };
  SenseiTrainingRequest.prototype.setGamerChargedAmount = function (
    _gamerChargedAmount
  ) {
    this.gamerChargedAmount = _gamerChargedAmount;
  };
  SenseiTrainingRequest.prototype.setGamerChargedGrossAmount = function (
    _gamerChargedGrossAmount
  ) {
    this.gamerChargedGrossAmount = _gamerChargedGrossAmount;
  };
  SenseiTrainingRequest.prototype.setGamerDiscountAmount = function (
    _gamerDiscountAmount
  ) {
    this.gamerDiscountAmount = _gamerDiscountAmount;
  };
  SenseiTrainingRequest.prototype.setGamerExtensionAmounts = function (
    _gamerExtensionAmounts
  ) {
    this.gamerExtensionAmounts = _gamerExtensionAmounts;
  };
  SenseiTrainingRequest.prototype.setLessonExtensionAmountsInCurrency =
    function (lessonExtensionAmountsInCurrency) {
      this.lessonExtensionAmountsInCurrency = lessonExtensionAmountsInCurrency;
    };
  SenseiTrainingRequest.prototype.setPromoApply = function (_promoApply) {
    this.promoApply = _promoApply;
  };
  SenseiTrainingRequest.prototype.setDiscountText = function () {
    this.discountText = "";
    if (this.promoApply) {
      this.discountText = "Discount";
      if (this.promoApply.promo_type === "discounted_promo")
        this.discountText +=
          " (" + this.promoApply.promo_discount_percentage + "% Off)";
    }
  };
  SenseiTrainingRequest.prototype.setGamerChargedAmountInCurrency = function (
    gamer_charged_amount_in_currency
  ) {
    this.gamerChargedAmountInCurrency = gamer_charged_amount_in_currency;
  };
  SenseiTrainingRequest.prototype.setSenseiPayoutAmountInCurrency = function (
    sensei_payout_amount_in_currency
  ) {
    this.senseiPayoutAmountInCurrency = sensei_payout_amount_in_currency;
  };
  SenseiTrainingRequest.prototype.setReferralBonusText = function () {
    this.referralBonusText = "";
    if (this.promoApply && this.promoApply.promo_type === "sensei_referral")
      this.referralBonusText = "Referral Bonus";
  };
  SenseiTrainingRequest.prototype.setVODReviewLink = function (_VODLink) {
    this.VODReviewLink = _VODLink;
  };
  SenseiTrainingRequest.prototype.getExistCTA = function () {
    return this.existCTA;
  };
  SenseiTrainingRequest.prototype.getHourlyRate = function () {
    return this.hourlyRate;
  };
  SenseiTrainingRequest.prototype.getCreditCard = function () {
    return this.creditCard;
  };
  SenseiTrainingRequest.prototype.getPayPal = function () {
    return this.payPal;
  };
  SenseiTrainingRequest.prototype.getShowDetail = function () {
    return this.showDetail;
  };
  SenseiTrainingRequest.prototype.getShowExtendLesson = function () {
    return this.showExtendLesson;
  };
  SenseiTrainingRequest.prototype.getRequestedExtensionRequester = function () {
    return this.requestedExtensionRequester;
  };
  SenseiTrainingRequest.prototype.getEligibleForExtension = function () {
    return this.eligibleForExtension;
  };
  SenseiTrainingRequest.prototype.getGamerChargedAmount = function (
    _gamerChargedAmount
  ) {
    return this.gamerChargedAmount;
  };
  SenseiTrainingRequest.prototype.getGamerChargedGrossAmount = function (
    _gamerChargedGrossAmount
  ) {
    return this.gamerChargedGrossAmount;
  };
  SenseiTrainingRequest.prototype.getGamerDiscountAmount = function (
    _gamerDiscountAmount
  ) {
    return this.gamerDiscountAmount;
  };
  SenseiTrainingRequest.prototype.getGamerExtensionAmounts = function (
    _gamerExtensionAmounts
  ) {
    return this.gamerExtensionAmounts;
  };
  SenseiTrainingRequest.prototype.getLessonExtensionAmountsInCurrency =
    function (lessonExtensionAmountsInCurrency) {
      return this.lessonExtensionAmountsInCurrency;
    };
  SenseiTrainingRequest.prototype.getPromoApply = function () {
    return this.promoApply;
  };
  SenseiTrainingRequest.prototype.getDiscountText = function () {
    return this.discountText;
  };
  SenseiTrainingRequest.prototype.getGamerChargedAmountInCurrency =
    function () {
      return this.gamerChargedAmountInCurrency;
    };
  SenseiTrainingRequest.prototype.getSenseiPayoutAmountInCurrency =
    function () {
      return this.senseiPayoutAmountInCurrency;
    };
  SenseiTrainingRequest.prototype.getReferralBonusText = function () {
    return this.referralBonusText;
  };
  SenseiTrainingRequest.prototype.getVODReviewLink = function () {
    return this.VODReviewLink;
  };
  SenseiTrainingRequest.prototype.getGamerImportantInformation = function () {
    return (
      this.isGamer() &&
      (this.statusText === "Confirmed" || this.statusText === "New Time")
    );
  };
  SenseiTrainingRequest.prototype.getGamerCancelReschedulePolicy = function () {
    return (
      this.isGamer() &&
      this.statusText !== "Gamer Cancelled" &&
      this.statusText !== "Completed"
    );
  };
  SenseiTrainingRequest.prototype.getExpiredActionable = function () {
    return (
      this.getExpiredSessionEligibleForFurtherInteractions() &&
      this.statusText === "Expired"
    );
  };
  SenseiTrainingRequest.prototype.returnPaymentAndPayoutDetails = function () {
    if (this.lessonPaymentDetails.opened) {
      this.lessonPaymentDetails.opened = false;
    } else if (
      !this.lessonPaymentDetails.opened &&
      this.lessonPaymentDetails.hasOpened
    ) {
      this.lessonPaymentDetails.opened = true;
    } else {
      const str = this;
      $http
        .get(
          "/api/web/sensei_training_requests/" +
            this.id +
            "/payout_and_charge_amounts"
        )
        .then((res) => {
          if (str.perspective === "sensei") {
            str.lessonPaymentDetails.totalAmount =
              res.data.sensei_payout_amount;
            str.lessonPaymentDetails.totalAmountLabel = "Total Payout";
            str.lessonPaymentDetails.grossAmountLabel =
              str.sessionDurationHours + " Hour Lesson";
          } else {
            str.lessonPaymentDetails.totalAmount = res.data.gamer_charge_amount;
            str.lessonPaymentDetails.totalAmountLabel = "Total";
            str.lessonPaymentDetails.grossAmount =
              res.data.gamer_charged_gross_amount;
            str.lessonPaymentDetails.grossAmountLabel =
              str.sessionDurationHours + " Hour Lesson";
            str.lessonPaymentDetails.discountAmount =
              res.data.gamer_discount_amount;
            str.lessonPaymentDetails.discountAmountLabel = "Discount";
          }

          str.lessonPaymentDetails.opened = true;
          str.lessonPaymentDetails.hasOpened = true;
        })
        .catch((err) => console.error(err));
    }
  };
  SenseiTrainingRequest.prototype.setUnread = function (request, perspective) {
    var isSensei = perspective === "sensei";
    if (isSensei) this.unread = request.notify_sensei_lesson_changed;
    else this.unread = request.notify_gamer_lesson_changed;
  };
  SenseiTrainingRequest.prototype.setShowDetail = function (_status) {
    this.showDetail =
      this.showDetail !== undefined
        ? this.showDetail
        : _status === "accepted_and_in_progress";
  };
  SenseiTrainingRequest.prototype.setShowExtendLesson = function (
    perspective,
    requester
  ) {
    if (this.status === "accepted_and_in_progress") {
      if (requester && requester !== perspective) this.showExtendLesson = true;
      else if (this.showExtendLesson !== undefined)
        this.showExtendLesson = this.showExtendLesson;
      else this.showExtendLesson = false;
    }
  };

  SenseiTrainingRequest.prototype.setConsolidatedChargedAmount = function (
    request,
    perspective
  ) {
    if (perspective === "sensei") {
      this.sensei_consolidated_payout_amount =
        request.sensei_consolidated_payout_amount;
    } else {
      this.gamer_consolidated_charge_amount =
        request.gamer_consolidated_charge_amount;
    }
  };

  SenseiTrainingRequest.prototype.update = function (request, perspective) {
    this.request = request;
    this.setRequestScheduleId(
      getRequestScheduleIdByRequestSchedule(
        request.direct_training_request_schedule
      )
    );
    this.setPerspective(perspective);
    this.setAffinity(request.affinity);
    this.setOtherPartyByRequest(request, perspective);
    this.setGameName(request.game_name);
    this.setConferenceAppOff(request.conference_app_off);
    this.setConferenceAvailableAtRequest(
      request.conference_cta_pre_lesson_available_at
    );
    this.setConferenceAvailableUntilRequest(
      request.conference_cta_post_lesson_available_until
    );
    this.setDate(request.starts_at);
    this.setDateString(getDateMap(request.starts_at).dateString);
    this.setTzString(getTimeZone(request.starts_at));
    this.setTimeString(getDateMap(request.starts_at).timeString);
    this.setStatus(request.status);
    this.setStatusText(getStatusTextByRequest(request, perspective));
    this.setSecondHeadline(getSecondHeadlineByRequest(request, perspective));
    this.setBodyStatusText(getBodyStatusTextByRequest(request, perspective));
    this.setTimeInteractionCTAsVisible(
      getTimeInteractionCTAsVisibleByRequest(request, perspective)
    );
    this.setPertinentLessonFee(
      getPertinentLessonFeeByPerspective(request, perspective)
    );
    this.setSessionDurationHours(request.session_duration_hours);
    this.setExpiredSessionEligibleForFurtherInteractions(
      request.expired_session_eligible_for_further_interaction
    );
    this.setImproveOnNotes(request.improve_on_notes);
    this.setTokenTypeName(request.token_type_name);
    this.setExistCTA();
    this.setUnread(request, perspective);
    this.setShowDetail(request.status);
    this.setShowExtendLesson(
      perspective,
      this.getRequestedExtensionRequester()
    );
    this.setHourlyRate(request.booking_hourly_rate);
    this.setPayPal(request.paypal_authorization);
    this.setCreditCard(request.credit_card);
    this.setRequestedExtensionRequester(request.requested_extension_requester);
    this.setEligibleForExtension(
      request.eligible_for_extension,
      perspective,
      this.getRequestedExtensionRequester()
    );
    this.setGamerChargedAmount(request.gamer_charged_amount);
    this.setGamerChargedGrossAmount(request.gamer_charged_gross_amount);
    this.setGamerDiscountAmount(request.gamer_discount_amount);
    this.setGamerExtensionAmounts(request.gamer_extension_amounts);
    this.setLessonExtensionAmountsInCurrency(
      request.lesson_extension_amounts_in_currency
    );
    this.setPromoApply(request.promo_apply);
    this.setDiscountText();
    this.setGamerChargedAmountInCurrency(
      request.gamer_charged_amount_in_currency
    );
    this.setSenseiPayoutAmountInCurrency(
      request.sensei_payout_amount_in_currency
    );
    this.setReferralBonusText();
    this.setVODReviewLink(request.vod_review_link);
    if (request.sensei_training_request_review !== null) {
      this.setReviewId(request.sensei_training_request_review.id);
      this.setRatingByUser(getRatingByPerspective(request, perspective));
    }
    return this;
  };

  SenseiTrainingRequest.prototype.toObject = function () {
    return {
      id: this.getId(),
      uuid: this.getUuid(),
      requestScheduleId: this.getRequestScheduleId(),
      perspective: this.getPerspective(),
      affinity: this.getAffinity(),
      otherParty: this.getOtherParty(),
      gameName: this.getGameName(),
      conferenceAppOff: this.getConferenceAppOff(),
      conferenceCTAPreLessonAvailableAt: this.getConferenceAvailableAtRequest(),
      conferenceCTAPostLessonAvailableUntil:
        this.getConferenceAvailableUntilRequest(),
      date: this.getDate(),
      dateString: this.getDateString(),
      tzString: this.getTzString(),
      timeString: this.getTimeString(),
      status: this.getStatus(),
      statusText: this.getStatusText(),
      secondHeadline: this.getSecondHeadline(),
      bodyStatusText: this.getBodyStatusText(),
      communicationMedium: this.getCommunicationMedium(),
      timeInteractionCTAsVisible: this.getTimeInteractionCTAsVisible(),
      pertinent_lesson_fee: this.getPertinentLessonFee(),
      sessionDurationHours: this.getSessionDurationHours(),
      expiredSessionEligibleForFurtherInteractions:
        this.getExpiredSessionEligibleForFurtherInteractions(),
      expiredActionable: this.getExpiredActionable(),
      rating_by_user: this.getRatingByUser(),
      reviewId: this.getReviewId(),
      improveOnNotes: this.getImproveOnNotes(),
      setSenseiNotes: this.setSenseiNotes(),
      tokenTypeName: this.getTokenTypeName(),
      existCTA: this.getExistCTA(),
      showDetail: this.getShowDetail(),
      showExtendLesson: this.getShowExtendLesson(),
      gamerChargedAmount: this.getGamerChargedAmount(),
      gamerChargedGrossAmount: this.getGamerChargedGrossAmount(),
      gamerDiscountAmount: this.getGamerDiscountAmount(),
      gamerExtensionAmounts: this.getGamerExtensionAmounts(),
      lessonExtensionAmountsInCurrency:
        this.getLessonExtensionAmountsInCurrency(),
      promoApply: this.getPromoApply(),
      discountText: this.getDiscountText(),
      referralBonusText: this.getReferralBonusText(),
      VODReviewLink: this.getVODReviewLink(),
      payPal: this.getPayPal(),
      creditCard: this.getCreditCard(),
    };
  };
  return SenseiTrainingRequest;
}

senseiTrainingRequestService.$inject = ["$http"];
