import RecentSenseisTemplate from "../../templates/directives/users/recent_senseis_template";

export default function recentSenseisDirective(
  $cookies,
  RecentLessons,
  SenseiReviewService
) {
  return {
    restrict: "E",
    scope: {
      userId: "=",
      gamerId: "=",
      recentSenseiDesc: "=?",
      recentSenseiTitle: "=?",
    },
    link: link,
    template: RecentSenseisTemplate,
  };

  function link(scope, elem, attrs) {
    scope.senseiReview = SenseiReviewService;
    // preset
    scope.senseiReviewDismissId = parseInt($cookies.get("senseiReviewDismiss"));
    scope.mostRecentPastCompletedLesson = null;
    scope.reviewParams = null;
    scope.senseiReviewModal = {
      openedModal: false,
    };

    // cta
    scope.openSenseiReviewModal = (lesson, update) => {
      const senseiId = lesson.sensei_id;
      const pastReviewParams = {
        params: {
          reviewer_id: scope.gamerId,
          reviewee_id: senseiId,
        },
      };

      scope.senseiReview
        .get(pastReviewParams)
        .then((reviewResp) => {
          const reviews = reviewResp.data.sensei_reviews;

          if (reviews.length === 0 || update) {
            scope.mostRecentPastCompletedLesson = lesson;
            scope.mostRecentPastCompletedLesson.senseiImage =
              lesson.square_profile_photo_url;
            scope.mostRecentPastCompletedLesson.sensei_name =
              lesson.screen_name || lesson.senseiName;

            if (update) {
              let latestReview = null;
              const updatedReviewsLength = reviews[0].updated_reviews.length;

              if (updatedReviewsLength > 0) {
                latestReview =
                  reviews[0].updated_reviews[updatedReviewsLength - 1];
              } else {
                latestReview = reviews[0];
              }
              scope.reviewParams = {
                stars: latestReview.star_rating,
                description: latestReview.body,
              };
            }
            scope.senseiReviewModal.openedModal = true;
          }
        })
        .catch(angular.noop);
    };

    // init
    RecentLessons.senseis(scope.userId, { params: { limit: 3 } })
      .then((res) => {
        scope.recentLessons = res.data.recent_senseis;
      })
      .catch(angular.noop);
  }
}

recentSenseisDirective.$inject = [
  "$cookies",
  "RecentLessons",
  "SenseiReviewService",
];
