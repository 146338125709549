import { getURLParameters } from "../../config/utils/utilsConfig";
import { title as termsTitle } from "../../config/legal/terms-of-service-text";
import { title as privacyTitle } from "../../config/legal/privacy-policy-text";
import { infoIcon } from "../../config/icons/iconConfig";
import userAccessTemplate from "../../templates/directives/users/user_access_template";

export default function userAccessDirective(
  $rootScope,
  $timeout,
  $window,
  AccessControl,
  ModalManager,
  EventTrackingManager,
  OmnibarPointer
) {
  return {
    restrict: "E",
    scope: {
      containerClassNames: "=",
      role: "=",
      isSignUp: "=",
      onSignUpComplete: "&", // optional callback
      onLoginComplete: "&", // optional callback
    },
    link,
    template: userAccessTemplate,
  };

  function link(scope, elm, attr) {
    // services
    scope.access = AccessControl;
    scope.modal = ModalManager;
    // providers
    scope.eventTracking = EventTrackingManager;
    scope.omnibarPointer = OmnibarPointer;
    // imported
    scope.termsTitle = termsTitle;
    scope.privacyTitle = privacyTitle;
    scope.infoIcon = infoIcon;
    // time
    scope.years = scope.access.getPast100Years();
    scope.months = scope.access.getMonths();
    // access
    scope.access.config.set("isSignUp", scope.isSignUp);
    scope.access.config.set("formSubmit", false);
    scope.access.config.set("referrer", $rootScope.referrer);

    // true/false
    scope.gigya = $rootScope.isGigyaOn;
    scope.verifyNeeded = false;

    $window.recaptchaResults = scope.access.recaptcha.complete;
    $window.recaptchaSetupError = scope.access.recaptcha.setError;

    if (getURLParameters("email") !== null) {
      scope.access.setUserParams("email", getURLParameters("email"));
    }

    $timeout(() => scope.access.recaptcha.init(), 0);

    angular.element(() => {
      scope.modal.setEventListener();
    });

    // Register User
    scope.connectUser = ($event) => {
      if ($event) $event.preventDefault();

      if (scope.isSignUp) {
        scope.access.userRegistration($event, scope.gigya, scope.role, {
          onSuccess: (res, postParams) => {
            const eventParams = {
              ...postParams.user,
              id: res.data.user.id,
              gigya_uid: res.data.user.gigya_uid,
              brand: res.data.user.brand,
              brand_domain: res.data.user.brand_domain,
            };

            scope.eventTracking.pushUserConnect(
              "accountRegistration",
              eventParams,
              "student"
            );

            scope.verifyNeeded = true;
            scope.modal.setOpenBG();
            scope.newUser = res.data.user;
          },
        });
      } else {
        scope.access.login({
          onSuccess: (newUser) => {
            scope.eventTracking.pushUserConnect("accountLogin", newUser);

            if (scope.sendVerifyEvent) {
              scope.eventTracking.pushVerifiedUser(newUser, false);
            }

            const role = scope.access.getResRole(newUser);

            if (scope.onLoginComplete) {
              $timeout(() => scope.onLoginComplete({ newUser, role }), 100);
            }
          },
          getTerms: () => {
            scope.termsNeeded = true;
          },
          getVerification: () => {
            scope.verifyNeeded = true;
          },
        });
      }
    };

    // Confirm parental approval
    scope.ageGateCallback = () => {
      scope.access.setUserParams("parentalConsent", true);
      scope.access.setUserParams("isMinor", true);

      scope.connectUser();
    };

    // Verify Complete
    scope.completeVerifyAndContinue = () => {
      if (scope.sendVerifyEvent) {
        scope.eventTracking.pushVerifiedUser(scope.newUser, true);
      }

      if (scope.onSignUpComplete) {
        $timeout(() => scope.onSignUpComplete({ newUser: scope.newUser }), 100);
      }
    };

    // Id names for uniqueness
    scope.getIdName = (name) => {
      return `${name}_${scope.$id}`;
    };
  }
}

userAccessDirective.$inject = [
  "$rootScope",
  "$timeout",
  "$window",
  "AccessControl",
  "ModalManager",
  "EventTrackingManager",
  "OmnibarPointer",
];
