const flattenObject = (obj) => {
  const result = {};

  for (const key of Object.keys(obj)) {
    if (obj[key] && typeof obj[key] === "object") {
      const nested = flattenObject(obj[key]);
      for (const nestedKey of Object.keys(nested)) {
        result[`${key}.${nestedKey}`] = nested[nestedKey];
      }
    } else {
      result[key] = obj[key];
    }
  }
  return result;
};

export default flattenObject;
