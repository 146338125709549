import getEventEndTime from "./getEventEndTime";

export default function calendarEventObject(
  lesson,
  role,
  onDate,
  CalendarBasedLessons
) {
  const startTime = onDate + " " + lesson.start_time;
  const endTime = getEventEndTime(
    lesson.start_time,
    lesson.end_time,
    lesson.duration_hours,
    onDate
  );

  const userName = role === "gamer" ? lesson.sensei_name : lesson.gamer_name;
  const userProposed = role === "gamer" ? "sensei_proposed" : "gamer_proposed";

  const calendarSession = {
    title: `${lesson.duration_hours} hour ${lesson.game_name} session with ${userName}`,
    start: moment.utc(startTime, "YYYY-MM-DD HH:mm").local().toDate(),
    end: moment.utc(endTime, "YYYY-MM-DD HH:mm").local().toDate(),
    stick: true,
    status: lesson.status,
    singleChangeRequest: CalendarBasedLessons.singleChangeRequest(
      lesson,
      userProposed
    ),
    recurringChangeRequest: CalendarBasedLessons.recurringChangeRequest(
      lesson,
      userProposed
    ),
  };

  return calendarSession;
}
