export default function omnibarService($http) {

  var service = {
    close: closeOmnibar
  };

  return service;

  ///////////

  function closeOmnibar(data) {
    return $http({ method: 'POST', url: '/api/web/omni_tooltip_dismissals', data: data });
  }

}

omnibarService.$inject = ['$http'];