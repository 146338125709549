import UploadPhotoTemplate from "../../templates/directives/users/upload_photo_template";

export default function uploadPhotoDirective(Upload, User, OmnibarPointer) {
  return {
    restrict: "E",
    scope: {
      profilePic: "=",
      validPhoto: "=",
      showLoading: "=",
    },
    template: UploadPhotoTemplate,
    transclude: true,
    link: (scope, elem, attrs) => {
      scope.omnibarPointer = OmnibarPointer;

      scope.$on("uploadProfilePic", function (event, args) {
        scope.uploadProfilePic(args.profilePic);
      });

      scope.uploadCancel = () => {
        scope.profilePic = undefined;
        scope.uploadPhotoToggle();
        scope.omnibarPointer.resetOmnibar();
      };

      scope.validatePhoto = (file) => {
        let valid = true;
        scope.errorMsg = undefined;

        if (file !== undefined) {
          valid = true;
          if (Math.floor(file.size / 1000000) > 2) {
            valid = false;
          } else if (file.type.indexOf("image") == -1) {
            scope.errorMsg = "Please upload an image.";
            valid = false;
          }
        } else {
          valid = false;
        }

        scope.validPhoto = valid;

        if (!!scope.uploadProfileForm.$error.maxSize || (!valid && file)) {
          scope.omnibarPointer.pushErrorByMessage(
            "Your image has exceeded the max size of 5mb."
          );
        } else {
          scope.omnibarPointer.resetOmnibar();
        }
      };

      scope.uploadProfilePic = (file) => {
        if (file !== undefined) {
          scope.showLoading = true;

          const image = new Image();
          image.src = URL.createObjectURL(file);

          User.setProfilePic(image.src);

          file.upload = Upload.upload({
            method: "post",
            url: "/api/web/users/" + User.getId() + "/profile_photo_upload",
            data: {
              user: {
                profile_photo: file,
              },
            },
          });

          file.upload.then(
            (res) => {
              User.setProfilePic(res.data.user.profile_photo_url_square);
              scope.showLoading = false;
            },
            (err) => {
              if (err.status > 0) {
                scope.showLoading = false;
                scope.omnibarPointer.pushErrorByHash(err.data.error);
              }
            },
            (evt) => {}
          );
        }
      };
    },
  };
}

uploadPhotoDirective.$inject = ["Upload", "User", "OmnibarPointer"];
