export default function gamerNotificationPreferenceApiService($http) {
  var service = {
    show: show,
    update: update,
  };

  return service;

  ///////////

  function show(id) {
    return $http.get("/api/web/gamer_notification_preferences/" + id);
  }

  function update(id, data) {
    return $http.put("/api/web/gamer_notification_preferences/" + id, data);
  }
}
gamerNotificationPreferenceApiService.$inject = ["$http"];
