export default function gamingAttributesService($http) {

  var service = {
    get: get
  };

  return service;

  ///////////

  function get(config) {
    return $http.get('/api/web/gaming_attributes/', config);
  }

}

gamingAttributesService.$inject = ['$http'];