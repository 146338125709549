import heroImg1 from "../../images/backgrounds/home_header_assessments.jpg";
import heroImg2 from "../../images/backgrounds/home_header_bundles.jpg";
import heroImg3 from "../../images/backgrounds/home_header_default.jpg";
import heroImg1WebP from "../../images/backgrounds/home_header_assessments.webp";
import heroImg2WebP from "../../images/backgrounds/home_header_bundles.webp";
import heroImg3WebP from "../../images/backgrounds/home_header_default.webp";
import v3LaunchPoster from "../../images/meta/logo-green-bg.jpg";
import v3LaunchVideo from "../../videos/gs_v3_launch.mp4";
import { supportsWebP } from "../../config/utils/utilsConfig";
import {
  discordIcon,
  facebookIcon,
  fastTimeIcon,
  happyIcon,
  instagramIcon,
  pauseIcon,
  playIcon,
  nextIcon,
  shieldIcon,
  supportIcon,
  twitterIcon,
} from "../../config/icons/iconConfig";

export default class HomePageController {
  constructor(
    $scope,
    $stateParams,
    $anchorScroll,
    $timeout,
    $window,
    MetaTagsService,
    EventTrackingManager,
    LazyLoad,
    pulledGames,
    OmnibarPointer,
    TestimonialUtils
  ) {
    this.OmnibarPointer = OmnibarPointer;
    this.games = pulledGames.data.games;

    this.testimonials = TestimonialUtils.customers;
    this.launchVideo = {
      poster: v3LaunchPoster,
      video: v3LaunchVideo,
    };
    this.icons = {
      discordIcon,
      facebookIcon,
      fastTimeIcon,
      happyIcon,
      instagramIcon,
      pauseIcon,
      playIcon,
      nextIcon,
      shieldIcon,
      supportIcon,
      twitterIcon,
    };
    this.testimonialConfig = {
      enabled: true,
      dots: false,
      arrows: false,
      speed: 600,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      method: {},
      responsive: [
        {
          breakpoint: 880,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    this.heroConfig = {
      enabled: true,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 600,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: "linear",
      playIcon: `<span class="icon icon-green">${this.icons.playIcon}</span>`,
      pauseIcon: `<span class="icon icon-green">${this.icons.pauseIcon}</span>`,
      method: {},
      event: {},
    };

    this.hero = [
      {
        innerHtml: `START YOUR JOURNEY AND<br />UNLOCK YOUR ACHIEVEMENTS`,
        imagePath: heroImg1,
        webPPath: heroImg1WebP,
      },
      {
        innerHtml: `NOW AVAILABLE: ONE-ON-ONE AND<br />TEAM BUNDLES FOR ALL GAMES.`,
        imagePath: heroImg2,
        webPPath: heroImg2WebP,
      },
      {
        innerHtml: `RANK UP WITH THE<br />WORLD'S BEST COACHES.`,
        imagePath: heroImg3,
        webPPath: heroImg3WebP,
      },
    ];

    $scope.connectEvent = (event, name) =>
      this.connectEvent(event, name, $window, EventTrackingManager);

    // inits
    this.initMetaTags(MetaTagsService);

    // DOM ready
    angular.element(() =>
      this.initPage(
        LazyLoad,
        EventTrackingManager,
        $stateParams,
        $timeout,
        $anchorScroll
      )
    );
  }

  // init methods
  initMetaTags(MetaTagsService) {
    const metaTitle = "Esports Coaching and Lessons with Pro Gamers";
    const metaDescription =
      "Gamer Sensei is the world's premier esports coaching platform and community, connecting gamers with an expansive list of professional, fully vetted esports and gaming coaches.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initPage(
    LazyLoad,
    EventTrackingManager,
    $stateParams,
    $timeout,
    $anchorScroll
  ) {
    LazyLoad.set();
    EventTrackingManager.pushViewEvent("Homepage");

    supportsWebP().then((supported) => {
      if (supported) {
        this.useWebP = true;
      } else {
        this.useWebP = false;
      }
    });

    if ($stateParams.welcome) {
      $timeout(() => {
        $anchorScroll("we-leveled-up");
      }, 1000);
    }
  }

  // actions
  openFeatureSenseiModal(senseiId) {
    this.openedFeatureSenseiModal = true;
    this.selectedFeaturedSensei = senseiId;
  }

  // display methods
  displayImage(item) {
    if (item.webPPath && this.useWebP) {
      return item.webPPath;
    }

    return item.imagePath;
  }

  // internal methods
  connectEvent(event, name, $window, EventTrackingManager) {
    event.preventDefault();
    var href = event.target.href;
    EventTrackingManager.pushEventOnly(name);
    $window.location.href = href;
  }
}

HomePageController.$inject = [
  "$scope",
  "$stateParams",
  "$anchorScroll",
  "$timeout",
  "$window",
  "MetaTagsService",
  "EventTrackingManager",
  "LazyLoad",
  "pulledGames",
  "OmnibarPointer",
  "TestimonialUtils",
];
