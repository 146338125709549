import getEventEndTime from "./getEventEndTime";

export default function detailedCalendarEventObject(
  date,
  lesson,
  role,
  clickedDate,
  currentSTR
) {
  const startTime = clickedDate + " " + lesson.start_time;
  const endTime = getEventEndTime(
    lesson.start_time,
    lesson.end_time,
    lesson.duration_hours,
    clickedDate
  );

  const userName = role === "gamer" ? lesson.sensei_name : lesson.gamer_name;
  const userProposed = role === "gamer" ? "sensei_proposed" : "gamer_proposed";

  let otherPartyProposal;

  if (!!lesson.recurring_lessons_reschedule_requests[0]) {
    otherPartyProposal = {
      id: lesson.recurring_lessons_reschedule_requests[0].id,
      status:
        lesson.recurring_lessons_reschedule_requests[0].status === userProposed,
      offsetHours:
        lesson.recurring_lessons_reschedule_requests[0].reschedule_params
          .gamer_proposed_offset_hours,
      senseiTrainingRequests:
        lesson.recurring_lessons_reschedule_requests[0]
          .sensei_training_requests,
    };
  } else if (lesson.status === "awaiting_direct_booking_schedule_confirm") {
    otherPartyProposal = {
      id: lesson.direct_training_request_schedule_id,
      status:
        lesson.direct_training_request_schedule_status === "gamer_proposed",
      singleLesson: true,
    };
  } else {
    otherPartyProposal = null;
  }

  const lessonDetails = {
    id: lesson.id,
    directTrainingRequestScheduleId: lesson.direct_training_request_schedule_id,
    title: `${lesson.duration_hours} hour ${lesson.game_name} session with ${userName}`,
    date: moment.utc(date).local().format("YYYY-MM-DD"),
    day: moment.utc(date).local().format("dddd"),
    recurring: currentSTR.recurring,
    rescheduleDate: moment.utc(date).local().format("MM/DD/YY"),
    rescheduleTime: [
      moment.utc(startTime, "YYYY-MM-DD HH:mm").local().format("h:mm A"),
      lesson.start_time,
    ],
    senseiTrainingRequestIDs: currentSTR.sensei_training_request_ids,
    start: moment.utc(startTime, "YYYY-MM-DD HH:mm").local().format("h:mma"),
    end: moment.utc(endTime, "YYYY-MM-DD HH:mm").local().format("h:mma"),
    lessonDate: moment
      .utc(startTime, "YYYY-MM-DD HH:mm")
      .local()
      .format("YYYY-MM-DDTHH:mm:ss"),
    options: false,
    rescheduleOptions: false,
    otherPartyActive:
      otherPartyProposal &&
      (otherPartyProposal.status || !!otherPartyProposal.id),
    otherPartyRequests: otherPartyProposal,
  };

  return lessonDetails;
}
