import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";
import { shieldIcon } from "../../config/icons/iconConfig";
import certnLogo from "../../images/logos/security/certn_logo.png";

export default class CheckoutContainerController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    User,
    pulledGames,
    OmnibarPointer,
    CartManager,
    MetaTagsService,
    EventTrackingManager,
    LazyLoad,
    SenseiService,
    SenseiReviewService
  ) {
    this.userId = $scope.currentId;
    this.role = $scope.role;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.cart = CartManager;
    this.sensei = SenseiService;
    // injected and imported
    this.games = pulledGames.data.games;
    this.shieldIcon = shieldIcon;
    this.certnLogo = certnLogo;
    this.navBar = navBar;
    this.gsLogoHorizontal = gsLogoHorizontal;
    // booleans
    this.displayEmptyCart = false;

    this.initWatchers(
      $scope,
      $state,
      EventTrackingManager,
      SenseiReviewService
    );
    this.initEventListeners($rootScope, EventTrackingManager, User, LazyLoad);

    this.initCheckout($state, $stateParams);
    this.initUser(User);
    this.initMetaTags(MetaTagsService);

    // Dom ready
    angular.element(() => this.initPage(LazyLoad));
  }

  // init methods
  initWatchers($scope, $state, EventTrackingManager, SenseiReviewService) {
    const watchPaymentInfo = $scope.$watch(
      () => this.cart.total,
      (newVal) => {
        if (Object.keys(newVal).length) {
          this.checkStatesForEvents($state.current.name, EventTrackingManager);
          this.setUpFeaturedReview(SenseiReviewService);
          watchPaymentInfo();
        }
      }
    );
  }

  initEventListeners($rootScope, EventTrackingManager, User, LazyLoad) {
    $rootScope.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams, options) => {
        window.scrollTo(0, 0);
        LazyLoad.set();
        if (fromState.name !== "checkout-v2.landing") {
          if (
            toState.name === "checkout-v2.access" ||
            toState.name === "checkout-v2.payment"
          ) {
            this.checkStatesForEvents(toState.name, EventTrackingManager);
          } else if (toState.name === "checkout-v2.success") {
            // wrap in timeout to try to prevent /payment from being called
            angular.element(() => {
              EventTrackingManager.pushViewEvent(
                "Order Confirmation",
                "Token Purchase"
              );
              EventTrackingManager.pushEcommerceTransaction(
                this.cart.getCartItems(),
                this.cart.getPaymentDetails(),
                this.cart.getOrderId()
              );
              EventTrackingManager.pushCompletedOrderV2(
                this.cart.getCartItems(),
                this.cart.getPaymentDetails(),
                User.getData(),
                this.cart.getOrderId()
              );
            });
          }
        }
      }
    );
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Checkout | Gamer Sensei";
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initUser(User) {
    if (this.userId) {
      User.setCurrentId(this.userId);
      User.setRole(this.role);
      User.getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }

  // check for local storage and setup
  initCheckout($state, $stateParams) {
    if (this.cart.checkForCartContents()) {
      this.cart.setCartDisplay(true, true);
      this.redirectFromBasePage($state, $stateParams);
    } else if (!this.cart.checkForCartContents()) {
      if ($state.current.name !== "checkout-v2.landing") {
        $state.go("checkout-v2.landing");
      }
      this.displayEmptyCart = true;
    }

    const cartStatus = this.cart.getStatus();

    if (cartStatus !== "checkout") {
      this.cart.setStatus("checkout");
    }
  }

  // DOM ready
  initPage(LazyLoad) {
    LazyLoad.set();
  }

  // shows time of updated review
  reviewUpdatedAt(review) {
    if (review !== null && review.updated_at) {
      return moment(review.updated_at).format("MM/DD/YY");
    }
  }

  // watchPaymentInfo methods
  setUpFeaturedReview(SenseiReviewService) {
    const cartItems = this.cart.getCartItems();
    const itemsWithSenseiID = Object.values(cartItems).filter((item) => {
      return item.senseiId;
    });
    if (itemsWithSenseiID.length) {
      const featuredSensei = itemsWithSenseiID.reduce((prev, current) => {
        return prev.base_student_cost > current.base_student_cost
          ? prev
          : current;
      });

      const featuredReviewParams = {
        params: {
          reviewee_id: featuredSensei.senseiId,
          is_testimonial: true,
        },
      };

      this.sensei.setSensei(featuredSensei.senseiId);

      SenseiReviewService.get(featuredReviewParams)
        .then((featuredResp) => {
          const featuredReview = featuredResp.data.sensei_reviews;
          if (featuredReview.length === 1) {
            const featuredParentReview = featuredResp.data.sensei_reviews[0];
            let updatedReviewsLength =
              featuredParentReview.updated_reviews.length;
            if (updatedReviewsLength > 0) {
              this.featuredReview =
                featuredParentReview.updated_reviews[updatedReviewsLength - 1];
            } else {
              this.featuredReview = featuredParentReview;
            }
            this.hasTestimonial = true;
          }
        })
        .catch(angular.noop);
    }
  }

  // watchPaymentInfo and $stateChangeSuccess
  checkStatesForEvents(state, EventTrackingManager) {
    if (state === "checkout-v2.access" || state === "checkout-v2.payment") {
      EventTrackingManager.pushCheckoutDetailsV2(
        "checkout",
        this.cart.getCartItems(),
        this.cart.getPaymentDetails(),
        this.calculateStep(state),
        this.cart.getPaymentTab()
      );
    }
  }

  // initCheckout call
  redirectFromBasePage($state, $stateParams) {
    if (this.userId && $state.current.name === "checkout-v2.landing") {
      $state.go(
        "checkout-v2.payment",
        { ref: $stateParams.ref },
        {
          location: "replace",
        }
      );
    } else if ($state.current.name === "checkout-v2.landing") {
      $state.go(
        "checkout-v2.access",
        { ref: $stateParams.ref },
        {
          location: "replace",
        }
      );
    }
  }

  // checkStateForEvents call
  calculateStep(state) {
    if (state === "checkout-v2.access") {
      return 1;
    } else if (state === "checkout-v2.payment") {
      return 2;
    }
  }

  // methods
  doesUserHaveASubscriptionInCart() {
    const cartItemsWithSubs = Object.values(this.cart.getCartItems()).filter(
      (item) => {
        return item.plan_name || item.subscribed;
      }
    );

    return cartItemsWithSubs.length;
  }
}

CheckoutContainerController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$stateParams",
  "User",
  "pulledGames",
  "OmnibarPointer",
  "CartManager",
  "MetaTagsService",
  "EventTrackingManager",
  "LazyLoad",
  "SenseiService",
  "SenseiReviewService",
];
