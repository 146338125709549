export default function userOsService() {
  var service = {
    _windowsOS: false,
    _windowsIsa: false,
    setOS: setOS,
    getOS: getOS,
    setWinIsa: setWinIsa,
    getWinIsa: getWinIsa,
  };

  return service;

  ///////////

  function getOS() {
    return this._windowsOS;
  }
  function getWinIsa() {
    return this._windowsIsa;
  }

  function setOS(win) {
    this._windowsOS = win;
  }
  function setWinIsa(winIsa) {
    this._windowsIsa = winIsa;
  }

}
