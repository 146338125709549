import alexImg from "../../images/user/test_alex";
import jordanImg from "../../images/user/test_jordan";
import kateAndRichImg from "../../images/user/test_kate_and_rich";
import michaelImg from "../../images/user/test_micheal";
import saurabhImg from "../../images/user/test_saurabh";

export default function testimonialUtilsService() {
  var utils = {
    customers: [
      {
        name: "Alex Wi",
        image: alexImg,
        active: true,
        quote:
          "<p>I doubled up my MMR, started as a crusader… I highly recommend coaching with Gamer Sensei.</p>",
      },
      {
        name: "Jordan Dueley",
        image: jordanImg,
        active: true,
        quote:
          "<p>I went from Champion 3 to 1800 [in Rocket League] in the course of a year.</p>",
      },
      {
        name: "Kate and Richard Swearingen",
        image: kateAndRichImg,
        active: true,
        quote:
          "<p>My win rate went from below 40% to above 50%. I highly recommend Gamer Sensei to my high school players and have it on my Discord.</p>",
      },
      {
        name: "Micheal Northrop",
        image: michaelImg,
        active: true,
        quote:
          "<p>His in-depth understanding of gameplay and strategy allows him to see things that I miss. I definitely progressed up several ranks as a direct result.</p>",
      },
      {
        name: "Saurabh Tomar",
        image: saurabhImg,
        quote:
          "<p>[My coach] helped me a lot to understand the game better... Now I understand things better even when I am just watching pro teams play.</p>",
      },
    ],
    getCustomers,
    getCount,
  };

  return utils;

  ////

  function getCustomers() {
    var newCustomerArray = [...utils.customers];

    return newCustomerArray.sort(function () {
      return 0.5 - Math.random();
    });
  }

  function getCount() {
    return utils.customers.length.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  }
}
