import pageNavTemplate from "../../templates/directives/navigation/pagination_nav_template.html";

export default function paginationNavDirective() {
  return {
    restrict: "E",
    scope: {
      pages: "=",
      currentPage: "=",
      setCurrentPage: "&",
    },
    link: link,
    template: pageNavTemplate,
  };

  function link(scope, elem, attr) {
    // pagination
    scope.lastPage = scope.pages;
    scope.firstPage = 1;
  }
}
