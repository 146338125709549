import legalTemplate from "../../../templates/directives/popup/modal/legal_modal_template";
import tos from "../../../config/legal/terms-of-service-text";
import privacy from "../../../config/legal/privacy-policy-text";

export default function legalModalDirective() {
  return {
    restrict: "E",
    scope: {
      openModal: "=",
      legalHeader: "@",
    },
    template: legalTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.tos = tos;
    scope.privacy = privacy;

    scope.openModal = function () {
      const scrollY =
        document.documentElement.style.getPropertyValue("--scroll-y");
      const html = document.documentElement;
      angular.element(document.querySelector("html")).addClass("open-modal");
      html.style.top = `-${scrollY}`;
      scope.opened = true;
    };

    scope.closeModal = function () {
      const html = document.documentElement;
      const scrollY = html.style.top;
      angular.element(document.querySelector("html")).removeClass("open-modal");
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      scope.opened = false;
    };

    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });
  }
}
