export default class GamerWalletRedeemGiftCardController {
  constructor(
    $rootScope,
    $state,
    UIFeatureGate,
    OmnibarPointer,
    GiftCardManager
  ) {
    $rootScope.bodylayout = "gamer gift-card";
    // angular providers
    this.$state = $state;
    // services
    this.giftCardManager = GiftCardManager;
    this.omnibarPointer = OmnibarPointer;
    // booleans
    this.submitting = false;

    // feature gates
    this.giftCardsAvailable =
      UIFeatureGate.data.ui_feature_gates.gift_cards_available;

    this.redirectIfGiftCardsNotAvailable();
  }

  // init methods
  redirectIfGiftCardsNotAvailable() {
    if (!this.giftCardsAvailable) {
      this.$state.go("gamer_dashboard.landing");
    }
  }

  // action methods
  submitGiftCardCode() {
    this.submitting = true;

    const giftCodeParams = {
      credit_redemption: {
        gift_card_redemption_code: this.giftCardCode,
      },
    };

    this.giftCardManager
      .redeemCode(giftCodeParams)
      .then(() => {
        this.submitting = false;
        this.$state.go("gamer_dashboard.wallet.summary", {}, { reload: true });
      })
      .catch((err) => {
        this.submitting = false;
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }
}

GamerWalletRedeemGiftCardController.$inject = [
  "$rootScope",
  "$state",
  "UIFeatureGate",
  "OmnibarPointer",
  "GiftCardManager",
];
