export default function availabilityService($http) {
  var service = {
    getAvailabilities: getAvailabilities,
    setAvailabilities: setAvailabilities,
    getSenseiBookingUnavailabilities: getSenseiBookingUnavailabilities,
    getGamerBookingUnavailabilities: getGamerBookingUnavailabilities,
    getSenseiGeneralAvailabilities: getSenseiGeneralAvailabilities,
  };

  return service;

  ///////////

  function getAvailabilities(timeZone) {
    return $http
      .get("/api/web/availabilities?time_zone=" + timeZone)
      .then(getAvailabilitiesComplete)
      .catch(angular.noop);

    function getAvailabilitiesComplete(res) {
      return res.data;
    }
  }

  function setAvailabilities(data) {
    return $http({
      method: "POST",
      url: "/api/web/availabilities",
      data: data,
    });
  }

  function getSenseiBookingUnavailabilities(config) {
    return $http.get("/api/web/sensei_booking_unavailabilities", config);
  }

  function getGamerBookingUnavailabilities(config) {
    return $http.get("/api/web/gamer_booking_unavailabilities", config);
  }

  function getSenseiGeneralAvailabilities(config) {
    return $http.get("/api/web/textual_specific_availabilities", config);
  }
}

availabilityService.$inject = ["$http"];
