export default function pagePermissionGateService($http) {

  var service = {
    check: check
  };

  return service;

  ///////////

  function check(config) {
    return $http.get('/api/web/page_permissions', config);
  }

}

pagePermissionGateService.$inject = ['$http'];