export default class BusinessLessonsNotesListController {
  constructor($rootScope, $state, UIFeatureGate, SenseiTrainingRequestManager) {
    $rootScope.bodylayout = "lessons list gamer";
    this.$state = $state;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.SenseiTrainingRequestManager.setWithSenseiNotes(true);
    this.SenseiTrainingRequestManager.setWithSenseiChats(null);
    this.allLessons = this.SenseiTrainingRequestManager.allLessons;

    // Feature Gate
    this.isSenseiNotesAvailable =
      UIFeatureGate.data.ui_feature_gates.sensei_notes_available;

    this.initSenseiNotesList(true);
  }

  // init methods
  initSenseiNotesList(force) {
    if (this.isConfirmedLessonBucketAvailable) {
      this.SenseiTrainingRequestManager.isConfirmedLessonBucketAvailable = true;
    }

    this.pageNotification = { show: false };
    this.SenseiTrainingRequestManager.init(force)
      .then(function () {})
      .catch(angular.noop);
  }

  // display methods
  buildSessionTitle(session) {
    let time;

    if (session.sessionDurationHours === 0.5) {
      time = "30-Minute";
    } else {
      time = `${session.sessionDurationHours}-Hour`;
    }

    if (session.tokenTypeName) {
      return `${time} ${session.gameName} ${session.tokenTypeName} Session on ${session.dateString} at ${session.timeString} ${session.tzString}`;
    }

    return `${time} ${session.gameName} Lesson on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
  }

  // action methods
  toggleSenseiNotes(lesson) {
    lesson.senseiNotesOpened = !lesson.senseiNotesOpened;
  }

  openNotes(lesson) {
    this.$state.go("business_dashboard.lessons.sensei_note_page", {
      lessonId: lesson.request.id,
    });
  }
}

BusinessLessonsNotesListController.$inject = [
  "$rootScope",
  "$state",
  "UIFeatureGate",
  "SenseiTrainingRequestManager",
];
