import moment from "moment-timezone";

export default class SenseiLessonsSubscribersController {
  constructor(
    $scope,
    $state,
    $stateParams,
    User,
    TokenBookingService,
    UserSubscriptionsService,
    OmnibarPointer
  ) {
    this.userService = User;
    this.tokenBooking = TokenBookingService;
    this.omnibarPointer = OmnibarPointer;
    this.sessionProposal = {
      selectedDate: moment(),
      proposalTimeZone: moment.tz.guess(),
      sessionProposalNotes: "",
    };
    this.studentName = null;
    this.proposalModal = {};
    this.userSubs = UserSubscriptionsService;
    this.completedGrab = false;

    if ($stateParams.studentName) {
      this.studentName = $stateParams.studentName;
    }

    this.userService.setCurrentId($scope.currentId);
    this.userService.setRole("sensei");

    this.filterBy = function () {
      $state.go("sensei_dashboard.lessons.student_history", {
        studentName: this.studentName,
      });
    };

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.userSubs
          .setSubscriptions("sensei", userData.sensei.id)
          .then(() => {
            this.completedGrab = true;
          })
          .catch((err) => {
            this.completedGrab = true;
          });

        this.subTableHeader = {
          last_lesson_date: {
            title: "Last Lesson",
            isActive: true,
          },
          screen_name: {
            title: "Screen Name",
          },
          communication_handle: {
            title: "Discord Name",
          },
          subscription_plan_name: {
            title: "Subscription Plan",
          },
        };
      })
      .catch(angular.noop);

    this.doesUserHaveAnySubscriptions = function () {
      return this.completedGrab && this.userSubs.getSubscriptions().length;
    };
  }
}

SenseiLessonsSubscribersController.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "User",
  "TokenBookingService",
  "UserSubscriptionsService",
  "OmnibarPointer",
];
