const doEntriesMatch = (addedItem, listOfItems) => {
  listOfItems.some((v) => {
    const listItem = v.toLowerCase();
    const newItem = addedItem.toLowerCase();

    return newItem.indexOf(listItem) >= 0;
  });
};

export default doEntriesMatch;
