export default class MarketingContainerController {
  constructor($scope, User, OmnibarPointer, pulledGames) {
    this.games = pulledGames.data.games;
    this.omnibarPointer = OmnibarPointer;

    //create user
    this.initUser($scope, User);
  }

  initUser($scope, User) {
    if ($scope.currentId) {
      User.setCurrentId($scope.currentId);
      User.setRole($scope.role);
      User.getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }
}

MarketingContainerController.$inject = [
  "$scope",
  "User",
  "OmnibarPointer",
  "pulledGames",
];
