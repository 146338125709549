export default function eventTrackingManagerService($analytics, $document) {
  window.__insp = window.__insp || [];
  ///
  window.dataLayer = window.dataLayer || [];
  var service = {
    pushEvent: pushEvent,
    pushTransactionTrack: pushTransactionTrack,
    ///
    pushEventOnly: pushEventOnly,
    pushEventObject: pushEventObject,
    pushViewEvent: pushViewEvent,
    pushUserConnect: pushUserConnect,
    pushNotifyChanges: pushNotifyChanges,
    pushVerifiedUser: pushVerifiedUser,
    pushCartDetailsV2: pushCartDetailsV2,
    pushAddOrRemoveFromCartV2: pushAddOrRemoveFromCartV2,
    pushCheckoutDetails: pushCheckoutDetails,
    pushCheckoutDetailsV2: pushCheckoutDetailsV2,
    pushCompletedOrder: pushCompletedOrder,
    pushCompletedOrderV2: pushCompletedOrderV2,
    pushEcommerceTransaction: pushEcommerceTransaction,
    pushLessonRefund: pushLessonRefund,
    pushLessonManagement: pushLessonManagement,
    pushLessonManagementV2: pushLessonManagementV2,
    pushLessonReschedule: pushLessonReschedule,
    pushSearchResults: pushSearchResults,
    pushProfileUpdate: pushProfileUpdate,
    pushContactUpdate: pushContactUpdate,
    pushNotificationUpdate: pushNotificationUpdate,
  };

  function pushEventOnly(event) {
    dataLayer.push({ event: event });
  }

  function pushEventObject(obj) {
    dataLayer.push(obj);
  }

  function pushViewEvent(type, name) {
    var obj = {
      event: "pageView",
      pageType: type,
      pageTitle: name ? name : $document[0].title,
    };
    dataLayer.push(obj);
  }

  function pushUserConnect(event, user, type) {
    var obj = {
      event: event,
      customerEmail: user.email,
      customerID: user.id,
      gigyaID: user.gigya_uid,
      brandName: user.brand,
      brandDomain: user.brand_domain,
    };

    if (event === "accountRegistration") {
      obj.termsService = user.agree_to_terms;
      obj.marketingConsent = user.general_communication_opt_in || false;
      obj.esportsOpportunities = user.scholarship_info_opt_in || false;
      obj.userName = user.screen_name;
    }

    if (type) {
      obj.userType = type;
    } else {
      var role = "student";
      if (role.gamer) {
        role = "student";
      } else if (user.sensei) {
        role = "teacher";
      }

      obj.userType = role;
    }

    dataLayer.push(obj);
  }

  function pushNotifyChanges(event, eventData) {
    var obj = {
      event: event,
      ...eventData,
    };

    dataLayer.push(obj);
  }

  function pushVerifiedUser(user, isRegister) {
    var obj = {
      event: "verifiedUser",
      customerEmail: user.email,
      customerID: user.id,
      gigyaID: user.gigya_uid,
      brandName: user.brand,
      brandDomain: user.brand_domain,
    };

    if (isRegister) {
      obj.registrationSuccess = "success";
    } else {
      obj.loginSuccess = "success";
    }

    dataLayer.push(obj);
  }

  function pushCartDetailsV2(cartItems, payment) {
    var cartContents = [];
    if (Object.keys(cartItems).length) {
      angular.forEach(cartItems, function (item, key) {
        cartContents.push(setCartBasketItemObjectV2(item));
      });
    }

    var obj = {
      event: "cartBasket",
      orderSubtotal: calculateSubTotal(payment),
      orderTax: payment.service_fee / 100,
      orderShipping: "0.00",
      orderDiscount: "",
      orderTotal: payment.amount / 100,
      currencyCode: "USD",
      orderCouponCode: "",
      cartContents: cartContents,
    };

    dataLayer.push(obj);
  }

  function pushAddOrRemoveFromCartV2(event, item, cartItems) {
    var sku;
    var product;
    var cat;

    if (item.itemType === "single") {
      sku = `${item.duration} hour ${item.token_tier_data.name} ${item.token_type_data.name}`;
      product = item.token_tier_data.name;
      cat = `${item.game}-token`;
    } else if (item.itemType === "bundle") {
      sku = `Bundle: ${item.name}`;
      product = item.name;
      cat = "bundle-token";
    }

    var obj = {
      event: event,
      sku: sku,
      productName: product,
      tokenGame: item.game,
      productPrice: (item.base_student_cost * item.quantity) / 100,
      currencyCode: "USD",
      quantity: item.quantity,
      category: cat,
      addedFrom: window.location.pathname,
    };

    if (item.sensei) {
      obj.coachName = item.sensei.fullName;
      obj.coachRating = item.sensei.ratingAverage;
      obj.coachImage = item.sensei.image;
      obj.coachDescription = item.sensei.description;
    }

    if (Object.keys(cartItems).length !== 0) {
      var cartContents = [];
      angular.forEach(cartItems, function (cartItem) {
        cartContents.push(setCartBasketItemObjectV2(cartItem));
      });
      obj.cartContents = cartContents;
    }

    dataLayer.push(obj);
  }

  function pushCheckoutDetails(event, details, step, option) {
    var optionName = "";
    if (option === "card") {
      optionName = "Credit Card";
    } else if (option === "paypal") {
      optionName = "PayPal";
    } else if (option === "amazonPay") {
      optionName = "Amazon Pay";
    }

    var cartContents = [];
    if (details.selectedBundles) {
      for (let i = 0; i < details.selectedBundles.length; i++) {
        cartContents.push(
          setCartBasketItemObject(details.sensei, details.selectedBundles[i])
        );
      }
    }

    var obj = {
      event: event,
      orderSubtotal: details.rawCost / 100,
      orderTax: details.serviceFee / 100,
      orderShipping: "0.00",
      orderDiscount: calculateDiscount(details),
      orderTotal: details.totalValue / 100,
      currencyCode: "USD",
      orderCouponCode: setCouponCode(details),
      cartContents: cartContents,
      ecommerce: {
        checkout: {
          actionField: { step: step, option: optionName },
        },
      },
    };

    dataLayer.push(obj);
  }

  function pushCheckoutDetailsV2(event, cartItems, payment, step, option) {
    var optionName = "";
    if (option === "card") {
      optionName = "Credit Card";
    } else if (option === "paypal") {
      optionName = "PayPal";
    } else if (option === "amazonPay") {
      optionName = "Amazon Pay";
    } else if (option) {
      optionName = option;
    }

    var cartContents = [];
    if (Object.keys(cartItems).length) {
      angular.forEach(cartItems, function (item, key) {
        cartContents.push(setCartBasketItemObjectV2(item));
      });
    }

    var obj = {
      event: event,
      orderSubtotal: calculateSubTotal(payment),
      orderTax: payment.service_fee / 100,
      orderShipping: "0.00",
      orderDiscount: "",
      orderTotal: payment.amount / 100,
      currencyCode: "USD",
      orderCouponCode: "",
      cartContents: cartContents,
      ecommerce: {
        checkout: {
          actionField: { step: step, option: optionName },
        },
      },
    };

    dataLayer.push(obj);
  }

  function pushCompletedOrder(details, user) {
    var cartContents = [];
    if (details.selectedBundles) {
      for (let i = 0; i < details.selectedBundles.length; i++) {
        cartContents.push(
          setCartBasketItemObject(details.sensei, details.selectedBundles[i])
        );
      }
    }
    var phoneNumber = function (code, number) {
      if (!number) {
        return null;
      }

      if (code) {
        return code + " " + number;
      } else {
        return number;
      }
    };

    var obj = {
      event: "orderConfirmation",
      orderID: details.confirmationId,
      customerEmail: user.email,
      orderSubtotal: details.rawCost / 100,
      orderTax: details.serviceFee / 100,
      orderShipping: "0.00",
      orderDiscount: calculateDiscount(details),
      orderTotal: details.totalValue / 100,
      currencyCode: "USD",
      orderCouponCode: setCouponCode(details),
      billingFirstName: user.first_name,
      billingLastName: user.last_name,
      billingPhone: phoneNumber(user.country_code, user.phone_number),
      cartContents: cartContents,
    };

    dataLayer.push(obj);
  }

  function pushCompletedOrderV2(cartItems, payment, user, orderId) {
    var cartContents = [];
    if (Object.keys(cartItems).length) {
      angular.forEach(cartItems, function (item, key) {
        cartContents.push(setCartBasketItemObjectV2(item));
      });
    }
    var phoneNumber = function (code, number) {
      if (!number) {
        return null;
      }

      if (code) {
        return code + " " + number;
      } else {
        return number;
      }
    };

    var obj = {
      event: "orderConfirmation",
      orderID: orderId,
      customerEmail: user.email,
      orderSubtotal: calculateSubTotal(payment),
      orderTax: payment.service_fee / 100,
      orderShipping: "0.00",
      orderDiscount: "",
      orderTotal: payment.amount / 100,
      currencyCode: "USD",
      orderCouponCode: "",
      cartContents: cartContents,
      billingFirstName: user.first_name,
      billingLastName: user.last_name,
      billingPhone: phoneNumber(user.country_code, user.phone_number),
      cartContents: cartContents,
    };

    dataLayer.push(obj);
  }

  function pushEcommerceTransaction(cartItems, payment, orderId) {
    var cartContents = [];
    if (Object.keys(cartItems).length) {
      angular.forEach(cartItems, function (item, key) {
        cartContents.push(setCartBasketItemObjectV2(item));
      });
    }

    var obj = {
      event: "ecommerce-transaction",
      transactionId: orderId,
      transactionTotal: payment.amount / 100,
      transactionProducts: cartContents,
    };

    dataLayer.push(obj);
  }

  function pushLessonRefund(deposit, type) {
    var refundAmount =
      type === "hard"
        ? deposit.refundability.hard_refundable_amount / 100
        : deposit.refundability.refundable_amount / 100;
    var refundHours =
      type === "hard"
        ? deposit.refundability.hard_refundable_hours
        : deposit.refundability.refundable_hours;

    var obj = {
      event: "orderReturn",
      totalRefundAmount: refundAmount,
      totalRefundHours: refundHours,
      coachId: deposit.sensei_id,
      coachName: deposit.sensei_full_name,
      coachGame: deposit.game_name,
      currencyCode: "USD",
      category: deposit.game_name + " Sensei",
    };

    dataLayer.push(obj);
  }

  function pushLessonManagement(event, lesson, gamer, sensei, token) {
    var obj = {
      event: event,
      studentID: gamer.gamer.id,
      coachName: sensei.full_name,
      game: lesson.game_name,
      lessonDate: lesson.gamer_proposed_date,
      lessonTime: lesson.gamer_proposed_hour,
    };

    dataLayer.push(obj);
  }

  function pushLessonManagementV2(event, avail, gamer, sensei, token) {
    var obj = {
      event: event,
      studentID: gamer.gamer.id,
      coachId: sensei.id,
      coachName: sensei.screen_name,
      game: token.game,
      tokenTier: token.tier,
      tokenType: token.type,
      lessonDate: moment(avail.start).format("YYYY-MM-DD"),
      lessonTime: moment(avail.start).format("h:mm a ZZ"),
    };

    dataLayer.push(obj);
  }

  function pushLessonReschedule(lesson, data) {
    var obj = {
      event: "rescheduleLesson",
      studentID: data.gamer_id,
      coachId: data.sensei_id,
      coachName: data.sensei_full_name,
      game: data.game_name,
      lessonDate: lesson.proposed_date,
      lessonTime: lesson.proposed_hour,
    };

    dataLayer.push(obj);
  }

  function pushSearchResults(event, game, term, results) {
    var obj = {
      event: event,
      searchGame: game,
      searchTerm: term,
    };
    if (results) obj.results = numSearchResults;
    dataLayer.push(obj);
  }

  function pushProfileUpdate(oldUser, newUser) {
    if (hasAnyUserDataChanged(oldUser, newUser)) {
      var obj = {
        event: "accountProfileUpdate",
      };
      if (oldUser.firstName !== newUser.first_name) {
        obj.firstName = newUser.first_name;
      }
      if (oldUser.lastName !== newUser.last_name) {
        obj.lastName = newUser.last_name;
      }
      if (oldUser.screenName !== newUser.screen_name) {
        obj.screenName = newUser.screen_name;
      }
      if (oldUser.games !== newUser.game_description) {
        obj.games = newUser.game_description.split(", ");
      }

      dataLayer.push(obj);
    }
  }

  function pushContactUpdate(userEvent, user, phone, chat) {
    var communicationServices = calculateNewChatServices(chat);

    var obj = {
      event: "accountContactDetailsUpdate",
      email: user.email,
      games: user.game_description.split(", "),
      phoneNumber:
        phone && userEvent.phoneNumber !== phone
          ? phone
          : userEvent.phoneNumber || null,
    };

    if (communicationServices.length) {
      obj.communicationServices = communicationServices;
    }

    dataLayer.push(obj);
  }

  function pushNotificationUpdate(email, notification) {
    var obj = {
      event: "consentUpdates",
      customerEmail: email,
      [notification.key]: notification.toggle,
    };

    dataLayer.push(obj);
  }

  function setCartBasketItemObject(sensei, item) {
    return {
      sku: item.bundle_snapshot,
      name: item.total_hours + " Hour Bundle Purchase",
      coachID: sensei.senseiId,
      coachName: sensei.combinedName,
      coachGame: sensei.game.name,
      price: item.net_cost / 100,
      currencyCode: "USD",
      quantity: item.count,
      category: sensei.game.name + " Sensei",
      productImageURL: sensei.image,
    };
  }

  function setCartBasketItemObjectV2(item) {
    return {
      sku: `${item.id}-${item.game}-${item.token_tier}-${item.token_type}`,
      name: item.name,
      tokenGame: item.game,
      price: (item.base_student_cost * item.quantity) / 100,
      currencyCode: "USD",
      quantity: item.quantity,
      category: `${item.game}-token`,
    };
  }

  function setCouponCode(details) {
    var code = "";
    if (details.promo) {
      code = details.promo;
    } else if (details.giftCardCode) {
      code = details.giftCardCode;
    }

    return code;
  }

  function calculateDiscount(details) {
    return (
      details.combinedDiscount() +
      (details.giftCardAmount +
        details.discount +
        details.firstChargeDiscount) /
        100
    );
  }

  function calculateSubTotal(paymentDetails) {
    var amount = 0;

    if (
      Array.isArray(paymentDetails.sensei_tokens) &&
      paymentDetails.sensei_tokens
    ) {
      for (let payment of paymentDetails.sensei_tokens) {
        amount = amount + payment.net_cost;
      }
    }
    if (
      Array.isArray(paymentDetails.token_bundles) &&
      paymentDetails.token_bundles
    ) {
      for (let payment of paymentDetails.token_bundles) {
        amount = amount + payment.net_cost;
      }
    }

    return amount / 100;
  }

  function hasAnyUserDataChanged(oldUser, newUser) {
    return (
      oldUser.firstName !== newUser.first_name ||
      oldUser.lastName !== newUser.last_name ||
      oldUser.screenName !== newUser.screen_name ||
      oldUser.games !== newUser.game_description
    );
  }

  function calculateNewChatServices(newChat) {
    if (newChat.length === 0) return [];

    const list = [];

    for (let chat of newChat) {
      if (chat.remove) {
        list.push({ name: chat.medium, userName: "" });
      } else if (!chat.remove) {
        list.push({ name: chat.medium, userName: chat.handle });
      }
    }

    return list;
  }

  ///

  function pushEvent(eventName, eventObject) {
    if (typeof eventObject === "object") {
      $analytics.eventTrack(eventName, eventObject);
    } else {
      $analytics.eventTrack(eventName);
    }
  }

  function pushTransactionTrack(eventObject) {
    $analytics.transactionTrack(eventObject);
  }

  return service;
}

eventTrackingManagerService.$inject = ["$analytics", "$document"];
