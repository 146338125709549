import angular from "angular";

export default function featuredSenseiManagerService($http) {
  const featuredEndpoint = "/api/web/featured_senseis";
  const manager = {
    loading: false,
    sensei: {},
    config: {
      game: null,
      arrows: false,
      height: "200px",
      slick: {
        enabled: true,
        dots: false,
        draggable: false,
        arrows: false,
        speed: 600,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        method: {},
        event: {
          init: (event, slick) => {
            areArrowsOnThisBreakpoint(slick.activeBreakpoint);
          },
          reInit: (event, slick) => {
            areArrowsOnThisBreakpoint(slick.activeBreakpoint);
          },
          breakpoint: (event, slick, breakpoint) => {
            areArrowsOnThisBreakpoint(breakpoint);
          },
        },
        responsive: [
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 650,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 950,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1250,
            settings: {
              slidesToShow: 4,
            },
          },
        ],
      },
    },
    getSlickConfig,
    getArrowDisplay,
    getLoader,
    getHeight,
    getFeaturedSenseis,
    setFeaturedSenseis,
    getFeaturedGame,
    setFeaturedGame,
    areThereFeaturedSenseis,
  };

  return manager;

  // Service functions

  function getSlickConfig() {
    return manager.config.slick;
  }

  function getArrowDisplay() {
    return manager.config.arrows;
  }

  function getLoader() {
    return manager.loading;
  }

  function getHeight() {
    return manager.config.height;
  }

  function getFeaturedSenseis() {
    return manager.sensei;
  }

  function setFeaturedSenseis(gameSlug) {
    const wrapperHeight = document.querySelector("#feature-loader")
      ? document.querySelector("#feature-loader").clientHeight
      : 250;
    manager.config.height = `${wrapperHeight}px`;
    manager.loading = true;
    manager.config.game = gameSlug;

    return $http
      .get(`${featuredEndpoint}?game_slug=${gameSlug}`)
      .then((res) => {
        manager.config.slick.enabled = false;
        manager.sensei = formatSenseisToObj(res.data.featured_senseis);
        manager.loading = false;
        manager.config.slick.enabled = true;
      })
      .catch((err) => {
        manager.loading = false;
        return err;
      });
  }

  function getFeaturedGame() {
    return manager.config.game;
  }

  function setFeaturedGame(gameSlug) {
    manager.config.game = gameSlug;
    manager.setFeaturedSenseis(gameSlug);
  }

  function areThereFeaturedSenseis() {
    return Object.keys(manager.sensei).length;
  }

  // Internal functions
  function formatSenseisToObj(sensei) {
    const initialValue = {};

    return sensei.reduce(function (obj, item) {
      return {
        ...obj,
        [item.user_id]: {
          ...item,
        },
      };
    }, initialValue);
  }

  function areArrowsOnThisBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 500:
        manager.config.arrows =
          manager.areThereFeaturedSenseis() > 1 ? true : false;
        break;
      case 650:
        manager.config.arrows =
          manager.areThereFeaturedSenseis() > 2 ? true : false;
        break;
      case 950:
        manager.config.arrows =
          manager.areThereFeaturedSenseis() > 3 ? true : false;
        break;
      case 1250:
        manager.config.arrows =
          manager.areThereFeaturedSenseis() > 4 ? true : false;
        break;
      default:
        manager.config.arrows =
          manager.areThereFeaturedSenseis() > 5 ? true : false;
        break;
    }
  }
}

featuredSenseiManagerService.$inject = ["$http"];
