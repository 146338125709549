export default function passwordResetService($http) {
  let passwordRecover = false;

  const service = {
    request: passwordResetInstruction,
    submit: passwordResetSubmission,
    setStatus: setPasswordRecoverStatus,
    getStatus: getPasswordRecoverStatus,
  };

  return service;

  ///////////

  function passwordResetInstruction(data) {
    return $http.post("/api/web/password_reset_instructions", data);
  }

  function passwordResetSubmission(data) {
    return $http.post("/api/web/password_reset_submissions", data);
  }

  function setPasswordRecoverStatus(value) {
    passwordRecover = value;
  }

  function getPasswordRecoverStatus() {
    return passwordRecover;
  }
}

passwordResetService.$inject = ["$http"];
