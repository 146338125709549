import selectExistingCardPartial from "../../../../templates/includes/payment/payment_select_existing_card_partial";
import selectNewCardPartial from "../../../../templates/includes/payment/payment_select_new_card_partial";
import selectPaypalPartial from "../../../../templates/includes/payment/payment_select_paypal_partial";
import selectAmazonPartial from "../../../../templates/includes/payment/payment_select_amazon_partial";
import {
  creditCardIcon,
  paypalIcon,
  amazonPayIcon,
} from "../../../../config/icons/iconConfig";

export default class BusinessWalletPurchaseController {
  constructor(
    $q,
    $scope,
    $timeout,
    $location,
    $window,
    OmnibarPointer,
    User,
    TokenBookingService,
    FlexiTokenService,
    pulledGames,
    EventTrackingManager
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.$window = $window;
    this.currentUser = {};
    this.userService = User;
    this.games = pulledGames.data.games;
    this.tokenBooking = TokenBookingService;
    this.OmnibarPointer = OmnibarPointer;
    this.flexi = FlexiTokenService;
    this.eventTracking = EventTrackingManager;
    this.icon = {
      creditCardIcon,
      paypalIcon,
      amazonPayIcon,
    };
    this.selectExistingCardPartial = selectExistingCardPartial;
    this.selectNewCardPartial = selectNewCardPartial;
    this.selectPaypalPartial = selectPaypalPartial;
    this.selectAmazonPartial = selectAmazonPartial;
    this.cartItems = {};
    this.selectedQty = 5;

    //
    this.creditCards = [];
    this.creditCardForm = {};
    this.addPayment = false;
    this.promoText =
      $location.search() && $location.search().ref
        ? $location.search().ref
        : "";
    this.promoAttached = false;
    this.cardId = false;
    this.cardErrors = {};
    this.loading = false;
    this.loadingCheckout = false;
    this.makingPayment = false;
    this.disabledSubmitButton = false;
    this.ctaCopy = "Continue";
    this.shownAmazonButton = false;
    this.allowAmazonPay = false;

    this.paypalOptions = {
      onPaymentMethodReceived: (payload) => {
        $timeout(() => {
          this.packagePurchase();
        }, 1000);
      },
      onCancelled: () => {
        $timeout(() => {
          this.flexi.setPaymentDetails({
            amazon_pay: { void: true },
            credit_card: { void: true },
            paypal: { void: true },
          });
        }, 1000);
      },
      onUnsupported: () => {
        this.paypalError = "Authorization failed";
      },
    };

    this.initUser();
    this.initSelectedFlexiToken();

    angular.element(() => {
      this.flexi.setPaymentTab("card");
      this.eventTracking.pushViewEvent("Business Purchase Sessions");
    });
  }

  // init methods
  initUser() {
    const watchUser = this.$scope.$watch(
      () => this.userService._userData,
      (newVal) => {
        if (newVal) {
          this.updateUser();
          watchUser();
        }
      }
    );
  }

  initSelectedFlexiToken() {
    const watchSelectedToken = this.$scope.$watch(
      () => this.flexi.selectedFlexiToken,
      (newVal) => {
        if (newVal?.id) {
          this.selectedFlexiModel = newVal.id;
          watchSelectedToken();
        }
      }
    );
  }

  updateUser(force) {
    const deferred = this.$q.defer();
    let updateType;

    if (force) {
      updateType = this.userService.getUserByIdAndRole(
        this.currentUser.id,
        "gamer",
        force
      );
    } else {
      updateType = this.userService.getUser();
    }

    updateType.then(
      (userData) => {
        this.currentUser = userData;
        this.creditCards = userData.credit_cards;

        if (this.creditCards && this.creditCards.length === 0) {
          this.cardId = false;
          this.addPayment = true;
        } else {
          this.cardId = this.creditCards[0].id;
          this.addPayment = false;

          if (this.cardId) {
            const cardData = this.getSelectedCard(this.cardId);
            this.flexi.setPaymentDetails({
              paypal: { void: true },
              amazon_pay: { void: true },
              credit_card: {
                credit_card_id: cardData.id,
                credit_card_name: cardData.credit_card_name,
                credit_card_last_four: cardData.credit_card_last_four,
                credit_card_type: cardData.credit_card_type,
                expiration_month: cardData.expiration_month,
                postal_code: cardData.postal_code,
                expiration_year: cardData.expiration_year,
              },
            });
          }
        }
        this.disabledSubmitButton = false;
        this.loadingCheckout = false;
        deferred.resolve(userData);
      },
      (error) => {
        deferred.reject(error);
      }
    );
    return deferred.promise;
  }

  isSelected(tokenTemplate) {
    return (
      tokenTemplate.token_template_id ===
      this.flexi.getSelectedTokenTemplateOption().token_template_id
    );
  }

  isFlexiGrabComplete() {
    const finish = this.flexi.finished;
    return finish;
  }

  isThereAFlexiTokenSelected() {
    const selected = this.flexi.getSelectedFlexiToken();

    return selected?.id && this.selectedFlexiModel;
  }

  selectPaymentTab(type) {
    this.flexi.setPaymentTab(type);
    this.packagePurchase();
  }

  selectCurrentQty(event, qty) {
    this.flexi.adjustQty(this.selectedQty);
  }

  beginPurchaseProcess() {
    this.disabledSubmitButton = true;

    this.flexi
      .processFlexiTokenPurchase()
      .then(() => {
        this.flexi
          .redeemOwnedFlexTokens()
          .then(() => {
            this.disabledSubmitButton = false;
            this.$window.location.href = "/business/dashboard/lessons/book";
          })
          .catch(angular.noop);
      })
      .catch((err) => {
        this.disabledSubmitButton = false;
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  // payment methods
  selectPaymentTab(type) {
    this.flexi.setPaymentTab(type);
    this.packagePurchase();
  }

  isActiveCard() {
    var cardNumber = this.creditCardForm.cardNumber.$viewValue;
    if (cardNumber && cardNumber.length) {
      switch (cardNumber[0]) {
        case "3":
          return "americanExpress";
        case "4":
          return "visa";
        case "5":
          return "mastercard";
        case "6":
          return "discover";
      }
    }
  }

  hideUserAnotherPayment() {
    return this.creditCards ? this.creditCards.length === 0 : true;
  }

  useAnotherPayment() {
    this.addPayment = true;
    this.cardId = false;
    this.ctaCopy = "";
    this.disabledSubmitButton = false;
    this.flexi.setPaymentDetails({
      amazon_pay: { void: true },
      credit_card: { void: true },
      paypal: { void: true },
    });
  }

  switchPaymentTab(tab) {
    this.flexi.setPaymentTab(tab);
    this.disabledSubmitButton = tab === "other";
    this.ctaCopy =
      tab === "paypal" || tab === "other" ? "Continue" : "Add Payment Method";
    if (tab === "amazonPay" && !this.shownAmazonButton) {
      //running showAmazonPayButton multiple times will produce multiple buttons
      this.shownAmazonButton = true;
      showAmazonPayButton();
    }
  }

  selectCard() {
    this.addPayment = false;
    this.ctaCopy = "Continue";
    this.disabledSubmitButton = false;
  }

  selectUserCard(id) {
    if (this.cardId !== id) {
      this.cardId = id;
      this.packagePurchase();
    }
  }

  paymentButtonText() {
    if (this.cardId || this.flexi.getPaymentTab() === "paypal") {
      return "Continue";
    } else {
      return "Add Payment Method";
    }
  }

  paymentHighlight(id) {
    return this.cardId == id;
  }

  invalidFormInput(input) {
    if (this.creditCardForm.$submitted) return input.$invalid;
  }

  shortenYear(card) {
    var year = card.expiration_year;
    return year.substr(year.length - 2);
  }

  packagePurchase() {
    this.loading = true;
    this.loadingCheckout = true;
    this.paypalError = null;

    // paypal purchase
    if (this.flexi.getPaymentTab() === "paypal") {
      const paypalNonce = document.querySelector(
        'input[name="payment_method_nonce"]'
      );

      if (paypalNonce.value) {
        this.flexi.setPaymentDetails({
          amazon_pay: { void: true },
          credit_card: { void: true },
          paypal: { nonce: paypalNonce.value },
        });
      }
    }
    // amazon purchase (not active -- todo?)
    else if (this.flexi.getPaymentTab() === "amazonPay") {
      if (window.orderReferenceId) {
        this.flexi.setPaymentDetails({
          credit_card: { void: true },
          paypal: { void: true },
          amazon_pay: {
            amazon_order_reference_id: window.orderReferenceId,
          },
        });
      } else {
        this.amazonError = "Please log in to Amazon and select a payment";
      }
    }
    // existing card payment
    else if (this.cardId) {
      const cardData = this.getSelectedCard(this.cardId);

      this.flexi.setPaymentDetails({
        paypal: { void: true },
        amazon_pay: { void: true },
        credit_card: {
          credit_card_id: cardData.id,
          credit_card_name: cardData.credit_card_name,
          credit_card_last_four: cardData.credit_card_last_four,
          credit_card_type: cardData.credit_card_type,
          expiration_month: cardData.expiration_month,
          postal_code: cardData.postal_code,
          expiration_year: cardData.expiration_year,
        },
      });
    }
    // submit new credit card
    else if (this.flexi.getPaymentTab() === "card") {
      this.creditCardForm.$setSubmitted();
      if (this.creditCardForm.$valid) {
        getCreditCard()
          .then((_card) => {
            this.updateUser(true).then((_res) => {
              const cardData = this.getSelectedCard(this.cardId);

              this.flexi.setPaymentDetails({
                paypal: { void: true },
                amazon_pay: { void: true },
                credit_card: {
                  credit_card_id: cardData.id,
                  credit_card_name: cardData.credit_card_name,
                  credit_card_last_four: cardData.credit_card_last_four,
                  credit_card_type: cardData.credit_card_type,
                  expiration_month: cardData.expiration_month,
                  postal_code: cardData.postal_code,
                  expiration_year: cardData.expiration_year,
                },
              });
              this.loading = false;
            });
          })
          .catch(angular.noop);
      } else {
        this.loading = false;
      }
    }

    this.eventTracking.pushCheckoutDetailsV2(
      "checkoutOption",
      this.flexi.getCartItems(),
      this.flexi.getPaymentDetails(),
      2,
      this.flexi.getPaymentTab()
    );
    this.loadingCheckout = false;
  }

  areThereMoreCreditsThanCost() {
    // var credits = this.flexi.getSpendableCredits();
    // var sub = this.flexi.getCartSubTotal();
    // var service = this.flexi.getServiceFee() ? this.flexi.getServiceFee() : 0;
    // return credits && credits >= sub + service;
    // TODO: attach value to initial view
    return false;
  }

  isBuyButtonDisabled() {
    const availCredits = this.areThereMoreCreditsThanCost();

    if (this.makingPayment) {
      return true;
    } else if (availCredits) {
      return false;
    }
    return !this.flexi.isPaymentMethodActive();
  }

  // internal methods
  getSelectedCard(id) {
    const length = this.creditCards ? this.creditCards.length : 0;
    let returnCard = null;

    for (let i = 0; i < length; i++) {
      if (this.creditCards[i].id === parseInt(id)) {
        returnCard = this.creditCards[i];
        break;
      }
    }

    return returnCard;
  }
}

BusinessWalletPurchaseController.$inject = [
  "$q",
  "$scope",
  "$timeout",
  "$location",
  "$window",
  "OmnibarPointer",
  "User",
  "TokenBookingService",
  "FlexiTokenService",
  "pulledGames",
  "EventTrackingManager",
];
