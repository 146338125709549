export default class BusinessWalletController {
  constructor($scope, $rootScope, User, OmnibarPointer) {
    $rootScope.bodylayout = "business wallet";
    this.currentUser = {};
    this.$rootScope = $rootScope;
    this.userId = $scope.currentId;
    this.OmnibarPointer = OmnibarPointer;
    this.userService = User;

    this.initUser();
    this.setStateChangeSuccess();
  }

  // init methods
  initUser() {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("gamer");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
      })
      .catch(angular.noop);
  }

  setStateChangeSuccess() {
    this.$rootScope.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams) => {
        this.userService
          .getUser(true)
          .then((userData) => {
            this.currentUser = userData;
          })
          .catch(angular.noop);
      }
    );
  }
}

BusinessWalletController.$inject = [
  "$scope",
  "$rootScope",
  "User",
  "OmnibarPointer",
];
