import omnibarTemplate from "../../../templates/directives/popup/banner/omnibar_template";

export default function omnibarPointerDirective(OmnibarPointer, $timeout) {
  return {
    restrict: "E",
    scope: {
      notify: "=",
    },
    link: function (scope, elem) {
      if (!scope.notify.persist) scope.notify.resetOmnibar();
      var fadeOut;
      var fadeoutTimeout;
      var resetTimeout;

      scope.$watch("notify.omnibarStatus", function (val) {
        $timeout.cancel(fadeOut);
        $timeout.cancel(resetTimeout);
        elem.removeAddClass("fade-out", "visible");
        if (
          val &&
          scope.notify.timerMilliseconds &&
          !GS_VARIABLES.TEST_ENVIRONMENT
        ) {
          fadeOut = $timeout(function () {
            elem.removeAddClass("visible", "fade-out");
          }, scope.notify.timerMilliseconds);
          fadeoutTimeout = fadeOut
            .then(function () {
              // Must wait for the fade effect to finish before resetting.
              resetTimeout = $timeout(function () {
                OmnibarPointer.resetOmnibar();
                elem.removeAddClass("fade-out", "visible");
              }, 1000);
            })
            .catch(angular.noop);
        }
      });
      angular.element.prototype.removeAddClass = function (remove, add) {
        this.removeClass(remove);
        this.addClass(add);
      };
    },
    template: omnibarTemplate,
  };
}

omnibarPointerDirective.$inject = ["OmnibarPointer", "$timeout"];
