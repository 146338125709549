export default function verifyUserEmailService($http) {
  var service = {
    resendEmail,
    verifyEmail
  };

  return service;

  ///

  function resendEmail(data) {
    return $http.post("/api/web/gigya/send_code", data);
  }

  function verifyEmail(data) {
    return $http.post("/api/web/gigya/validate_code", data);
  }

}

verifyUserEmailService.$inject = ["$http"];