export default function lessonDiscardService($http) {
  var services = {
    gamerCancel: gamerCancel,
    senseiReject: senseiReject
  };

  return services;

  function gamerCancel(senseiTrainingRequestId) {
    return $http({method: 'PUT', url: '/api/web/sensei_training_requests/' + senseiTrainingRequestId + '/cancel'});
  }

  function senseiReject(senseiTrainingRequestId) {
    return $http({method: 'PUT', url: '/api/web/sensei_training_requests/' + senseiTrainingRequestId + '/reject'});
  }
}

lessonDiscardService.$inject = ['$http'];