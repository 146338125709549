export default function missingRank(game) {
  const gameHasRanks = game.ranks.length > 0;
  const userMissingRank = !!!game.rank;

  // user is missing rank if the game allows ranks and the user does not have one
  if (gameHasRanks && userMissingRank) {
    return true;
  } else {
    return false;
  }
}
