import moment from "moment-timezone";

export default function masterclassService($q, $http) {
  var service = {
    loading: false,
    finished: false,
    upcoming: {},
    config: {
      timezone: moment.tz.guess(),
    },
    getFetchStatus,
    getUpcomingClasses,
    setUpcomingClasses,
  };

  return service;

  ///

  function getFetchStatus() {
    return !service.loading && service.finished;
  }

  function getUpcomingClasses() {
    return service.masterclasses;
  }

  function setUpcomingClasses() {
    service.loading = true;
    var deferred = $q.defer();
    var today = moment();
    return $http
      .get("/api/web/masterclasses", {
        params: {
          starts_at_from_date: today.format("YYYY-MM-DD"),
          starts_at_end_date: today
            .clone()
            .add(1, "month")
            .format("YYYY-MM-DD"),
          timezone: service.config.timezone,
        },
      })
      .then((res) => {
        service.masterclasses = res.data.masterclasses;
        service.loading = false;
        service.finished = true;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        service.finished = true;
        deferred.reject(err);
      });
  }
}

masterclassService.$inject = ["$q", "$http"];
