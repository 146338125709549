import moment from "moment-timezone";

export default class LessonCardListController {
  constructor(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $timeout,
    $cookies,
    UIFeatureGate,
    SenseiTrainingRequestManager,
    ActionableCount,
    ActionableLessons,
    AsyncUpdate,
    User,
    SenseiTrainingRequestAPIService,
    SenseiReviewService,
    MasterclassService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "lessons list";
    this.BuildSessionTimeoutTime = 10000;
    this.UpdateActionableTimeout;
    this.senseiReviewDismissId = parseInt($cookies.get("senseiReviewDismiss"));
    // angular providers
    this.root = $rootScope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$timeout = $timeout;
    // services
    this.userService = User;
    this.eventTracking = EventTrackingManager;
    this.masterclasses = MasterclassService;
    this.actionableLessons = ActionableLessons;
    this.senseiReviews = SenseiReviewService;
    this.strAPI = SenseiTrainingRequestAPIService;
    this.strManager = SenseiTrainingRequestManager;
    this.asyncUpdate = AsyncUpdate;
    this.hideFindSenseiBtn = ActionableCount !== 0;
    // objects and arrays
    this.reviewedSensei = [];
    this.currentUser = {};
    this.reviewParams = {};
    this.modals = {
      cancelReschedulePolicy: false,
      importantGamerInformation: false,
    };
    this.senseiReviewModal = {
      openedModal: false,
    };
    this.mostRecentPastCompletedLesson = null;
    this.timeZone = moment.tz.guess();

    // Feature Gate
    this.isConfirmedLessonBucketAvailable =
      UIFeatureGate.data.ui_feature_gates.confirmed_lesson_bucket;
    this.isStoreValueAvailable =
      UIFeatureGate.data.ui_feature_gates.points_to_store_value;
    this.isLessonArrivalAvailable =
      UIFeatureGate.data.ui_feature_gates.lesson_arrival_handshake;
    $scope.isExtendLessonRequestAvailable =
      UIFeatureGate.data.ui_feature_gates.extend_lesson_request;

    this.strManager.setWithSenseiNotes(null);
    this.strManager.setWithSenseiChats(null);

    if ($stateParams?.status) {
      this.matchStatus = $stateParams.status;
    } else {
      this.matchStatus = "";
    }

    if ($stateParams?.date) {
      var stateDate = $stateParams.date.split("-");
      this.dateSelection = moment(stateDate, "YYYY-MM-DD").format(
        "MMMM D, YYYY"
      );
    } else {
      this.dateSelection = "";
    }

    if ($stateParams?.lesson) {
      this.matchLesson = $stateParams.lesson;
    } else {
      this.matchLesson = null;
    }

    this.actionableLessons = this.strManager.actionableLessons;
    this.frozenLessons = this.strManager.frozenLessons;

    if (this.isConfirmedLessonBucketAvailable) {
      this.confirmedLessons = this.strManager.confirmedLessons;
    }

    this.cancelModal = {
      opened: false,
      toggle: () => {
        this.cancelModal.opened = !this.cancelModal.opened;
      },
      setSession: (session) => {
        this.cancelModal.session = session;
        if (!this.cancelModal.opened) {
          this.cancelModal.toggle();
        }
      },
      session: null,
    };

    if (this.shouldMasterClassesBeShown()) {
      this.masterclasses.setUpcomingClasses();
    }

    this.initDashboardLessonCard(true);
    this.initUser();

    $scope.$on("$destroy", () => {
      $timeout.cancel(this.UpdateActionableTimeout);
    });
  }

  // Init methods
  initUser() {
    this.userService
      .getUser()
      .then((userData) => {
        if (userData?.gamer) {
          this.populatePastReviews(userData.gamer.id);
        }
        this.currentUser = userData;
        this.gameList = userData.games;

        if (this.$stateParams.game) {
          this.matchGame = this.$stateParams.game;
        } else {
          this.matchGame = null;
        }

        this.watchDateSelection();
      })
      .catch(angular.noop);
  }
  initDashboardLessonCard(force) {
    if (this.isConfirmedLessonBucketAvailable) {
      this.strManager.isConfirmedLessonBucketAvailable = true;
    }
    this.pageNotification = { show: false };

    this.strManager
      .init(force)
      .then(() => {
        this.hideFindSenseiBtn = false;
        if (this.asyncUpdate) {
          this.UpdateActionableTimeout = this.$timeout(
            () => this.updateActionableLessons,
            this.BuildSessionTimeoutTime
          );
        }

        if (this.strManager.isGamer()) {
          this.openSenseiReviewModal(this.closestPastLesson());
        }
      })
      .catch(angular.noop);
  }

  // watch if user selects new date
  watchDateSelection() {
    this.root.$watch(
      () => this.dateSelection,
      (newDate, oldDate) => {
        if (newDate && moment(newDate, "MMMM D, YYYY", true).isValid()) {
          this.matchDate = moment(newDate, "MMMM D, YYYY").format("YYYY-MM-DD");
          this.addStateParams();
        } else {
          this.matchDate = null;
          if (oldDate) this.addStateParams();
        }
      },
      true
    );
  }
  isGamerWithNoUpcomingSessions() {
    return (
      this.strManager.isGamer() &&
      !this.actionableLessons.length &&
      !this.confirmedLessons.length
    );
  }

  // Time/Date displays
  displayTime(time) {
    return time % 60 === 0 ? `${time / 60} Hour` : `${time} Minute`;
  }
  formatTimeRangeDisplay(time, duration) {
    // example format 10:00am - 9:00pm
    const startTime = moment(time).format("h:mmA");
    const endTime = moment(startTime, "h:mmA")
      .add(duration, "minutes")
      .format("h:mmA");

    return startTime + " - " + endTime;
  }
  formatDateDisplay = function (date) {
    return moment(date, "YYYY-MM-DD").format("dddd, MMMM Do");
  };
  clearFilter() {
    this.dateSelection = "";
  }

  //repropose modal methods
  openReproposeModal(item) {
    this.root.chosenSession = item;
    angular.element(document.querySelector("html")).addClass("open-modal");
  }

  // Lesson functionality and helpers
  openGamerCancelReschedule() {
    this.modals.cancelReschedulePolicy = true;
  }
  openGamerImportantInformation() {
    this.modals.importantGamerInformation = true;
  }
  isCancelReschedulePolicyOpen() {
    return this.modals.cancelReschedulePolicy;
  }
  isImportantInformationOpen() {
    return this.modals.importantGamerInformation;
  }
  closeExtraInfoModal() {
    this.modals = {
      cancelReschedulePolicy: false,
      importantGamerInformation: false,
    };
  }
  refreshLessonCard() {
    this.pageNotification = { show: true, refreshing: true };
    if (this.asyncUpdate) {
      this.$timeout.cancel(this.UpdateActionableTimeout);
    }
    const config = { params: { status: "actionable" } };
    this.actionableLessons
      .getCounts(config)
      .then((res) => {
        this.hideFindSenseiBtn = res.data.count !== 0;
        this.initDashboardLessonCard(true);
      })
      .catch(angular.noop);
  }
  updateActionableLessons() {
    if (this.strManager && !this.strManager.hasFilter.any()) {
      this.strManager
        .updateActionable()
        .then((results) => {
          if (results.actionableDeleted) {
            this.$state.reload();
          }
          if (this.isConfirmedLessonBucketAvailable) {
            if (results.actionableAdded.length > 0) {
              for (let new_actionable in results.actionableAdded) {
                this.confirmedLessons.removeByRequest(
                  results.actionableAdded[new_actionable]
                );
              }
            }
          }
          //
          if (this.asyncUpdate) {
            this.UpdateActionableTimeout = this.$timeout(
              () => this.updateActionableLessons,
              this.BuildSessionTimeoutTime
            );
          }
        })
        .catch(angular.noop);
    }
  }
  addStateParams() {
    let lessonLink;
    if (this.strManager.isGamer()) {
      lessonLink = "gamer_dashboard.lessons.list";
    } else if (this.strManager.isSensei()) {
      lessonLink = "sensei_dashboard.lessons.list";
    }
    this.$state.go(lessonLink, {
      status: this.matchStatus,
      game: this.matchGame,
      date: this.matchDate,
    });
  }
  openSenseiReviewModal(lastLesson, update) {
    if (
      (lastLesson &&
        (lastLesson.status === "pending_complete" ||
          lastLesson.status === "completed") &&
        this.senseiReviewDismissId !== lastLesson.id) ||
      update
    ) {
      this.mostRecentPastCompletedLesson = lastLesson;
      this.strAPI
        .show(this.mostRecentPastCompletedLesson.id)
        .then((respSTR) => {
          const senseiId = respSTR.data.sensei_training_request.sensei_id;
          const pastReviewParams = {
            params: {
              reviewer_id: respSTR.data.sensei_training_request.gamer_id,
              reviewee_id: senseiId,
            },
          };
          this.senseiReviews
            .get(pastReviewParams)
            .then((respSRS) => {
              if (respSRS.data.sensei_reviews.length === 0 || update) {
                this.senseiReviewModal.openedModal = true;
                this.mostRecentPastCompletedLesson.senseiId = senseiId;
                this.mostRecentPastCompletedLesson.senseiImage =
                  respSTR.data.sensei_training_request.sensei_square_profile_photo_url;
                this.mostRecentPastCompletedLesson.sensei_name =
                  respSTR.data.sensei_training_request.sensei_screen_name;
                if (update && respSRS.data.sensei_reviews.length > 0) {
                  var updated_reviews =
                    respSRS.data.sensei_reviews[0].updated_reviews;
                  if (updated_reviews.length) {
                    this.reviewParams = {
                      stars:
                        updated_reviews[updated_reviews.length - 1].star_rating,
                      description:
                        updated_reviews[updated_reviews.length - 1].body,
                    };
                  } else {
                    this.reviewParams = {
                      stars: respSRS.data.sensei_reviews[0].star_rating,
                      description: respSRS.data.sensei_reviews[0].body,
                    };
                  }
                }
              }
            })
            .catch(angular.noop);
        })
        .catch(angular.noop);
    }
  }

  // Masterclasses TODO: remove masterclass functionality?
  areThereMasterclasses() {
    const upcoming = this.masterclasses.getUpcomingClasses();
    const finished = this.masterclasses.getFetchStatus();

    return upcoming && Object.keys(upcoming).length && finished;
  }
  masterclassListHeader() {
    if (this.strManager.isSensei()) {
      return `Your Upcoming <span class="text-highlight">Masterclasses</span>`;
    }

    return `Upcoming <span class="text-highlight">Masterclasses</span>`;
  }
  masterclassName(masterclass) {
    return masterclass.title
      ? masterclass.title
      : `${this.displayTime(masterclass.duration_minutes)} ${
          masterclass.game_name
        } Masterclass`;
  }
  // internal methods
  shouldMasterClassesBeShown() {
    return (
      !this.$stateParams.date &&
      !this.$stateParams.status &&
      !this.$stateParams.game &&
      !this.$stateParams.sensei &&
      !this.$stateParams.lesson
    );
  }
  closestPastLesson() {
    if (!this.frozenLessons[0] && !this.actionableLessons[0]) {
      return null;
    } else if (!this.frozenLessons[0] && this.actionableLessons[0]) {
      return this.actionableLessons[0];
    } else if (this.frozenLessons[0] && !this.actionableLessons[0]) {
      return this.frozenLessons[0];
    } else if (
      moment(this.actionableLessons[0].date) -
        moment(this.frozenLessons[0].date) <
      0
    ) {
      return this.frozenLessons[0];
    } else {
      return null;
    }
  }
  populatePastReviews(reviewerId) {
    this.senseiReviews
      .get({ params: { reviewer_id: reviewerId } })
      .then((res) => {
        for (let i in res.data.sensei_reviews) {
          this.reviewedSensei.push(res.data.sensei_reviews[i].reviewee_id);
        }
      })
      .catch(angular.noop);
  }
}

LessonCardListController.$inject = [
  "$scope",
  "$rootScope",
  "$state",
  "$stateParams",
  "$timeout",
  "$cookies",
  "UIFeatureGate",
  "SenseiTrainingRequestManager",
  "ActionableCount",
  "ActionableLessons",
  "AsyncUpdate",
  "User",
  "SenseiTrainingRequestAPIService",
  "SenseiReviewService",
  "MasterclassService",
  "EventTrackingManager",
];
