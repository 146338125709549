export default function sessionTypesService($q, $http) {
  const service = {
    all: {},
    loading: false,
    status: null,
    getSessionTypes,
    setSessionTypes,
  };

  return service;

  //////

  function getSessionTypes() {
    return service.all;
  }

  function setSessionTypes() {
    const deferred = $q.defer();

    service.loading = true;

    $http
      .get("/api/web/token_types")
      .then((res) => {
        service.loading = false;
        service.status = res.xhrStatus;
        service.all = res.data.token_types;

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        service.status = err.xhrStatus;
        deferred.reject(err);
      });

    return deferred.promise;
  }
}

sessionTypesService.$inject = ["$q", "$http"];
