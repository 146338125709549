import reusableApp from "./gs-reusable";
import accessApp from "./gs-access";
import paymentApp from "./gs-payment";
import responsiveTemplateApp from "./gs-responsive-template";
import {
  uiFeatureGate,
  getGames,
  getVisitorGeoLocale,
} from "../config/utils/utilsConfig";

const checkoutApp = angular.module("gamerSenseiCheckout", [
  "ngResource",
  "ui.router",
  "ui.router.state.events",
  reusableApp.name,
  accessApp.name,
  paymentApp.name,
  responsiveTemplateApp.name,
  "angular-stripe",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "braintree-angular",
  "star-rating",
]);

// constant setup for controllers
const constantName = "clientTokenPath";
const constantValue = "/api/web/braintree_tokens";

// Routing setup
import CheckoutContainerController from "../controllers/checkout/checkout-container-controller";
import CheckoutContainerTemplate from "../templates/checkout/checkout_container_template";
import CheckoutAccessController from "../controllers/checkout/checkout-access-controller";
import CheckoutAccessTemplate from "../templates/checkout/checkout_access_template";
import CheckoutPaymentController from "../controllers/checkout/checkout-payment-controller";
import CheckoutPaymentTemplate from "../templates/checkout/checkout_payment_template";
import CheckoutSuccessController from "../controllers/checkout/checkout-success-controller";
import CheckoutSuccessTemplate from "../templates/checkout/checkout_success_template";

checkoutApp
  .config(configCheckout)
  .constant("clientTokenPath", "/api/web/braintree_tokens");

function configCheckout(
  $stateProvider,
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
  $stateProvider
    .state("checkout-v2", {
      abstract: true,
      url: "/checkout?ref",
      template: CheckoutContainerTemplate,
      controller: CheckoutContainerController,
      controllerAs: "vm",
      resolve: {
        pulledGames: getGames,
        UIFeatureGate: uiFeatureGate,
      },
    })
    .state("checkout-v2.root", {
      url: "/",
      redirectTo: "checkout-v2.landing",
    })
    .state("checkout-v2.landing", {
      url: "",
      template: "<div></div>",
    })
    .state("checkout-v2.access", {
      url: "/access",
      template: CheckoutAccessTemplate,
      controller: CheckoutAccessController,
      controllerAs: "vm",
      resolve: {
        VisitorGeoLocale: getVisitorGeoLocale,
      },
    })
    .state("checkout-v2.payment", {
      url: "/payment",
      template: CheckoutPaymentTemplate,
      controller: CheckoutPaymentController,
      resolve: {
        UIFeatureGate: uiFeatureGate,
      },
      controllerAs: "vm",
    })
    .state("checkout-v2.success", {
      url: "/success?ref",
      template: CheckoutSuccessTemplate,
      controller: CheckoutSuccessController,
      controllerAs: "vm",
      forcePreviousState: ["checkout-v2.payment"],
    })
    .state("checkout-v2.otherwise", {
      url: "/*path",
      redirectTo: "checkout-v2.landing",
    });
}

configCheckout.$inject = [
  "$stateProvider",
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

checkoutApp.run([
  "$rootScope",
  "$state",
  function ($rootScope, $state) {
    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams, options) {
        if (
          toState.forcePreviousState !== undefined &&
          toState.forcePreviousState.indexOf(fromState.name) === -1
        ) {
          event.preventDefault();
          return $state.go(toState.forcePreviousState[0], toParams, {
            location: "replace",
          });
        }
      }
    );
  },
]);

export default checkoutApp;
