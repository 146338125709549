import DashboardSidebarTemplate from "../../templates/directives/dashboard/dashboard_sidebar_template";
import { nextIcon } from "../../config/icons/iconConfig";

export default function dashboardSidebarDirective(
  $rootScope,
  $window,
  $state,
  $timeout,
  User,
  SenseiLeaderboard,
  AsyncUpdate,
  NotificationManager,
  EventTrackingManager
) {
  return {
    restrict: "EA",
    scope: {
      userRole: "=",
      user: "=",
      menu: "=",
      mobileView: "=",
      menuClosed: "=",
      senseiLeaderboard: "=",
    },
    link: link,
    template: DashboardSidebarTemplate,
  };

  function link(scope, element, attr) {
    const senseiLanding = "sensei_dashboard.landing";
    const gamerLanding = "gamer_dashboard.landing";
    let UpdateNotificationTimeout;
    const BUILDSESSIONTIMEOUTTIME = 30000;
    scope.nextIcon = nextIcon;
    scope.notifications = NotificationManager;
    scope.eventTracking = EventTrackingManager;
    scope.UserData = User;
    scope.defaultActive =
      $state.current.name === senseiLanding ||
      $state.current.name === gamerLanding;
    scope.width = $window.innerWidth;
    scope.menuClosed = scope.mobileView || scope.width <= 750;
    var bodylayout = $rootScope.bodylayout;

    scope.menuToggle = function () {
      scope.menuClosed = !scope.menuClosed;
    };

    scope.navLink = function () {
      User.setSaveStatus(false);
      if ((scope.mobileView || scope.width <= 750) && !scope.menuClosed) {
        scope.menuClosed = true;
      }
    };

    scope.logout = function () {
      scope.eventTracking.pushEventOnly("accountLogout");
      $window.location.href = "/users/sign_out";
    };

    if (scope.userRole === "sensei") {
      SenseiLeaderboard.getStandings({ params: { mode: "localized" } })
        .then((res) => {
          scope.sensei_leaderboard = res.data.sensei_leaderboard_standings;
          for (
            let i = 0,
              senseiLeaderboardLength = scope.sensei_leaderboard.length;
            i < senseiLeaderboardLength;
            i++
          ) {
            if (
              scope.sensei_leaderboard[i].sensei_screen_name ===
              scope.UserData.getScreenName()
            ) {
              const currentLeader = scope.sensei_leaderboard[i];
              scope.sensei_score = currentLeader.score;
              scope.sensei_position =
                currentLeader.position !== null
                  ? currentLeader.position + 1
                  : "N/A";
              break;
            }
          }
        })
        .catch(angular.noop);
    }

    scope.$watchGroup(
      ["UserData.tempProfilePic", "user"],
      function (newVal, oldVal) {
        var userInfo = newVal[1];
        if (User.getProfilePic()) {
          scope.userImage = User.getProfilePic();
        } else if (userInfo) {
          scope.userImage = userInfo.profile_photo_url_square;
        }

        if (scope.user && scope.userRole === "sensei") {
          scope.gameDescription = getGameDescription(scope.user);
          scope.ratingAvg = 5; //place holder until we use actual average
          scope.isActive = scope.user.status === "active";
        }
        if (scope.user) {
          if (scope.userRole === "gamer") {
            scope.referralLaunched = !!scope.user.referral_policy_launch;
          } else {
            scope.lessonBundleDiscountChosen =
              scope.user.sensei.lesson_bundle_discount_chosen;
          }
        }
      },
      true
    );

    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        if (
          toState.name === "sensei_dashboard.lessons.calendar" ||
          toState.name === "gamer_dashboard.lessons.calendar"
        ) {
          submitDismissedUnreadLessons();
        }
      }
    );

    if (
      $state.current.name === "sensei_dashboard.lessons.calendar" ||
      $state.current.name === "gamer_dashboard.lessons.calendar"
    ) {
      submitDismissedUnreadLessons()
        .then(checkForNewNotifications())
        .catch(angular.noop);
    } else {
      checkForNewNotifications();
    }

    scope.dashboardTabHighlight = function (menuItem) {
      if (scope.userRole === "gamer") {
        return (
          (menuItem.name === "Get Free Lessons" && !scope.referralLaunched) ||
          (menuItem.name === "Unscheduled Hours" &&
            scope.user.gamer.banked_hour_adjusted)
        );
      } else {
        return (
          (menuItem.name === "Pricing" && !scope.lessonBundleDiscountChosen) ||
          (menuItem.name === "Unscheduled Hours" &&
            scope.user.sensei.banked_hour_adjusted)
        );
      }
    };

    scope.$watch(
      "menuClosed",
      function (newVal, oldVal) {
        if (!newVal) {
          $rootScope.bodylayout = bodylayout + " nav-open";
        } else {
          $rootScope.bodylayout = bodylayout;
        }
      },
      true
    );

    function submitDismissedUnreadLessons() {
      return scope.notifications.setActionableLessonToRead(
        scope.user && scope.user.id
      );
    }

    function checkForNewNotifications() {
      return scope.notifications
        .setNotifications()
        .then(function (_res) {
          if (AsyncUpdate) {
            UpdateNotificationTimeout = $timeout(
              checkForNewNotifications,
              BUILDSESSIONTIMEOUTTIME
            );
          }
        })
        .catch(angular.noop);
    }

    function onResize() {
      if (scope.width !== $window.innerWidth) {
        scope.width = $window.innerWidth;
        scope.menuClosed = scope.width <= 750;
        scope.$digest();
      }
    }

    function cleanUp() {
      angular.element($window).off("resize", onResize);
    }

    function getGameDescription(user) {
      var description = "";
      if (user.game_description) {
        description = user.game_description;
      } else if (user.sensei.games && user.sensei.games.length > 0) {
        description = user.sensei.games[0].name;
      }

      return description + " Sensei";
    }

    angular.element($window).on("resize", onResize);
    scope.$on("$destroy", function () {
      $timeout.cancel(UpdateNotificationTimeout);
      cleanUp();
    });
  }
}

dashboardSidebarDirective.$inject = [
  "$rootScope",
  "$window",
  "$state",
  "$timeout",
  "User",
  "SenseiLeaderboard",
  "AsyncUpdate",
  "NotificationManager",
  "EventTrackingManager",
];
