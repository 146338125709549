import dashboardModulesApp from "./gs-dashboard-modules";
import dashboardGamerApp from "./gs-dashboard-gamer";
import dashboardSenseiApp from "./gs-dashboard-sensei";
import dashboardBusinessApp from "./gs-dashboard-business";

const dashboardApp = angular.module("gamerSenseiDashboards", [
  dashboardModulesApp.name,
  dashboardGamerApp.name,
  dashboardSenseiApp.name,
  dashboardBusinessApp.name,
]);

// Service setup
import studentHistoryService from "../services/senseis/student-history-service";
import tokenBookingService from "../services/lessons/token-booking-service";

dashboardApp.factory("StudentHistoryService", studentHistoryService);
dashboardApp.factory("TokenBookingService", tokenBookingService);

// Config/run/constant functions
const GS_VARIABLES = window.GS_VARIABLES || {};

dashboardApp
  .config(configDashboard)
  .run(changeStateTo)
  .constant("AsyncUpdate", GS_VARIABLES.TEST_ENVIRONMENT === false);

function configDashboard(
  $urlMatcherFactoryProvider,
  $locationProvider,
  $analyticsProvider
) {
  $analyticsProvider.firstPageview(false);
  $analyticsProvider.settings.ga = {
    additionalAccountHitTypes: {
      pageview: true,
    },
  };
  if (window.GUID !== "logged_off") {
    $analyticsProvider.settings.ga.userId = window.GUID;
  }

  $locationProvider.html5Mode(true);
  $urlMatcherFactoryProvider.caseInsensitive(true);
  $urlMatcherFactoryProvider.strictMode(false);
}

configDashboard.$inject = [
  "$urlMatcherFactoryProvider",
  "$locationProvider",
  "$analyticsProvider",
];

// allow to redirect
changeStateTo.$inject = ["$rootScope", "$state"];
function changeStateTo($rootScope, $state) {
  $rootScope.$on("$stateChangeStart", (evt, to, params) => {
    if (to.redirectTo) {
      evt.preventDefault();
      $state.go(to.redirectTo, params, { location: "replace" });
    }
  });
}

export default dashboardApp;
