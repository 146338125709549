import passwordTemplate from "../../templates/directives/data/password_template";
import { eyeShowIcon, eyeHideIcon } from "../../config/icons/iconConfig";

export default function passwordDirective() {
  return {
    restrict: "E",
    scope: {
      showPassword: "=",
      passPattern: "=",
      passId: "=",
      passName: "=",
      passModel: "=",
    },
    template: passwordTemplate,
    link,
  };

  function link(scope, elm, attr) {
    scope.eye = scope.showPassword ? eyeHideIcon : eyeShowIcon;
  }
}
