import {
  testCommunication,
  testPhoneNumber,
} from "../../../../config/utils/utilsConfig";
import { infoIcon } from "../../../../config/icons/iconConfig";

export default class SenseiAccountNotificationsController {
  constructor(
    $scope,
    $timeout,
    User,
    EventTrackingManager,
    OmnibarPointer,
    SenseiNotificationPreferenceApi
  ) {
    this.userId = $scope.currentId;
    // angular providers
    this.timeout = $timeout;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.senseiNotificationPreference = SenseiNotificationPreferenceApi;
    // injected and imported
    this.infoIcon = infoIcon;
    this.notifications = {};
    this.notificationId;

    this.saveFlagTime = 2000;

    this.isSchedulingSaved = false;
    this.isChatSaved = false;
    this.isPostLessonSaved = false;
    this.isLeaderboardSaved = false;
    this.isContactSaved = false;

    this.initUser(User);
  }

  // init methods
  initUser(User) {
    User.setCurrentId(this.userId);
    User.setRole("sensei");
    User.getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.notificationId =
          this.currentUser.sensei_notification_preference_id;
        this.senseiNotificationPreference
          .show(this.notificationId)
          .then((res) => {
            this.populateNotifications(res.data.sensei_notification_preference);
            notifications = res.data.sensei_notification_preference;
          })
          .catch(angular.noop);
      })
      .catch(angular.noop);
  }

  // display settings
  populateNotifications(notifications) {
    //TODO: check if can be added
    this.notifications.scheduling = [
      {
        name: "Hour(s) purchased",
        key: "notify_hours_purchase",
        toggle: notifications.notify_hours_purchase,
        disable: false,
      },
      {
        name: "Subscription purchased",
        key: "notify_subscription_purchase",
        toggle: notifications.notify_subscription_purchase,
        disable: false,
      },
    ];

    this.notifications.chat = [
      {
        name: "New Messages",
        key: "notify_new_message",
        toggle: notifications.notify_new_message,
        disable: false,
      },
    ];

    this.notifications.scheduling = [
      {
        name: "Lesson(s) requested",
        key: "notify_lesson_requested",
        toggle: true,
        disable: true,
      },
      {
        name: "1-hour lesson reminder",
        key: "notify_one_hour_lesson_reminder",
        toggle: notifications.notify_one_hour_lesson_reminder,
        disable: false,
      },
      {
        name: "Lesson reschedule request",
        key: "notify_lesson_reshedule_request",
        toggle: notifications.notify_lesson_reshedule_request,
        disable: false,
      },
      {
        name: "Lesson confirmation",
        key: "lesson_confirmation",
        toggle: true,
        disable: true,
      },
      {
        name: "Lesson cancellation",
        key: "notify_lesson_cancellation",
        toggle: notifications.notify_lesson_cancellation,
        disable: false,
      },
    ];

    this.notifications.postLesson = [
      {
        name: "Review written",
        key: "notify_review_written",
        toggle: notifications.notify_review_written,
        disable: false,
      },
      {
        name: "Lesson completed",
        key: "notify_lesson_completed",
        toggle: notifications.notify_lesson_completed,
        disable: false,
      },
    ];

    this.notifications.contactMethods = [
      {
        name: "Email",
        key: "contact_via_email",
        activated: true,
        toggle: true,
        disable: false,
        default: this.currentUser.email,
      },
      {
        name: "Discord",
        key: "contact_via_discord",
        activated: testCommunication(
          "discord",
          this.currentUser.communication_services
        ),
        toggle: notifications.contact_via_discord,
        disable: false,
      },
      {
        name: "SMS",
        key: "contact_via_sms",
        activated: testPhoneNumber(this.currentUser.phone_number),
        toggle: notifications.contact_via_sms,
        disable: false,
      },
    ];
  }

  triggerSchedulingFlag() {
    this.isSchedulingSaved = true;
    this.timeout(() => {
      this.isSchedulingSaved = false;
    }, this.saveFlagTime);
  }

  triggerChatFlag() {
    this.isChatSaved = true;
    this.timeout(() => {
      this.isChatSaved = false;
    }, this.saveFlagTime);
  }

  triggerPostLessonFlag() {
    this.isPostLessonSaved = true;
    this.timeout(() => {
      this.isPostLessonSaved = false;
    }, this.saveFlagTime);
  }

  triggerLeaderboardFlag() {
    this.isLeaderboardSaved = true;
    this.timeout(() => {
      this.isLeaderboardSaved = false;
    }, this.saveFlagTime);
  }

  triggerContactFlag() {
    this.isContactSaved = true;
    this.timeout(() => {
      this.isContactSaved = false;
    }, this.saveFlagTime);
  }

  // action methods
  updateNotification(notification, context) {
    if (!notification.disable) {
      const params = { sensei_notification_preference: {} };
      params["sensei_notification_preference"][notification.key] =
        notification.toggle;
      this.senseiNotificationPreference
        .update(this.notificationId, params)
        .then((res) => {
          this.populateNotifications(res.data.sensei_notification_preference);
          if (context === "scheduling") {
            this.triggerSchedulingFlag();
          } else if (context === "postLesson") {
            this.triggerPostLessonFlag();
          } else if (context === "contact") {
            this.triggerContactFlag();
          } else if (context === "chat") {
            this.triggerChatFlag();
          }
        })
        .catch((err) => {
          //this.populateNotifications(res.data.sensei_notification_preference);
          this.omnibarPointer.pushErrorByHash(err.data.errors);
        });
    }
  }
}

SenseiAccountNotificationsController.$inject = [
  "$scope",
  "$timeout",
  "User",
  "EventTrackingManager",
  "OmnibarPointer",
  "SenseiNotificationPreferenceApi",
];
