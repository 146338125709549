export default function moveToNextDirective() {
  return {
    restrict: "A",
    link,
  };

  function link(scope, elem, attrs) {
    elem.on("input", function (e) {
      var partsId = attrs.id.match(/code-(\d+)/);
      var currentId = parseInt(partsId[1]);

      var l = elem.val().length;
      if (l == elem.attr("maxlength")) {
        var nextElement = document.querySelector("#code-" + (currentId + 1));
        nextElement.focus();
      }
    });
  }
}
