export default function giftCardManagerService(
  $http,
  $state,
  EventTrackingManager
) {
  var manager = {
    serviceFee: 0,
    allowServiceFee: false,
    cardDesigns: [],
    giftOptions: [2000, 5000, 10000],
    selectedOption: 5000,
    selectedDesign: {},
    deliveryMethod: "email",
    redeemCode: redeemCode,
    recipientName: "",
    recipientEmail: "",
    purchaserName: "",
    purchaserEmail: "",
    isGuest: false,
    message: "",
    giftCard: null,
    calculateValue: calculateValue,
    currentState: currentState,
    createGiftCard: createGiftCard,
    getGiftCardDesigns: getGiftCardDesigns,
    setCardDesigns: setCardDesigns,
    // Analyitics
    addToCartAnalytics: addToCartAnalytics,
    abandonCartAnalytics: abandonCartAnalytics,
  };

  function setCardDesigns() {
    manager.getGiftCardDesigns().then((res) => {
      manager.cardDesigns = res.data.design_resources;
      manager.selectedDesign = manager.cardDesigns[0]; //Default Design
      manager.addToCartAnalytics();
    });
  }

  function calculateValue() {
    // service fee = 2.9% + 30 cents
    var serviceFee = manager.selectedOption * 0.029 + 30;
    manager.serviceFee = manager.allowServiceFee ? serviceFee : 0;
    return manager.selectedOption + serviceFee;
  }

  function currentState() {
    return $state.current.name;
  }

  function redeemCode(data) {
    return $http.post("/api/web/gift_card_credit_redemptions", data);
  }

  function createGiftCard(config) {
    return $http.post("/api/web/gift_cards", config);
  }

  function getGiftCardDesigns(config) {
    return $http.get("/api/web/gift_card_design_resources", config);
  }

  function addToCartAnalytics() {
    EventTrackingManager.pushEvent("AddToCart", {
      category: "Gift Card",
      label: "Gift Card Purchase Flow",
    });
  }

  function abandonCartAnalytics() {
    EventTrackingManager.pushEvent("Intent to Leave Page", {
      category: "Abandon Cart",
      label: "Gift Card Purchase Flow",
    });
  }

  return manager;
}

giftCardManagerService.$inject = ["$http", "$state", "EventTrackingManager"];
