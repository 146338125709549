import moment from "moment-timezone";

export default function tokenBookingService($q, $http, $rootScope) {
  const service = {
    loading: false,
    finished: false,
    config: {
      pageNum: null,
      pageSize: null,
    },
    game: {},
    sensei: {},
    deposits: {},
    tokenItemDeposits: {},
    selectedDeposit: {},
    availabilities: {},
    availableSenseis: {},
    selectedAvailability: {},
    getGame: getGame,
    setGame: setGame,
    setSenseiGame: setSenseiGame,
    getSensei: getSensei,
    setSensei: setSensei,
    getAvailableSenseis: getAvailableSenseis,
    getTokenDeposits: getTokenDeposits,
    getTokenItemDeposits: getTokenItemDeposits,
    setTokenDeposits: setTokenDeposits,
    setTokenItemDeposits: setTokenItemDeposits,
    haveTokenDepositsBeenSet: haveTokenDepositsBeenSet,
    refundToken: refundToken,
    getSelectedTokenDeposit: getSelectedTokenDeposit,
    setSelectedTokenDeposit: setSelectedTokenDeposit,
    getAvailabilities: getAvailabilities,
    setAvailabilities: setAvailabilities,
    fetchAvailabilityEndpoint: fetchAvailabilityEndpoint,
    getSelectedAvailability: getSelectedAvailability,
    setSelectedAvailability: setSelectedAvailability,
    scheduleSelectedAvailability: scheduleSelectedAvailability,
    senseiScheduleProposal: senseiScheduleProposal,
    scheduleReset: scheduleReset,
  };

  return service;

  // Service functions
  function getGame() {
    return service.game;
  }
  function setGame(games) {
    var deposit = service.getSelectedTokenDeposit();
    for (let g of games) {
      if (g.slug === deposit.game) {
        service.game = g;
      }
    }
  }
  function setSenseiGame(game) {
    service.game = game;
  }
  function getSensei() {
    return service.sensei;
  }
  function setSensei(sensei) {
    service.sensei = sensei;
  }
  function getAvailableSenseis() {
    return service.availableSenseis;
  }
  function getTokenDeposits() {
    return service.deposits;
  }
  function getTokenItemDeposits() {
    return service.tokenItemDeposits;
  }

  function setTokenDeposits() {
    service.loading = true;
    const endpoint = "/api/web/token_deposits";

    return $http
      .get(endpoint)
      .then((res) => {
        if (res.data.token_deposits.length) {
          service.deposits = formatDepositsToObj(res.data.token_deposits);
          $rootScope.$broadcast("tokenBookingDepositsSet", {
            deposits: service.deposits,
          });
        }
        service.loading = false;
        service.finished = true;
      })
      .catch((err) => console.error(err));
  }

  function setTokenItemDeposits() {
    service.loading = true;
    var endpoint = "/api/web/token_deposits/items";

    return $http.get(endpoint).then((res) => {
      if (res.data.token_deposits.length) {
        service.tokenItemDeposits = formatDepositsToObj(
          res.data.token_deposits
        );
      }
      service.loading = false;
    });
  }

  function haveTokenDepositsBeenSet() {
    return service.finished;
  }

  function getSelectedTokenDeposit() {
    return service.selectedDeposit;
  }

  function setSelectedTokenDeposit(deposit) {
    service.selectedDeposit = deposit;
  }

  function getAvailabilities() {
    return service.availabilities;
  }

  function setAvailabilities(id, date, duration) {
    service.loading = true;
    var deferred = $q.defer();
    var endpoint = "/api/web/token_templates/" + id + "/bookable_hours";
    var mins = date.format("mm");
    var hour = date.format("HH");
    var getTime = getTimeFormatted(mins, hour);

    var params = {
      duration_minutes: duration,
      proposed_date: date.format("YYYY-MM-DD"),
      proposed_hour: moment(getTime, "HH:mm").format("HH:mm"),
      proposed_time_zone: moment.tz.guess(),
      slots: 336,
    };

    $http.get(endpoint, { params }).then(
      (res) => {
        service.availabilities = res.data.bookable_hours;
        service.availableSenseis = res.data.senseis ? res.data.senseis : {};
        service.loading = false;
        deferred.resolve(res);
      },
      (err) => {
        service.loading = false;
        deferred.reject(err);
      }
    );

    return deferred.promise;
  }

  function fetchAvailabilityEndpoint(id, date, duration, sensei) {
    service.loading = true;
    var endpoint = "/api/web/token_templates/" + id + "/bookable_hours";
    var mins = date.format("mm");
    var hour = date.format("HH");
    var getTime = getTimeFormatted(mins, hour);

    var params = {
      duration_minutes: duration,
      proposed_date: date.format("YYYY-MM-DD"),
      proposed_hour: moment(getTime, "HH:mm").format("HH:mm"),
      proposed_time_zone: moment.tz.guess(),
      slots: 336,
    };
    if (sensei) {
      params.sensei_id = sensei;
    }
    return $http.get(endpoint, { params });
  }

  function getSelectedAvailability() {
    return service.selectedAvailability;
  }

  function setSelectedAvailability(availability) {
    if (availability.senseis) {
      service.selectedAvailability = {
        ...availability,
        senseiData: buildSenseiData(availability.senseis),
      };
    } else {
      service.selectedAvailability = availability;
    }
  }

  function scheduleSelectedAvailability(gamer, senseiId, vodURL, userTz) {
    //var deferred = $q.defer();
    const chatHandle = chooseUserChatHandle(gamer.communication_services);
    const userParticipation = chooseParticipation(gamer);

    const params = {
      gamer_id: gamer.gamer.id,
      sensei_training_requests: [
        {
          game_id: service.game.id,
          token_template_id: service.selectedDeposit.template_id,
          session_duration_minutes: service.selectedDeposit.duration_minutes,
          improve_on_notes: "",
          sensei_notes: null,
          promo_code: null,
          communication_handle: chatHandle,
          communication_medium: "discord",
          participation_handle: userParticipation.handle,
          participation_medium: userParticipation.medium,
          vod_review_link: vodURL,
        },
      ],
      direct_bookings: [
        {
          sensei_id: senseiId,
          gamer_proposed_date:
            service.selectedAvailability.start.format("YYYY-MM-DD"),
          gamer_proposed_hour:
            service.selectedAvailability.start.format("HH:mm"),
          gamer_time_zone: userTz,
        },
      ],
    };

    return $http.post("/api/web/token_lessons", params);
  }

  function senseiScheduleProposal(user, gamer, proposal) {
    var params = {
      gamer_id: gamer.studentId,
      sensei_training_requests: [
        {
          game_id: service.game.id,
          session_duration_hours: 1,
          token_template_id: getTokenTemplateId(user, gamer.token_template_id),
          communication_handle: gamer.discord,
          communication_medium: "discord",
          participation_handle: gamer.inGameName,
          participation_medium: gamer.inGameNameLabel,
          session_proposal_notes: proposal.sessionProposalNotes,
        },
      ],
      direct_bookings: [
        {
          sensei_id: user.sensei.id,
          sensei_proposed_date: moment(
            proposal.selectedDate,
            "MMMM D, YYYY"
          ).format("YYYY-MM-DD"),
          sensei_proposed_hour: proposal.selectedTime[1],
          sensei_time_zone: proposal.proposalTimeZone,
        },
      ],
    };

    return $http.post("/api/web/sensei_lesson_proposes", params);
  }

  function refundToken(data) {
    return $http.post("/api/web/token_item_refunds", data);
  }

  function scheduleReset() {
    service.game = {};
    service.sensei = {};
    service.selectedDeposit = {};
    service.availabilities = {};
    service.availableSenseis = {};
    service.selectedAvailability = {};
  }

  // Internal functions
  function getTimeFormatted(mins, hour) {
    var newHour;
    var newMin;
    // remove for now
    //if (mins <= "30") {
    //  newHour = hour;
    //  newMin = "30";
    //} else {
    newHour = moment(hour, "HH").add(1, "hours").format("HH");
    newMin = "00";
    //}

    return newHour + ":" + newMin;
  }

  function formatDepositsToObj(deposits) {
    const initialValue = {};

    return deposits.reduce((obj, item) => {
      const key = item.token_item_id
        ? `${item.token_item_id}-${item.tier}-${item.game}-${
            item.sensei_id ? item.sensei_id : 0
          }`
        : `${item.template_id}-${item.tier}-${item.game}-${
            item.sensei_id ? item.sensei_id : 0
          }`;

      return {
        ...obj,
        [key]: { ...item },
      };
    }, initialValue);
  }

  function chooseParticipation(user) {
    var userParticipation = {
      medium: "",
      handle: "",
    };
    var games =
      user.gamer && user.gamer.games ? user.gamer.games : user.sensei.games;

    angular.forEach(games, function (game) {
      if (game.id === service.game.id) {
        userParticipation = {
          medium: game.in_game_id_label,
          handle: game.in_game_id,
        };
      }
    });
    return userParticipation;
  }

  function chooseUserChatHandle(communications) {
    var discord = communications.filter(function (chat) {
      return chat.medium === "discord";
    });

    return discord && discord[0] ? discord[0].handle : "";
  }

  function buildSenseiData(senseiArr) {
    var senseiObj = {};
    for (let sensei of senseiArr) {
      senseiObj[sensei] = service.availableSenseis[sensei];
    }

    return senseiObj;
  }

  function getTokenTemplateId(user, templateId) {
    if (templateId) {
      return templateId;
    }
    var senseiTokens = user.sensei.token_templates;
    var oneOnOne = senseiTokens.filter(function (token) {
      return token.token_type === "one-on-one-training";
    });

    return oneOnOne && oneOnOne[0] ? oneOnOne[0].id : "";
  }
}

tokenBookingService.$inject = ["$q", "$http", "$rootScope"];
