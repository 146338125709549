export default function userService(
  $http,
  $q,
  $rootScope,
  UserDataGamer,
  UserDataSensei
) {
  const service = {
    _currentId: null,
    _role: null,
    _userData: null,
    saveStatus: null,
    tempProfilePic: null,
    getUser: getUser,
    getUserByApi: getUserByApi,
    getUserById: getUserById,
    getUserByIdAndRole: getUserByIdAndRole,
    toggleAfk: toggleAfk,
    articles: listedArticles,
    setCurrentId: setCurrentId,
    isGamer: isGamer,
    isSensei: isSensei,
    isBusiness: isBusiness,
    isAffiliate: false,
    setRole: setRole,
    getId: getId,
    getScreenName: getScreenName,
    getEmail: getEmail,
    getData: getData,
    setUserDataToGamerData: setUserDataToGamerData,
    setUserDataToSenseiData: setUserDataToSenseiData,
    setSaveStatus: setPrefSaveStatus,
    getSaveStatus: getPrefSaveStatus,
    getProfilePic: getTempProfilePic,
    setProfilePic: setTempProfilePic,
    getCurrentBalance: getCurrentBalance,
    balanceShortFromCost: balanceShortFromCost,
    isGamerHasSubscription: isGamerHasSubscription,
    getGamerBundleSubscription: getGamerBundleSubscription,
    getGamerReferralPolicy: getGamerReferralPolicy,
    getReferralURL: getReferralURL,
    resendConfirmationEmail: resendConfirmationEmail,
    connectToCommunicationService: connectToCommunicationService,
    validateDiscord: validateDiscord,
    returnCurrentChatService: returnCurrentChatService,
    insertUserCommunication: insertUserCommunication,
    getNewUserRole: getNewUserRole,
  };

  return service;

  ///////////

  function getUser(force) {
    const self = this;
    const deferred = $q.defer();

    if (self._userData !== null && force !== true) {
      deferred.resolve(self._userData);
      return deferred.promise;
    }

    if (self._currentId === null || self._role === null) {
      deferred.reject({
        data: { errors: { error: "id and role is required before sign in." } },
      });
      return deferred.promise;
    }

    const userParams = { id: self._currentId, role: self._role };
    self
      .getUserByApi(userParams)
      .then((res) => {
        if (self.isGamer()) {
          self.setUserDataToGamerData(res.data.user);
        } else if (self.isSensei()) {
          self.setUserDataToSenseiData(res.data.user);
        }
        $rootScope.$broadcast("userDataSet", { userData: self._userData });

        // send age gate notice if needed
        const { birth_date, is_minor, parental_consent } = res.data.user;
        if (!birth_date || (is_minor && !parental_consent)) {
          $rootScope.$broadcast("openAgeGate", { birthday: null });
        }

        // return userData when call complete
        deferred.resolve(self._userData);
      })
      .catch((res) => {
        deferred.reject(res);
      });

    return deferred.promise;
  }

  // Public
  function getUserByApi(data) {
    return $http.get("/api/web/users/" + data.id + "?role=" + data.role);
  }

  function getUserById(data) {
    return $http.get("/api/web/users/" + data.id);
  }

  function listedArticles(id, config) {
    return $http.get("/api/web/users/" + id + "/articles_overview", config);
  }

  function resendConfirmationEmail(id) {
    return $http.put("/api/web/users/" + id + "/resend_confirmation_email");
  }

  function connectToCommunicationService(serviceId) {
    return $http.put(
      "/api/web/communication_services/" + serviceId + "/connect"
    );
  }

  function toggleAfk(id) {
    return $http.put("/api/web/users/" + id + "/toggle_afk");
  }

  function getUserByIdAndRole(id, role, force) {
    this.setCurrentId(id);
    this.setRole(role);
    return this.getUser(force);
  }

  function setCurrentId(id) {
    if (id !== undefined) this._currentId = id;
  }

  function setRole(role) {
    if (role === "gamer" || role === "sensei") this._role = role;
  }

  function getNewUserRole(user) {
    if (user.gamer) {
      return "gamer";
    } else if (user.sensei) {
      return "sensei";
    }
  }

  function isGamer() {
    return this._role === "gamer";
  }

  function isSensei() {
    return this._role === "sensei";
  }

  function isBusiness() {
    return this.isGamer() && this._userData && this._userData.gamer.is_business;
  }

  function getId() {
    return this._userData ? this._userData.id : null;
  }

  function getScreenName() {
    return this._userData ? this._userData.screen_name : null;
  }

  function getEmail() {
    return this._userData ? this._userData.email : null;
  }

  function setUserDataToGamerData(_data) {
    this._user = new UserDataGamer(_data);
    this._userData = this._user.data;
  }

  function setUserDataToSenseiData(_data) {
    this._user = new UserDataSensei(_data);
    this._userData = this._user.data;
  }

  function getData() {
    return this._userData;
  }

  function setPrefSaveStatus(status) {
    this.saveStatus = status;
  }

  function getPrefSaveStatus() {
    return this.saveStatus;
  }

  function setTempProfilePic(file) {
    this.tempProfilePic = file;
  }

  function getTempProfilePic() {
    return this.tempProfilePic;
  }

  function getCurrentBalance() {
    return this._userData ? this._userData.current_balance : 0;
  }

  function balanceShortFromCost(cost) {
    var currentBalance = this.getCurrentBalance();
    if (cost - currentBalance <= 0) return 0;
    return cost - currentBalance;
  }

  // Gamer
  function isGamerHasSubscription() {
    return (
      this.isGamer() &&
      this._userData.gamer.gamer_currency_account.bundle_subscription
    );
  }
  function getGamerBundleSubscription() {
    return this._userData.gamer
      ? this._userData.gamer.gamer_currency_account.bundle_subscription
      : null;
  }
  function getGamerReferralPolicy() {
    return this._userData ? this._userData.referral_policy_launch : null;
  }
  function getReferralURL() {
    return this._userData.referral_link;
  }

  //communication setup
  function validateDiscord(handle) {
    var re = /^(?!.*?[.]{2})[a-zA-Z0-9_.]+$/;
    var count = handle.length >= 2 && handle.length <= 32;
    return count && re.test(handle);
  }

  function returnCurrentChatService(chatMedium, currentChatApps) {
    for (let current = 0; current < currentChatApps.length; current++) {
      if (currentChatApps[current].medium === chatMedium) {
        return currentChatApps[current];
      }
    }
    return null;
  }

  function insertUserCommunication(user, currentChatApps) {
    if (user.communication_services.length === 0 && user.communication_handle) {
      var serviceAdded = {
        id: null,
        changed: true,
        editMode: false,
        connected: false,
        medium: user.communication_medium,
        handle: user.communication_handle,
        tempHandle: user.communication_handle,
        remove: false,
      };
      currentChatApps.push(serviceAdded);
    } else if (user.communication_services.length) {
      for (let n = 0; n < user.communication_services.length; n++) {
        var newService = {
          id: user.communication_services[n].id,
          changed: false,
          editMode: false,
          connected: user.communication_services[n].connected,
          medium: user.communication_services[n].medium,
          handle: user.communication_services[n].handle,
          tempHandle: user.communication_services[n].handle,
          remove: false,
        };
        currentChatApps.push(newService);
      }
    }
  }
}

userService.$inject = [
  "$http",
  "$q",
  "$rootScope",
  "UserDataGamer",
  "UserDataSensei",
];
