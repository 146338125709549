export default function similarSenseiService($http) {

  var service = {
    getSimilarSenseis: getSimilarSenseis,
  }

  return service;

  ///////////

  function getSimilarSenseis(config) {
    return $http.get('/api/web/similar_senseis', config);
  }

}

similarSenseiService.$inject = ['$http'];
