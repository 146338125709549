export default function featureGateService($http) {

  var service = {
    check: check
  };

  return service;

  ///////////

  function check(config) {
    return $http.get('/api/web/ui_feature_gates', config);
  }
}

featureGateService.$inject = ['$http'];
