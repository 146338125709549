export default class DailyCoSessionController {
  constructor($scope, $stateParams, OmnibarPointer) {
    this.metrics = {};
    this.omnibarPointer = OmnibarPointer;

    this.initDailyCo($scope, $stateParams);
  }

  initDailyCo($scope, $stateParams) {
    const dailyCoOPts = {
      iframeStyle: {
        position: "fixed",
        border: "none",
        width: "100%",
        height: "100%",
        zIndex: "9999",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
    };

    const callFrame = DailyIframe.createFrame(dailyCoOPts);
    callFrame.join({
      url: "https://" + $scope.dailyCoBaseURL + "/" + $stateParams.room_key,
      showLeaveButton: true,
      showFullscreenButton: true,
      token: $scope.ownerToken,
    });
  }
}

DailyCoSessionController.$inject = ["$scope", "$stateParams", "OmnibarPointer"];
