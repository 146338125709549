export default function senseiProfileService($http) {
  //let tempProfilePic;
  const service = {
    update,
    doesSenseiHaveGoogleCalendar,
    optInToGoogleCalendar,
    //getProfilePic,
    //setProfilePic,
  };

  return service;

  ///////////

  function update(senseiId, data) {
    //setProfilePic(data.sensei.profile_pic);
    return $http({
      method: "PUT",
      url: "/api/web/sensei_profiles/" + senseiId,
      data: data,
    });
  }

  function doesSenseiHaveGoogleCalendar(sensei) {
    return sensei.google_calendar_id && sensei.google_calendar_link;
  }

  function optInToGoogleCalendar() {
    return $http.post("/api/web/google_calendar");
  }

  /*function setProfilePic(file) {
    tempProfilePic = file;
  }

  function getProfilePic() {
    return tempProfilePic;
  }*/
}

senseiProfileService.$inject = ["$http"];
