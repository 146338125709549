const cardCreateParams = (data) => {
  return {
    number: data.cardNumber.$viewValue,
    name: data.name.$viewValue,
    exp_month: data.expMonth.$viewValue,
    exp_year: data.expYear.$viewValue,
    cvc: data.security.$viewValue,
    address_zip: data.zipcode.$viewValue,
  };
};

export default cardCreateParams;
