import paginationPartial from "../../../../templates/includes/nav/pagination_partial";
import { starIcon } from "../../../../config/icons/iconConfig";

export default class SenseiLessonsReviewsController {
  constructor($rootScope, User, SenseiReviewService) {
    this.userId = $rootScope.currentId;
    this.role = "sensei";
    // services
    this.userService = User;
    this.senseiReviewService = SenseiReviewService;
    // imported and injected
    this.paginationPartial = paginationPartial;
    this.starIcon = starIcon;
    // numbers pagination
    this.last = 1;
    this.first = 1;
    this.current = 1;
    this.pages = 0;
    // to be set
    this.senseiId = null;
    this.reviewParams = null;
    this.featuredReview = null;
    // objects and arrays
    this.recentReviews = [];

    this.initUser();
  }

  // init methods
  initUser() {
    this.userService
      .getUserByIdAndRole(this.userId, this.role)
      .then((res) => {
        this.senseiId = res.sensei.id;
        // fetches first review page and attaches standards params
        this.setCurrent(this.first);
      })
      .catch(angular.noop);
  }

  // fetch methods
  loadSenseiReviews(reviewParams) {
    // first get featured review
    const featuredReviewParams = {
      params: {
        reviewee_id: this.senseiId,
        is_testimonial: true,
      },
    };

    this.senseiReviewService
      .get(featuredReviewParams)
      .then((featuredResp) => {
        const featuredReview = featuredResp.data.sensei_reviews;

        if (featuredReview.length === 1) {
          const featuredParentReview = featuredResp.data.sensei_reviews[0];
          const updatedReviewsLength =
            featuredParentReview.updated_reviews.length;

          if (updatedReviewsLength > 0) {
            this.featuredReview =
              featuredParentReview.updated_reviews[updatedReviewsLength - 1];
          } else {
            this.featuredReview = featuredParentReview;
          }
        }

        this.senseiReviewService
          .get(reviewParams)
          .then((res) => {
            this.last = res.data.pages;
            this.pages = res.data.pages;
            this.current = reviewParams.params.page;

            this.recentReviews = res.data.sensei_reviews;

            for (let x in this.recentReviews) {
              const review = this.recentReviews[x];
              review.lastReview = null;

              if (review.updated_reviews.length > 0) {
                const updatedReviewLength = review.updated_reviews.length;
                review.lastReview =
                  review.updated_reviews[updatedReviewLength - 1];
              }
            }
          })
          .catch(angular.noop);
      })
      .catch(angular.noop);
  }

  // action methods
  setCurrent(page) {
    this.loadSenseiReviews(this.nonTestimonialParams(page));
  }
  chooseTestimonial(review) {
    this.senseiReviewService
      .chooseTestimonial(review.id)
      .then((res) => {
        this.setCurrent(this.current);
      })
      .catch(angular.noop);
  }

  // internal methods
  nonTestimonialParams(page) {
    return {
      params: {
        reviewee_id: this.senseiId,
        status: "approved",
        is_testimonial: false,
        page: page,
      },
    };
  }
}

SenseiLessonsReviewsController.$inject = [
  "$rootScope",
  "User",
  "SenseiReviewService",
];
