export default function senseiSessionsManagerService($q, $http, $timeout) {
  const senseiTokensApi = "/api/web/sensei_token_templates";
  const senseiBundlesApi = "/api/web/sensei_token_bundles";
  const senseiBundleItemsApi = "/api/web/sensei_token_bundle_items";
  const senseiSubscriptionsApi = "/api/web/sensei_token_subscription_plans";

  const service = {
    loading: false,
    getCurrentId,
    getLoadingStatus,
    setLoadingStatus,
    // new
    sessionsComplete: false,
    sessions: {},
    bundlesComplete: false,
    bundles: {},
    subscriptions: {},
    // session functions
    getSessions,
    setSessions,
    createSession,
    changeSession,
    // bundle functions
    getBundles,
    setBundles,
    createBundle,
    changeBundle,
    // bundle item functions
    createBundleItem,
    deleteBundleItem,
    // subscription functions
    getSubscriptions,
    setSubscriptions,
    createSubscription,
    changeSubscription,
    // functionality
    getSessionName,
    areSessionsAndBundlesComplete,
  };

  window.service = service;

  return service;

  // new

  // Service Functions
  function getSessions() {
    return service.sessions;
  }

  function setSessions() {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .get(senseiTokensApi)
      .then((res) => {
        service.loading = false;
        service.sessions = formatSessions("session", res.data.token_templates);
        service.sessionsComplete = true;

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function createSession(data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .post(senseiTokensApi, data)
      .then((res) => {
        const sessionData = res.data.token_template;
        service.loading = false;
        service.sessions[`id_${sessionData.id}`] = {
          ...sessionData,
          productType: "session",
          ongoingSale:
            sessionData.base_student_cost < sessionData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function changeSession(id, data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .put(`${senseiTokensApi}/${id}`, data)
      .then((res) => {
        const sessionData = res.data.token_template;

        service.loading = false;
        service.sessions[`id_${id}`] = {
          ...sessionData,
          productType: "session",
          ongoingSale:
            sessionData.base_student_cost < sessionData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getBundles() {
    return service.bundles;
  }
  function setBundles() {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .get(senseiBundlesApi)
      .then((res) => {
        service.loading = false;
        service.bundles = formatSessions("bundle", res.data.token_bundles);
        service.bundlesComplete = true;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function createBundle(data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .post(senseiBundlesApi, data)
      .then((res) => {
        const bundleData = res.data.token_bundle;

        service.loading = false;
        service.bundles[`id_${bundleData.id}`] = {
          ...bundleData,
          productType: "bundle",
          ongoingSale:
            bundleData.base_student_cost < bundleData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function changeBundle(id, data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .put(`${senseiBundlesApi}/${id}`, data)
      .then((res) => {
        const bundleData = res.data.token_bundle;
        service.loading = false;
        service.bundles[`id_${id}`] = {
          ...bundleData,
          productType: "session",
          ongoingSale:
            bundleData.base_student_cost < bundleData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function createBundleItem(bundleId, item) {
    const deferred = $q.defer();
    const params = {
      token_bundle_item: {
        token_bundle_id: bundleId,
        token_template_id: item.id,
        quantity: item.quantity,
      },
    };

    $http
      .post(senseiBundleItemsApi, params)
      .then((res) => {
        service.bundles[`id_${bundleId}`].token_bundle_items.unshift(
          res.data.token_bundle_item
        );
        deferred.resolve(res);
      })
      .catch((err) => {
        console.error(err);
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function deleteBundleItem(item) {
    const deferred = $q.defer();
    $http
      .delete(`${senseiBundleItemsApi}/${item.id}`)
      .then((res) => {
        const key = `id_${item.token_bundle_id}`;
        service.bundles[key].token_bundle_items = service.bundles[
          key
        ].token_bundle_items.filter((bItem) => bItem.id !== item.id);

        deferred.resolve(res);
      })
      .catch((err) => {
        console.error(err);
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getSubscriptions() {
    return service.subscriptions;
  }
  function setSubscriptions() {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .get(senseiSubscriptionsApi)
      .then((res) => {
        service.loading = false;
        service.subscriptions = formatSessions(
          "subscription",
          res.data.token_subscription_plans
        );

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function createSubscription(data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .post(senseiSubscriptionsApi, data)
      .then((res) => {
        const subData = res.data.token_subscription_plan;
        service.loading = false;
        service.subscriptions[`id_${subData.id}`] = {
          ...subData,
          productType: "subscription",
          ongoingSale:
            subData.base_student_cost < subData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function changeSubscription(id, data) {
    const deferred = $q.defer();
    service.loading = true;

    $http
      .put(`${senseiSubscriptionsApi}/${id}`, data)
      .then((res) => {
        const subData = res.data.token_subscription_plan;
        service.loading = false;
        service.subscriptions[`id_${id}`] = {
          ...subData,
          productType: "subscription",
          ongoingSale:
            subData.base_student_cost < subData.advertised_student_cost,
        };

        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  // functionality
  function getSessionName(type, id) {
    let product;
    let name = "name";
    if (type === "session") {
      product = service.sessions[`id_${id}`];
    } else if (type === "bundle") {
      product = service.bundles[`id_${id}`];
    } else {
      product = service.subscriptions[`id_${id}`];
      name = "plan_name";
    }

    return product[name];
  }

  function areSessionsAndBundlesComplete() {
    return service.sessionsComplete && service.bundlesComplete;
  }

  function getCurrentId() {
    return service.current;
  }

  function getLoadingStatus() {
    return service.loading;
  }

  function setLoadingStatus(status) {
    service.loading = status;
  }

  // Internal
  function formatSessions(type, sessions) {
    var initialValue = {};
    return sessions.reduce(function (obj, item) {
      return {
        ...obj,
        [`id_${item.id}`]: {
          ...item,
          productType: type,
          ongoingSale: item.base_student_cost < item.advertised_student_cost,
        },
      };
    }, initialValue);
  }
}

senseiSessionsManagerService.$inject = ["$q", "$http", "$timeout"];
