import coachProductProfileApp from "./gs-sensei-public-profile";
import paymentApp from "./gs-payment";
import reusableApp from "./gs-reusable";
import responsiveTemplateApp from "./gs-responsive-template";

const dashboardModulesApp = angular.module("gamerSenseiDashboardModules", [
  "ngResource",
  "ngMessages",
  "ui.calendar",
  "ui.router",
  "ui.router.state.events",
  "ngFileUpload",
  "ngclipboard",
  "angular-stripe",
  "ncy-angular-breadcrumb",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "star-rating",
  "braintree-angular",
  "monospaced.elastic",
  "ngAnimate",
  "angularUtils.directives.dirPagination",
  "ordinal",
  reusableApp.name,
  paymentApp.name,
  responsiveTemplateApp.name,
  "720kb.datepicker",
  "720kb.socialshare",
  coachProductProfileApp.name,
  "ng-showdown",
]);

// Service setup
import accountService from "../services/users/account";
import actionableLessonService from "../services/lessons/actionable-lessons";
import calendarBasedLessonService from "../services/lessons/lesson-calendar-based";
import masterclassService from "../services/lessons/masterclass-service";
import recentLessonsService from "../services/lessons/recent-lessons";
import senseiLeaderboardService from "../services/senseis/sensei-leaderboard";
import senseiNotificationPreferenceApiService from "../services/senseis/sensei-notification-preferences";
import senseiProfileService from "../services/senseis/sensei-profile";

dashboardModulesApp.factory("Account", accountService);
dashboardModulesApp.factory("ActionableLessons", actionableLessonService);
dashboardModulesApp.factory("CalendarBasedLessons", calendarBasedLessonService);
dashboardModulesApp.factory("MasterclassService", masterclassService);
dashboardModulesApp.factory("RecentLessons", recentLessonsService);
dashboardModulesApp.factory("SenseiLeaderboard", senseiLeaderboardService);
dashboardModulesApp.factory(
  "SenseiNotificationPreferenceApi",
  senseiNotificationPreferenceApiService
);
dashboardModulesApp.factory("SenseiProfile", senseiProfileService);

// Directive setup
import communicationDirective from "../directives/user/communication-directive";
import dashboardSidebarDirective from "../directives/dashboard/dashboard-sidebar-directive";
import dashboardTableDirective from "../directives/dashboard/dashboard-table-directive";
import extendLessonRequestDirective from "../directives/dashboard/lessons/extend-lesson-request-directive";
import lessonArrivalDirective from "../directives/dashboard/lessons/lesson-arrival-directive";
import lessonCardDirective from "../directives/dashboard/lessons/lesson-card-directive";
import proposalModalDirective from "../directives/popup/modal/proposal-modal-directive";
import recentSenseisDirective from "../directives/user/recent-senseis-directive";
import sessionListItemDirective from "../directives/dashboard/session-list-item-directive";
import uploadPhotoDirective from "../directives/user/upload-photo-directive";

dashboardModulesApp.directive("communication", communicationDirective);
dashboardModulesApp.directive("dashboardSidebar", dashboardSidebarDirective);
dashboardModulesApp.directive("dashboardTable", dashboardTableDirective);
dashboardModulesApp.directive(
  "extendLessonRequest",
  extendLessonRequestDirective
);
dashboardModulesApp.directive("lessonArrival", lessonArrivalDirective);
dashboardModulesApp.directive("lessonCard", lessonCardDirective);
dashboardModulesApp.directive("proposalModal", proposalModalDirective);
dashboardModulesApp.directive("recentSenseis", recentSenseisDirective);
dashboardModulesApp.directive("sessionListItem", sessionListItemDirective);
dashboardModulesApp.directive("uploadPhoto", uploadPhotoDirective);

export default dashboardModulesApp;
