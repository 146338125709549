import giftCardOrderSummaryPartial from "../../templates/includes/gift_cards/gift_card_order_summary_partial";

export default class GiftCardConfirmationController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $state,
    GiftCardManager,
    OmnibarPointer,
    AccessControl,
    User,
    EventTrackingManager
  ) {
    this.gigya = $scope.isGigyaOn;
    // angular providers
    this.state = $state;
    // services
    this.userService = User;
    this.giftCard = GiftCardManager;
    this.OmnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    //imported and injected
    this.giftCardOrderSummaryPartial = giftCardOrderSummaryPartial;
  }

  // display methods
  completedText() {
    if (this.giftCard.isGuest) {
      return "Thanks and welcome to Gamer Sensei!";
    } else {
      return "Thanks for purchasing a Gamer Sensei Gift Card!";
    }
  }

  isEmail() {
    return this.giftCard.deliveryMethod === "email";
  }

  // action methods
  print() {
    this.giftCard.deliveryMethod = "print";

    $timeout(() => {
      window.print();
    }, 0);
  }

  goTo(state) {
    this.state.go(state);
  }

  // internal methods
  updateUser(newUser) {
    const role = this.userService.getNewUserRole(newUser);
    this.userService.setCurrentId(newUser.id);
    this.userService.setRole(role);
    this.userService.getUser(true).then((userData) => {
      this.loading = false;
      this.currentUser = userData;
      this.giftCard.isGuest = false;
    });
  }
}

GiftCardConfirmationController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "$state",
  "GiftCardManager",
  "OmnibarPointer",
  "AccessControl",
  "User",
  "EventTrackingManager",
];
