import parentsImg from "../../images/sections/gs_parents";
import gameTimeBgImg from "../../images/backgrounds/game_time";
import { shieldIcon, supportIcon } from "../../config/icons/iconConfig";

export default class ParentsPageController {
  constructor(MetaTagsService) {
    this.icons = { shieldIcon, supportIcon };
    this.parentsImg = parentsImg;
    this.gameTimeBgImg = gameTimeBgImg;
    this.mostOfGameTime = {
      title: '<h2 class="h1 med text-light">MAKING THE MOST OF GAME TIME</h1>',
      body: "<div class=\"text-large\"><p>All Sensei approach training from a lifestyle perspective, addressing both in-game performance and out-of-game variables such as sleep and mental wellness.</p><p>We're not here to just help people win. We're building better gamers that are healthy and mindful team players</p>",
    };

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Parents | Gamer Sensei";
    const metaDescription =
      "Take your skills to the next level with a professional coach from Gamer Sensei.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

ParentsPageController.$inject = ["MetaTagsService"];
