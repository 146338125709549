import moment from "moment-timezone";

export default function userDataGamerService(UserData) {
  const gamerData = function (_userData) {
    UserData.apply(this, arguments);
    if (_userData.gamer !== undefined) {
      this.data.games = _userData.gamer.games;
      this.data.game_description = this.getGameDescriptionFromGame(
        _userData.gamer.games
      );
      this.data.description = _userData.gamer.description;
      this.data.credit_cards = _userData.gamer.credit_cards;
      this.data.current_balance = this.getCurrentBalance(_userData);
      if (this.hasSubscription(_userData)) {
        this.setCurrentBillingEndsAtLocal(
          this.currentBillingEndsAtLocal(_userData)
        );
      }
      this.data.gamer_notification_preference_id =
        this.getGamerNotificationPreferenceId(_userData);
    }
  };

  gamerData.prototype = Object.create(UserData.prototype);

  gamerData.prototype.hasGamerCurrencyAccount = function (userData) {
    return userData.gamer.gamer_currency_account;
  };

  gamerData.prototype.hasSubscription = function (userData) {
    if (!this.hasGamerCurrencyAccount(userData)) return false;
    return userData.gamer.gamer_currency_account.bundle_subscription;
  };

  gamerData.prototype.setCurrentBillingEndsAtLocal = function (val) {
    this.data.gamer.gamer_currency_account.bundle_subscription.current_billing_ends_at_local =
      val;
  };

  gamerData.prototype.currentBillingEndsAtLocal = function (userData) {
    var current_billing_ends_at =
      userData.gamer.gamer_currency_account.bundle_subscription
        .current_billing_ends_at;
    return moment(current_billing_ends_at).tz(moment.tz.guess());
  };

  gamerData.prototype.getCurrentBalance = function (userData) {
    if (!this.hasGamerCurrencyAccount(userData)) return 0;
    if (!userData.gamer.gamer_currency_account.spendable_balance) return 0;
    return userData.gamer.gamer_currency_account.spendable_balance;
  };

  gamerData.prototype.getGamerNotificationPreferenceId = function (userData) {
    return userData.gamer.gamer_notification_preference_id;
  };

  return gamerData;
}

userDataGamerService.$inject = ["UserData"];
