import angular from "angular";

export default class ChatsPageController {
  constructor(
    $scope,
    $rootScope,
    $stateParams,
    $timeout,
    Dashboard,
    SenseiTrainingRequestManager,
    SenseiTrainingRequest,
    SenseiTrainingRequestAPIService,
    OmnibarPointer,
    NotificationManager,
    AsyncUpdate
  ) {
    $rootScope.bodylayout = "chat-page";
    this.userId = $scope.currentId;
    this.BuildChatTimeoutTime = 30000;
    this.updateChatTimeout;
    // angular providers
    this.$timeout = $timeout;
    // services
    this.asyncUpdate = AsyncUpdate;
    this.dashboardManager = Dashboard;
    this.omnibarPointer = OmnibarPointer;
    this.str = SenseiTrainingRequest;
    this.strAPI = SenseiTrainingRequestAPIService;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.notifications = NotificationManager;
    this.messages = [];

    if (this.SenseiTrainingRequestManager.isSensei()) {
      this.userType = "sensei";
    } else {
      this.userType = "gamer";
    }

    this.initSenseiChatPage($stateParams.lessonId);
  }

  // init methods
  initSenseiChatPage(lessonStateParam) {
    this.dashboardManager
      .getSingleSenseiTrainingRequests(lessonStateParam)
      .then((res) => {
        this.lesson = new this.str(
          res.data.sensei_training_request,
          this.userType
        );
        this.conversationId = res.data.sensei_training_request.conversation_id;

        if (this.conversationId) {
          this.notifications.setSenseiChatsToRead(this.conversationId);
          this.checkForNewChatMessages();
        }
      });
  }

  // action methods
  addMessage(lesson) {
    if (!this.newMessage) {
      return;
    }

    this.savingChatLoading = true;

    this.strAPI
      .addMessage(lesson.id, {
        message: { text: this.newMessage },
      })
      .then((res) => {
        this.savingChatLoading = false;

        if (!this.conversationId) {
          this.conversationId = res.data.conversation.id;
          this.messages = res.data.conversation.messages;
        } else {
          this.messages.push(res.data.message);
        }

        this.newMessage = null;

        angular.element(".chat-main-container").scrollTop(10000000);
      })
      .catch((err) => {
        this.savingNotesLoading = false;
        this.omnibarPointer.pushErrorByMessage(err.data.errors);
      });
  }

  // fetch methods
  setChatMessages() {
    return this.dashboardManager
      .fetchMessages(this.conversationId)
      .then((res) => {
        this.messages = res.data.messages;
        angular.element(() => {
          angular.element(".chat-main-container").scrollTop(100000000);
        });
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByMessage(err.data.errors);
      });
  }

  // internal methods
  checkForNewChatMessages() {
    this.setChatMessages();
    if (this.asyncUpdate) {
      this.updateChatTimeout = this.$timeout(
        this.checkForNewChatMessages,
        this.BuildChatTimeoutTime
      );
    }
  }
}

ChatsPageController.$inject = [
  "$scope",
  "$rootScope",
  "$stateParams",
  "$timeout",
  "Dashboard",
  "SenseiTrainingRequestManager",
  "SenseiTrainingRequest",
  "SenseiTrainingRequestAPIService",
  "OmnibarPointer",
  "NotificationManager",
  "AsyncUpdate",
];
