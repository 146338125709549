export default function compileTemplateDirective($compile, $parse) {
  return {
    link: (scope, element, attr) => {
      const parsed = $parse(attr.ngBindHtml);

      function getStringValue() {
        return (parsed(scope) || "").toString();
      }

      scope.$watch(getStringValue, function () {
        $compile(element, null, -9999)(scope);
      });
    },
  };
}

compileTemplateDirective.$inject = ["$compile", "$parse"];
