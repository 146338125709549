import flatpickr from "flatpickr";
import {
  infoIcon,
  calendarIcon,
  filterIcon,
} from "../../config/icons/iconConfig";
import {
  flattenObject,
  getHours,
  pricingFilterOptions,
  getTierName,
} from "../../config/utils/utilsConfig";
import sessionPanel from "../../templates/includes/sessions/session_tier_panel_normal_partial";

export default class GamesLandingPageController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $location,
    $state,
    $stateParams,
    LocaleSelection,
    Game,
    SenseiService,
    TokenService,
    TokenDetailsUtil,
    LazyLoad,
    OmnibarPointer,
    MetaTagsService,
    EventTrackingManager,
    User,
    BlogManager,
    pulledGames,
    UIFeatureGate
  ) {
    this.userId = $scope.currentId;
    this.role = $scope.role;
    // angular providers
    this.$location = $location;
    this.state = $state;
    this.$stateParams = $stateParams;
    this.delay = $timeout;
    this.root = $rootScope;
    // services
    this.sensei = SenseiService;
    this.tokenManager = TokenService;
    this.tokenDetails = TokenDetailsUtil;
    this.eventTracking = EventTrackingManager;
    this.OmnibarPointer = OmnibarPointer;
    this.blog = BlogManager;
    this.localeSelection = LocaleSelection;
    // other injected/imported
    this.hours = getHours();
    this.games = pulledGames.data.games;
    this.infoIcon = infoIcon;
    this.calendarIcon = calendarIcon;
    this.filterIcon = filterIcon;
    this.sessionPanel = sessionPanel;

    $scope.games = this.games;

    this.pricing = pricingFilterOptions();

    this.pageGame = {};
    this.tokenSenseis = {};
    this.showPrice = false;
    this.coachTarget = "_parent";
    this.sortByFilter = null;

    this.isBlogAvailable = UIFeatureGate.data.ui_feature_gates.v3_blog;

    this.displayTokenTierBundles = function () {
      return false;
    };

    this.initFilters();
    this.initUser(User);

    this.initCurrentGame(Game, {
      onSuccess: () => {
        this.initGameAnnotations(Game, this.pageGame.id);
        this.initMetaTags(MetaTagsService);
        this.initTokens();

        if (this.isBlogAvailable) {
          this.blog.setRecentBlogs(
            `articles?filters[category][slug][$eq]=${this.pageGame.slug}&populate[0]=image&populate[1]=author&populate[2]=author.picture`
          );
        }

        const gameFilter = { game_id: this.pageGame.id };

        this.sensei.setCurrentSearchFilters(gameFilter, {
          search: () => {
            this.sensei.setSearchResults();
          },
        });

        this.eventTracking.pushViewEvent(`${this.pageGame.name} Landing Page`);
      },
    });

    this.delay(() => {
      LazyLoad.set();
    }, 0);
  }

  // init methods
  initFilters() {
    // pull filters
    this.sensei.setAvailableFilters({
      priceConfig: (prices) => {
        const maxPrice = this.getMaxPrice(prices);
        this.priceSlider = {
          minValue: 0,
          maxValue: maxPrice,
          options: {
            floor: 0,
            ceil: maxPrice,
            translate: (value) => {
              return "$" + value;
            },
            onEnd: () => this.onPriceChange(),
          },
        };
      },
      dateConfig: () => {
        const dateInput = document.querySelector("#date-filter");
        const config = {
          enableTime: true,
          dateFormat: "Y-m-d GK",
          minDate: "today",
          maxDate: new Date().fp_incr(21), // 21 days from now
          onChange: (selectedDates, dateStr, instance) => this.onDateChange(),
        };
        const fp = flatpickr(dateInput, config);

        // Only hide the Minutes input if `enableTime: true` and `dateFormat: ...` doesn't include minutes (`'i'` flag)
        if (config.enableTime && !config.dateFormat.includes("i")) {
          const flatpickrTimeElement = fp.timeContainer; // <div class="flatpickr-time" tabindex="-1">...</div>
          const [hoursInputWrapper, minutesInputWrapper] =
            flatpickrTimeElement.getElementsByClassName("numInputWrapper");

          // Set the width of the Hours wrapper to 100%
          hoursInputWrapper.style.width = "100%";

          // Remove the `:` separator
          flatpickrTimeElement
            .querySelector(".flatpickr-time-separator")
            .remove();

          // Completely remove the minutes wrapper and input
          minutesInputWrapper.remove();
        }
      },
    });
  }

  initUser(User) {
    if (this.userId) {
      User.setCurrentId(this.userId);
      User.setRole(this.role);
      User.getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }

  initCurrentGame(Game, callbacks) {
    Game.findGame(this.$stateParams.title)
      .then((res) => {
        this.pageGame = res.data;
        this.pageGame.pageTitle = res.data.landing_page_main_title
          ? res.data.landing_page_main_title
          : "Take your game to the next level.";

        if (callbacks?.onSuccess) {
          callbacks.onSuccess();
        }
      })
      .catch(angular.noop);
  }

  initTokens() {
    this.tokenManager.setTierLowestPrices(this.pageGame.id);
    this.tokenManager
      .setTokens({
        game: this.pageGame.slug,
        tokenType: "one-on-one-training",
      })
      .then((res) => {
        const filteredSessions = Object.values(res).filter((sess) => {
          return (
            !sess.token_tier.includes("legendary") &&
            sess.display_name === "General 1-on-1 Coaching"
          );
        });

        this.oneOnOneSessions = this.formatTokensToObj(filteredSessions);

        angular.forEach(this.oneOnOneSessions, (singleToken, key) => {
          this.tokenManager
            .fetchSenseisOnCurrentToken(singleToken.id)
            .then((res) => {
              this.tokenSenseis[key] = res.data.sensei_token_tiers;
            });
        });
      });
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = `${this.pageGame.name} Coaching and Lessons with Pro Gamers | Gamer Sensei `;
    const metaDescription = `Take personalized ${this.pageGame.name} lessons from pro coaches with Gamer Sensei, the world's premier esports coaching platform.`;

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initGameAnnotations(Game, id) {
    const gameParams = {
      params: {
        game_id: id,
      },
    };

    return Game.specificPage(gameParams)
      .then((res) => {
        this.gameAnnotations = res.data.game_annotations;
        this.gameSpecificData = this.gameAnnotations.length > 0 ? true : false;
      })
      .catch(angular.noop);
  }

  // cta
  applySearchResults() {
    const newDate = moment(this.dateAndTime, "YYYY-MM-DD hhA");
    const hour = newDate.format("HH:mm");
    const dateLook = newDate.clone().format("MM/DD/YYYY");

    if (this.dateAndTime && hour) {
      this.sensei.setCurrentSearchFilters({ proposed_hour: hour });
    }
    if (this.dateAndTime && dateLook) {
      this.sensei.setCurrentSearchFilters({ proposed_date: dateLook });
    }
    this.setLanguageFilters();
    this.setPlatforms();

    this.delay(() => {
      const newFilters = { ...this.sensei.search.filters };
      delete newFilters["sort_by[]"];
      delete newFilters.game_id;
      delete newFilters.proposed_hour;
      delete newFilters.proposed_time_zone;

      if (newFilters["languages[]"]) {
        delete Object.assign(newFilters, {
          languages: newFilters["languages[]"],
        })["languages[]"];
      }

      if (newFilters["platforms[]"]) {
        delete Object.assign(newFilters, {
          platforms: newFilters["platforms[]"],
        })["platforms[]"];
      }

      this.currentFilters = flattenObject(newFilters);

      this.sensei.setSearchResults();
    }, 100);
  }

  // get multi select filters
  setLanguageFilters() {
    const languages = this.sensei.availableFilters.languages;
    const selectedLanguages = languages
      .filter((language) => language.selected)
      .map((newObj) => {
        return newObj.id;
      });

    this.sensei.setCurrentSearchFilters({ "languages[]": selectedLanguages });
  }

  setPlatforms() {
    const platforms = this.sensei.availableFilters.platforms;
    const selectedPlatforms = platforms
      .filter((platform) => platform.selected)
      .map((newObj) => {
        return newObj.name;
      });

    this.sensei.setCurrentSearchFilters({ "platforms[]": selectedPlatforms });
  }

  // Filter methods
  toggleMobileFilterOptions() {
    this.openedMobileFilter = !this.openedMobileFilter;

    if (this.openedMobileFilter) {
      this.delay(() => {
        this.root.$broadcast("reCalcViewDimensions");
      }, 100);
    }
  }

  areThereCurrentFilters() {
    const filterCheck =
      this.currentFilters && Object.keys(this.currentFilters).length;

    return filterCheck;
  }

  updateMultiSelect() {
    this.applySearchResults();
  }

  removeThisFilter(key, filter) {
    if (key === "proposed_date") {
      this.dateAndTime = null;
      delete this.sensei.search.filters.proposed_date;
      delete this.sensei.search.filters.proposed_hour;
    } else if (key.includes("platforms")) {
      const keyArr = key.split(".");

      this.sensei.search.filters["platforms[]"].splice(keyArr[1], 1);

      this.sensei.availableFilters.platforms.forEach((item) => {
        if (item.name === filter) {
          item.selected = false;
          this.root.$broadcast("removeIndividual", {
            item: item.name,
            label: "Platforms",
          });
        }
      });
    } else if (key.includes("languages")) {
      const keyArr = key.split(".");

      this.sensei.search.filters["languages[]"].splice(keyArr[1], 1);

      this.sensei.availableFilters.languages.forEach((item) => {
        if (item.id === filter) {
          item.selected = false;
          this.root.$broadcast("removeIndividual", {
            item: item.name,
            label: "Languages",
          });
        }
      });
    } else if (key === "review_ratings") {
      delete this.sensei.search.filters.review_ratings;
    } else if (key === "max_price") {
      this.priceSlider.maxValue = this.priceSlider.options.ceil;
      delete this.sensei.search.filters.max_price;
    } else if (key === "min_price") {
      this.priceSlider.minValue = this.priceSlider.options.floor;
      delete this.sensei.search.filters.min_price;
    }

    delete this.currentFilters[key];
    this.applySearchResults();
  }

  getPillLabel(key, filter) {
    if (key === "proposed_date") {
      return `Available on ${filter}`;
    } else if (key.includes("platforms")) {
      return filter;
    } else if (key.includes("languages")) {
      const lang = this.sensei.availableFilters.languages.find(
        (item) => item.id === filter
      );
      return lang.name;
    } else if (key === "review_ratings") {
      return `At least ${filter} ★'s`;
    } else if (key === "max_price") {
      return `Max Price: $${filter / 100}`;
    } else if (key === "min_price") {
      return `Min Price: $${filter / 100}`;
    }
  }

  clearAllFilters() {
    this.currentFilters = {};
    this.sensei.clearAllFilters(true, this.sensei.search.filters.game_id, {
      resetMultiSelects: () => this.root.$broadcast("clearMultiSelects"),
    });
  }

  searchDifferentGame() {
    const gameId = this.sensei.search.filters.game_id;
    const searchedGame = this.games.filter((elm) => {
      return elm.id === gameId;
    });
    const gameSlug = searchedGame[0].seo_slug;

    this.state.go("games_landing.page", { title: gameSlug });
  }

  onSortByChange() {
    if (this.sortByFilter) {
      this.sensei.setCurrentSearchFilters({
        "sort_by[]": [this.sortByFilter.slug],
      });
    } else {
      delete this.sensei.search.filters["sort_by[]"];
    }

    this.applySearchResults();
  }

  onReviewRatingChange() {
    if (this.sensei.search.filters.review_ratings === null) {
      delete this.sensei.search.filters.review_ratings;
    }

    this.applySearchResults();
  }

  onDateChange() {
    this.applySearchResults();
  }

  onPriceChange() {
    if (this.priceSlider.minValue !== this.priceSlider.options.floor) {
      this.sensei.setCurrentSearchFilters({
        min_price: this.priceSlider.minValue * 100,
      });
    }

    if (this.priceSlider.maxValue !== this.priceSlider.options.ceil) {
      this.sensei.setCurrentSearchFilters({
        max_price: this.priceSlider.maxValue * 100,
      });
    }

    this.applySearchResults();
  }

  getMaxPrice(prices) {
    const max = Math.ceil(prices.max_price / 100) * 100;

    return max;
  }

  togglePriceRange() {
    this.priceRangeOpened = !this.priceRangeOpened;

    if (this.priceRangeOpened) {
      this.delay(() => {
        this.root.$broadcast("reCalcViewDimensions");
      }, 100);
    }
  }

  isSortingAvailable() {
    const sorting = this.sensei.availableFilters.sort_by;

    return sorting && Object.keys(sorting).length;
  }

  // Display methods
  areThereTokenSenseis() {
    return Object.keys(this.tokenSenseis).length;
  }

  doesThisTierHaveSenseis(key) {
    return this.tokenSenseis && this.tokenSenseis[key];
  }

  convertMarkdown(content) {
    return marked(content);
  }

  isThereLowestPricingInfo(token) {
    const list = this.tokenManager.getTierLowestPrices();

    if (Object.keys(list).length > 0 && list[token.token_tier_data.name]) {
      return true;
    }

    return false;
  }

  getLowestPrice(token) {
    const list = this.tokenManager.getTierLowestPrices();

    return list[token.token_tier_data.name].student_base_cost;
  }

  formatUrl(url) {
    const refParam = this.$location.search() && this.$location.search().ref;
    const stateRef = this.$stateParams.slug;

    if (refParam) {
      return `${url}?ref=${refParam}`;
    } else if (stateRef) {
      return `${url}?ref=${stateRef}`;
    }

    return url;
  }

  generateStyles(index) {
    switch (index) {
      case 0:
        return "gs-bg--gray text-light";
      case 1:
        return "gs-bg--dark-gray text-light";
      case 2:
        return "gs-bg--green";
      case 3:
        return "gs-bg--dark-gray text-light";
      case 4:
        return "gs-bg--green";
      default:
        return "gs-bg--gray text-light";
    }
  }

  ratingDisplay(rating) {
    switch (rating) {
      case "5":
        return `★ ★ ★ ★ ★`;
      case "4":
        return `★ ★ ★ ★ and up`;
      case "3":
        return `★ ★ ★ and up`;
      case "2":
        return `★ ★ and up`;
      case "1":
        return `★ and up`;
      default:
        return `All`;
    }
  }

  getSenseiTierName(tierName) {
    return getTierName(tierName);
  }

  // internal methods
  formatTokensToObj(tokens, qty) {
    const initialValue = {};

    return tokens.reduce((obj, item) => {
      return {
        ...obj,
        [`${item.token_tier}-${item.token_type}-${item.id}`]: {
          ...item,
          quantity: qty ? qty : 1,
        },
      };
    }, initialValue);
  }
}

GamesLandingPageController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$location",
  "$state",
  "$stateParams",
  "LocaleSelection",
  "Game",
  "SenseiService",
  "TokenService",
  "TokenDetailsUtil",
  "LazyLoad",
  "OmnibarPointer",
  "MetaTagsService",
  "EventTrackingManager",
  "User",
  "BlogManager",
  "pulledGames",
  "UIFeatureGate",
];
