const metaTitles = {
  "business_dashboard.landing": "Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.book_lesson_landing":
    "Lessons | Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.book_lesson_calendar":
    "Lessons | Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.calendar":
    "Calendar | Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.my_reviews":
    "Sensei Reviews | Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.sensei_notes":
    "Sensei Notes | Business Dashboard | Gamer Sensei",
  "business_dashboard.lessons.sensei_note_page":
    "Sensei Notes | Business Dashboard | Gamer Sensei",
  "business_dashboard.wallet.summary":
    "Payments | Business Dashboard | Gamer Sensei",
  "business_dashboard.wallet.history":
    "Payments | Business Dashboard | Gamer Sensei",
  "business_dashboard.wallet.redeem_session_token":
    "Redeem Sessions | Business Dashboard | Gamer Sensei",
  "business_dashboard.wallet.profile":
    "Profile | Business Dashboard | Gamer Sensei",
  "business_dashboard.contact_information":
    "Contact | Business Dashboard | Gamer Sensei",
  "business_dashboard.notifications":
    "Notifications | Business Dashboard | Gamer Sensei",
  "business_dashboard.wallet.payment":
    "Payments | Business Dashboard | Gamer Sensei",
};

const setBusinessMetaTitle = (name) => {
  return metaTitles[name] || "Business Dashboard | Gamer Sensei";
};

export default setBusinessMetaTitle;
