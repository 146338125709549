export const title = "Cookies and Similar Technologies";

const cookies = {
  title: title,
  content: {
    overview: {
      header: "Overview",
      body: `<p>Please read the following to understand the Personal Data and other data we collect via cookies, pixels, web beacons, and similar tracking technologies on our Sites, Platforms, and Apps, and in certain emails and advertisements ("Service"), how we use and share that data, and how you can control or manage your consent to the collection of data using these technologies. This Cookie Policy is part of our <a class="alt" href="/privacy-policy" target="_self">Privacy Policy</a>.</p>`,
    },
    whatAreCookies: {
      header: "What are cookies and similar technologies?",
      body: `<p>A cookie is a small text file containing unique information your browser stores at the request of a website. Similar technologies, such as web beacons or pixel tags typically function through the use of small files, such as a GIF or PNG, which are loaded automatically on a webpage, mobile application, image, ad, or in an email. The request for these files from a server reveals certain information, such as your browser type, IP address, and other information.These beacons or pixels may also include tags with a unique identifier or other attributes of the webpage, application, feature, image, message, etc.</p>`,
    },
    dateWeCollect: {
      header: "Data we collect",
      body: `<p>Cookies and similar technologies are used on our Service to automatically collect or infer the following categories of Personal Data. The following categories and examples are supplements to the descriptions of the categories described in our <a class="alt" target="_self" href="/privacy-policy">Privacy Policy</a>;</p>
      <ul>
        <li><strong>Identity</strong><strong>Data</strong> (e.g.unique identifiers)</li>
        <li><strong>Device/Network</strong><strong>Data</strong> (e.g.IP address, browser type/version, OS system/version, metadata about your interactions with the site, referral page)</li>
        <li><strong>Usage Data</strong> (e.g. click stream, navigation flow, browsing metadata)</li>
        <li><strong>Inference</strong><strong>Data</strong> (e.g. product preferences, navigation history)</li>
        <li><strong>Transaction</strong><strong>Data</strong> (e.g. products purchased or viewed)</li>
      </ul>
      <p>Note: Some of these technologies can be used by us and/or our third-party partners to identify you across platforms, devices, sites, and services.</p>`,
    },
    processing: {
      header: "Purposes of processing",
      body: `<p>We use the data from cookies and similar technologies for the following purposes:</p>
      <ol class="custom-list parens-around lower-roman">
        <li><strong>Strictly necessary</strong>-Processing that is essential to the operation of our Service. For example, it is necessary for us to process data from cookies and similar technologies to:
          <ul>
            <li>display our Sites in the proper format and language;</li>
            <li>load font packages;</li>
            <li>authenticate/validate certain login, payment, or form submission events;and</li>
            <li>maintain active user sessions.</li>
          </ul>
        </li>
        <li class="mt-2"><strong>Functional </strong>-Processing that is required to enable or operate features you request or choices you make. For example, we may process data from cookies or similar technologies as necessary for the following functional purposes:
          <ul>
            <li>remember your preferences relating to the use of optional cookies;</li>
            <li>prefill username/passwords;</li>
            <li>maintain shopping carts; and</li>
            <li>enable support and communications features.</li>
          </ul>
        </li>
        <li class="mt-2"><strong>Analytics and personalization</strong>-Processing,consistent with our legitimate interests and subject to your consent where required by law, related to understanding how users arrive at, navigate, and use our Service, engage with our products and brands, and to customize our Service for users. For example, we may process data from cookies and similar technologies for the following purposes:
          <ul>
            <li>determine what sites users visit before visiting our Service, how often they visit our Service, and other similar information;</li>
            <li>greet users by name and modify the appearance of the Service to usage history;</li>
            <li>tailor the Service based on geographic location, and understand characteristics of users based on general location;</li>
            <li>understand navigation patterns, time spent on pages, screens, using features, or particular portions thereof;</li>
            <li>establish the number page views and unique viewers;</li>
            <li>resolve support issues, fix bugs, or fix errors;</li>
            <li>attribute page views and purchases to affiliate/referral URLs or similar sources; and</li>
            <li>"A-B" testing of features or functionality across our Service.</li>
          </ul>
        </li>
        <li class="mt-2"><strong>Advertising</strong>- Processing related to the marketing of our products and services and for online advertising. This processing may be subject to your consent where required by law, or rights to opt out. This collection and ad targeting takes place both on our Service and on third-party websites participating in an ad network, e.g., when our advertisements are delivered by an ad network on a third-party website. For example, we (and our advertising partners) may process data from cookies and similar technologies for the following purposes:
          <ul>
            <li>deliver ads for our products and services on sites and services operated by third parties;</li>
            <li>retarget users or devices that have used our Service with advertisements relating to our products or services;</li>
            <li>target users meeting certain inferred or behavioral attributes;</li>
            <li>evaluate and improve the targeting and relevance of ads delivered to users;</li>
            </ul>
            <ul>
            <li>determine which ads have been viewed, and prevent the same advertisements from appearing too frequently;</li>
            <li>determine whether someone interacted with or viewed ads and the attributes common to those that did so;</li>
            <li>determine whether someone opened a marketing email or interacted with content in that email; and</li>
            <li>develop and improve (our or our marketing partners') user profiles used for marketing purposes.</li>
          </ul>
        </li>
        <li class="mt-2"><strong>Social Media-</strong>Processing related to our integration with third party social media sites, pages, and channels. This processing may be subject to your consent where required by law, or rights to opt out. Note that this processing involves our transfer of information to Social Media Sites, which may use this data for their own purposes, or which may collect additional information on their own sites, and in each case, that processing will be subject to the privacy policies of that site. For example, we (or the social media partner) may process data from cookies and similar technologies for the following purposes:
          <ul>
            <li>Display ads for our products and services on a social media site or other products and services that the social media site may deem relevant to you;</li>
            <li>determine which ads have been viewed on a social media site and prevent the same advertisements from appearing too frequently;</li>
            <li>target users meeting certain inferred or behavioral attributes maintained by the social media site and/or that we select;</li>
            <li>evaluate and improve the targeting and relevance of the ads delivered to users on a social media site; and</li>
            <li>allow a social media site to track user activity across our Service and those of third parties to determine consumer interests and common characteristics.</li>
          </ul>
        </li>
      </ol>
      <p>Please see the following links for additional information about processing via Social Media Sites:</p>
      <section class="mb-2">
        <h3 class="h6">Facebook / Instagram</h3>
        <a class="alt" target="_blank" href="https://www.facebook.com/business/help/742478679120153?id=1205376682832142">About Facebook Pixel</a><br>
        Privacy Controls: <a class="alt" target="_blank" href="https://www.facebook.com/privacy/policy/">registered user (FB)</a> / <a class="alt" target="_blank" href="https://www.instagram.com/accounts/privacy_and_security/">registered user (IG)</a> / <a class="alt" target="_blank" href="http://optout.aboutads.info/?c=2&amp;lang=EN">unregistered user</a><br>
        <a class="alt" target="_blank" href="https://www.facebook.com/about/privacy/previous">Facebook Data Policy</a> / <a class="alt" target="_blank" href="https://help.instagram.com/519522125107875">Instagram Data Policy</a>
      </section>
      <section class="mb-2">
        <h3 class="h6">Twitter</h3>
        <a class="alt" target="_blank" href="https://business.twitter.com/en/help/troubleshooting/how-twitter-ads-work.html">How Twitter Ads Work</a><br>
        <a class="alt" target="_blank" href="https://help.twitter.com/en/safety-and-security/twitter-privacy-settings">Twitter Privacy Controls</a><br>
        <a class="alt" target="_blank" href="https://twitter.com/en/privacy">Twitter Privacy Policy</a>
      </section>
      <section class="mb-2">
        <h3 class="h6">Twitch</h3>
        <a class="alt" target="_blank" href="https://www.twitch.tv/p/en/legal/privacy-choices/">Twitch Privacy Choices</a><br>
        <a class="alt" target="_blank" href="https://www.twitch.tv/p/en/legal/privacy-notice/">Twitch Privacy Notice</a>
      </section>
      <section class="mb-2">
        <h3 class="h6">YouTube/Google</h3>
        <a class="alt" target="_blank" href="https://policies.google.com/privacy?hl=en">Google Privacy Policy</a><br>
        <a class="alt" target="_blank" href="https://adssettings.google.com/">Google Privacy Choices</a><br>
      </section>
      <p>
        <em><span class="text-underline">Please note:</span> not all portions of the Corsair Service collect data for purposes relating to advertising or social media (e.g. Sites targeting children do not collect data for advertising purposes, whether on our Sites, or on behalf of the social media partner).</em>
      </p>`,
    },
    share: {
      header: "How we share this data",
      body: `<p>Cookies and similar technologies may be operated by us, service providers operating on our behalf, or in some cases, by third parties to which we grant the right to collect information from our Service (e.g. via pixels or "third party cookies"). Some third parties may process data for their own purposes, as described in the third party's privacy policy.</p>`,
    },
    choices: {
      header: "Your choices",
      body: `<p>To control the storage of data via cookies and similar technologies, you may change your preferences using our Cookie Settings (available on our website <a class="alt" target="_self" href="/">here</a>). You may also modify your browser settings or activate certain browser features to block certain cookies and technologies, install third party add-ons or plug-ins, or otherwise use choice mechanisms that are communicated to you from time to time.Our Service does not respond to Do-Not-Track signals at this time. However, you may be able to opt out of the use of cookies and similar technologies for personalized/behavioral advertising using programs managed by third party advertising companies, industry groups, or associations, such as:<br>
      <a class="alt" target="_blank" href="https://optout.networkadvertising.org/">Network Advertising Initiative (NAI)</a><br>
      <a class="alt" target="_blank" href="https://optout.aboutads.info/">Digital Advertising Alliances(DAA)/WebChoices</a><br>
      <a class="alt" target="_blank" href="https://optout.aboutads.info/%22%20%EF%B7%9FHYPERLINK%20%22https://www.youronlinechoices.eu/">EU Your Online Choices</a></p>`,
    },
    expiryPeriod: {
      header: "Expiry period of cookies",
      body: `<p>Some cookies are saved on your device only for the duration of your visit to our Service (session cookies). Others are placed for a longer period. This can allow us to recognize you or your device when you revisit our Service at some time in the future.</p>`,
    },
  },
};

export default cookies;
