import shutDownTemplate from "../../../templates/directives/popup/modal/shut_down_template.html";

export default function shutDownDirective(User) {
  return {
    restrict: "E",
    scope: {},
    template: shutDownTemplate,
    link: link,
  };

  function link(scope, elem, attr) {
    scope.user = User.getData();

    scope.linkObj = {
      link: scope.user?.id ? `/gamer/dashboard` : "/",
      text: scope.user?.id ? "Take me to my dashboard" : "Back to Gamer Sensei",
    };
  }
}

shutDownDirective.$inject = ["User"];
