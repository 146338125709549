import navBar from "../../templates/includes/nav/simple_nav_bar";
import { gsLogoHorizontal } from "../../config/brand/brandConfig";

export default class GamerSignUpPageController {
  constructor(
    $rootScope,
    $timeout,
    $window,
    AccessControl,
    OmnibarPointer,
    LazyLoad,
    $anchorScroll,
    Game,
    MetaTagsService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "gamer_sign_up";
    this.role = "gamer";
    // angular providers
    this.$anchorScroll = $anchorScroll;
    this.$timeout = $timeout;
    this.$window = $window;
    // services
    this.access = AccessControl;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.gsLogoHorizontal = gsLogoHorizontal;
    this.navBar = navBar;
    // objects and arrays
    this.containerClasses = ["card", "card-column"];
    // booleans
    this.isSignUp = true;

    this.initMetaTags(MetaTagsService);
    this.initGames(Game, LazyLoad);
  }

  // init methods
  initMetaTags(MetaTagsService) {
    const metaTitle = "Sign up to Take Lessons With Gamer Sensei";
    const metaDescription =
      "Sign up with Gamer Sensei, the world's premier esports coaching platform servicing your personal journey in competitive gaming.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initGames(Game, LazyLoad) {
    const gameQueryParams = {
      params: {
        status: "active",
      },
    };
    Game.query(gameQueryParams)
      .then((res) => {
        this.games = res.data.games;
        this.$timeout(() => {
          LazyLoad.set();
          this.$anchorScroll("scroll");
        }, 0);
      })
      .catch(angular.noop);
  }

  // push event if user clicks sign in
  connectEvent(event, name) {
    if (event) event.preventDefault();

    const href = event.target.href;
    this.eventTracking.pushEventOnly(name);
    this.$window.location.href = href;
  }

  // Verify Complete
  sendUserToDashboard() {
    if (
      this.access.config.get("referrer") &&
      this.access.config.get("referrer").substring(0, 9) == "/senseis/"
    ) {
      this.$window.location.href = this.access.config.get("referrer");
    } else {
      this.$window.location.href = "/gamer/dashboard/profile";
    }
  }
}

GamerSignUpPageController.$inject = [
  "$rootScope",
  "$timeout",
  "$window",
  "AccessControl",
  "OmnibarPointer",
  "LazyLoad",
  "$anchorScroll",
  "Game",
  "MetaTagsService",
  "EventTrackingManager",
];
