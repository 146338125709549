export default function userSubscriptionsService($q, $http) {
  var subEndpoint = "/api/web/token_subscription_plans";

  var service = {
    loading: true,
    subscriptions: [],
    getLoadingStatus,
    getSubscriptions,
    setSubscriptions,
  };

  return service;

  // Service functions
  function getLoadingStatus() {
    return service.loading;
  }

  function getSubscriptions() {
    return service.subscriptions;
  }

  function setSubscriptions(role, id) {
    if (!service.loading) service.loading = true;
    var deferred = $q.defer();

    callSetSubEndpoint(role, id)
      .then((res) => {
        service.subscriptions = res.data.token_subscription_plans;
        service.loading = false;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }

  // Internal functions
  function formatTokenSubscriptionsToObj(items) {
    var initialValue = {};
    return items.reduce(function (obj, item) {
      var keyName = returnItemTypeAndId(item);

      return {
        ...obj,
        [`${keyName}`]: {
          ...item,
        },
      };
    }, initialValue);
  }

  function callSetSubEndpoint(role, id) {
    return $http.get(`${subEndpoint}/${role}/${id}`);
  }

  function returnItemTypeAndId(item) {
    if (item.token_subscription_plan_id) {
      return `planItem-${item.id}`;
    } else if (item.token_template && !item.token_bundle) {
      return `single-${item.token_template.id}`;
    } else if (item.token_bundle && !item.token_template) {
      return `bundle-${item.token_bundle.id}`;
    } else {
      return "invalidItem";
    }
  }
}

userSubscriptionsService.$inject = ["$q", "$http"];
