export default class GamerWalletController {
  constructor($scope, $rootScope, User, OmnibarPointer) {
    $rootScope.bodylayout = "gamer wallet";
    this.currentUser = {};
    this.OmnibarPointer = OmnibarPointer;

    User.setCurrentId($scope.currentId);
    User.setRole("gamer");

    User.getUser(true)
      .then((userData) => {
        this.currentUser = userData;
      })
      .catch(angular.noop);

    $rootScope.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams) => {
        User.getUser(true)
          .then((userData) => {
            this.currentUser = userData;
          })
          .catch(angular.noop);
      }
    );
  }
}

GamerWalletController.$inject = [
  "$scope",
  "$rootScope",
  "User",
  "OmnibarPointer",
];
