const userInfoReminder = () => {
  return {
    show: false,
    completed: false,
    game_id: null,
    rank: {
      show: false,
      label: "",
      current: "",
      data: [],
    },
    region: {
      show: false,
      label: "",
      current: "",
      data: [],
    },
    username: {
      show: false,
      label: "",
      current: "",
    },
  };
};

export default userInfoReminder;
