const reusableApp = angular.module("gamerSenseiReusable", [
  "ngAria",
  "ngCookies",
  "ngSanitize",
  "ngPatternRestrict",
  "rzSlider",
  "dibari.angular-ellipsis",
]);

// Service Setup
import abExperiencesService from "../services/ux/ab-experiences";
import accessControlService from "../services/users/access";
import availabilityService from "../services/users/availability";
import availabilityProposeService from "../services/senseis/availability-propose";
import blogManagerService from "../services/blog/blog-service";
import cartManagerService from "../services/purchases/cart-manager";
import dashboardManagerService from "../services/users/dashboard";
import directTrainingRequestScheduleService from "../services/lessons/direct-training-request-schedule";
import favoritesService from "../services/gamers/favorites";
import featureGateService from "../services/ux/feature-gate";
import featuredSenseiManagerService from "../services/senseis/featured-sensei-manager";
import flexiTokenService from "../services/lessons/flex-tokens-service";
import gameMetricProfileService from "../services/users/game-metric-profile";
import gamePublisherService from "../services/games/game-publisher";
import gameService from "../services/games/game";
import gamingAttributesService from "../services/games/gaming-attributes";
import giftCardManagerService from "../services/purchases/gift-card-manager";
import lazyLoadService from "../services/ux/lazy-load";
import lessonDiscardService from "../services/lessons/lesson-discard";
import lessonExtensionService from "../services/lessons/lesson-extension";
import lessonRescheduleService from "../services/lessons/lesson-reschedule";
import localeSelectionService from "../services/senseis/locale-selection";
import metaTagService from "../services/meta/seo-service";
import modalManagerService from "../services/ux/modal-manager";
import notificationManagerService from "../services/users/notification-service";
import omnibarPointerService from "../services/ux/omnibar-pointer";
import omnibarService from "../services/ux/omnibar";
import pagePermissionGateService from "../services/ux/page-permissions";
import paymentService from "../services/purchases/payment";
import playerLandingPageService from "../services/senseis/landing-page-sensei";
import programContactService from "../services/programs/program-contact-service";
import referralPolicyService from "../services/users/referral-policy";
import senseiBenefitListService from "../services/senseis/sensei-benefit-list-service";
import senseiReviewService from "../services/users/sensei-reviews";
import senseiScheduleService from "../services/senseis/sensei-schedule-service";
import senseiService from "../services/senseis/sensei-service";
import senseiSessionsManagerService from "../services/senseis/sensei-sessions-manager";
import senseiTrainingRequestAPIService from "../services/lessons/sensei-training-request-api";
import senseiTrainingRequestHolderService from "../services/lessons/sensei-training-request-holder";
import senseiTrainingRequestManagerService from "../services/lessons/sensei-training-request-manager";
import senseiTrainingRequestService from "../services/lessons/sensei-training-request";
import sessionTypesService from "../services/lessons/session-types";
import similarSenseiService from "../services/senseis/similar-senseis";
import tokenRedemptionService from "../services/purchases/token-redemption-service";
import tokenService from "../services/purchases/token-service";
import tokenSubscriptionService from "../services/purchases/token-subscription-service";
import transactionHistoriesService from "../services/users/histories";
import userDataGamerService from "../services/gamers/user-data-gamer";
import userDataSenseiService from "../services/senseis/user-data-sensei";
import userDataService from "../services/users/user-data";
import userOsService from "../services/users/user-os";
import UserProfileService from "../services/users/user-profile";
import userService from "../services/users/user";
import userSubscriptionsService from "../services/users/users-subscription-service";
import verifyUserEmailService from "../services/users/verify-email-service";
import visitorService from "../services/users/visitor";

reusableApp.factory("abExperiences", abExperiencesService);
reusableApp.factory("AccessControl", accessControlService);
reusableApp.factory("Availability", availabilityService);
reusableApp.factory("AvailabilityPropose", availabilityProposeService);
reusableApp.factory("BlogManager", blogManagerService);
reusableApp.factory("CartManager", cartManagerService);
reusableApp.factory("Dashboard", dashboardManagerService);
reusableApp.factory(
  "DirectTrainingRequestSchedule",
  directTrainingRequestScheduleService
);
reusableApp.factory("Favorites", favoritesService);
reusableApp.factory("FeatureGate", featureGateService);
reusableApp.factory("FeaturedSenseiManager", featuredSenseiManagerService);
reusableApp.factory("FlexiTokenService", flexiTokenService);
reusableApp.factory("GameMetricProfile", gameMetricProfileService);
reusableApp.factory("GamePublisher", gamePublisherService);
reusableApp.factory("Game", gameService);
reusableApp.factory("GamingAttributes", gamingAttributesService);
reusableApp.factory("GiftCardManager", giftCardManagerService);
reusableApp.factory("LazyLoad", lazyLoadService);
reusableApp.factory("LessonDiscard", lessonDiscardService);
reusableApp.factory("LessonExtension", lessonExtensionService);
reusableApp.factory("LessonReschedule", lessonRescheduleService);
reusableApp.factory("LocaleSelection", localeSelectionService);
reusableApp.factory("MetaTagsService", metaTagService);
reusableApp.factory("ModalManager", modalManagerService);
reusableApp.factory("NotificationManager", notificationManagerService);
reusableApp.factory("OmnibarPointer", omnibarPointerService);
reusableApp.factory("Omnibar", omnibarService);
reusableApp.factory("PagePermission", pagePermissionGateService);
reusableApp.factory("Payment", paymentService);
reusableApp.factory("PlayerLandingPage", playerLandingPageService);
reusableApp.factory("ProgramContactService", programContactService);
reusableApp.factory("ReferralPolicy", referralPolicyService);
reusableApp.factory("SenseiBenefitListService", senseiBenefitListService);
reusableApp.factory("SenseiReviewService", senseiReviewService);
reusableApp.factory("SenseiSchedule", senseiScheduleService);
reusableApp.factory("SenseiService", senseiService);
reusableApp.factory("SenseiSessionsManager", senseiSessionsManagerService);
reusableApp.factory(
  "SenseiTrainingRequestAPIService",
  senseiTrainingRequestAPIService
);
reusableApp.factory(
  "SenseiTrainingRequestHolder",
  senseiTrainingRequestHolderService
);
reusableApp.factory(
  "SenseiTrainingRequestManager",
  senseiTrainingRequestManagerService
);
reusableApp.factory("SenseiTrainingRequest", senseiTrainingRequestService);
reusableApp.factory("SessionTypes", sessionTypesService);
reusableApp.factory("SimilarSenseis", similarSenseiService);
reusableApp.factory("TokenRedemptionService", tokenRedemptionService);
reusableApp.factory("TokenService", tokenService);
reusableApp.factory("TokenSubscriptionService", tokenSubscriptionService);
reusableApp.factory("TransactionHistories", transactionHistoriesService);
reusableApp.factory("UserDataGamer", userDataGamerService);
reusableApp.factory("UserDataSensei", userDataSenseiService);
reusableApp.factory("UserData", userDataService);
reusableApp.factory("UserOS", userOsService);
reusableApp.factory("UserProfile", UserProfileService);
reusableApp.factory("User", userService);
reusableApp.factory("UserSubscriptionsService", userSubscriptionsService);
reusableApp.factory("VerifyEmailService", verifyUserEmailService);
reusableApp.factory("VisitorService", visitorService);

// Utils Setup
import partnerUtilsService from "../controllers/utils/partners";
import eventTrackingManagerService from "../controllers/utils/event-tracking";
import testimonialUtilsService from "../controllers/utils/testimonials";
import tokenDetailsService from "../controllers/utils/token-details";

reusableApp.factory("PartnersUtils", partnerUtilsService);
reusableApp.factory("EventTrackingManager", eventTrackingManagerService);
reusableApp.factory("TestimonialUtils", testimonialUtilsService);
reusableApp.factory("TokenDetailsUtil", tokenDetailsService);

// Directive Setup
import shutDownDirective from "../directives/popup/modal/shut-down-directive";
import ageGateModalDirective from "../directives/popup/modal/age-gate-modal-directive";
import calendarModalDirective from "../directives/popup/modal/calendar-modal-directive";
import cartDrawerDirective from "../directives/cart/cart-drawer-directive";
import clickOutsideDirective from "../directives/display/click-outside-directive";
import compileTemplateDirective from "../directives/display/compile-template-directive";
import faqAccordionDirective from "../directives/data/faq-accordion-directive";
import favoriteSenseiDirective from "../directives/sensei/favorite-sensei-directive";
import featuredSenseiDirective from "../directives/sensei/featured-sensei-directive";
import filterDataDirective from "../directives/data/filter-data-directive";
import imageDisplayDirective from "../directives/display/image-display-directive";
import lazyLoadResetDirective from "../directives/data/lazy-load-reset-directive";
import legalModalDirective from "../directives/popup/modal/legal-modal-directive";
import loadingOverlayDirective from "../directives/popup/modal/loading-overlay-directive";
import mainFooterDirective from "../directives/navigation/main-footer-directive";
import mainNavBarDirective from "../directives/navigation/main-nav-bar-directive";
import moveToNextDirective from "../directives/data/move-to-next-directive";
import multiSelectDirective from "../directives/display/multi-select-directive";
import omnibarPointerDirective from "../directives/popup/banner/omnibar-directive";
import passwordDirective from "../directives/data/password-directive";
import paginationNavDirective from "../directives/navigation/pagination-nav-directive";
import sectionImageBackgroundDirective from "../directives/display/section-image-background-directive";
import selectGamesService from "../directives/popup/select/select-games-directive";
import senseiProfilePreviewModalDirective from "../directives/popup/modal/sensei-profile-preview-modal-directive";
import senseiReviewModalDirective from "../directives/popup/modal/sensei-review-modal-directive";
import tokenBundleTierDirective from "../directives/session/session-bundle-tier-directive";
import trapFocusDirective from "../directives/display/trap-focus-directive";
import verifyEmailDirective from "../directives/user/verify-email-directive";
import {
  tooltipDirective,
  tooltipConfigurationProvider,
  tooltipDirectiveName,
} from "../directives/popup/banner/tooltip-directive";
import userCombinedNameDirective from "../directives/user/user-combined-name-directive";

reusableApp.directive("shutDown", shutDownDirective);
reusableApp.directive("ageGateModal", ageGateModalDirective);
reusableApp.directive("calendarModal", calendarModalDirective);
reusableApp.directive("cartDrawer", cartDrawerDirective);
reusableApp.directive("clickOutside", clickOutsideDirective);
reusableApp.directive("compileTemplate", compileTemplateDirective);
reusableApp.directive("faqAccordion", faqAccordionDirective);
reusableApp.directive("favoriteSensei", favoriteSenseiDirective);
reusableApp.directive("featuredSensei", featuredSenseiDirective);
reusableApp.directive("filterData", filterDataDirective);
reusableApp.directive("imageDisplay", imageDisplayDirective);
reusableApp.directive("lazyLoadReset", lazyLoadResetDirective);
reusableApp.directive("legalModal", legalModalDirective);
reusableApp.directive("loadingOverlay", loadingOverlayDirective);
reusableApp.directive("mainFooter", mainFooterDirective);
reusableApp.directive("mainNavBar", mainNavBarDirective);
reusableApp.directive("moveToNext", moveToNextDirective);
reusableApp.directive("multiSelect", multiSelectDirective);
reusableApp.directive("omnibar", omnibarPointerDirective);
reusableApp.directive("password", passwordDirective);
reusableApp.directive("paginationNav", paginationNavDirective);
reusableApp.directive(
  "sectionImageBackground",
  sectionImageBackgroundDirective
);
reusableApp.directive("selectGames", selectGamesService);
reusableApp.directive(
  "senseiProfilePreviewModal",
  senseiProfilePreviewModalDirective
);
reusableApp.directive("senseiReviewModal", senseiReviewModalDirective);
reusableApp.directive("tokenBundleTier", tokenBundleTierDirective);
reusableApp.directive("trapFocus", trapFocusDirective);
reusableApp.directive("verifyEmail", verifyEmailDirective);
reusableApp
  .provider(`${tooltipDirectiveName}Conf`, tooltipConfigurationProvider)
  .directive(tooltipDirectiveName, tooltipDirective);
reusableApp.directive("userCombinedName", userCombinedNameDirective);

// Filter Setup
import capitalizeFilter from "../filters/capitalize";
import orderReviewsFilter from "../filters/order-review";
import paginationFilter from "../filters/pagination";
import sessionIndexFilter from "../filters/session-index";
import toHtmlFilter from "../filters/to-html";
import toArrayFilter from "../filters/to-array";

reusableApp.filter("capitalize", capitalizeFilter);
reusableApp.filter("orderReviews", orderReviewsFilter);
reusableApp.filter("pagination", paginationFilter);
reusableApp.filter("sessionIndex", sessionIndexFilter);
reusableApp.filter("toHtml", toHtmlFilter);
reusableApp.filter("toArray", toArrayFilter);

// App Setup
reusableApp.config(configReusable).run(runMetaService);

function configReusable($ariaProvider) {
  $ariaProvider.config({
    bindRoleForClick: false,
    tabindex: false,
  });
}
configReusable.$inject = ["$ariaProvider"];

function runMetaService(MetaTagsService) {
  var ogTitle = document.querySelector('meta[property="og:title"]');
  var twiTitle = document.querySelector('meta[name="twitter:title"]');
  var ogDesc = document.querySelector('meta[property="og:description"]');
  var twiDesc = document.querySelector('meta[name="twitter:description"]');

  ogTitle.parentNode.removeChild(ogTitle);
  twiTitle.parentNode.removeChild(twiTitle);
  ogDesc.parentNode.removeChild(ogDesc);
  twiDesc.parentNode.removeChild(twiDesc);

  MetaTagsService.setDefaultTags({
    // General SEO
    author: "Gamer Sensei",
    // Indexing / Spiders
    googlebot: "all",
    bingbot: "all",
    robots: "all",
    // OpenGraph
    "og:type": "product",
    // Twitter
    "twitter:site": "@GamerSenseiApp",
    "twitter:card": "summary_large_image",
  });
}
runMetaService.$inject = ["MetaTagsService"];

export default reusableApp;
