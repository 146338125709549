export default function CurrencyPayoutService($http) {
  const service = {
    payout: currencyPayout,
  };

  return service;

  ///////////

  function currencyPayout(data) {
    return $http.post("/api/web/sensei_currency_payout_requests", data);
  }
}

CurrencyPayoutService.$inject = ["$http"];
