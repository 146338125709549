export default function abExperiencesService($http) {

  var service = {
    get: getExperience
  };

  return service;

  ///////////

  function getExperience(config) {
    return $http.get('/api/web/ab_experiences', config);
  }
}

abExperiencesService.$inject = ['$http'];