export default function resizeService($window, $rootScope) {
  const service = {
    currentWidth: $window.innerWidth,
    setCurrentWidth: setCurrentWidth,
    getCurrentWidth: getCurrentWidth,
  };

  function setCurrentWidth(width) {
    this.currentWidth = width;
    $rootScope.$broadcast("width:updated", width);
  }
  function getCurrentWidth() {
    return this.currentWidth;
  }

  return service;
}
resizeService.$inject = ["$window", "$rootScope"];
