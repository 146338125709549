import moment from "moment-timezone";

export default class LessonsProposeTimeController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $location,
    $state,
    AvailabilityPropose,
    EventTrackingManager,
    DirectTrainingRequestSchedule,
    SenseiTrainingRequestManager,
    OmnibarPointer
  ) {
    this.previousLink = $rootScope.proposeTimeSubsequentStepLink;
    this.closetLessonStart = $scope.closestLessonStartDeltaMinutes;
    this.times = $scope.times;
    this.role = this.previousLink.includes("sensei") ? "sensei" : "gamer";
    // angular providers
    this.state = $state;
    this.timeout = $timeout;
    this.root = $rootScope;
    this.location = $location;
    // services
    this.propose = AvailabilityPropose;
    this.omnibarPointer = OmnibarPointer;
    this.strManager = SenseiTrainingRequestManager;
    this.dtrSchedule = DirectTrainingRequestSchedule;
    this.eventTracking = EventTrackingManager;
    // dates
    this.today = moment();

    // Time settings
    moment.fn.roundNext30Min = function () {
      var intervals = Math.floor(this.minutes() / 30);
      if (this.minutes() % 30 != 0) {
        intervals++;
      }
      if (intervals == 2) {
        this.add(1, "hours");
        intervals = 0;
      }
      this.minutes(intervals * 30);
      this.seconds(0);
      return this;
    };

    this.initMomentSettings();
    this.initProposeToPerson();
    this.initController();
  }

  // init methods
  initMomentSettings() {
    const nearestTime = moment()
      .roundNext30Min()
      .add(this.closetLessonStart, "minutes");

    this.upcomingProposalTime = [
      moment(nearestTime).format("hh:mm A"),
      moment(nearestTime).format("HH:mm"),
    ];

    this.dateSet = this.today.format("MMMM D, YYYY");
    this.timeZoneZ = moment.tz(moment.tz.guess()).format("z");
  }
  initController() {
    if (this.search) {
      this.timezoneFromBooking = moment
        .tz(
          moment(this.search.date),
          "yyyy-MM-ddTHH:mm:ss.sssZ",
          moment.tz.guess()
        )
        .format("z");
    }

    this.root.$broadcast("pickerUpdate", "proposal-date-pick", {
      minDate: this.today,
    });

    this.proposeToPerson.selectedTime = this.upcomingProposalTime;

    this.timeout(() => {
      this.timeCheck(this.proposeToPerson.chooseDate);
      this.checkBookableHours();
    });
  }

  initProposeToPerson() {
    const chosenSession = this.root.chosenSession;
    this.proposeToPerson = {
      contextual_object_id: chosenSession.requestScheduleId,
      full_name: chosenSession.otherParty.fullName,
      screen_name: chosenSession.otherParty.screenName,
      slug: chosenSession.otherParty.slug,
      game_name: chosenSession.gameName,
      square_profile_photo_url: chosenSession.otherParty.profilePhoto,
      chooseDate: moment(chosenSession.date),
    };
  }

  // action methods
  processProposedTime() {
    const data = {
      proposed_schedule: {
        proposed_date: moment(this.proposeToPerson.chooseDate).format(
          "YYYY-MM-DD"
        ),
        proposed_hour: this.proposeToPerson.selectedTime[1],
        proposed_time_zone: moment.tz.guess(),
      },
    };

    let proposeLesson;
    if (this.role === "sensei") {
      proposeLesson = this.dtrSchedule.senseiPropose;
    } else {
      proposeLesson = this.dtrSchedule.gamerPropose;
    }

    proposeLesson(this.proposeToPerson.contextual_object_id, data)
      .then((res) => {
        this.eventTracking.pushLessonReschedule(
          data.proposed_schedule,
          res.data.sensei_training_request
        );

        this.strManager.addOrUpdateToActionable(
          res.data.sensei_training_request
        );

        this.proceedToClosingModal();
        this.omnibarPointer.resetOmnibar();
      })
      .catch((err) => {
        this.proceedToClosingModal();
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  timeHasChanged() {
    this.timeNotSaved = true;
  }

  changeProposalDate(model, date) {
    this.timeCheck(date);
    this.timeHasChanged();
    angular.forEach(this.times, (time, id) => {
      this.addTZLabel(time[0]);
    });
  }

  proceedToClosingModal() {
    this.timeNotSaved = false;
    this.closeModal();
  }

  dontCloseModal() {
    this.timeNotSaved = false;
    this.noSaveModal = false;
  }

  closeModal() {
    if (this.timeNotSaved) {
      this.noSaveModal = true;
    } else {
      angular.element(document.querySelector("html")).removeClass("open-modal");
      this.location.path(this.previousLink);
    }
  }

  // display methods
  addTZLabel(time) {
    return time + " " + this.timeZoneZ;
  }

  // internal methods
  timeCheck(date) {
    if (typeof date === "string") {
      date = moment(date);
    }

    if (moment().isSame(date, "day")) {
      angular
        .element(document.querySelector(".time-pick"))
        .children("option")
        .each(() => {
          if (
            moment(this.value, "HH:mm").isSame(
              moment(this.upcomingProposalTime[1], "HH:mm")
            )
          ) {
            if (!this.selected) this.selected = true;
            return false;
          } else {
            this.disabled = true;
          }
        });
    } else {
      angular
        .element(document.querySelector(".time-pick"))
        .children("option")
        .prop("disabled", false);
    }
  }

  checkBookableHours() {
    if (this.state.current.name === "gamer_dashboard_booking.propose_time") {
      let proposedDate;

      if (typeof this.search.date === "string")
        // For testing deprecation purpose
        proposedDate = moment(this.search.date).format("YYYY-MM-DD");
      else
        proposedDate = moment(
          this.search.date,
          "yyyy-MM-ddTHH:mm:ss.sssZ"
        ).format("YYYY-MM-DD");

      const params = {
        params: {
          user_id: $scope.chosenSensei.user_id,
          duration_hours: $scope.params.sessionDuration,
          proposed_date: proposedDate,
          proposed_hour: $scope.timeParams[0][1],
          proposed_time_zone: this.search.timeZone,
        },
      };

      this.propose
        .getSenseiAvailability(params)
        .then((results) => {
          this.search.bookable_hours = results.data.bookable_hours;
        })
        .catch(angular.noop);
    }
  }
}

LessonsProposeTimeController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$location",
  "$state",
  "AvailabilityPropose",
  "EventTrackingManager",
  "DirectTrainingRequestSchedule",
  "SenseiTrainingRequestManager",
  "OmnibarPointer",
];
