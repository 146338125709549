import moment from "moment-timezone";
import { getSortName } from "../../config/utils/utilsConfig";

export default function senseiService($q, $http) {
  const textSearchEndpoint = "/api/web/sensei_text_search_result_displays";
  const senseiSearchEndpoint = "/api/web/sensei_search_results";
  const senseiFilterEndpoint = "/api/web/sensei_search_filters";

  const service = {
    loading: false,
    config: {},
    availableFilters: {},
    sensei: {},
    search: {
      filters: {
        proposed_time_zone: moment.tz.guess(),
      },
      results: [],
    },
    getSensei,
    setSensei,
    getCurrentSearchFilters,
    setCurrentSearchFilters,
    clearAllFilters,
    setAvailableFilters,
    doFiltersExist,
    getSearchResults,
    setSearchResults,
  };

  return service;

  // Service functions
  function getSensei() {
    return service.sensei;
  }

  function setSensei(ids) {
    service.loading = true;
    const idsToGet = Array.isArray(ids) ? [...ids] : [ids];
    const senseiIds = { "sensei_ids[]": idsToGet };

    return $http
      .get(textSearchEndpoint, { params: senseiIds })
      .then((res) => {
        service.loading = false;
        service.sensei = res.data.sensei_search_results[0];
      })
      .catch((err) => {
        service.loading = false;
      });
  }

  function getCurrentSearchFilters() {
    return service.search.filters;
  }

  function setCurrentSearchFilters(config, callbacks) {
    const objArr = Object.entries(config);

    objArr.forEach((obj) => {
      service.search.filters[obj[0]] = obj[1];
    });

    if (callbacks?.search) callbacks.search();
  }

  function clearAllFilters(search, gameId, callbacks) {
    service.search.filters = {
      game_id: gameId,
      proposed_time_zone: moment.tz.guess(),
    };

    const newPlatforms = service.availableFilters.platforms.map((platform) => {
      return {
        ...platform,
        selected: false,
      };
    });

    const newLanguages = service.availableFilters.languages.map((language) => {
      return {
        ...language,
        selected: false,
      };
    });

    service.availableFilters = {
      ...service.availableFilters,
      platforms: newPlatforms,
      languages: newLanguages,
    };

    if (search) service.setSearchResults();
    if (callbacks.resetMultiSelects) callbacks.resetMultiSelects();
  }

  function setAvailableFilters(callbacks) {
    $http
      .get(senseiFilterEndpoint)
      .then((res) => {
        const platforms = res.data.platforms;
        const sortBy = res.data.sort_by;

        service.availableFilters = {
          ...res.data,
          platforms: platforms.map((platform) => {
            return { name: platform };
          }),
          sort_by: sortBy.map((sortType) => {
            return {
              name: getSortName(sortType),
              slug: sortType,
            };
          }),
        };

        if (callbacks?.priceConfig) callbacks.priceConfig(res.data.prices);
        if (callbacks?.dateConfig) callbacks.dateConfig();
      })
      .catch(angular.noop);
  }

  function doFiltersExist() {
    const filters = service.availableFilters;

    return filters && typeof filters === "object";
  }

  function getSearchResults() {
    return service.search.results;
  }

  function setSearchResults() {
    const deferred = $q.defer();
    service.loading = true;

    const searchParams = {
      ...service.search.filters,
      min_price: service.search.filters.min_price || 0,
      max_price: service.search.filters.max_price || 50000,
    };

    $http
      .get(senseiSearchEndpoint, { params: searchParams })
      .then((res) => {
        service.loading = false;
        service.search.results = res.data.sensei_search_results;

        deferred.resolve(res.data.sensei_search_results);
      })
      .catch((err) => {
        service.loading = false;
        deferred.reject(err);
      });

    return deferred.promise;
  }
}

senseiService.$inject = ["$q", "$http"];
