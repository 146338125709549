const communicationServiceParams = (services) => {
  const initialValue = {};

  return services.reduce(function (obj, item) {
    return {
      ...obj,
      [item.medium]: { ...item },
    };
  }, initialValue);
};

export default communicationServiceParams;
