import paymentApp from "./gs-payment";
import reusableApp from "./gs-reusable";

const coachProductProfileApp = angular.module("gamerSenseiCoachProfilePages", [
  "ngResource",
  "ui.router.compat",
  reusableApp.name,
  paymentApp.name,
  "angular-stripe",
  "angulartics",
  "angulartics.google.analytics",
  "angulartics.google.tagmanager",
  "star-rating",
  "braintree-angular",
  "ngMessages",
  "ngMaterial",
  "monospaced.elastic",
  "duScroll",
  "ui.calendar",
]);

// Directive setup
import senseiReviewsDirective from "../directives/sensei/sensei-reviews-directive";

coachProductProfileApp.directive("senseiReviews", senseiReviewsDirective);

export default coachProductProfileApp;
