const tooltipDirectiveName = "tooltips";

class ResizeObserver {
  constructor($timeout) {
    this.timeout = $timeout;
    this.callbacks = [];
    this.lastTime = 0;
    this.resizeTimeout = null;

    this.resize = this.resize.bind(this);
  }

  resize() {
    window.clearTimeout(this.resizeTimeout);
    this.resizeTimeout = window.setTimeout(() => {
      window.requestAnimationFrame((frame) => {
        if (frame - this.lastTime >= 15) {
          this.callbacks.forEach((callback) => callback());
          this.lastTime = frame;
        }
      });
    }, 500);
  }

  add(callback) {
    if (!this.callbacks.length) {
      window.addEventListener("resize", this.resize);
    }
    if (callback) {
      this.callbacks.push(callback);
    }
  }

  remove(callback) {
    const index = this.callbacks.indexOf(callback);
    if (index !== -1) {
      this.callbacks.splice(index, 1);
    }
    if (!this.callbacks.length) {
      window.clearTimeout(this.resizeTimeout);
      window.removeEventListener("resize", this.resize);
    }
  }
}
ResizeObserver.$inject = ["$timeout"];

const resizeObserver = new ResizeObserver();

const getAttributesToAdd = (element) => {
  const attributesToAdd = {};

  element.removeAttr(tooltipDirectiveName);
  if (element.attr("tooltip-template") !== undefined) {
    attributesToAdd["tooltip-template"] = element.attr("tooltip-template");
    element.removeAttr("tooltip-template");
  }

  if (element.attr("tooltip-template-url") !== undefined) {
    attributesToAdd["tooltip-template-url"] = element.attr(
      "tooltip-template-url"
    );
    element.removeAttr("tooltip-template-url");
  }

  if (element.attr("tooltip-template-url-cache") !== undefined) {
    attributesToAdd["tooltip-template-url-cache"] = element.attr(
      "tooltip-template-url-cache"
    );
    element.removeAttr("tooltip-template-url-cache");
  }

  if (element.attr("tooltip-controller") !== undefined) {
    attributesToAdd["tooltip-controller"] = element.attr("tooltip-controller");
    element.removeAttr("tooltip-controller");
  }

  if (element.attr("tooltip-side") !== undefined) {
    attributesToAdd["tooltip-side"] = element.attr("tooltip-side");
    element.removeAttr("tooltip-side");
  }

  if (element.attr("tooltip-show-trigger") !== undefined) {
    attributesToAdd["tooltip-show-trigger"] = element.attr(
      "tooltip-show-trigger"
    );
    element.removeAttr("tooltip-show-trigger");
  }

  if (element.attr("tooltip-hide-trigger") !== undefined) {
    attributesToAdd["tooltip-hide-trigger"] = element.attr(
      "tooltip-hide-trigger"
    );
    element.removeAttr("tooltip-hide-trigger");
  }

  if (element.attr("tooltip-smart") !== undefined) {
    attributesToAdd["tooltip-smart"] = element.attr("tooltip-smart");
    element.removeAttr("tooltip-smart");
  }

  if (element.attr("tooltip-class") !== undefined) {
    attributesToAdd["tooltip-class"] = element.attr("tooltip-class");
    element.removeAttr("tooltip-class");
  }

  if (element.attr("tooltip-show") !== undefined) {
    attributesToAdd["tooltip-show"] = element.attr("tooltip-show");
    element.removeAttr("tooltip-show");
  }

  if (element.attr("tooltip-close-button") !== undefined) {
    attributesToAdd["tooltip-close-button"] = element.attr(
      "tooltip-close-button"
    );
    element.removeAttr("tooltip-close-button");
  }

  if (element.attr("tooltip-size") !== undefined) {
    attributesToAdd["tooltip-size"] = element.attr("tooltip-size");
    element.removeAttr("tooltip-size");
  }

  if (element.attr("tooltip-speed") !== undefined) {
    attributesToAdd["tooltip-speed"] = element.attr("tooltip-speed");
    element.removeAttr("tooltip-speed");
  }

  return attributesToAdd;
};

const getStyle = (anElement) => {
  if (window.getComputedStyle) {
    return window.getComputedStyle(anElement, "");
  } else if (anElement.currentStyle) {
    return anElement.currentStyle;
  }
};

const getAppendedTip = (theTooltipElement) => {
  const tipsInBody = window.document.querySelectorAll("._exradicated-tooltip");
  const tipsInBodyLength = tipsInBody.length;
  let angularizedElement;
  let aTipInBody;

  for (
    let tipsInBodyIndex = 0;
    tipsInBodyIndex < tipsInBodyLength;
    tipsInBodyIndex += 1
  ) {
    aTipInBody = tipsInBody.item(tipsInBodyIndex);
    if (aTipInBody) {
      angularizedElement = angular.element(aTipInBody);
      if (
        angularizedElement.data("_tooltip-parent") &&
        angularizedElement.data("_tooltip-parent") === theTooltipElement
      ) {
        return angularizedElement;
      }
    }
  }
};

const removeAppendedTip = (theTooltipElement) => {
  let tipElement = getAppendedTip(theTooltipElement);

  if (tipElement) {
    tipElement.remove();
  }
};

const isOutOfPage = (theTipElement) => {
  if (theTipElement) {
    const squarePosition = theTipElement[0].getBoundingClientRect();

    if (
      squarePosition.top < 0 ||
      squarePosition.top > window.document.body.offsetHeight ||
      squarePosition.left < 0 ||
      squarePosition.left > window.document.body.offsetWidth ||
      squarePosition.bottom < 0 ||
      squarePosition.bottom > window.document.body.offsetHeight ||
      squarePosition.right < 0 ||
      squarePosition.right > window.document.body.offsetWidth
    ) {
      theTipElement.css({
        top: "",
        left: "",
        bottom: "",
        right: "",
      });
      return true;
    }

    return false;
  }

  throw new Error("You must provide a position");
};

const getSideClasses = (sides) => {
  return sides
    .split(" ")
    .map((side) => {
      return "_" + side;
    })
    .join(" ");
};

const directions = [
  "_top",
  "_top _left",
  "_left",
  "_bottom _left",
  "_bottom",
  "_bottom _right",
  "_right",
  "_top _right",
];

const smartPosition = (tipElement, tooltipElement, startSide) => {
  const directionsLength = directions.length;
  let directionsIndex = directions.indexOf(getSideClasses(startSide));

  for (
    let directionsCount = 0;
    directionsCount < directionsLength && isOutOfPage(tipElement);
    directionsCount += 1
  ) {
    directionsIndex += 1;
    if (directionsIndex >= directions.length) {
      directionsIndex = 0;
    }
    tooltipElement.removeClass("_top _left _bottom _right");
    tooltipElement.addClass(directions[directionsIndex]);
  }
};

function tooltipConfigurationProvider() {
  const tooltipConfiguration = {
    side: "top",
    showTrigger: "mouseenter",
    hideTrigger: "mouseleave",
    class: "",
    smart: false,
    closeButton: false,
    size: "",
    speed: "steady",
    tooltipTemplateUrlCache: false,
    show: null,
  };

  return {
    configure: (configuration) => {
      const configurationKeys = Object.keys(tooltipConfiguration);
      let aConfigurationKey;

      if (configuration) {
        for (
          let configurationIndex = 0;
          configurationIndex < configurationKeys.length;
          configurationIndex += 1
        ) {
          aConfigurationKey = configurationKeys[configurationIndex];
          if (aConfigurationKey && configuration[aConfigurationKey]) {
            tooltipConfiguration[aConfigurationKey] =
              configuration[aConfigurationKey];
          }
        }
      }
    },
    $get: () => {
      return tooltipConfiguration;
    },
  };
}

const tooltipDirective = (
  $q,
  $http,
  $templateCache,
  $compile,
  $timeout,
  $controller,
  $log,
  tooltipsConf
) => {
  return {
    restrict: "A",
    transclude: "element",
    priority: 1,
    terminal: true,
    link,
  };

  function link(scope, $element, attrs, controller, transcludeFn) {
    if (attrs.tooltipTemplate && attrs.tooltipTemplateUrl) {
      throw new Error(
        "You can not define tooltip-template and tooltip-template-url together"
      );
    }

    if (
      !(attrs.tooltipTemplateUrl || attrs.tooltipTemplate) &&
      attrs.tooltipController
    ) {
      throw new Error(
        "You can not have a controller without a template or templateUrl defined"
      );
    }

    let oldTooltipSide = getSideClasses(tooltipsConf.side);
    let oldTooltipShowTrigger = tooltipsConf.showTrigger;
    let oldTooltipHideTrigger = tooltipsConf.hideTrigger;
    let oldTooltipClass;
    let oldSize = tooltipsConf.size;
    let oldSpeed = "_" + tooltipsConf.speed;

    attrs.tooltipSide = attrs.tooltipSide || tooltipsConf.side;
    attrs.tooltipShowTrigger =
      attrs.tooltipShowTrigger || tooltipsConf.showTrigger;
    attrs.tooltipHideTrigger =
      attrs.tooltipHideTrigger || tooltipsConf.hideTrigger;
    attrs.tooltipShow = attrs.tooltipShow || tooltipsConf.show;
    attrs.tooltipClass = attrs.tooltipClass || tooltipsConf.class;
    attrs.tooltipSmart = attrs.tooltipSmart === "true" || tooltipsConf.smart;
    attrs.tooltipCloseButton =
      attrs.tooltipCloseButton || tooltipsConf.closeButton.toString();
    attrs.tooltipSize = attrs.tooltipSize || tooltipsConf.size;
    attrs.tooltipSpeed = attrs.tooltipSpeed || tooltipsConf.speed;
    attrs.tooltipAppendToBody = attrs.tooltipAppendToBody === "true";

    transcludeFn(scope, (element, scope) => {
      const attributes = getAttributesToAdd(element);
      const tooltipElement = angular.element(
        window.document.createElement("tooltip")
      );
      const tipContElement = angular.element(
        window.document.createElement("tip-cont")
      );
      const tipElement = angular.element(window.document.createElement("tip"));
      const tipTipElement = angular.element(
        window.document.createElement("tip-tip")
      );
      const closeButtonElement = angular.element(
        window.document.createElement("span")
      );
      const tipArrowElement = angular.element(
        window.document.createElement("tip-arrow")
      );

      const whenActivateMultilineCalculation = () => {
        return tipContElement.html();
      };
      const calculateIfMultiLine = (newValue) => {
        if (
          newValue !== undefined &&
          tipContElement[0].getClientRects().length > 1
        ) {
          tooltipElement.addClass("_multiline");
        } else {
          tooltipElement.removeClass("_multiline");
        }
      };
      const onTooltipShow = (event) => {
        if (event && !tooltipElement.hasClass("active")) {
          event.stopImmediatePropagation();
        }

        tipElement.addClass("_hidden");
        if (attrs.tooltipSmart) {
          switch (attrs.tooltipSide) {
            case "top":
            case "left":
            case "bottom":
            case "right":
            case "top left":
            case "top right":
            case "bottom left":
            case "bottom right": {
              smartPosition(tipElement, tooltipElement, attrs.tooltipSide);
              break;
            }

            default: {
              throw new Error("Position not supported");
            }
          }
        }

        if (attrs.tooltipAppendToBody) {
          const tipTipElementStyle = getStyle(tipTipElement[0]);
          const tipArrowElementStyle = getStyle(tipArrowElement[0]);
          const tipElementStyle = getStyle(tipElement[0]);
          const tipElementBoundingClientRect =
            tipElement[0].getBoundingClientRect();
          const exradicatedTipElement = angular.copy(tipElement);
          const tipTipStyleLength = tipTipElementStyle.length;
          const tipArrowStyleLength = tipArrowElementStyle.length;
          const tipStyleLength = tipElementStyle.length;
          const tipTipCssToSet = {};
          const tipCssToSet = {};
          const tipArrowCssToSet = {};
          let aStyleKey;
          let paddingTopValue;
          let paddingBottomValue;
          let paddingLeftValue;
          let paddingRightValue;

          tipElement.removeClass("_hidden");
          exradicatedTipElement.removeClass("_hidden");
          exradicatedTipElement.data("_tooltip-parent", tooltipElement);
          removeAppendedTip(tooltipElement);

          for (
            let tipTipStyleIndex = 0;
            tipTipStyleIndex < tipTipStyleLength;
            tipTipStyleIndex += 1
          ) {
            aStyleKey = tipTipElementStyle[tipTipStyleIndex];
            if (aStyleKey && tipTipElementStyle.getPropertyValue(aStyleKey)) {
              tipTipCssToSet[aStyleKey] =
                tipTipElementStyle.getPropertyValue(aStyleKey);
            }
          }

          for (
            let tipArrowStyleIndex = 0;
            tipArrowStyleIndex < tipArrowStyleLength;
            tipArrowStyleIndex += 1
          ) {
            aStyleKey = tipArrowElementStyle[tipArrowStyleIndex];
            if (aStyleKey && tipArrowElementStyle.getPropertyValue(aStyleKey)) {
              tipArrowCssToSet[aStyleKey] =
                tipArrowElementStyle.getPropertyValue(aStyleKey);
            }
          }

          for (
            let tipStyleIndex = 0;
            tipStyleIndex < tipStyleLength;
            tipStyleIndex += 1
          ) {
            aStyleKey = tipElementStyle[tipStyleIndex];
            if (
              aStyleKey &&
              aStyleKey !== "position" &&
              aStyleKey !== "display" &&
              aStyleKey !== "opacity" &&
              aStyleKey !== "z-index" &&
              aStyleKey !== "bottom" &&
              aStyleKey !== "height" &&
              aStyleKey !== "left" &&
              aStyleKey !== "right" &&
              aStyleKey !== "top" &&
              aStyleKey !== "width" &&
              tipElementStyle.getPropertyValue(aStyleKey)
            ) {
              tipCssToSet[aStyleKey] =
                tipElementStyle.getPropertyValue(aStyleKey);
            }
          }

          paddingTopValue = window.parseInt(
            tipElementStyle.getPropertyValue("padding-top"),
            10
          );
          paddingBottomValue = window.parseInt(
            tipElementStyle.getPropertyValue("padding-bottom"),
            10
          );
          paddingLeftValue = window.parseInt(
            tipElementStyle.getPropertyValue("padding-left"),
            10
          );
          paddingRightValue = window.parseInt(
            tipElementStyle.getPropertyValue("padding-right"),
            10
          );

          tipCssToSet.top =
            tipElementBoundingClientRect.top + window.pageYOffset + "px";
          tipCssToSet.left =
            tipElementBoundingClientRect.left + window.pageXOffset + "px";
          tipCssToSet.height =
            tipElementBoundingClientRect.height -
            (paddingTopValue + paddingBottomValue) +
            "px";
          tipCssToSet.width =
            tipElementBoundingClientRect.width -
            (paddingLeftValue + paddingRightValue) +
            "px";

          exradicatedTipElement.css(tipCssToSet);

          exradicatedTipElement.children().css(tipTipCssToSet);
          exradicatedTipElement.children().next().css(tipArrowCssToSet);
          if (event && attrs.tooltipHidden !== "true") {
            exradicatedTipElement.addClass("_exradicated-tooltip");
            angular.element(window.document.body).append(exradicatedTipElement);
          }
        } else {
          tipElement.removeClass("_hidden");
          if (event && attrs.tooltipHidden !== "true") {
            tooltipElement.addClass("active");
          }
        }
      };

      const onTooltipHide = (event) => {
        if (event && tooltipElement.hasClass("active")) {
          event.stopImmediatePropagation();
        }

        if (attrs.tooltipAppendToBody) {
          removeAppendedTip(tooltipElement);
        } else {
          tooltipElement.removeClass("active");
        }
      };

      const registerOnScrollFrom = function (theElement) {
        const parentElement = theElement.parent();
        let timer;

        if (
          theElement[0] &&
          (theElement[0].scrollHeight > theElement[0].clientHeight ||
            theElement[0].scrollWidth > theElement[0].clientWidth)
        ) {
          theElement.on("scroll", function () {
            const that = this;

            if (timer) {
              $timeout.cancel(timer);
            }

            timer = $timeout(function () {
              const theTipElement = getAppendedTip(tooltipElement);
              const tooltipBoundingRect =
                tooltipElement[0].getBoundingClientRect();
              const thatBoundingRect = that.getBoundingClientRect();

              if (
                tooltipBoundingRect.top < thatBoundingRect.top ||
                tooltipBoundingRect.bottom > thatBoundingRect.bottom ||
                tooltipBoundingRect.left < thatBoundingRect.left ||
                tooltipBoundingRect.right > thatBoundingRect.right
              ) {
                removeAppendedTip(tooltipElement);
              } else if (theTipElement) {
                onTooltipShow(true);
              }
            });
          });
        }

        if (parentElement && parentElement.length) {
          registerOnScrollFrom(parentElement);
        }
      };

      const showTemplate = (template) => {
        tooltipElement.removeClass("_force-hidden");
        tipTipElement.empty();
        tipTipElement.append(closeButtonElement);

        // Check if template is an AngularJS template (You might need a better check here)
        if (template && template.indexOf("ng-") !== -1) {
          const compiledTemplate = $compile(template)(scope);
          tipTipElement.append(compiledTemplate);
        } else {
          // If it's just a string
          tipTipElement.append(template);
        }

        $timeout(() => {
          onTooltipShow();
        });
      };

      const hideTemplate = () => {
        //hide tooltip because is empty
        tipTipElement.empty();
        tooltipElement.addClass("_force-hidden"); //force to be hidden if empty
      };

      const getTemplate = (tooltipTemplateUrl) => {
        const template = $templateCache.get(tooltipTemplateUrl);

        if (typeof template !== "undefined") {
          // Wrap template in a Promise so that getTemplate always returns a Promise
          return $q.resolve(template);
        }

        // How should failing to load the template be handled?
        return $http.get(tooltipTemplateUrl).then((response) => {
          $templateCache.put(tooltipTemplateUrl, response.data);

          return response.data;
        });
      };

      const onTooltipTemplateChange = (newValue) => {
        if (newValue) {
          showTemplate(newValue);
        } else {
          hideTemplate();
        }
      };

      const onTooltipTemplateUrlChange = (newValue) => {
        if (newValue && !attrs.tooltipTemplateUrlCache) {
          getTemplate(newValue)
            .then((template) => {
              showTemplate($compile(template)(scope));
            })
            .catch((reason) => {
              $log.error(reason);
            });
        } else {
          hideTemplate();
        }
      };

      const onTooltipTemplateUrlCacheChange = (newValue) => {
        if (newValue && attrs.tooltipTemplateUrl) {
          getTemplate(attrs.tooltipTemplateUrl)
            .then((template) => {
              showTemplate($compile(template)(scope));
            })
            .catch((reason) => {
              $log.error(reason);
            });
        } else {
          hideTemplate();
        }
      };

      const onTooltipSideChange = (newValue) => {
        if (newValue) {
          if (oldTooltipSide) {
            tooltipElement.removeClass(oldTooltipSide);
          }
          tooltipElement.addClass(getSideClasses(newValue));
          oldTooltipSide = newValue;
        }
      };

      const onTooltipShowTrigger = (newValue) => {
        if (newValue) {
          if (oldTooltipShowTrigger) {
            tooltipElement.off(oldTooltipShowTrigger);
          }
          tooltipElement.on(newValue, onTooltipShow);
          oldTooltipShowTrigger = newValue;
        }
      };

      const onTooltipHideTrigger = (newValue) => {
        if (newValue) {
          if (oldTooltipHideTrigger) {
            tooltipElement.off(oldTooltipHideTrigger);
          }
          tooltipElement.on(newValue, onTooltipHide);
          oldTooltipHideTrigger = newValue;
        }
      };

      const onTooltipShowTooltip = (newValue) => {
        if (newValue === "true") {
          tooltipElement.addClass("active");
        } else {
          tooltipElement.removeClass("active");
        }
      };

      const onTooltipClassChange = (newValue) => {
        if (newValue) {
          if (oldTooltipClass) {
            tipElement.removeClass(oldTooltipClass);
          }
          tipElement.addClass(newValue);
          oldTooltipClass = newValue;
        }
      };

      const onTooltipSmartChange = () => {
        if (typeof attrs.tooltipSmart !== "boolean") {
          attrs.tooltipSmart = attrs.tooltipSmart === "true";
        }
      };

      const onTooltipCloseButtonChange = (newValue) => {
        const enableButton = newValue === "true";

        if (enableButton) {
          closeButtonElement.on("click", onTooltipHide);
          closeButtonElement.css("display", "block");
        } else {
          closeButtonElement.off("click");
          closeButtonElement.css("display", "none");
        }
      };

      const onTooltipTemplateControllerChange = (newValue) => {
        if (newValue) {
          const tipController = $controller(newValue, {
            $scope: scope,
          });
          const newScope = scope.$new(false, scope);
          const indexOfAs = newValue.indexOf("as");
          let controllerName;

          if (indexOfAs >= 0) {
            controllerName = newValue.substr(indexOfAs + 3);
            newScope[controllerName] = tipController;
          } else {
            angular.extend(newScope, tipController);
          }

          tipTipElement.replaceWith($compile(tipTipElement)(newScope));
          /*eslint-disable no-use-before-define*/
          unregisterOnTooltipControllerChange();
          /*eslint-enable no-use-before-define*/
        }
      };

      const onTooltipSizeChange = (newValue) => {
        if (newValue) {
          if (oldSize) {
            tipTipElement.removeClass("_" + oldSize);
          }
          tipTipElement.addClass("_" + newValue);
          oldSize = newValue;
        }
      };

      const onTooltipSpeedChange = (newValue) => {
        if (newValue) {
          if (oldSpeed) {
            tooltipElement.removeClass("_" + oldSpeed);
          }
          tooltipElement.addClass("_" + newValue);
          oldSpeed = newValue;
        }
      };

      const onResize = () => {
        registerOnScrollFrom(tooltipElement);
      };

      const registerResize = () => {
        calculateIfMultiLine();
        onTooltipShow();
      };

      const unregisterOnTooltipTemplateChange = attrs.$observe(
        "tooltipTemplate",
        onTooltipTemplateChange
      );

      const unregisterOnTooltipTemplateUrlChange = attrs.$observe(
        "tooltipTemplateUrl",
        onTooltipTemplateUrlChange
      );

      const unregisterOnTooltipTemplateUrlCacheChange = attrs.$observe(
        "tooltipTemplateUrlCache",
        onTooltipTemplateUrlCacheChange
      );

      const unregisterOnTooltipSideChangeObserver = attrs.$observe(
        "tooltipSide",
        onTooltipSideChange
      );

      const unregisterOnTooltipShowTrigger = attrs.$observe(
        "tooltipShowTrigger",
        onTooltipShowTrigger
      );

      const unregisterOnTooltipHideTrigger = attrs.$observe(
        "tooltipHideTrigger",
        onTooltipHideTrigger
      );

      const unregisterOnTooltipShowTooltip = attrs.$observe(
        "tooltipShow",
        onTooltipShowTooltip
      );

      const unregisterOnTooltipClassChange = attrs.$observe(
        "tooltipClass",
        onTooltipClassChange
      );

      const unregisterOnTooltipSmartChange = attrs.$observe(
        "tooltipSmart",
        onTooltipSmartChange
      );

      const unregisterOnTooltipCloseButtonChange = attrs.$observe(
        "tooltipCloseButton",
        onTooltipCloseButtonChange
      );

      const unregisterOnTooltipControllerChange = attrs.$observe(
        "tooltipController",
        onTooltipTemplateControllerChange
      );

      const unregisterOnTooltipSizeChange = attrs.$observe(
        "tooltipSize",
        onTooltipSizeChange
      );

      const unregisterOnTooltipSpeedChange = attrs.$observe(
        "tooltipSpeed",
        onTooltipSpeedChange
      );

      const unregisterTipContentChangeWatcher = scope.$watch(
        whenActivateMultilineCalculation,
        calculateIfMultiLine
      );

      closeButtonElement.addClass("close-button");
      closeButtonElement.html("&times;");

      tipElement.addClass("_hidden");

      tipTipElement.append(closeButtonElement);
      tipTipElement.append(attrs.tooltipTemplate);

      tipElement.append(tipTipElement);
      tipElement.append(tipArrowElement);
      tipElement[0].setAttribute("role", "tooltip");

      tipContElement.append(element);

      tooltipElement.attr(attributes);
      tooltipElement.addClass("tooltips");

      tooltipElement.append(tipContElement);
      tooltipElement.append(tipElement);
      $element.after(tooltipElement);

      if (attrs.tooltipAppendToBody) {
        resizeObserver.add(onResize);
        registerOnScrollFrom(tooltipElement);
      }

      resizeObserver.add(registerResize);

      $timeout(() => {
        onTooltipShow();
        tipElement.removeClass("_hidden");
        tooltipElement.addClass("_ready");
      });

      // unregister event listeners
      scope.$on("$destroy", () => {
        unregisterOnTooltipTemplateChange();
        unregisterOnTooltipTemplateUrlChange();
        unregisterOnTooltipTemplateUrlCacheChange();
        unregisterOnTooltipSideChangeObserver();
        unregisterOnTooltipShowTrigger();
        unregisterOnTooltipHideTrigger();
        unregisterOnTooltipShowTooltip();
        unregisterOnTooltipClassChange();
        unregisterOnTooltipSmartChange();
        unregisterOnTooltipCloseButtonChange();
        unregisterOnTooltipSizeChange();
        unregisterOnTooltipSpeedChange();
        unregisterTipContentChangeWatcher();

        resizeObserver.remove(onResize);
        resizeObserver.remove(registerResize);

        element.off(attrs.tooltipShowTrigger + " " + attrs.tooltipHideTrigger);
      });
    });
  }
};

tooltipDirective.$inject = [
  "$q",
  "$http",
  "$templateCache",
  "$compile",
  "$timeout",
  "$controller",
  "$log",
  "tooltipsConf",
];

export { tooltipDirective, tooltipDirectiveName, tooltipConfigurationProvider };
