export default class GamesLandingAllController {
  constructor(
    $scope,
    $stateParams,
    $location,
    User,
    pulledGames,
    MetaTagsService,
    EventTrackingManager
  ) {
    this.$location = $location;
    this.$stateParams = $stateParams;
    this.userId = $scope.currentId;
    this.role = $scope.role;
    this.eventTracking = EventTrackingManager;
    this.userService = User;
    this.games = pulledGames.data.games;

    this.initUser();
    this.initMetaTags(MetaTagsService);
    angular.element(() => this.initEventTracking);
  }

  // init methods
  initEventTracking() {
    this.eventTracking.pushViewEvent("Buy Lessons - Game Select");
  }
  initMetaTags(MetaTagsService) {
    const metaTitle =
      "Games Available for Esports Coaching & Lessons | Gamer Sensei";
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initUser() {
    if (this.userId) {
      this.userService.setCurrentId(this.userId);
      this.userService.setRole(this.role);

      this.userService
        .getUser(true)
        .then((userData) => {
          this.currentUser = userData;
        })
        .catch(angular.noop);
    }
  }

  // general method
  formatUrl(url) {
    const refParam = this.$location.search() && this.$location.search().ref;
    const stateRef = this.$stateParams.slug;

    if (refParam) {
      return `${url}?ref=${refParam}`;
    } else if (stateRef) {
      return `${url}?ref=${stateRef}`;
    }

    return url;
  }

  // cta on game click
  gameSelectEvent(event, game) {
    if (event) event.preventDefault();

    this.eventTracking.pushEventObject({
      event: "gameSelection",
      gameName: game.name,
      gameLink: `/games/${game.seo_slug}`,
    });

    this.$location.url(this.formatUrl(`/games/${game.seo_slug}`));
  }
}

GamesLandingAllController.$inject = [
  "$scope",
  "$stateParams",
  "$location",
  "User",
  "pulledGames",
  "MetaTagsService",
  "EventTrackingManager",
];
