const getSelectedAttributesCategories = (attributeCategories) => {
  const selected = {};

  for (let i in attributeCategories) {
    const category = attributeCategories[i];
    const lowerCasedName = category.name.toLowerCase();

    selected[lowerCasedName] = [];

    for (let idx in category.attributes) {
      const attribute = category.attributes[idx];

      if (attribute.selected) selected[lowerCasedName].push(attribute.name);
    }
  }
  return selected;
};

export default getSelectedAttributesCategories;
