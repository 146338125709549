export default class GamerWalletSummaryController {
  constructor(User, TransactionHistories, OmnibarPointer) {
    this.role = "gamer";
    // services
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.transactionHistory = TransactionHistories;
    this.currentPage = 1;
    this.historyResults = {};
    this.historyTableHeader = {
      date: {
        title: "Date",
        isActive: true,
        isSortable: false,
      },
      description: {
        title: "Description",
        isActive: false,
        isSortable: false,
      },
      type: {
        title: "Type",
        isActive: false,
        isSortable: false,
      },
      status: {
        title: "Status",
        isActive: false,
        isSortable: false,
      },
      amount: {
        title: "Amount",
        isActive: false,
        isSortable: true,
      },
    };

    this.noResults = {
      header: "No purchase history",
      helper:
        '<p><a href="/games" target="_self">Click here</a> to purchase sessions<p>',
    };

    this.initUser({
      walletHistory: () => this.pullWalletHistory(),
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.gamer = this.currentUser.gamer;

        if (callbacks?.walletHistory) callbacks.walletHistory();
      })
      .catch((err) => this.handleErrors(err.data.errors));
  }

  // fetch methods
  pullWalletHistory() {
    const historyParams = {
      params: {
        account_id: this.gamer.gamer_currency_account.id,
        account_type: this.role,
        page: this.currentPage,
      },
    };

    this.transactionHistory
      .currencyHistory(historyParams)
      .then((res) => {
        this.pages = res.data.pages;
        this.historyResults = res.data.transactions;
      })
      .catch((err) => this.handleErrors(err.data.errors));
  }

  // display methods
  getMorePointsText() {
    if (this.isStoreValueAvailable) return "Get More Credits";
    return "Get More Points";
  }

  // internal methods
  handleErrors(message) {
    this.omnibarPointer.pushErrorByHash(message);
  }
}

GamerWalletSummaryController.$inject = [
  "User",
  "TransactionHistories",
  "OmnibarPointer",
];
