import giftCardHeaderPartial from "../../templates/includes/gift_cards/gift_card_header_partial";

export default class GiftCardContainerController {
  constructor(
    $rootScope,
    $scope,
    $state,
    activeGames,
    UIFeatureGate,
    GiftCardManager,
    User,
    MetaTagsService,
    EventTrackingManager,
    OmnibarPointer
  ) {
    this.userId = $scope.currentId;
    this.role = $scope.role;
    // angular providers
    this.$state = $state;
    // services
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.giftCard = GiftCardManager;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.giftCardHeaderPartial = giftCardHeaderPartial;
    this.activeGames = activeGames.data.games;

    if (
      UIFeatureGate.data.ui_feature_gates
        .final_balance_protection_measures_activated
    ) {
      window.location = "/404";
      return;
    }

    this.initGiftCard(UIFeatureGate);
    this.initUser({
      onSuccess: (userData) => {
        $rootScope.currentUser = userData;

        this.giftCard.purchaserName =
          userData.full_name ||
          userData.screen_name ||
          "a Friend at Gamer Sensei";
        this.giftCard.purchaserEmail = userData.email;
      },
    });
    this.initMetaTags(MetaTagsService);

    angular.element(() => {
      this.eventTracking.pushViewEvent("Gift Cards");
    });
  }

  // init methods
  initMetaTags(MetaTagsService) {
    const metaTitle =
      "Gift Cards for Esports Coaching & Lessons | Gamer Sensei";
    const metaDescription =
      "The world's premier esports coaching platform servicing your personal journey in competitive gaming.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initUser(callbacks) {
    if (this.userId) {
      this.userService
        .getUserByIdAndRole(this.userId, this.role)
        .then((userData) => {
          if (callbacks?.onSuccess) callbacks.onSuccess(userData);
        })
        .catch(angular.noop);
    }
  }
  initGiftCard(UIFeatureGate) {
    this.giftCard.setCardDesigns();

    this.giftCard.allowServiceFee =
      UIFeatureGate.data.ui_feature_gates.transaction_service_fee;

    this.giftCard.allowAmazonPay =
      UIFeatureGate.data.ui_feature_gates.amazon_pay;

    window.onbeforeunload = () => {
      if (this.warnAboutLeaving(this.$state.current.name)) {
        this.giftCard.abandonCartAnalytics();
        return "Navigating away will lose your progress!";
      }
    };
  }

  // state methods
  isConfirmStep() {
    return this.$state.current.name === "gift-cards.confirmation";
  }
  isCurrentStep(step) {
    return this.$state.current.name === step;
  }
  isAccountStep() {
    return this.$state.current.name === "gift-cards.account";
  }
  showWhenNecessary() {
    const confirmState = this.$state.current.name === "gift-cards.confirmation";
    const deliveryMethod = this.giftCard.deliveryMethod === "print";

    // hide if it's on the confirmation state and print is selected
    return !(confirmState && deliveryMethod);
  }

  // internal methods
  warnAboutLeaving(state) {
    const warnableStates = [
      "gift-cards.landing",
      "gift-cards.account",
      "gift-cards.payment",
    ];

    const warn = warnableStates.indexOf(state) > -1;

    return warn;
  }
}

GiftCardContainerController.$inject = [
  "$rootScope",
  "$scope",
  "$state",
  "activeGames",
  "UIFeatureGate",
  "GiftCardManager",
  "User",
  "MetaTagsService",
  "EventTrackingManager",
  "OmnibarPointer",
];
