import senseiProfilePreviewModalTemplate from "../../../templates/directives/popup/modal/sensei_profile_preview_modal_template";
import { loadingIcon } from "../../../config/icons/iconConfig";

export default function senseiProfilePreviewModalDirective(
  $location,
  $stateParams,
  Dashboard,
  OmnibarPointer
) {
  return {
    restrict: "E",
    scope: {
      senseiId: "=",
      open: "=",
    },
    link: link,
    template: senseiProfilePreviewModalTemplate,
  };

  function link(scope, elem, attrs) {
    scope.omnibarPointer = OmnibarPointer;
    scope.loadingIcon = loadingIcon;

    scope.closeModal = function () {
      scope.open = false;
      const html = document.documentElement;
      const scrollY = html.style.top;
      angular.element(document.querySelector("html")).removeClass("open-modal");
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
      angular.element(function () {
        scope.senseiPreview = {};
      });
    };

    scope.formatUrl = function (url) {
      var refParam = $location.search() && $location.search().ref;
      var stateRef = $stateParams.slug;

      if (refParam) {
        return `${url}?ref=${refParam}`;
      } else if (stateRef) {
        return `${url}?ref=${stateRef}`;
      }

      return url;
    };

    scope.$watch(
      "open",
      function (openUpdated, oldStatus) {
        if (openUpdated && !oldStatus) {
          scope.loading = true;
          const scrollY =
            document.documentElement.style.getPropertyValue("--scroll-y");
          const html = document.documentElement;
          angular
            .element(document.querySelector("html"))
            .addClass("open-modal");
          html.style.top = `-${scrollY}`;
          var params = {
            params: { "sensei_ids[]": [scope.senseiId] },
          };
          Dashboard.displaySenseiByIds(params)
            .then((res) => {
              scope.senseiPreview = res.data.sensei_search_results[0];
              scope.loading = false;
            })
            .catch((err) => {
              scope.loading = false;
              scope.closeModal();
              scope.omnibarPointer.pushErrorByMessage(err.data.errors);
            });
        }
      },
      true
    );

    window.addEventListener("scroll", () => {
      document.documentElement.style.setProperty(
        "--scroll-y",
        `${window.scrollY}px`
      );
    });
  }
}

senseiProfilePreviewModalDirective.$inject = [
  "$location",
  "$stateParams",
  "Dashboard",
  "OmnibarPointer",
];
