export default function sessionIndexFilter() {
  return function (sessions, sessionId) {
    var index = 0;
    var identifiedIndex = 0;
    angular.forEach(sessions, function (aSession) {
      if (aSession.id === sessionId) {
        identifiedIndex = index;
      }
      index = index + 1;
    });

    return identifiedIndex;
  };
}
