import crossLogos from "../../../images/logos/corsair_X_gamer_sensei";
import bg from "../../../images/backgrounds/gift-card-landing/corsair_hero_image";
import promotionSectionPartial from "../../../templates/includes/promotion/promotion_section_partial.html";

export default class CorsairPartnerGiftCardPageController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    pulledGames,
    LazyLoad,
    UIFeatureGate,
    MetaTagsService,
    EventTrackingManager
  ) {
    $rootScope.bodylayout = "corsair-gift-card-landing-page";
    this.games = pulledGames.data.games;
    // angular providers
    this.$window = $window;
    this.eventTracking = EventTrackingManager;
    // imported and injected
    this.crossLogos = crossLogos;
    this.corsairBgImage = bg;
    this.promotionSectionPartial = promotionSectionPartial;
    this.orderedGames = pulledGames.data.games;
    // objects and arrays
    this.gameConfig = {
      enabled: true,
      dots: true,
      appendDots: ".pyt-carousel-dots",
      arrows: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 600,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            arrows: false,
          },
        },
      ],
    };
    this.sections = [
      {
        id: 1,
        header: "Pick your game",
        body: "Whether you want to improve your FPS game, MOBA skills, or card battle results, there's a sensei for your skill level.",
        align: "center",
        direction: "left",
        icon: "/icons/gift-card-landing/corsair_icon_pickyourgame.png",
        bg: "/backgrounds/gift-card-landing/corsair_bg_img_pickyourgame.png",
      },
      {
        id: 2,
        header: "Find your sensei",
        body: "Choose from a list of highly qualified, experienced teachers and pair up for live instruction in the game you want to play.",
        align: "center",
        direction: "right",
        icon: "/icons/gift-card-landing/corsair_icon_findyoursensei.png",
        bg: "/backgrounds/gift-card-landing/corsair_bg_img_findyoursensei.png",
      },
      {
        id: 3,
        header: "Get good",
        body: "Receive analysis, tips, tricks, and strategies from experts who work with you in-game and teach you how to dominate.",
        align: "center",
        direction: "left",
        icon: "/icons/gift-card-landing/corsair_icon_getgood.png",
        bg: "/backgrounds/gift-card-landing/corsair_bg_img_getgood.png",
      },
    ];
    // boolean
    this.showPress = true;
    this.isSignUp = true;
    this.hideGameButtons = true;
    this.corsairLandingPageAvailable =
      UIFeatureGate.data.ui_feature_gates.corsair_landing_page;
    // string
    this.redirectUrl = "/gamer/dashboard/wallet/session-code";

    if (!this.corsairLandingPageAvailable) {
      $window.location.replace("/");
    }

    this.initMetTags(MetaTagsService);
    $timeout(() => {
      LazyLoad.set();
    }, 0);
  }

  // init methods
  initMetTags(MetaTagsService) {
    const metaTitle = "Corsair | Gamer Sensei";
    const metaDescription = "Redeem your Corsair purchase at Gamer Sensei.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  // display methods
  setDirectionClasses(direction) {
    if (direction) {
      return `section-block-${direction}`;
    }
    return "";
  }

  // action methods
  goTo(url) {
    window.location = url;
  }

  finishLogin(newUser, role) {
    this.$window.location.href = this.redirectUrl;
  }

  finishRegister(newUser) {
    this.$window.location.href = this.redirectUrl;
  }

  switchForm() {
    this.eventTracking.pushEventOnly(
      this.isSignUp ? "accountLoginOpen" : "accountRegistrationOpen"
    );

    this.isSignUp = !this.isSignUp;
  }
}

CorsairPartnerGiftCardPageController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "pulledGames",
  "LazyLoad",
  "UIFeatureGate",
  "MetaTagsService",
  "EventTrackingManager",
];
