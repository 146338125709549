export default class GamerLessonsReviewsController {
  constructor($rootScope, $window, RecentLessons, User, SenseiReviewService) {
    $rootScope.bodylayout = "gamer reviews";
    this.userId = $rootScope.currentId;
    this.role = "gamer";
    // angular providers
    this.$window = $window;
    // services
    this.userService = User;
    this.recentLessonsService = RecentLessons;
    this.senseiReviewService = SenseiReviewService;
    // numbers
    this.pages = 1;
    // to be set
    this.reviewParams = null;
    this.gamerId = null;
    // objects and arrays
    this.recentLessons = [];

    this.senseiReviewModal = {
      openedModal: false,
    };

    this.recentSenseisTableHeader = {
      name: {
        title: "Name",
        isActive: true,
        isSortable: true,
      },
      game_name: {
        title: "Game",
        isActive: false,
        isSortable: true,
      },
      buttons: {
        title: "",
        isHtml: true,
      },
    };

    this.noResults = {
      header: "No completed sessions",
      helper:
        '<p><a href="/games" target="_self">Click here</a> to purchase sessions<p>',
    };

    this.buttonActions = [
      {
        text: "Visit Profile",
        click: (sensei) => this.visitProfile(sensei),
        classes: "btn-stripped anchor alt",
      },
      {
        text: "Write a review",
        click: (sensei) => this.startReview(sensei),
        classes: "btn btn-green btn-inline-input",
        hidden: this.hideWriteReviewButton,
      },
      {
        text: "Update review",
        click: (sensei) => this.startReview(sensei),
        classes: "btn btn-primary btn-inline-input",
        hidden: this.hideUpdateReviewButton,
      },
    ];

    this.initUser();
    this.initRecentLessons();
  }

  // init methods
  initUser() {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser()
      .then((res) => {
        this.gamerId = res.gamer.id;
      })
      .catch(angular.noop);
  }
  initRecentLessons() {
    this.recentLessonsService
      .senseis(this.userId)
      .then((res) => {
        const respObj = res.data.recent_senseis.map((obj) => {
          return {
            ...obj,
            name: this.splitUserName(obj.full_name, obj.screen_name),
          };
        });

        this.senseiReviews = respObj;
        this.recentLessons = res.data.recent_senseis;
      })
      .catch(angular.noop);
  }

  // action methods
  openSenseiReviewModal(lastLesson, update) {
    this.mostRecentPastCompletedLesson = lastLesson;

    if (this.gamerId) {
      const pastReviewParams = {
        params: {
          reviewer_id: this.gamerId,
          reviewee_id: lastLesson.sensei_id,
        },
      };

      this.senseiReviewService
        .get(pastReviewParams)
        .then((respSRS) => {
          if (respSRS.data.sensei_reviews.length === 0 || update) {
            // set most recent sensei
            this.mostRecentPastCompletedLesson.senseiId = lastLesson.sesnei_id;
            this.mostRecentPastCompletedLesson.senseiImage =
              lastLesson.square_profile_photo_url;
            this.mostRecentPastCompletedLesson.sensei_name =
              lastLesson.screen_name;
            if (update && respSRS.data.sensei_reviews.length > 0) {
              // set updated review if exists, other set first review
              const updated_reviews =
                respSRS.data.sensei_reviews[0].updated_reviews;
              if (updated_reviews.length) {
                this.reviewParams = {
                  stars:
                    updated_reviews[updated_reviews.length - 1].star_rating,
                  description: updated_reviews[updated_reviews.length - 1].body,
                };
              } else {
                this.reviewParams = {
                  stars: respSRS.data.sensei_reviews[0].star_rating,
                  description: respSRS.data.sensei_reviews[0].body,
                };
              }
            }
            this.senseiReviewModal.openedModal = true;
          }
        })
        .catch(angular.noop);
    }
  }

  visitProfile(sensei) {
    const landingUrl = `${this.$window.location.protocol}//${this.$window.location.host}/senseis/${sensei.sensei_user_slug}`;

    this.$window.location.href = landingUrl;
  }

  startReview(sensei) {
    if (!sensei.has_reviewed && sensei.is_reviewable) {
      this.openSenseiReviewModal(sensei, false);
    } else if (sensei.has_reviewed) {
      this.openSenseiReviewModal(sensei, true);
    }
  }

  // display methods
  hideWriteReviewButton(sensei) {
    return sensei.has_reviewed || !sensei.is_reviewable;
  }

  hideUpdateReviewButton(sensei) {
    return (
      (!sensei.has_reviewed && sensei.is_reviewable) || !sensei.is_reviewable
    );
  }

  // internal methods
  // TODO: move to single source
  splitUserName(usersName, screenName) {
    const full = usersName.split(/\s+/);
    const first = full.slice(0, -1).join(" ");
    const last = full.pop();
    let firstName;
    let lastName;

    if (first) {
      firstName = first;
      lastName = last;
    } else {
      firstName = last;
    }

    return first
      ? `${firstName} "${screenName}" ${lastName}`
      : `"${screenName}" ${lastName}`;
  }
}

GamerLessonsReviewsController.$inject = [
  "$rootScope",
  "$window",
  "RecentLessons",
  "User",
  "SenseiReviewService",
];
