import moment from "moment-timezone";
import landingPageTemplate from "../../../../templates/dashboard/sensei/lessons/sensei_lessons_calendar_template";
import calendarLegendPartial from "../../../../templates/includes/calendar/calendar_legend_partial";
import {
  calendarEventObject,
  detailedCalendarEventObject,
} from "../../../../config/dashboard/dashboardConfig";
import { copyIcon } from "../../../../config/icons/iconConfig";

export default class SenseiLessonsCalendarController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $anchorScroll,
    CalendarBasedLessons,
    SenseiTrainingRequestManager,
    UIFeatureGate,
    RecentLessons,
    User,
    SenseiProfile,
    OmnibarPointer
  ) {
    $rootScope.bodylayout = "sensei calendar";
    this.userId = $scope.currentId;
    this.role = "sensei";
    this.environment = process.env.NODE_ENV;
    // angular providers
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    //set anchorScroll offset
    this.$anchorScroll = $anchorScroll;
    this.$anchorScroll.yOffset = 80;
    // services
    this.userService = User;
    this.omnibarPointer = OmnibarPointer;
    this.profile = SenseiProfile;
    this.calendarBasedLessons = CalendarBasedLessons;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.recentLessons = RecentLessons;
    // imports and injections
    this.landingPageTemplate = landingPageTemplate;
    this.calendarLegendPartial = calendarLegendPartial;
    this.copyIcon = copyIcon;
    // strings
    this.toggleText = "OFF";
    // numbers
    this.width = $window.innerWidth;
    // booleans
    this.loadingLessons = false;
    this.toggleAfkModal = false;
    // time
    this.timeZone = moment.tz.guess();
    // to be set
    this.currentEvents = null;
    this.toggleState = null;
    // objects and arrays
    this.calendarEvents = [];
    this.calendarModal = {
      userType: this.role,
    };

    //set ui gate
    this.isConfirmedLessonBucketAvailable =
      UIFeatureGate.data.ui_feature_gates.confirmed_lesson_bucket;
    this.isLessonReschedulingAvailable =
      UIFeatureGate.data.ui_feature_gates.recurring_lesson_reschedule;

    if (this.isConfirmedLessonBucketAvailable) {
      this.confirmedLessons =
        this.SenseiTrainingRequestManager.confirmedLessons;
    }

    this.initCalendarConfig();
    this.initController();
  }

  // init methods
  initController() {
    this.initUser();
    this.initDashboardLessonCard();
    this.initCalendarEvents();
    this.setWindowResizeListeners();
  }
  initUser() {
    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
      })
      .catch(angular.noop);
  }
  initDashboardLessonCard() {
    if (this.isConfirmedLessonBucketAvailable) {
      this.SenseiTrainingRequestManager.isConfirmedLessonBucketAvailable = true;
    }
    this.pageNotification = { show: false };

    this.recentLessons
      .gamers(this.userId, { params: { limit: 3 } })
      .then((res) => {
        this.recentGamers = res.data.recent_gamers;
      })
      .catch(angular.noop);
  }
  initCalendarConfig() {
    this.uiConfig = {
      editable: false,
      eventOverlap: false,
      slotEventOverlap: false,
      slotDuration: "00:15:00",
      snapDuration: "00:15:00",
      header: {
        left: "prev, title, next",
        center: "",
        right: "month, agendaWeek",
      },
      aspectRatio: 1.25,
      allDaySlot: false,
      timeFormat: "h(:mm)a",
      eventLimit: 4,
      views: {
        agenda: {
          eventLimit: false,
        },
      },
      dayClick: this.calendarDayClick.bind(this),
      eventRender: this.calendarEventRender.bind(this),
      eventClick: this.calendarEventClick.bind(this),
      eventLimitClick: this.calendarEventLimitClick.bind(this),
      eventAfterAllRender: this.calendarAfterEventsRender.bind(this),
    };
  }
  initCalendarEvents() {
    this.$timeout(() => {
      angular.element("#senseiCal").fullCalendar({
        ...this.uiConfig,
        eventSources: [
          {
            url: "/api/web/calendar_based_lessons",
            method: "GET",
            startParam: "starts_at_from_date",
            endParam: "starts_at_end_date",
            success: this.processCalendarData.bind(this),
            error: this.handleCalendarError.bind(this),
          },
        ],
      });
    });
  }

  // calendar methods
  calendarAfterEventsRender(view) {
    const today = moment();

    if (this.isLessonReschedulingAvailable && !this.calendarModal.openedModal) {
      this.dayEventClick(today);
    }

    if (this.loadingLessons) {
      this.loadingLessons = false;
    }
  }

  calendarEventRender(event, element, view) {
    if (event.start.isBefore(moment())) {
      element.addClass("evt-past");
    }

    if (
      event.status === "gamer_cancelled" ||
      event.status === "sensei_rejected"
    ) {
      element.addClass("evt-cancelled");
    } else if (
      event.singleChangeRequest ||
      event.recurringChangeRequest ||
      event.status === "request_expired"
    ) {
      element.addClass("evt-actionable");
    } else if (
      event.status === "pre_awaiting_direct_booking_schedule_confirm" ||
      event.status === "awaiting_direct_booking_schedule_confirm" ||
      event.status === "sensei_proposed_lesson"
    ) {
      element.addClass("evt-pre");
    }
  }

  calendarEventClick(event, jsEvent, view) {
    this.dayEventClick(event.start, jsEvent, view);
    if (this.width <= 1020) {
      this.$anchorScroll("calendar-sidebar");
    }
  }

  calendarEventLimitClick(cellInfo, jsEvent) {
    this.dayEventClick(cellInfo.date, jsEvent);
    if (this.width <= 1020) {
      this.$anchorScroll("calendar-sidebar");
    }
  }

  calendarDayClick(date, jsEvent, view) {
    this.dayEventClick(date, jsEvent, view);
    if (this.width <= 1020) {
      this.$anchorScroll("calendar-sidebar");
    }
  }

  dayEventClick(date, jsEvent, view) {
    const clickedDate = date.format("YYYY-MM-DD");
    this.calendarModal.date = moment(clickedDate, "YYYY-MM-DD").format(
      "dddd, MMMM Do, YYYY"
    );
    this.calendarModal.filterDate = moment(clickedDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
    this.calendarModal.lessons = [];

    if (this.dateHasEvent(date)) {
      this.calendarModal.openedModal = true;
      this.calendarModal.noLessons = false;
      this.calendarModal.timezone = moment.tz
        .zone(this.timeZone)
        .abbr(date.format("x"));

      this.getCalendarLessons(clickedDate, clickedDate)
        .then((res) => {
          const lessonResults = res.data.sensei_training_requests;
          const trainingRequests = lessonResults[clickedDate];

          this.todaysSenseiTrainingRequests = trainingRequests;

          angular.forEach(this.todaysSenseiTrainingRequests, (lesson, i) => {
            const lessonDetails = detailedCalendarEventObject(
              date,
              lesson,
              this.role,
              clickedDate,
              this.todaysSenseiTrainingRequests[i]
            );

            this.calendarModal.lessons.push(lessonDetails);
          });
        })
        .catch((err) => console.error(err));
    } else {
      if (date.isSameOrAfter(moment(), "day")) {
        this.calendarModal.openedModal = true;
        this.calendarModal.noLessons = true;
      } else {
        this.calendarModal.openedModal = false;
        this.calendarModal.noLessons = false;
      }
    }
  }
  processCalendarData(data) {
    this.senseiTrainingRequests = data.sensei_training_requests;
    return this.transformToCalendarEvents(this.senseiTrainingRequests);
  }
  handleCalendarError(err) {
    if (this.environment === "development") console.error(err);
  }
  transformToCalendarEvents(trainingRequests) {
    const events = [];

    angular.forEach(trainingRequests, (lessonsOnDate, onDate) => {
      angular.forEach(lessonsOnDate, (lesson, i) => {
        const obj = calendarEventObject(
          lesson,
          this.role,
          onDate,
          this.calendarBasedLessons
        );

        events.push(...[obj]);
      });
    });

    return events;
  }

  // action methods
  addGoogleCalendar() {
    this.calendarDisable = true;
    this.profile
      .optInToGoogleCalendar(this.currentUser.sensei)
      .then((res) => {
        this.calendarDisable = false;
        window.location = "sensei/dashboard/lessons/calendar";
      })
      .catch((err) => {
        this.calendarDisable = false;
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  toggleClass() {
    if (this.toggleState === null && User.getData()) {
      this.toggleState = User.getData().is_afk;
    }
    this.toggleText = this.toggleState ? "ON" : "OFF";
    return this.toggleState ? "fa-toggle-on" : "fa-toggle-off";
  }

  // internal methods
  dateHasEvent(date) {
    let allEvents = [];
    allEvents = angular.element("#senseiCal").fullCalendar("clientEvents");

    const event = allEvents.filter(function (ev) {
      const dateFormat = date.format("MM-DD-YYYY");
      const eventStart = ev.start.format("MM-DD-YYYY");

      return eventStart === dateFormat;
    });
    return event.length > 0;
  }
  getCalendarLessons(start, end) {
    const calendarParams = {
      params: {
        starts_at_from_date: start,
        starts_at_end_date: end,
      },
    };

    return this.calendarBasedLessons.getLessons(calendarParams);
  }
  setWindowResizeListeners() {
    angular.element(this.$window).on("resize", this.onResize(this.$window));
    this.$scope.$on("$destroy", this.cleanUp(this.$window));
  }

  // browser updates
  onResize() {
    if (this.width !== this.$window.innerWidth) {
      this.width = this.$window.innerWidth;
      this.$scope.$digest();
    }
  }
  cleanUp() {
    angular.element(this.$window).off("resize", this.onResize(this.$window));
  }
}

SenseiLessonsCalendarController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "$anchorScroll",
  "CalendarBasedLessons",
  "SenseiTrainingRequestManager",
  "UIFeatureGate",
  "RecentLessons",
  "User",
  "SenseiProfile",
  "OmnibarPointer",
];
