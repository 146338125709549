export default function recentLessonsService($http) {
  var service = {
    senseis: getGamerRecentSenseis,
    gamers: getSenseiRecentGamers,
  };

  return service;

  ///////////

  function getGamerRecentSenseis(id, data) {
    return $http.get("/api/web/users/" + id + "/recent_senseis", data);
  }

  function getSenseiRecentGamers(id, data) {
    return $http.get("/api/web/users/" + id + "/recent_gamers", data);
  }
}

recentLessonsService.$inject = ["$http"];
