gamePublisherService.$inject = ["$cookies", "$analytics", "GameMetricProfile"];
export default function gamePublisherService(
  $cookies,
  $analytics,
  GameMetricProfile
) {
  var publisherConnections = {};
  var gamePublishers = {
    hearthstone: "bnet",
    "heroes-of-the-storm": "bnet",
    "starcraft-ii": "bnet",
    overwatch: "bnet",
    "league-of-legends": "riot",
    "world-of-tanks": "warGaming",
    "dota-2": "steam",
    "counter-strike-global-offensive": "steam",
  };
  var service = {
    getPublisher: getPublisher,
    refreshPublisherConnections: refreshPublisherConnections,
    trackSigninSuccess: trackSigninSuccess,
    isPublisherConnected: isPublisherConnected,
  };

  return service;

  ////////////////

  function getPublisher(gameSlug) {
    return gamePublishers[gameSlug];
  }

  function refreshPublisherConnections(currentId) {
    var gameMetricProfile = currentId
      ? GameMetricProfile.user(GUID)
      : GameMetricProfile.visitor($cookies.get("visitor_uuid"));

    return gameMetricProfile
      .then((res) => {
        var thirdPartyKeys = res.data.game_metric_profile.third_party_keys;
        if (thirdPartyKeys) {
          publisherConnections["steam"] = thirdPartyKeys.steamId !== undefined;
          publisherConnections["riot"] =
            thirdPartyKeys.riot_summonerName !== undefined &&
            thirdPartyKeys.riot_server !== undefined;
          publisherConnections["bnet"] = thirdPartyKeys.bnet_id !== undefined;
        }

        return publisherConnections;
      })
      .catch(angular.noop);
  }

  function trackSigninSuccess(publisher) {
    $analytics.eventTrack(publisher + "_success", {
      category: "signin",
    });

    publisherConnections[publisher] = true;
  }

  function isPublisherConnected(publisher) {
    return publisherConnections[publisher];
  }
}
