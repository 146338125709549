import moment from "moment-timezone";
import {
  setSenseiMetaTitle,
  sidebarMenuSensei,
  userIsOnProfile,
} from "../../../config/dashboard/dashboardConfig";

export default class SenseiDashboardContainerController {
  constructor(
    $scope,
    $state,
    $location,
    $anchorScroll,
    $rootScope,
    UserOS,
    DirectTrainingRequestSchedule,
    SenseiTrainingRequestManager,
    OmnibarPointer,
    User,
    UIFeatureGate,
    pulledGames,
    MetaTagsService,
    EventTrackingManager
  ) {
    this.userId = $scope.currentId;
    this.role = "sensei";
    this.gigya = $scope.isGigyaOn;
    // angular providers
    this.root = $rootScope;
    this.$anchorScroll = $anchorScroll;
    this.$state = $state;
    this.currentStateName = $state.current.name;
    // services
    this.games = pulledGames.data.games;
    this.userService = User;
    this.OmnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    // strings
    $rootScope.proposeTimeSubsequentStepLink = "sensei/dashboard";
    // booleans
    $scope.userDashboard = true;
    // to be set
    $scope.proposeToPerson = null;
    // objs and arrays
    $scope.currentUser = {};

    this.subscriptionsOn =
      UIFeatureGate.data.ui_feature_gates.gs_version_3_subscriptions;

    UserOS.setOS($scope.os_windows);
    UserOS.setWinIsa($scope.windows_isa);

    SenseiTrainingRequestManager.setRole("sensei");
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;

    this.initUser({
      setUser: (userData) => {
        $scope.currentUser = userData;
        this.currentUser = userData;
      },
      setUserSidebar: (userData) => {
        this.senseiMenu = sidebarMenuSensei(
          userData.status,
          this.subscriptionsOn
        );
      },
      isFirstRun: (firstRun) => {
        this.root.firstRun = firstRun;
        if (firstRun) {
          this.$state.go("sensei_dashboard.profile");
        }
      },
      setOmniTooltip: (tooltip) => {
        if (tooltip) {
          this.OmnibarPointer.pushTooltip(tooltip[0], { persist: true });
          this.$anchorScroll();
        }
      },
    });
    this.initMetaTags(MetaTagsService);
    this.initStateChange(MetaTagsService);
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService
      .getUser(true)
      .then((userData) => {
        if (callbacks?.setUser) callbacks.setUser(userData);
        if (callbacks?.setUserSidebar) callbacks.setUserSidebar(userData);
        if (callbacks?.isFirstRun) callbacks.isFirstRun(userData.first_run);
        if (callbacks?.setOmniTooltip)
          callbacks.setOmniTooltip(userData.omni_tooltips["/sensei/dashboard"]);
      })
      .catch((err) => {
        this.OmnibarPointer.pushErrorByHash(err.data.errors);
      });
  }
  initMetaTags(MetaTagsService) {
    const metaTitle = setSenseiMetaTitle(this.currentStateName);
    const metaDescription = "Your dashboard for all Sensei activities";

    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
  initStateChange(MetaTagsService) {
    this.root.$on(
      "$stateChangeSuccess",
      (event, toState, toParams, fromState, fromParams, options) => {
        this.$anchorScroll();
        const newMetaTitle = setSenseiMetaTitle(toState.name);

        MetaTagsService.setTags({
          title: newMetaTitle,
          "og:title": newMetaTitle,
          "twitter:title": newMetaTitle,
        });

        if (userIsOnProfile(toState.url)) {
          this.eventTracking.pushViewEvent("Profile");
        } else {
          this.eventTracking.pushViewEvent("Dashboard");
        }
      }
    );

    if (userIsOnProfile(this.$state.current.url)) {
      this.eventTracking.pushViewEvent("Profile");
    } else {
      this.eventTracking.pushViewEvent("Dashboard");
    }
  }

  // display methods
  showUserNotVerifiedDisplay() {
    return (
      this.currentUser &&
      !this.currentUser.is_email_verified &&
      this.$state.current.name !== "sensei_dashboard.verify_email" &&
      this.gigya
    );
  }
}

SenseiDashboardContainerController.$inject = [
  "$scope",
  "$state",
  "$location",
  "$anchorScroll",
  "$rootScope",
  "UserOS",
  "DirectTrainingRequestSchedule",
  "SenseiTrainingRequestManager",
  "OmnibarPointer",
  "User",
  "UIFeatureGate",
  "pulledGames",
  "MetaTagsService",
  "EventTrackingManager",
];
