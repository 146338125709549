export default function dashboardManagerService($http) {
  return {
    updateGamer: function(gamerId, data) {
        return $http({ method: 'PUT', url: '/api/web/gamer_profiles/'+gamerId, data: data });
    },
    updateSensei: function(id, data) {
        return $http({ method: 'PUT', url: '/api/web/sensei_profiles/'+id, data: data });
    },
    findUser: function(data) {
      return $http.get('/api/web/users/'+data.id+'?role='+data.role);
    },
    updateAccount: function(id, data) {
      return $http({ method: 'PUT', url: '/api/web/accounts/'+id, data: data });
    },
    searchForSensei: function(config) {
      return $http.get('/api/web/sensei_search_results', config);
    },
    searchForSenseiByText: function(config) {
      return $http.get('/api/web/sensei_text_search_results', config);
    },
    displaySenseiByIds: function(config) {
      return $http.get('/api/web/sensei_text_search_result_displays', config);
    },
    bookingsByStatus: function(status) {
      return $http.get('/api/web/sensei_training_requests/?status='+status);
    },
    getSenseiTrainingRequests: function(config) {
      return $http.get('/api/web/sensei_training_requests/', config);
    },
    getSingleSenseiTrainingRequests: function(id) {
      return $http.get('/api/web/sensei_training_requests/' + id);
    },
    createBooking: function(data) {
      return $http.post('/api/web/sensei_training_requests',data);
    },
    createCreditCard: function(data) {
      return $http.post('/api/web/credit_cards', data);
    },
    sensei_training_request_gamer_review: function(id, data) {
      return $http.put('/api/web/gamer_session_reviews/'+id, data);
    },
    sensei_training_request_sensei_review: function(id, data) {
      return $http.put('/api/web/sensei_session_reviews/'+id, data);
    },
    deleteCreditCard: function(id) {
      return $http.delete('/api/web/credit_cards/'+id);
    },
    closeOmnibar: function(data) {
      return $http({ method: 'POST', url: '/api/web/omni_tooltip_dismissals', data: data });
    },
    lessonChangesView: function(id, data) {
      return $http.put('/api/web/sensei_training_requests/'+id+'/lesson_changes_viewed', data);
    },
    senseiNotesViews: function(id) {
      return $http.put('/api/web/sensei_training_requests/'+id+'/sensei_notes_views');
    },
    unreadSenseiNotesCount: function() {
      return $http.get('/api/web/sensei_training_requests/unread_sensei_notes');
    },
    fetchMessages: function(id) {
      return $http.get('/api/web/conversations/' + id + '/messages');
    },
    senseiChatsViews: function(id) {
      return $http.post('/api/web/conversations/' + id + '/read');
    },
    unreadSenseiChatsCount: function() {
      return $http.get('/api/web/sensei_training_requests/unread_conversations', {});
    },
    privateStatGamesWhitelist: [
      'league-of-legends'
    ]
  };
}

dashboardManagerService.$inject = ["$http"];
