export default class LessonsNotesPageController {
  constructor(
    $state,
    $scope,
    $stateParams,
    Dashboard,
    SenseiTrainingRequestManager,
    SenseiTrainingRequest,
    SenseiTrainingRequestAPIService,
    OmnibarPointer,
    CartManager,
    TokenService,
    NotificationManager
  ) {
    // angular providers
    this.state = $state;
    this.stateParams = $stateParams;
    // services
    this.omnibarPointer = OmnibarPointer;
    this.str = SenseiTrainingRequest;
    this.strAPI = SenseiTrainingRequestAPIService;
    this.strManager = SenseiTrainingRequestManager;
    this.notifications = NotificationManager;
    this.cart = CartManager;
    this.tokenManager = TokenService;
    this.selectedRubricItems = {};
    this.selectedSessionGroup = "single";
    this.displayTokenTierBundles = false;
    this.currentTierBundles = [];

    this.initUserType();
    this.initSenseiNotePage(Dashboard);
    this.initBundles($scope);
  }

  // init methods
  initUserType() {
    if (this.strManager.isSensei()) {
      this.userType = "sensei";
    } else {
      this.userType = "gamer";
    }
  }
  initSenseiNotePage(Dashboard) {
    Dashboard.getSingleSenseiTrainingRequests(this.stateParams.lessonId).then(
      (res) => {
        this.lesson = new this.str(
          res.data.sensei_training_request,
          this.userType
        );

        this.lesson.request.sensei_notes_markdown = toMarkdown(
          this.lesson.request.sensei_notes
        );
        this.lesson.request.assessment_drills_markdown = toMarkdown(
          this.lesson.request.assessment_drills
        );
        this.lesson.request.assessment_action_plan_markdown = toMarkdown(
          this.lesson.request.assessment_action_plan
        );
        this.lesson.request.assessment_feedback_markdown = toMarkdown(
          this.lesson.request.assessment_feedback
        );

        this.lesson.request.token_rubric_selections.forEach((rubricItem) => {
          if (rubricItem.text) {
            this.selectedRubricItems[rubricItem.template.id] = rubricItem.value;
          }
        });

        if (this.userType === "gamer") {
          var params = {
            params: { "sensei_ids[]": [this.lesson.request.sensei_id] },
          };
          Dashboard.displaySenseiByIds(params).then((res) => {
            this.sensei = res.data.sensei_search_results[0];
            this.senseiTokens = this.formatTokensToObj(
              this.sensei.token_templates
            );
            var filteredTokObj = Object.values(this.senseiTokens).filter(
              function (obj, key) {
                return obj.displayOrder === 1;
              }
            );

            this.selectedSenseiProduct = filteredTokObj[0];
            this.tokenManager.setTokenBundles({
              game: res.data.sensei_search_results[0].token_templates[0].game,
            });
          });
          this.notifications.setSenseiNotesToRead(this.stateParams.lessonId);
        }
      }
    );
  }

  initBundles($scope) {
    const watchBundles = $scope.$watch(
      () => this.tokenManager.tokenBundles,
      (newVal) => {
        if (Object.keys(newVal).length) {
          this.displayTokenTierBundles = true;
          angular.forEach(newVal, (bundle) => {
            if (bundle.token_tier.toLowerCase() === this.getTierName()) {
              this.currentTierBundles.push(bundle);
            }
          });
          watchBundles();
        }
      }
    );
  }

  // action methods
  addSenseiNote(lesson) {
    this.savingNotesLoading = true;

    const {
      sensei_notes_markdown,
      assessment_drills_markdown,
      assessment_action_plan_markdown,
      assessment_feedback_markdown,
      token_type_name,
    } = this.lesson.request;

    strAPI
      .addNotes(lesson.id, {
        sensei_notes: marked(sensei_notes_markdown || ""),
      })
      .then(function (_response) {
        if (
          this.shouldAssessmentBeAdded(
            assessment_drills_markdown,
            assessment_action_plan_markdown,
            assessment_feedback_markdown,
            token_type_name
          )
        ) {
          const assessmentParams = {
            assessment_drills: marked(assessment_drills_markdown || ""),
            assessment_action_plan: marked(
              assessment_action_plan_markdown || ""
            ),
            assessment_feedback: marked(assessment_feedback_markdown || ""),
          };
          strAPI
            .addAssessment(
              lesson.id,
              this.selectedRubricItems,
              assessmentParams
            )
            .then(function (_res) {
              this.displaySuccessfulBooking();
            })
            .catch((err) => {
              this.displayErrorBooking(err);
            });
        } else {
          this.displaySuccessfulBooking();
        }
      })
      .catch((err) => {
        this.displayErrorBooking(err);
      });
  }

  addCartItem() {
    const itemWithSensei = {
      ...this.selectedSenseiProduct,
      sensei: this.sensei,
    };

    this.cart.addCartItem(itemWithSensei, this.selectedSessionGroup);
  }

  selectSessionGroup(group) {
    this.selectedSessionGroup = group;

    let filteredObjOnChange;
    if (group === "bundle") {
      filteredObjOnChange = Object.values(this.currentTierBundles).filter(
        (obj, key) => {
          return key === 0;
        }
      );
    } else {
      filteredObjOnChange = Object.values(this.senseiTokens).filter(
        (obj, key) => {
          return obj.displayOrder === 1;
        }
      );
    }
    this.selectedSenseiProduct = filteredObjOnChange[0];
  }

  // display methods
  displaySuccessfulBooking() {
    this.savingNotesLoading = false;
    const omniTooltipParams = {
      title: "Job's done!",
      body: "<p>Notes have been successfully saved!</p>",
    };
    this.omnibarPointer.pushTooltip(omniTooltipParams);
    this.state.reload();
  }

  displayErrorBooking(err) {
    this.savingNotesLoading = false;
    this.omnibarPointer.pushErrorByMessage(err.data.errors);
  }

  formatTokensToObj(tokens, qty) {
    var initialValue = {};
    return tokens.reduce(function (obj, item) {
      return {
        ...obj,
        [`${item.token_tier}-${item.token_type}-${item.id}`]: {
          ...item,
          displayOrder: this.getTypeDisplayOrder(item.token_type),
          sensei: this.sensei,
          quantity: qty ? qty : 1,
        },
      };
    }, initialValue);
  }

  getBundleShortName(bundle) {
    let count = 0;
    angular.forEach(bundle.items, (item) => {
      count = count + item.quantity;
    });

    const name = bundle.token_type
      ? `${bundle.token_type} ${count}-Pack`
      : `${count}-Pack`;

    return name;
  }

  getTierName() {
    const tier = this.sensei && this.sensei.token_templates[0].token_tier;

    if (tier.toLowerCase().includes("legendary")) {
      return "Legendary";
    }

    return tier;
  }

  setSenseiProductToSelected(token) {
    this.selectedSenseiProduct = token;
  }

  isSenseiProductSelected(token, type) {
    return token.id === this.selectedSenseiProduct.id;
  }

  getBuyButtonText() {
    let buttonText = "Buy";

    if (this.selectedSessionGroup === "single") {
      buttonText = `Buy ${this.selectedSenseiProduct.token_type_data.name}`;
    } else if (this.selectedSessionGroup === "bundle") {
      buttonText = `Buy ${this.getBundleShortName(this.selectedSenseiProduct)}`;
    }

    return buttonText;
  }

  canShowNotes() {
    if (this.userType == "gamer") {
      if (this.lesson.request.token_type_name == "Skills Assessment") {
        return !!this.lesson.request.sensei_notes;
      }

      return true;
    } else if (!this.lesson.request.sensei_can_edit_notes) {
      return true;
    }
  }

  buildSessionTitle(session) {
    let time;
    if (session.sessionDurationHours === 0.5) {
      time = "30-Minute";
    } else {
      time = `${session.sessionDurationHours}-Hour`;
    }
    if (session.tokenTypeName) {
      return `${time} ${session.gameName} ${session.tokenTypeName} Session on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
    }
    return `${time} ${session.gameName} Lesson on ${session.dateString} at ${session.timeString} ${session.tzString} with ${session.otherParty.screenName}`;
  }

  areThereSenseiTokens() {
    if (this.sensei && Object.keys(this.senseiTokens).length) {
      return true;
    }

    return false;
  }

  getRubricItemClass(index, template) {
    if (
      this.selectedRubricItems &&
      this.selectedRubricItems[template.id] == index
    ) {
      return "btn-primary";
    } else {
      return "btn-stripped anchor alt";
    }
  }

  selectRubricItem(index, template) {
    this.selectedRubricItems[template.id] = index;
  }

  isThereAPriceReduction(token) {
    return (
      token.advertised_student_cost &&
      token.advertised_student_cost > token.base_student_cost
    );
  }

  // internal methods
  shouldAssessmentBeAdded(
    assessment_drills,
    assessment_action_plan,
    assessment_feedback,
    token_type_name
  ) {
    if (
      token_type_name === "Skills Assessment" &&
      (!!assessment_drills || !!assessment_action_plan || !!assessment_feedback)
    ) {
      return true;
    }

    return false;
  }

  getTypeDisplayOrder(slug) {
    if (slug.includes("masterclass")) {
      return 0;
    } else if (slug.includes("one-on-one")) {
      return 1;
    } else if (slug.includes("skills-assessment")) {
      return 3;
    } else if (slug.includes("team")) {
      return 4;
    }
  }
}

LessonsNotesPageController.$inject = [
  "$state",
  "$scope",
  "$stateParams",
  "Dashboard",
  "SenseiTrainingRequestManager",
  "SenseiTrainingRequest",
  "SenseiTrainingRequestAPIService",
  "OmnibarPointer",
  "CartManager",
  "TokenService",
  "NotificationManager",
];
