import favoriteSenseiTemplate from "../../templates/directives/sensei/favorite_sensei_template";

export default function favoriteSenseiDirective(
  User,
  $timeout,
  $window,
  $state,
  Favorites,
  OmnibarPointer,
  adaptiveSenseiSearch
) {
  return {
    restrict: "E",
    scope: {
      featureGateOn: "=",
      favorited: "=",
      senseiId: "=",
      gameName: "=",
      senseiSlug: "=?",
      senseiCoachId: "=?",
    },
    link: link,
    template: favoriteSenseiTemplate,
  };

  function link(scope, elem, attrs) {
    scope.UserData = User;
    scope.favoriteSenseiV2 = false;

    var watchUser = scope.$watch(
      "UserData._userData",
      function (newVal) {
        if (newVal) {
          scope.userId = scope.UserData.getId();
          scope.currentUser = scope.UserData.getData();

          if (
            scope.userId &&
            scope.currentUser.gamer &&
            scope.currentUser.gamer.gamer_favorited_senseis.length
          ) {
            for (
              let favoriteSensei = 0;
              favoriteSensei <
              scope.currentUser.gamer.gamer_favorited_senseis.length;
              favoriteSensei++
            ) {
              if (
                scope.currentUser.gamer.gamer_favorited_senseis[favoriteSensei]
                  .sensei_id === scope.senseiId
              ) {
                scope.favorited = true;
                if (!scope.faveId) {
                  scope.faveId =
                    scope.currentUser.gamer.gamer_favorited_senseis[
                      favoriteSensei
                    ].id;
                }
              }
            }
          }
          watchUser();
        }
      },
      true
    );

    scope.visitorUI = function (stateName) {
      return !scope.userId && $state.current.name === stateName;
    };

    scope.signUpModalCommands = {
      isOpen: false,
      openModal: function () {
        this.isOpen = true;
      },
      closeModal: function () {
        this.isOpen = false;
      },
    };

    scope.favoriteSensei = function () {
      if (scope.userId && scope.currentUser.gamer) {
        if (!scope.favorited) {
          addSenseiToFavorites();
        } else {
          removeSenseiFromFavorites();
        }
      } else if ($state.current.name === "senseis" && scope.senseiCoachId) {
        $timeout(function () {
          scope.signUpModalCommands.openModal();
        }, 0);
      } else if (scope.senseiSlug) {
        $window.location.href = "/senseis/" + scope.senseiSlug;
      }
    };

    function addSenseiToFavorites() {
      var params = {
        gamer_favorited_sensei: {
          sensei_id: scope.senseiId,
          game_name: scope.gameName,
        },
      };
      Favorites.addFavorite(params)
        .then((res) => {
          var newFavoriteSensei = res.data.gamer_favorited_sensei;
          if (!scope.currentUser.gamer.gamer_favorited_senseis) {
            scope.currentUser.gamer.gamer_favorited_senseis = [];
          }
          if (!scope.favorited) {
            scope.favorited = true;
          }
          if (!scope.faveId) {
            scope.faveId = newFavoriteSensei.id;
          }
          if ($state.current.name === "adaptive_sensei_search") {
            adaptiveSenseiSearch.favorites.push(newFavoriteSensei);
          }
          scope.currentUser.gamer.gamer_favorited_senseis.push(
            newFavoriteSensei
          );
        })
        .catch((err) => {
          OmnibarPointer.pushErrorByHash(err.data.errors);
        });
    }

    function removeSenseiFromFavorites() {
      Favorites.removeFavorite(scope.faveId)
        .then((res) => {
          var removedFavoriteSensei = res.data.gamer_favorited_sensei;
          if (scope.favorited) {
            scope.favorited = false;
          }
          if (scope.faveId) {
            scope.faveId = null;
          }
          if ($state.current.name === "adaptive_sensei_search") {
            adaptiveSenseiSearch.favorites.splice(
              adaptiveSenseiSearch.favorites.findIndex(function (
                gamer_favorite_sensei
              ) {
                return gamer_favorite_sensei.id === removedFavoriteSensei.id;
              }),
              1
            );
          }
          scope.currentUser.gamer.gamer_favorited_senseis.splice(
            scope.currentUser.gamer.gamer_favorited_senseis.findIndex(function (
              gamer_favorite_sensei
            ) {
              return gamer_favorite_sensei.id === removedFavoriteSensei.id;
            }),
            1
          );
        })
        .catch((err) => {
          OmnibarPointer.pushErrorByHash(err.data.errors);
        });
    }
  }
}

favoriteSenseiDirective.$inject = [
  "User",
  "$timeout",
  "$window",
  "$state",
  "Favorites",
  "OmnibarPointer",
  "adaptiveSenseiSearch",
];
