export default function UserProfileService($http) {
  var service = {
    updateGamer,
    updateBusiness,
  };

  return service;

  ///////////

  function updateGamer(gamerId, data) {
    return $http({
      method: "PUT",
      url: "/api/web/gamer_profiles/" + gamerId,
      data: data,
    });
  }

  function updateBusiness(gamerId, data) {
    return $http({
      method: "PUT",
      url: "/api/web/business_profiles/" + gamerId,
      data: data,
    });
  }
}

UserProfileService.$inject = ["$http"];
