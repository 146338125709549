import moment from "moment-timezone";
import { messageIcon } from "../../config/icons/iconConfig";

export default class CheckoutSuccessController {
  constructor(CartManager, DirectTrainingRequestSchedule, OmnibarPointer) {
    this.messageIcon = messageIcon;
    this.cart = CartManager;
    this.trainingRequest = DirectTrainingRequestSchedule;
    this.omnibarPointer = OmnibarPointer;
    this.requestConfirmations = {};
    this.successMessage = "";

    this.cart.setStatus("complete");
    this.cartItems = this.cart.getCartItems();

    this.initSenseiProposal();
  }

  // init methods
  initSenseiProposal() {
    if (this.cart.doesCartIncludeSenseiProposalResponse()) {
      this.successMessage =
        "You have new booked or proposed sessions. Contact your sensei through Discord to coordinate!";
    } else {
      this.successMessage =
        "Book your sessions now to get on your Sensei's calendar.";
    }

    angular.forEach(this.cartItems, (item, key) => {
      if (item.sensei_proposed_lesson) {
        this.continueProposalOption(item);
      }
    });
  }

  // format time for startsAt field
  timeFormat(time) {
    return moment
      .tz(time, "YYYY-MM-DDTHH:mm:ss.sssZ", moment.tz.guess())
      .format("MM/DD/YYYY [at] hh:mm A");
  }

  // filters communications list to show only discord user name
  senseiDiscordName(communications) {
    const discord = communications.filter(function (chat) {
      return chat.medium === "discord";
    });

    return discord && discord[0] ? discord[0].handle : "";
  }

  // continues to gamer's options if sensei requested lesson
  continueProposalOption(item) {
    switch (item.sensei_proposed_lesson) {
      case "accepted":
        this.trainingRequest
          .gamerAccept(item.sensei_proposed_lesson_data.data.requestScheduleId)
          .then((res) => {
            this.requestConfirmations = {
              ...this.requestConfirmations,
              [res.data.sensei_training_request.id]: {
                type: "accepted",
                ...res.data.sensei_training_request,
              },
            };
          })
          .catch((err) => {
            this.omnibarPointer.pushErrorByHash(err.data.errors);
          });
        break;
      case "rescheduled":
        this.trainingRequest
          .gamerPropose(
            item.sensei_proposed_lesson_data.contextual_id,
            item.sensei_proposed_lesson_data.data
          )
          .then((res) => {
            this.requestConfirmations = {
              ...this.requestConfirmations,
              [res.data.sensei_training_request.id]: {
                type: "rescheduled",
                ...res.data.sensei_training_request,
              },
            };
          })
          .catch((err) => {
            this.successMessage = `Your sensei is unavailable at the time you proposed. Please visit the lesson list on your Dashboard, and use one of their contact methods to coordinate a better time.`;
            this.omnibarPointer.pushErrorByHash(err.data.errors);
          });
        break;
      default:
        break;
    }
  }
}

CheckoutSuccessController.$inject = [
  "CartManager",
  "DirectTrainingRequestSchedule",
  "OmnibarPointer",
];
