export default class SenseiAccountSubscriptionPlansController {
  constructor(
    $scope,
    $rootScope,
    User,
    OmnibarPointer,
    SenseiBenefitListService
  ) {
    $rootScope.bodylayout = "sensei settings";

    this.senseiSubs = SenseiBenefitListService;
    this.omnibarPointer = OmnibarPointer;
    this.userService = User;

    this.benefitModal = {
      opened: false,
    };
    this.completeCheck = false;
    this.userId = $scope.currentId;

    this.initUser({
      subscriptionItems: () =>
        this.setSubscriptionItems(this.currentUser.sensei.id),
      updateFirstRun: () => {
        $rootScope.firstRun = this.currentUser.first_run;
      },
    });
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("sensei");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;

        if (callbacks?.subscriptionItems) callbacks.subscriptionItems();
        if (callbacks?.updateFirstRun) callbacks.updateFirstRun();

        if (this.currentUser.omni_tooltips["/sensei/dashboard"]) {
          this.omnibarPointer.pushTooltip(
            this.currentUser.omni_tooltips["/sensei/dashboard"][0]
          );
        }
      })
      .catch(angular.noop);
  }

  setSubscriptionItems(id) {
    this.senseiSubs
      .setSubscriptionItems(id)
      .then((res) => {
        this.completeCheck = true;
      })
      .catch((err) => {
        this.completeCheck = true;
      });
  }

  //
  areThereNoSubscriptionPlans() {
    return (
      Object.keys(this.senseiSubs.getSenseiSubscriptionPlans()).length === 0
    );
  }

  submitBenefitForm() {
    const params = {
      sensei_id: this.currentUser.sensei.id,
      sensei_subscription_item: {
        name: this.senseiBenefitItem,
        description: this.senseiBenefitDescription,
        message: this.senseiPlanWelcomeMessage,
        link: this.senseiBenefitLink,
        active: true,
      },
    };

    if (!this.areThereNoSubscriptionPlans()) {
      params.token_subscription_plan_id =
        this.senseiSubs.getSenseiSubscriptionPlans().id;
    }

    this.senseiSubs
      .createSubscriptionItems(params)
      .then((res) => {
        const omniTooltipParams = {
          title: "Success",
          body: "<p>You have created a new benefit for your subscription plan.</p>",
        };
        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          persist: true,
          timer: true,
        });
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
      });
  }

  updateThisBenefitItem(benefit, action) {
    const title =
      action === "edit"
        ? "Edit Subscription Benefit"
        : "Delete Subscription Benefit";
    this.benefitModal = { ...benefit, action, title, opened: true };
  }

  closeBenefitModal() {
    this.benefitModal.opened = false;
  }

  confirmEditBenefitItem() {
    const params = {
      sensei_subscription_item: {
        id: this.benefitModal.id,
        name: this.benefitModal.name,
        token_subscription_plan_id:
          this.senseiSubs.getSenseiSubscriptionPlans().id,
        description: this.benefitModal.description,
        link: this.benefitModal.link,
      },
    };

    this.senseiSubs
      .updateSubscriptionItems(params)
      .then((res) => {
        this.closeBenefitModal();
        var omniTooltipParams = {
          title: "Success",
          body: "<p>Your subscription benefit has been updated.</p>",
        };
        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          persist: true,
          timer: true,
        });
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
        this.closeBenefitModal();
      });
  }

  confirmDeleteBenefitItem() {
    this.senseiSubs
      .deleteSubscriptionItems(this.benefitModal.id)
      .then((res) => {
        this.closeBenefitModal();
        const omniTooltipParams = {
          title: "Success",
          body: "<p>Your subscription benefit has been deleted.</p>",
        };
        this.omnibarPointer.pushTooltip(omniTooltipParams, {
          tooltipCallback: false,
          persist: true,
          timer: true,
        });
      })
      .catch((err) => {
        this.omnibarPointer.pushErrorByHash(err.data.errors);
        this.closeBenefitModal();
      });
  }
}

SenseiAccountSubscriptionPlansController.$inject = [
  "$scope",
  "$rootScope",
  "User",
  "OmnibarPointer",
  "SenseiBenefitListService",
];
