export default function studentHistoryService($q, $http) {
  const service = {
    loading: false,
    finished: false,
    students: {},
    config: {
      pageNum: 1,
      perPage: 50,
      total: 0,
    },
    getFetchStatus,
    setLoadStatus,
    getStudents,
    setStudents,
    getStudentTotal,
    areStudentsEmpty,
    getPageSize,
    getCurrentPage,
    setCurrentPage,
    getNumberOfPages,
  };

  return service;

  ///

  function getFetchStatus() {
    return !service.loading && service.finished;
  }

  function setLoadStatus(status) {
    service.loading = status;
  }

  function getStudents() {
    return service.students;
  }

  function setStudents(studentName) {
    service.loading = true;
    var deferred = $q.defer();
    return $http
      .get(
        "/api/web/sensei_completed_lessons/students" +
          (studentName ? `?student_name=${studentName}` : "")
      )
      .then((res) => {
        service.config.total = res.data.length;
        service.students = formatStudentsToArr(res.data);
        service.loading = false;
        service.finished = true;
        deferred.resolve(res);
      })
      .catch((err) => {
        service.loading = false;
        service.finished = true;
        deferred.reject(err);
      });
  }

  function getStudentTotal() {
    return service.config.total;
  }

  function areStudentsEmpty() {
    return service.students.length <= 0;
  }

  function getPageSize() {
    return service.config.perPage;
  }

  function getCurrentPage() {
    return service.config.pageNum;
  }

  function setCurrentPage(num) {
    service.config.pageNum = num;
  }

  function getNumberOfPages() {
    return Math.ceil(service.config.total / service.config.perPage);
  }

  // Internal functions
  function formatStudentsToArr(students) {
    const initialValue = [];
    return students.reduce(function (arr, item) {
      return [
        ...arr,
        {
          recentLessonCompletionDate: moment
            .utc(new Date(item.recent_lesson_completion_date))
            .local()
            .format("YYYY/MM/DD h:mmA"),
          studentId: item.student_id,
          studentScreenName: item.student_screen_name,
          inGameName: item.in_game_name,
          inGameNameLabel: item.in_game_name_label,
          discord: getDiscordName(item.communication_handles),
          buttons: true,
        },
      ];
    }, initialValue);
  }

  function getDiscordName(communications) {
    const discord = communications.filter(function (chat) {
      return chat.medium === "discord";
    });

    return discord && discord[0] ? discord[0].handle : "";
  }
}

studentHistoryService.$inject = ["$q", "$http"];
