import moment from "moment-timezone";
import landingPageTemplate from "../../../../templates/dashboard/gamer/lessons/gamer_lessons_calendar_template";
import calendarLegendPartial from "../../../../templates/includes/calendar/calendar_legend_partial";
import {
  calendarEventObject,
  detailedCalendarEventObject,
} from "../../../../config/dashboard/dashboardConfig";

export default class GamerLessonsCalendarController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $window,
    $anchorScroll,
    $cookies,
    CalendarBasedLessons,
    User,
    SenseiTrainingRequestManager,
    SenseiTrainingRequestAPIService
  ) {
    $rootScope.bodylayout = "gamer calendar";
    this.role = "gamer";
    this.userId = $scope.currentId;
    this.environment = process.env.NODE_ENV;
    // angular providers
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    //set anchorScroll offset for header
    this.$anchorScroll = $anchorScroll;
    this.$anchorScroll.yOffset = 80;
    // services
    this.userService = User;
    this.SenseiTrainingRequestManager = SenseiTrainingRequestManager;
    this.strAPI = SenseiTrainingRequestAPIService;

    this.calendarBasedLessons = CalendarBasedLessons;
    // imported and injected
    this.landingPageTemplate = landingPageTemplate;
    this.calendarLegendPartial = calendarLegendPartial;
    // number

    this.width = $window.innerWidth;
    // booleans
    this.loadingLessons = false;
    // times
    this.timeZone = moment.tz.guess();
    // to be set
    this.currentEvents = null;
    // objects and arrays
    this.bundleBackground = { slug: "", defined: false };
    this.calendarEvents = [];
    this.calendarModal = {
      userType: this.role,
      openedModal: false,
    };
    this.confirmedLessons = this.SenseiTrainingRequestManager.confirmedLessons;

    this.initCalendarConfig();
    this.initUser();
    this.initCalendarEvents();

    angular.element($window).on("resize", this.onResize($window));
    $scope.$on("$destroy", this.cleanUp($window));
  }

  // init methods
  initUser(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole(this.role);
    this.userService.getUser().then((userData) => {
      this.currentUser = userData;
      if (callbacks && callbacks.onSuccess) {
        callbacks.onSuccess();
      }
    });
  }

  initCalendarConfig() {
    /* config object */
    this.uiConfig = {
      editable: false,
      eventOverlap: false,
      slotEventOverlap: false,
      slotDuration: "00:15:00",
      snapDuration: "00:15:00",
      header: {
        left: "prev, title, next",
        center: "",
        right: "month, agendaWeek",
      },
      aspectRatio: 1.25,
      allDaySlot: false,
      timeFormat: "h(:mm)a",
      eventLimit: 4,
      views: {
        agenda: {
          eventLimit: false,
        },
      },
      dayClick: this.calendarDayClick.bind(this),
      eventRender: this.calendarEventRender.bind(this),
      eventClick: this.calendarEventClick.bind(this),
      eventLimitClick: this.calendarEventLimitClick.bind(this),
      eventAfterAllRender: this.calendarAfterEventsRender.bind(this),
    };
  }
  initCalendarEvents() {
    this.$timeout(() => {
      angular.element("#gamerCal").fullCalendar({
        ...this.uiConfig,
        eventSources: [
          {
            url: "/api/web/calendar_based_lessons",
            method: "GET",
            startParam: "starts_at_from_date",
            endParam: "starts_at_end_date",
            data: {
              timezone: this.timeZone,
            },
            success: this.processCalendarData.bind(this),
            error: this.handleCalendarError.bind(this),
          },
        ],
      });
    });
  }

  // Calendar functionality
  calendarEventRender(event, element, view) {
    (element[0].nodeName === "A" ? element : element.find("a")).attr(
      "href",
      "#"
    );
    if (event.start.isBefore(moment())) {
      element.addClass("evt-past");
    }
    if (
      event.status === "gamer_cancelled" ||
      event.status === "sensei_rejected"
    ) {
      element.addClass("evt-cancelled");
    } else if (
      event.singleChangeRequest ||
      event.recurringChangeRequest ||
      event.status === "request_expired"
    ) {
      element.addClass("evt-actionable");
    } else if (
      event.status === "pre_awaiting_direct_booking_schedule_confirm" ||
      event.status === "awaiting_direct_booking_schedule_confirm" ||
      event.status === "sensei_proposed_lesson"
    ) {
      element.addClass("evt-pre");
    }
  }
  calendarAfterEventsRender(view) {
    if (this.loadingLessons) {
      this.loadingLessons = false;
    }
  }

  calendarEventClick(event, jsEvent, view) {
    this.dayEventClick(event.start, jsEvent, view);
  }
  calendarEventLimitClick(cellInfo, jsEvent) {
    this.dayEventClick(cellInfo.date, jsEvent);
  }
  calendarDayClick(date, jsEvent, view) {
    this.dayEventClick(date, jsEvent, view);
  }
  dayEventClick(date, jsEvent, view) {
    const clickedDate = date.format("YYYY-MM-DD");
    const clickedStartDate = moment(clickedDate, "YYYY-MM-DD")
      .subtract(1, "day")
      .format("YYYY-MM-DD");
    const clickedEndDate = moment(clickedDate, "YYYY-MM-DD")
      .add(1, "day")
      .format("YYYY-MM-DD");

    this.calendarModal.date = moment(clickedDate, "YYYY-MM-DD").format(
      "dddd, MMMM Do, YYYY"
    );
    this.calendarModal.filterDate = moment(clickedDate, "YYYY-MM-DD").format(
      "YYYY-MM-DD"
    );
    this.calendarModal.lessons = [];

    if (this.dateHasEvent(date)) {
      this.calendarModal = {
        ...this.calendarModal,
        openedModal: true,
        noLessons: false,
        timezone: moment.tz.zone(this.timeZone).abbr(date.format("x")),
      };

      this.getCalendarLessons(clickedStartDate, clickedEndDate)
        .then((res) => {
          const lessonResults = Object.entries(
            res.data.sensei_training_requests
          )
            .map((data, key) => {
              const newData = data[1].map((obj, index) => {
                return { ...obj, date: `${data[0]} ${obj.start_time}` };
              });

              return newData;
            })
            .flat(1);

          const trainingRequests = lessonResults.filter((item, index) => {
            const localDate = moment.utc(item.date, "YYYY-MM-DD HH:mm").local();

            return localDate.isSame(moment(clickedDate, "YYYY-MM-DD"), "day");
          });
          this.todaysSenseiTrainingRequests = trainingRequests;

          angular.forEach(this.todaysSenseiTrainingRequests, (lesson, i) => {
            const lessonDetails = detailedCalendarEventObject(
              date,
              lesson,
              this.role,
              clickedDate,
              this.todaysSenseiTrainingRequests[i]
            );

            this.calendarModal.lessons.push(lessonDetails);
          });
        })
        .catch(angular.noop);
    } else {
      if (date.isSameOrAfter(moment(), "day")) {
        this.calendarModal.openedModal = true;
        this.calendarModal.noLessons = true;
      } else {
        this.calendarModal.openedModal = false;
        this.calendarModal.noLessons = false;
      }
    }
  }
  processCalendarData(data) {
    this.senseiTrainingRequests = data.sensei_training_requests;
    return this.transformToCalendarEvents(this.senseiTrainingRequests);
  }
  handleCalendarError(err) {
    if (this.environment === "development") console.error(err);
  }
  transformToCalendarEvents(trainingRequests) {
    const events = [];

    angular.forEach(trainingRequests, (lessonsOnDate, onDate) => {
      angular.forEach(lessonsOnDate, (lesson, i) => {
        const obj = calendarEventObject(
          lesson,
          this.role,
          onDate,
          this.calendarBasedLessons
        );

        events.push(...[obj]);
      });
    });

    return events;
  }

  // actions
  goTo(link) {
    window.location = "/senseis/" + link;
  }

  // internal methods
  dateHasEvent(date) {
    // check if this day has an event before
    let allEvents = [];
    allEvents = angular.element("#gamerCal").fullCalendar("clientEvents");

    const event = allEvents.filter((ev) => {
      const dateFormat = date.format("MM-DD-YYYY");
      const eventStart = ev.start.format("MM-DD-YYYY");

      return eventStart === dateFormat;
    });

    return event.length > 0;
  }

  getCalendarLessons(start, end) {
    const calendarParams = {
      params: {
        starts_at_from_date: start,
        starts_at_end_date: end,
        timezone: this.timeZone,
      },
    };
    return this.calendarBasedLessons.getLessons(calendarParams);
  }

  // browser updates
  onResize($window) {
    if (this.width !== $window.innerWidth) {
      this.width = $window.innerWidth;
      this.$scope.$digest();
    }
  }
  cleanUp($window) {
    angular.element($window).off("resize", this.onResize($window));
  }
}

GamerLessonsCalendarController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$window",
  "$anchorScroll",
  "$cookies",
  "CalendarBasedLessons",
  "User",
  "SenseiTrainingRequestManager",
  "SenseiTrainingRequestAPIService",
];
