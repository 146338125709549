import contactImage from "../../images/sections/gs_customer_service.png";
import { communicationIcon } from "../../config/icons/iconConfig";

export default class ContactPageController {
  constructor(MetaTagsService) {
    this.communicationIcon = communicationIcon;
    this.contactImage = contactImage;
    this.contactFAQ = [
      {
        question: "HOW DO I USE A PROMO CODE?",
        answer:
          "<p>If you have a promo code, you can use it when checking out while buying lessons with a coach.</p><p>When you get to the checkout page (after you select hours with a coach and proceed), you will see on the right side by the total there is a selection that says *Apply Discount Code* That's where you can enter your promo that you have.</p>",
      },
      {
        question: "HOW DO I BOOK A LESSON?",
        answer:
          '<p>Once you have purchased hours with your Sensei, you can click on your "Unscheduled Hours" tab on the left hand of your dashboard when signed in. Then click the green "schedule" button and you will be taken to your sensei\'s availability calendar where you can schedule your lesson.</p><p>The timezones shown on the coach profiles and calendars are automatically set to your browser timezone, so they should be in your local timezone!</p>',
      },
      {
        question:
          "WHAT TIMEZONE IS SHOWN ON THE SITE / WHAT IS MY COACH'S AVAILABILITY?",
        answer:
          "<p>Our site automatically displays in the timezone tied to your browser, so your local timezone!</p><p>On each coach profile, you can view their general availability in your local timezone. Once you buy hours with that coach and proceed to scheduling your hours, you will then be taken to a calendar view where you can book your lessons directly on the coach's calendar, again in your local timezone!</p>",
      },
      {
        question: "HOW DO I CANCEL A LESSON?",
        answer:
          "<p>Customers can manage their bookings via their List View tab. If a coach proposes an alternate time for the lesson or has not responded to the lesson request in time, customers have the option to propose a new time for the lesson or cancel the request, returning their session to their Book a Session tab.</p><p>Please also feel free to contact your coach in advance of booking your lesson to discuss times that work well for both of your schedules.</p><p>A coach's contact information is listed during the booking process and shows on the List View.</p><p>Please contact Support if you need help connecting with your coach or have additional questions about how to cancel your lesson.</p>",
      },
      {
        question: "HOW DO I CONTACT A COACH?",
        answer:
          '<p>If you\'d like to reach out to a coach before your lesson starts, you can find their contact information displayed on their profile page.</p><p>Your selected coach\'s contact information is displayed during the scheduling process after you select a time for your lesson.</p><p>It can also be found on your List View tab after you have proposed a time for your lesson, and you can find our sensei in our Discord server: <a href="https://discord.gg/bxSKTbcWRs" target="_blank" title="Gamer Sensei Discord Server">https://discord.gg/bxSKTbcWRs</a></p>',
      },
      {
        question: "REFUNDS AND PURCHASE EXPIRATION POLICY",
        answer:
          "<p>Purchases generally expire 6 months after being acquired if they are never used. The expiration date is listed under your Purchased Sessions tab. Before a session expires, you can request a Payment Refund back to your original payment method through contacting Support or instead opt for a Credit Refund to switch out your purchases for Gamer Sensei credits.</p><p> Payment refunds take anywhere from 7-10 business days to process and credit refunds are instant. Gamer Sensei credit balances are not refundable back to your original payment method. Once a credit refund has been selected, the value will no longer be eligible for a refund back to the original payment method.</p><p>If you have any Gamer Sensei credits on your account, they will automatically be applied to your next purchase during the checkout flow. Any amount of the transaction that is not covered by your credit balance will need to be charged to a payment method.</p>",
      },
      {
        question: "REFUND POLICY FOR MISSED LESSON (CUSTOMER NO SHOW)",
        answer:
          "<p>If a customer confirms a lesson with their coach, fails to cancel it within 24 hours and does not show up to the confirmed lesson, they will not be eligible for a refund of their session. To avoid these circumstances, please establish contact with your coach in advance of your lesson.</p>",
      },
      {
        question: "REFUND POLICY FOR MISSED LESSON (COACH NO SHOW)",
        answer:
          "<p>If your coach does not show up for the lesson and you can prove that you attempted to reach out to your coach in advance of the lesson, please contact support and your session will be returned to your Book a Session tab.</p><p>Please feel free to contact Support with any questions or concerns regarding Refunds and Token expiration policy.</p>",
      },
    ];

    this.initMetaTags(MetaTagsService);
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "Contact | Gamer Sensei";
    const metaDescription =
      "Get in touch with us, the world's premier esports coaching platform and community!";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }
}

ContactPageController.$inject = ["MetaTagsService"];
