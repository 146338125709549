import howItWorksImg from "../../images/sections/gs_how_it_works.jpg";
import corsairKeyboardBgImg from "../../images/backgrounds/hs80_rgb_wireless_black_69";
import wave3BgImg from "../../images/backgrounds/wave_3_lifestyle_shot_14";
import small7088Img from "../../images/backgrounds/smal7088";
import headerAssessmentsImg from "../../images/backgrounds/home_header_assessments.jpg";
import headerAssessmentsWebP from "../../images/backgrounds/home_header_assessments.webp";
import defaultBg from "../../images/backgrounds/default";
import {
  checkListIcon,
  communicationIcon,
  teamIcon,
} from "../../config/icons/iconConfig";

export default class HowItWorksPageController {
  constructor(MetaTagsService, EventTrackingManager) {
    this.howItWorksImg = howItWorksImg;
    this.corsairKeyboardBgImg = corsairKeyboardBgImg;
    this.wave3BgImg = wave3BgImg;
    this.small7088Img = small7088Img;
    this.headerAssessmentsImg = headerAssessmentsImg;
    this.headerAssessmentsWebP = headerAssessmentsWebP;
    this.defaultBg = defaultBg;
    this.icons = { checkListIcon, communicationIcon, teamIcon };
    this.benefits = {
      title: `<h2 class="h1 med text-light">Benefits of coaching</h1>`,
      body: `<div class="text-large"><p>No matter where you are in your personal esports journey, our Sensei can help you push past plateaus and guide you to success. They're experienced professionals who can identify your personal player profile and, through a combination of assessments and customized lesson plans, set you on a course to achieve your goals.</p><p>Even after just one lesson you'll immediately see the value of coaching and notice a difference in your gameplay.</p></div>`,
    };

    this.initMetaTags(MetaTagsService);

    angular.element(() => this.initPage(EventTrackingManager));
  }

  initMetaTags(MetaTagsService) {
    const metaTitle = "How It Works | Gamer Sensei";
    const metaDescription =
      "Step into a one-on-one personalized in-game coaching session designed to develop and improve your skills.";
    MetaTagsService.setTags({
      title: metaTitle,
      "og:title": metaTitle,
      "twitter:title": metaTitle,
      description: metaDescription,
      "og:description": metaDescription,
      "twitter:description": metaDescription,
    });
  }

  initPage(EventTracking) {
    EventTracking.pushViewEvent("How it Works");
  }
}

HowItWorksPageController.$inject = ["MetaTagsService", "EventTrackingManager"];
