export default function accessControlService(
  $http,
  $cookies,
  $injector,
  OmnibarPointer
) {
  const service = {
    // Minimum eight characters
    // at least one uppercase letter
    // one lowercase letter
    // one number
    // and one special character
    passPattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
    v2SiteKey: "6Lc2z8MUAAAAAKFYfJtF-kH4h_YfU_DPhpPkDrbr",
    invisibleSiteKey: "6LdmCcQUAAAAAH8xlSiHlsVPnB-KTMxu24hLTqo9",
    age: {
      month: null,
      year: null,
    },
    config: {
      role: null,
      isSignUp: false,
      formSubmit: false,
      referrer: null,
      registrationCallback: null,
      isLoginCaptchaOpen: false,
      get: getConfig,
      set: setConfig,
    },
    recaptcha: {
      error: false,
      token: null,
      getToken: getRecaptchaToken,
      setToken: setRecaptchaToken,
      setError: setRecaptchaError,
      complete: setRecaptchaResults,
      init: initRecaptchaSetup,
    },
    userParams: {
      email: "",
      password: "",
      username: "",
      agreeTerms: false,
      agreeNotifications: false,
      collegeNotification: false,
    },
    getAge,
    setAge,
    getUserParams,
    setUserParams,
    registerGamer: registerGamer,
    registerSensei: registerSensei,
    userRegistration,
    registrationComplete: registrationComplete,
    login: login,
    captcha: captcha,
    internalCaptcha: internalCaptcha,
    terms: terms,
    confirmAge: confirmAge,
    gameKitSubId: gameKitSubId,
    grabSignupAttribution: grabSignupAttribution,
    grabReferrer: grabReferrer,
    areAnyRequiredCharactersMissing: areAnyRequiredCharactersMissing,
    getResRole: getResRole,
    getRecaptchaID: getRecaptchaID,
    getPast100Years: getPast100Years,
    getMonths: getMonths,
    getBirthdayFormat: getBirthdayFormat,
    isValidBirthday: isValidBirthday,
  };

  return service;

  ///////////
  function getAge(type) {
    return service.age[type];
  }
  function setAge(type, value) {
    service.age[type] = value;
  }
  // Profile Setup
  function getUserParams(type) {
    return service.userParams[type];
  }
  function setUserParams(type, value) {
    service.userParams[type] = value;
  }
  // Config
  function getConfig(type) {
    return service.config[type];
  }
  function setConfig(type, value) {
    service.config[type] = value;
  }
  // Recaptcha
  function getRecaptchaToken() {
    return service.recaptcha.token;
  }
  function setRecaptchaToken(token) {
    service.recaptcha.token = token;
  }
  function setRecaptchaError() {
    service.recaptcha.error = true;
  }
  function setRecaptchaResults(token) {
    service.recaptcha.token = token;

    if (service.config.get("isSignUp")) {
      service.registrationComplete();
    }
  }
  function initRecaptchaSetup() {
    const $window = $injector.get("$window");
    const $rootScope = $injector.get("$rootScope");

    const watchCaptcha = $rootScope.$watch(
      function () {
        return $window.grecaptcha;
      },
      function (_newVal) {
        if (
          $window.grecaptcha &&
          typeof $window.grecaptcha.render === "function"
        ) {
          grecaptcha.render("recaptcha-check-signup", {
            sitekey: service.invisibleSiteKey,
            callback: $window.recaptchaResults,
            "error-callback": $window.recaptchaSetupError,
            size: "invisible",
            theme: "dark",
          });
          watchCaptcha();
        }
      }
    );
  }
  // Register functions
  function registerGamer(data) {
    return $http.post("/api/web/gamer_registrations", data);
  }
  function registerSensei(data) {
    return $http.post("/api/web/sensei_registrations", data);
  }
  function userRegistration(event, gigya, role, callbacks) {
    if (event) event.preventDefault();

    const $window = $injector.get("$window");
    const $rootScope = $injector.get("$rootScope");

    service.config.set("role", role);
    service.config.set("registrationCallback", callbacks?.onSuccess);
    service.config.set("formSubmit", true);

    if (!service.getUserParams("agreeTerms")) {
      OmnibarPointer.pushErrorByMessage(
        "You must agree to out terms and privacy to sign up."
      );
      service.config.set("formSubmit", false);
      return;
    }

    if (
      service.config.get("isSignUp") &&
      !service.isValidBirthday() &&
      !service.getUserParams("parentalConsent")
    ) {
      $rootScope.$broadcast("openAgeGate", {
        birthday: new Date(getAge("year"), getAge("month")),
        isSignUp: true,
      });
      service.config.set("formSubmit", false);
      return;
    }

    if (
      service.areAnyRequiredCharactersMissing(service.getUserParams("password"))
    ) {
      OmnibarPointer.pushErrorByMessage(
        "Your password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters"
      );
      service.config.set("formSubmit", false);
      return;
    }

    if (service.recaptcha.error || !gigya) {
      service.registrationComplete();
    } else {
      $window.grecaptcha.execute();
    }
  }

  function registrationComplete() {
    const postParams = {
      user: {
        email: service.getUserParams("email"),
        password: service.getUserParams("password"),
        screen_name: service.getUserParams("username"),
        general_communication_opt_in:
          service.getUserParams("agreeNotifications"),
        scholarship_info_opt_in: service.getUserParams("collegeNotification"),
        agree_to_terms: service.getUserParams("agreeTerms"),
        age_verified:
          service.isValidBirthday() ||
          (!service.isValidBirthday() &&
            service.getUserParams("parentalConsent")),
        is_minor: !service.isValidBirthday(),
        birth_date: service.getBirthdayFormat(),
      },
      signup_attribution: {
        attributed_to: service.grabSignupAttribution("organic"),
        referrer: service.grabReferrer(service.config.get("referrer")),
      },
    };

    if (service.getUserParams("parentalConsent")) {
      postParams.user.parental_consent =
        service.getUserParams("parentalConsent");
      postParams.user.is_minor = service.getUserParams("isMinor");
    }

    if (service.recaptcha.getToken()) {
      postParams.captcha = service.recaptcha.getToken();
    }

    service
      .registerGamer(postParams)
      .then((res) => {
        const callback = service.config.registrationCallback;

        if (callback) {
          callback(res, postParams);
        }
      })
      .catch((err) => {
        service.config.set("formSubmit", false);

        if (service.recaptcha.getToken()) grecaptcha.reset();
        if (err.data.errorCode) {
          OmnibarPointer.pushErrorByMessage(
            `${err.data.errorCode}: ${err.data.errorMessage}`
          );
        } else {
          OmnibarPointer.pushErrorByHash(err.data.errors);
        }
      });
  }

  function login(callbacks) {
    const $window = $injector.get("$window");
    const $rootScope = $injector.get("$rootScope");

    service.config.set("formSubmit", true);

    const postParams = {
      user: {
        email: service.getUserParams("email"),
        password: service.getUserParams("password"),
      },
    };

    if (service.recaptcha.getToken()) {
      postParams.captcha = service.recaptcha.getToken();
    }

    $http
      .post("/users/sign_in", postParams)
      .then((res) => {
        if (callbacks.onSuccess) callbacks.onSuccess(res.data.user);
      })
      .catch((err) => {
        service.config.set("formSubmit", false);

        if (service.config.get("isLoginCaptchaOpen")) grecaptcha.reset();

        if (
          err.data &&
          err.data.errorCode === "401020" &&
          !service.config.get("isLoginCaptchaOpen")
        ) {
          // captcha required
          grecaptcha.render("recaptcha-check-login", {
            sitekey: service.v2SiteKey,
            callback: $window.recaptchaResults,
            theme: "dark",
          });
          service.config.set("isLoginCaptchaOpen", true);
        } else if (err.data && err.data.errorCode === "206001") {
          if (callbacks.getTerms) callbacks.getTerms();
        } else if (err.data && err.data.errorCode === "206006") {
          if (callbacks.getVerification) callbacks.getVerification();
        } else {
          OmnibarPointer.pushErrorByMessage(
            "Oops! Something went wrong. Please check your username and password and try again."
          );
        }
      });
  }

  // TODO: are these used anymore?
  function captcha(data) {
    return $http.post("/api/web/gigya/captcha", data);
  }

  function internalCaptcha(data) {
    return $http.post("/api/web/verify_captcha", data);
  }

  function terms(data) {
    return $http.put("/api/web/gigya/update_terms", {
      ...data,
      terms: true,
      privacy: true,
    });
  }

  function confirmAge(data) {
    return $http.post("/api/web/gigya/parental_consent", data);
  }

  function gameKitSubId() {
    return $cookies.get("gamekitsubid");
  }

  function grabSignupAttribution(attribution) {
    if ($cookies.get("gamekitsubid")) {
      return "gamekit";
    }

    return attribution;
  }

  function grabReferrer(referrer) {
    if ($cookies.get("gamekitsubid")) {
      return $cookies.get("gamekitsubid");
    }

    return referrer;
  }

  function areAnyRequiredCharactersMissing(str) {
    // If string is not empty and 8 or more characters
    if (service.passPattern.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  function getResRole(user) {
    let userRole = "gamer";

    if (user.sensei) {
      userRole = "sensei";
    }

    return userRole;
  }

  function getRecaptchaID(containerID) {
    const returnNone = 0;
    const captchas = document.querySelectorAll("g-recaptcha");

    for (let i = 0; i < captchas.length; i++) {
      if (captchas[i].id === containerID) {
        return i;
      }
    }

    return returnNone;
  }

  function getPast100Years() {
    var today = new Date();
    var year = today.getYear() + 1900;
    var past100Years = [year];
    for (let i = 1; i <= 100; i++) {
      past100Years.push(year - i);
    }
    return past100Years;
  }

  function getMonths() {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  function isValidBirthday() {
    const today = new Date();
    const birthday = new Date(getAge("year"), getAge("month"));

    const validBirthday = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate() - 1
    );

    return validBirthday > birthday;
  }

  function getBirthdayFormat() {
    const birthday = new Date(getAge("year"), getAge("month"));
    const offset = birthday.getTimezoneOffset();

    const localDate = new Date(birthday.getTime() - offset * 60 * 1000);

    return localDate.toISOString().split("T")[0];
  }
}

accessControlService.$inject = [
  "$http",
  "$cookies",
  "$injector",
  "OmnibarPointer",
];
