import moment from "moment-timezone";

export default class SenseiLessonsStudentHistoryController {
  constructor(
    $scope,
    $state,
    $stateParams,
    User,
    StudentHistoryService,
    TokenBookingService,
    OmnibarPointer
  ) {
    this.userId = $scope.currentId;
    // angular providers
    this.state = $state;
    // services
    this.userService = User;
    this.history = StudentHistoryService;
    this.tokenBooking = TokenBookingService;
    this.omnibarPointer = OmnibarPointer;
    // strings
    this.studentName = null;
    // objects and arrays
    this.proposalModal = {};
    this.buttonActions = [
      {
        text: "Offer Session",
        click: (data) => this.startNewSessionProposal(data),
        classes: "btn btn-green btn-inline-input",
      },
    ];

    if ($stateParams.studentName) {
      this.studentName = $stateParams.studentName;
    }

    this.initUsers({
      onSuccess: (userData) => {
        const gameLabel = userData.sensei.games[0].in_game_id_label;

        this.studentTableHeader = {
          recentLessonCompletionDate: {
            title: "Last Lesson",
            isActive: true,
          },
          studentScreenName: {
            title: "Screen Name",
          },
          inGameName: {
            title: gameLabel,
          },
          discord: {
            title: "Discord Name",
          },
          buttons: {
            title: "",
            isHtml: true,
          },
        };
      },
    });

    this.history.setStudents(this.studentName);
  }

  // init methods
  initUsers(callbacks) {
    this.userService.setCurrentId(this.userId);
    this.userService.setRole("sensei");

    this.userService
      .getUser(true)
      .then((userData) => {
        this.currentUser = userData;
        this.tokenBooking.setSenseiGame(this.currentUser.sensei.games[0]);
        this.tokenBooking.setSensei(this.currentUser.sensei);

        if (callbacks?.onSuccess) callbacks.onSuccess(userData);
      })
      .catch(angular.noop);
  }

  // display methods
  isHistoryLoading() {
    return this.history.loading;
  }

  startFrom() {
    return this.history.getCurrentPage() * this.history.getPageSize();
  }

  isNextDisabled() {
    this.history.getCurrentPage() >=
      this.history.getStudentTotal() / this.history.getPageSize() - 1;
  }

  isPrevDisabled() {
    return this.history.getCurrentPage() === 0;
  }

  completedFetchAndHasStudents() {
    return this.history.getFetchStatus() && !this.history.areStudentsEmpty();
  }

  completedFetchAndNoStudents() {
    return this.history.getFetchStatus() && this.history.areStudentsEmpty();
  }

  // action methods
  startNewSessionProposal(data) {
    this.proposalModal = data;
  }

  filterBy() {
    this.state.go("sensei_dashboard.lessons.student_history", {
      studentName: this.studentName,
    });
  }

  changeCurrentPage(change) {
    const current = this.history.getCurrentPage();

    this.history.setCurrentPage(current + change);
  }

  // diretive methods
  onProposalSuccess(student) {
    const omniTooltipParams = {
      title: "Success!",
      body: `<p>You have sent a session request to ${student}</p>`,
    };
    this.omnibarPointer.pushTooltip(omniTooltipParams, {
      tooltipCallback: false,
      persist: true,
      timer: true,
    });

    this.state.go("sensei_dashboard.lessons.list", { reload: true });
  }

  onProposalFail(errors) {
    this.omnibarPointer.pushErrorByHash(errors);
  }

  setHistoryLoader(status) {
    this.history.setLoadStatus(status);
  }
}

SenseiLessonsStudentHistoryController.$inject = [
  "$scope",
  "$state",
  "$stateParams",
  "User",
  "StudentHistoryService",
  "TokenBookingService",
  "OmnibarPointer",
];
