export default class CheckoutAccessController {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    $location,
    OmnibarPointer,
    CartManager,
    AccessControl,
    User,
    EventTrackingManager
  ) {
    this.isGigyaOn = $scope.isGigyaOn;
    // angular providers
    this.root = $rootScope;
    this.$timeout = $timeout;
    this.location = $location;
    // services
    this.cart = CartManager;
    this.omnibarPointer = OmnibarPointer;
    this.eventTracking = EventTrackingManager;
    this.userService = User;
    this.accessControl = AccessControl;
    // booleans
    this.isSignUp = true;

    angular.element(() => {
      this.initEvents();
    });
  }

  // init methods
  initEvents() {
    this.eventTracking.pushViewEvent("Checkout");
    this.eventTracking.pushCheckoutDetailsV2(
      "checkoutOption",
      this.cart.getCartItems(),
      this.cart.getPaymentDetails(),
      1,
      "Account Information"
    );
  }

  // switch between sign in and sign up forms
  switchForm() {
    this.eventTracking.pushEventOnly(
      this.isSignUp ? "accountLoginOpen" : "accountRegistrationOpen"
    );
    this.isSignUp = !this.isSignUp;
  }

  // actions
  finishLogin(newUser, role) {
    this.updateUser(newUser.id);
  }

  finishRegister(newUser) {
    this.updateUser(newUser.id);
  }

  // load user
  updateUser(userId) {
    // If gamer detected, passes user to payment
    this.root.currentId = userId;
    this.userService.setCurrentId(userId);
    this.userService.setRole("gamer");
    this.userService
      .getUser(true)
      .then((_userData) => {
        if (this.location.search() && this.location.search().ref) {
          this.location.url(
            `/checkout/payment?ref=${this.location.search().ref}`
          );
        } else {
          this.location.url("/checkout/payment");
        }
      })
      .catch(angular.noop);
  }
}

CheckoutAccessController.$inject = [
  "$scope",
  "$rootScope",
  "$timeout",
  "$location",
  "OmnibarPointer",
  "CartManager",
  "AccessControl",
  "User",
  "EventTrackingManager",
];
