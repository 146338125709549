export default function senseiTrainingRequestHolderService(
  SenseiTrainingRequest
) {
  function ArrayConstructor() {
    const array = [];

    array.clear = function () {
      this.length = 0;
    };
    array.addOrUpdateByRequest = function (_request, perspective) {
      this.addOrUpdate(new SenseiTrainingRequest(_request, perspective));
      return this;
    };
    array.addOrUpdate = function (_senseiTrainingRequest) {
      var currentLesson = this.getLessonById(_senseiTrainingRequest.id);
      if (currentLesson !== undefined)
        currentLesson = angular.extend(currentLesson, _senseiTrainingRequest);
      else this.push(_senseiTrainingRequest);
      return this;
    };
    array.updateByRequests = function (_requests, perspective, pagenumber) {
      const results = {
        actionableDeleted: false,
        actionableAdded: [],
      };

      // Delete
      var lessonsLength = this.length;
      for (let i = lessonsLength - 1; i >= 0; i--) {
        var actionableLesson = _requests
          .map(function (x) {
            return x.id;
          })
          .indexOf(this[i].id);
        if (actionableLesson === -1) {
          this.splice(i, 1);
          results.actionableDeleted = true;
        }
      }
      // Add or Move/Update
      var retrivedActionableLessonsLength = _requests.length;
      for (
        let k = 0;
        k < retrivedActionableLessonsLength && k < pagenumber * 10;
        k++
      ) {
        var _retrivedActionableLesson = _requests[k];
        var currentLesson = this.getLessonById(_retrivedActionableLesson.id);
        if (!currentLesson) {
          if (k < pagenumber * 10) {
            var new_sensei_training_request = new SenseiTrainingRequest(
              _retrivedActionableLesson,
              perspective
            );
            this.insert(k, new_sensei_training_request);
            results.actionableAdded.push(new_sensei_training_request);
          }
        } else {
          this.move(this.indexOf(currentLesson), k);
          currentLesson.update(_retrivedActionableLesson, perspective);
        }
      }
      // Clean up
      if (this.length > pagenumber * 10) this.length = pagenumber * 10;
      return results;
    };
    array.getLessonById = function (_id) {
      var index = this.map(function (x) {
        return x.id;
      }).indexOf(_id);
      return this[index];
    };
    array.removeByRequest = function (_senseiTrainingRequest) {
      if (_senseiTrainingRequest === undefined) return;
      var removeLesson = this.getLessonById(_senseiTrainingRequest.id);
      if (removeLesson) this.splice(this.indexOf(removeLesson), 1);
      return this;
    };
    array.insert = function (index, item) {
      this.splice(index, 0, item);
      return this;
    };
    array.move = function (old_index, new_index) {
      if (old_index === new_index) return undefined;
      if (new_index >= this.length) {
        var k = new_index - this.length;
        while (k > 0) {
          this.push(undefined);
          k--;
        }
      }
      this.splice(new_index, 0, this.splice(old_index, 1)[0]);
      return this;
    };
    return array;
  }

  return ArrayConstructor;
}

senseiTrainingRequestHolderService.$inject = ["SenseiTrainingRequest"];
