import amazonPayIcon from "./library/payment/amazon-pay";
import calendarIcon from "./library/calendar";
import cartIcon from "./library/cart";
import checkIcon from "./library/check";
import checkListIcon from "./library/checklist";
import communicationIcon from "./library/communication";
import communityIcon from "./library/community";
import copyIcon from "./library/copy";
import corsairIcon from "./library/corsair/corsair-logo";
import creditCardIcon from "./library/payment/credit-card";
import discordIcon from "./library/discord";
import dollarSignIcon from "./library/dollar-sign";
import elGatoIcon from "./library/corsair/elgato-logo";
import eyeHideIcon from "./library/eye-hide";
import eyeShowIcon from "./library/eye-show";
import facebookIcon from "./library/facebook";
import fastTimeIcon from "./library/fast-time";
import filterIcon from "./library/filter";
import gamerSenseiIcon from "./library/gamer-sensei-icon";
import gearIcon from "./library/gear";
import hamburgerIcon from "./library/hamburger";
import happyIcon from "./library/happy";
import infoIcon from "./library/info";
import instagramIcon from "./library/instagram";
import loadingIcon from "./library/loading";
import messageIcon from "./library/message";
import nextIcon from "./library/next";
import originIcon from "./library/corsair/origin-logo";
import pauseIcon from "./library/pause";
import paypalIcon from "./library/payment/paypal";
import playIcon from "./library/play";
import scuffIcon from "./library/corsair/scuff-logo";
import seminarIcon from "./library/seminar";
import settingsIcon from "./library/settings";
import shieldIcon from "./library/shield";
import skrillIcon from "./library/payment/skrill";
import skypeIcon from "./library/skype";
import starIcon from "./library/star";
import subscribeIcon from "./library/subscribe";
import supportIcon from "./library/support";
import teamIcon from "./library/team";
import thumbsUpIcon from "./library/thumbs-up";
import trophyIcon from "./library/trophy";
import twitterIcon from "./library/twitter";
import verifiedIcon from "./library/verified";
import walletIcon from "./library/wallet";
import workshopIcon from "./library/workshop";

const getCommunicationMediumIcon = (medium) => {
  return medium === "discord" ? discordIcon : skypeIcon;
};

export {
  amazonPayIcon,
  calendarIcon,
  cartIcon,
  checkIcon,
  checkListIcon,
  communicationIcon,
  communityIcon,
  copyIcon,
  corsairIcon,
  creditCardIcon,
  discordIcon,
  dollarSignIcon,
  elGatoIcon,
  eyeHideIcon,
  eyeShowIcon,
  facebookIcon,
  fastTimeIcon,
  filterIcon,
  gamerSenseiIcon,
  gearIcon,
  hamburgerIcon,
  happyIcon,
  infoIcon,
  instagramIcon,
  loadingIcon,
  messageIcon,
  nextIcon,
  originIcon,
  pauseIcon,
  paypalIcon,
  playIcon,
  scuffIcon,
  seminarIcon,
  settingsIcon,
  shieldIcon,
  skrillIcon,
  skypeIcon,
  starIcon,
  subscribeIcon,
  supportIcon,
  teamIcon,
  thumbsUpIcon,
  trophyIcon,
  twitterIcon,
  verifiedIcon,
  walletIcon,
  workshopIcon,
  getCommunicationMediumIcon,
};
