export default function notificationManagerService($q, $http) {
  var openNotificationEndpoint = "/api/web/open_notifications";
  var manager = {
    notifications: {
      lessons: 0,
      notes: 0,
      chats: 0,
    },
    getUnreadActionableLessons,
    getUnreadSenseiNotes,
    getUnreadSenseiChats,
    getNotifications,
    setNotifications,
    setActionableLessonToRead,
    setSenseiNotesToRead,
    setSenseiChatsToRead,
    setAllNotificationsToRead,
  };

  return manager;

  // Service Functions
  function getUnreadActionableLessons() {
    return manager.notifications.lessons;
  }

  function getUnreadSenseiNotes() {
    return manager.notifications.notes;
  }

  function getUnreadSenseiChats() {
    return manager.notifications.chats;
  }

  function getNotifications() {
    return manager.notifications;
  }

  function setNotifications() {
    return $http.get(openNotificationEndpoint).then((res) => {
      var counts = res.data;

      if (
        counts.unviewed_actionable_lessons_count !==
        manager.notifications.lessons
      ) {
        manager.notifications.lessons =
          counts.unviewed_actionable_lessons_count;
      }

      if (counts.unread_sensei_notes_count !== manager.notifications.notes) {
        manager.notifications.notes = counts.unread_sensei_notes_count;
      }

      if (counts.unread_sensei_chats_count !== manager.notifications.chats) {
        manager.notifications.chats = counts.unread_sensei_chats_count;
      }
    });
  }

  function setActionableLessonToRead(userId) {
    return $http
      .put(`/api/web/users/${userId}/dismiss_unviewed_actionable_lessons`)
      .then(function (_res) {
        manager.notifications.lessons = 0;
      });
  }

  function setSenseiNotesToRead(lessonId) {
    return $http
      .put(`/api/web/sensei_training_requests/${lessonId}/sensei_notes_views`)
      .then(function (_res) {
        manager.notifications.notes =
          manager.notifications.notes <= 0
            ? 0
            : manager.notifications.notes - 1;
      });
  }

  function setSenseiChatsToRead(conversationId) {
    return $http
      .post(`/api/web/conversations/${conversationId}/read`)
      .then(function (_res) {
        manager.notifications.chats =
          manager.notifications.chats <= 0
            ? 0
            : manager.notifications.chats - 1;
      });
  }

  function setAllNotificationsToRead() {
    manager.setActionableLessonToRead();
    manager.setSenseiNotesToRead();
    manager.setSenseiChatsToRead();
  }
}

notificationManagerService.$inject = ["$q", "$http"];
